import React from 'react'
import { IconoFormulario } from '../../Iconos/IconoFormularios';
import { useGestionarUsuario } from '../../../hooks/useGestionarUsuario';
import { startMostrarEvaluacionIpCA } from '../../../actions/evipca';
import { startMostrarEvaluacionIpPE } from '../../../actions/evippe';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { startMostrarEvaluacionPertinencia } from '../../../actions/eppert';
import { startMostrarEvaluacionCalidad } from '../../../actions/epcal';



export const ListaIconosDeFormulario = ({ _listaFormularios = [], _tipoForm }) => {

  const dispatch = useDispatch();
  const history = useHistory();

  const {eipcaFocus} = useSelector( state => state.eipcaReducer);
  const {eippeFocus} = useSelector( state => state.eippeReducer);
  const {ppertFocus} = useSelector( state => state.eippeReducer);
  const {pcalFocus} = useSelector( state => state.eippeReducer);

  const {tipoUsuario} = useSelector( state => state.usrReducer.usrFocus);
  


  const {
      usuarioEsEstandar,
      usuarioEsAdmin,
      definirPermisoDeLecturaPorTipoUsuarioYEstadoProy
  } = useGestionarUsuario();


  const definirPermisoLecturaForm = (_ruta)=>
    definirPermisoDeLecturaPorTipoUsuarioYEstadoProy(_ruta);
    

  const obtenerEventoCargaFormulario = (e, _ruta = '' , _id = null)=>{
      e.preventDefault();
      
      if(_ruta.includes('eipca') && (!eipcaFocus)) {dispatch( startMostrarEvaluacionIpCA(_id));}
      if(_ruta.includes('eippe') && (!eippeFocus)) {dispatch( startMostrarEvaluacionIpPE(_id));}
      if(_ruta.includes('ppert') && (!ppertFocus)) {dispatch( startMostrarEvaluacionPertinencia(_id) );}
      if(_ruta.includes('pcal') && (!pcalFocus)) {dispatch( startMostrarEvaluacionCalidad(_id) );}
      
      history.push(`${_ruta}/${_id}`);
       
  }

  const definirMostrarNombre = (_ruta) => {
    if( _ruta === '/eippe' ) return true;

    if(!usuarioEsEstandar()) {
      return definirPermisoLecturaForm(_ruta);
    }
    return false;
  };

  return (
    <ul className='list-group'>
        {
          _tipoForm.includes('Pertinencia') && tipoUsuario === 'eval_ext' ? <></> : <p>{_tipoForm}</p>
        }
        {
            _listaFormularios.map( ({_id, _ruta , _nomUsr}) => {
                  
                  const permisoDeLectura = definirPermisoLecturaForm(_ruta);
               
                  const formularioEsVisible = usuarioEsAdmin() ? true : permisoDeLectura ;

                  return <IconoFormulario  key={`iconoForm-${_id}`}
                        _handleClick={obtenerEventoCargaFormulario}
                        _idForm={_id}
                        _indice={_id}
                        _leible = { permisoDeLectura }
                        _ruta={`/gestor/form${_ruta}`}
                        _visible={ formularioEsVisible  }
                        _nomUsr= { definirMostrarNombre (_ruta) ? _nomUsr : ''}
                        _mostrarNom={ formularioEsVisible }
                    />
                  }
                
                )
        }
    </ul>
  )
}
