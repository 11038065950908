import React from 'react'

export const Cronograma = ( {_cronograma = []} )=>
    _cronograma.length > 0 
    ?<ul className='list-group list-group-horizontal'>
        <li className={ `list-group-item p-1 ${_cronograma.some(mes => Number(mes) === 1) ? 'bg-primary text-light' : 'bg-none'} `}>E</li>
        <li className={ `list-group-item p-1 ${_cronograma.some(mes => Number(mes) === 2) ? 'bg-primary text-light' : 'bg-none'} `}>F</li>
        <li className={ `list-group-item p-1 ${_cronograma.some(mes => Number(mes) === 3) ? 'bg-primary text-light' : 'bg-none'} `}>M</li>
        <li className={ `list-group-item p-1 ${_cronograma.some(mes => Number(mes) === 4) ? 'bg-primary text-light' : 'bg-none'} `}>A</li>
        <li className={ `list-group-item p-1 ${_cronograma.some(mes => Number(mes) === 5) ? 'bg-primary text-light' : 'bg-none'} `}>M</li>
        <li className={ `list-group-item p-1 ${_cronograma.some(mes => Number(mes) === 6) ? 'bg-primary text-light' : 'bg-none'} `}>J</li>
        <li className={ `list-group-item p-1 ${_cronograma.some(mes => Number(mes) === 7) ? 'bg-primary text-light' : 'bg-none'} `}>J</li>
        <li className={ `list-group-item p-1 ${_cronograma.some(mes => Number(mes) === 8) ? 'bg-primary text-light' : 'bg-none'} `}>A</li>
        <li className={ `list-group-item p-1 ${_cronograma.some(mes => Number(mes) === 9) ? 'bg-primary text-light' : 'bg-none'} `}>S</li>
        <li className={ `list-group-item p-1 ${_cronograma.some(mes => Number(mes) === 10) ? 'bg-primary text-light' : 'bg-none'} `}>O</li>
        <li className={ `list-group-item p-1 ${_cronograma.some(mes => Number(mes) === 11) ? 'bg-primary text-light' : 'bg-none'} `}>N</li>
        <li className={ `list-group-item p-1 ${_cronograma.some(mes => Number(mes) === 12) ? 'bg-primary text-light' : 'bg-none'} `}>D</li>
    </ul>
    :<></>