
import { useSelector } from 'react-redux';
import { useGestionarEstados } from './useGestionarEstados';

export const useGestionarUsuario = () => {
    const {usrFocus} = useSelector( state => state.usrReducer);
    const {listaP,pFocus} = useSelector( state => state.pReducer);
    const {eipcaFocus} = useSelector(state => state.eipcaReducer);
    const {pcalFocus} = useSelector(state => state.pcalReducer);
    const {eippeFocus} = useSelector(state => state.eippeReducer);
    const {ppertFocus} = useSelector(state => state.ppertReducer);

    const  {
        instanciaEvaluacionCalTerminada,
        instanciaEvaluacionIpTerminada,
        instanciaEvaluacionPertTerminada,
        chequearEstadoRecepProyecto
    } = useGestionarEstados();
    
    const usuarioEsAdmin = ()=>usrFocus && usrFocus.tipoUsuario === 'admin';
    const usuarioEsGestion = ()=>usrFocus && usrFocus.tipoUsuario === 'gestion';
    const usuarioEsEvaluadorInterno = ()=>usrFocus && (usrFocus.tipoUsuario === 'eval_int' || usrFocus.tipoUsuario === 'eval_int_ext') ;
    const usuarioEsEvaluadorExterno = ()=>usrFocus && usrFocus.tipoUsuario === 'eval_ext';
    const usuarioEsEvaluadorProgramas =()=>usrFocus && usrFocus.tipoUsuario === 'eval_prog';
    const usuarioEsEstandar = ()=> usrFocus && usrFocus.tipoUsuario === 'estandar';
    const usuarioEsRespUUAA = ()=> usrFocus && usrFocus.tipoUsuario === 'sec_unl';
    const usuarioEsConesjoSuperior = ()=> usrFocus && usrFocus.tipoUsuario === 'cs';
    const usuarioEsGestionProgramas = ()=>usrFocus && usrFocus.tipoUsuario === 'gestion_prog';
    const usuarioHabilitadoCrearProyectos = ()=> 
        usrFocus && 
        usrFocus.puedeCrearProyectos &&
        (!usuarioEsEvaluadorExterno()) &&
        (!usuarioEsConesjoSuperior()) &&
        (!usuarioEsEvaluadorProgramas());

    const usuarioEsDueñoDelProyecto = ()=> 
        listaP && 
        pFocus &&
        listaP.listaProyectosUsuario
             ? listaP.listaProyectosUsuario.find( proy => proy.id === pFocus.id)
             : false;
             
    const evaluadorEsDueñoDeLaEvaluacion = () => {
        let idFormEval = undefined;
        if(eipcaFocus) idFormEval = eipcaFocus.id ;
        if(pcalFocus) idFormEval = pcalFocus.id ;
        if(eippeFocus) idFormEval = eippeFocus.id ;
        if(ppertFocus) idFormEval = ppertFocus.id ;
        if(idFormEval){
            return  pFocus &&
                    (usuarioEsEvaluadorInterno() || usuarioEsEvaluadorExterno() || usuarioEsEvaluadorProgramas()) &&
                    (pFocus.lFormsEval.some( _form => _form.id === idFormEval && _form.evaluador.id === usrFocus.id) );
            }
        return false;
    };
    
    const usuarioEsEvaluadorCalidad = ()=> {
        return usuarioEsEvaluadorExterno() || usuarioEsEvaluadorInterno();
    }
    const verPermisosDeLectura = ()=> usrFocus.permisos.filter( permiso => permiso.lectura)


    const definirPermisoLecturaDueñoDelProyecto = (_ruta = '')=>{
        let salida = false; 

        switch (_ruta) {
            case '/ip': salida = true;  break;
            case '/eippe': salida = instanciaEvaluacionIpTerminada(); break;
            case '/eipca_def':  salida = instanciaEvaluacionIpTerminada();  break;
            case '/p':  salida = chequearEstadoRecepProyecto();  break;
            case '/ppert_def':  salida = instanciaEvaluacionPertTerminada();   break;
            case '/pcal_def':  salida = instanciaEvaluacionCalTerminada();    break;
            case '/inf_avance':  salida = instanciaEvaluacionCalTerminada();    break;
            case '/inf_final':   salida = instanciaEvaluacionCalTerminada();   break;
            default:
                break;
        }

        return salida;
    }
    
    const definirPermisoLecturaEvaluadorInterno = (_ruta = '')=>{
        let salida = false; 

        switch (_ruta) {
            case '/ip': salida = true;  break;
            case '/eippe': salida = true; break;
            case '/eipca': salida = true; break;
            case '/eipca_def':  salida = true;  break;
            case '/p':  salida = chequearEstadoRecepProyecto();  break;
            case '/ppert': salida = true;   break;
            case '/ppert_def': salida = true;   break;
            case '/pcal':  salida = true;  break;
            case '/pcal_def':  salida = true;  break;
            default:
                break;
        }

        return salida;
    }

    const definirPermisoLecturaEvaluadorExterno = (_ruta = '')=>{
        let salida = false; 

        switch (_ruta) {
            case '/ip': salida = true;  break;
            case '/eippe': salida = true; break;
            case '/eipca_def':  salida = true;  break;
            case '/p':  salida = true;  break;
            case '/ppert_def': salida = true;   break;
            case '/pcal':  salida = true;  break;
            case '/pcal_def':  salida = true;  break;
            default:
                break;
        }

        return salida;
    }

    const definirPermisoLecturaEvaluadorProgramas = (_ruta = '')=>{
        let salida = false; 

        switch (_ruta) {
            case '/ip': salida = true;  break;
            case '/eippe': salida = true; break;
            default:
                break;
        }

        return salida;
    }
    
    const definirPermisoLecturaDeSupervision = (_ruta = '')=>{
        let salida = false; 

        switch (_ruta) {
            case '/ip': salida = true;  break;
            case '/eippe': salida = true; break;
            case '/eipca_def':  salida = true;  break;
            case '/p': salida = true;    break;
            case '/ppert_def': salida = true;   break;
            case '/pcal_def':  salida = true;  break;
            default:
                break;
        }

        return salida;
    }

    const definirPermisoDeLecturaPorTipoUsuarioYEstadoProy = (_ruta = '')=>{
        let salida = false;
        if( usuarioEsAdmin() || usuarioEsGestion() ){
            salida = true;
        }
        
        else if(usuarioEsConesjoSuperior() || usuarioEsGestionProgramas() || usuarioEsRespUUAA()){
            salida = definirPermisoLecturaDeSupervision(_ruta);
        }
        else if( usuarioEsDueñoDelProyecto() ) {
            salida = definirPermisoLecturaDueñoDelProyecto(_ruta);
        }
        else if( usuarioEsEvaluadorInterno() ){
            salida = definirPermisoLecturaEvaluadorInterno(_ruta);
        }
        else if( usuarioEsEvaluadorExterno() ){
            salida = definirPermisoLecturaEvaluadorExterno(_ruta);
        }
        else if( usuarioEsEvaluadorProgramas() ){
            salida = definirPermisoLecturaEvaluadorProgramas(_ruta);
        }
        return salida; 
    }

    return {
        definirPermisoDeLecturaPorTipoUsuarioYEstadoProy,
        verPermisosDeLectura ,
        usuarioEsGestion,
        usuarioEsAdmin,
        usuarioEsDueñoDelProyecto,
        usuarioEsEvaluadorInterno,
        usuarioEsEvaluadorExterno,
        usuarioEsEvaluadorProgramas,
        evaluadorEsDueñoDeLaEvaluacion,
        usuarioEsEstandar,
        usuarioEsRespUUAA,
        usuarioEsEvaluadorCalidad,
        usuarioHabilitadoCrearProyectos,
        usuarioEsConesjoSuperior
    }

}
