import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import {  useSelector } from 'react-redux';
import { useGestionarEstados } from '../../../hooks/useGestionarEstados';
import { Campo } from '../../Formulario/Campo';


export const FormEstadoProyecto = ({
    idEstadoFocus = null, 
    setshowFormEstado = ()=>{}
}) => {
    
    const {lTipoEstado} = useSelector( state => state.basesReducer);
    const estadosSelect = lTipoEstado ? lTipoEstado.map( tipo => ({ id: tipo.id, nom : tipo.tipoEstado})) : [];
    const {
        camposNuevoEstado,
        validacionesNuevoEstado,
        handleNuevoEstadoChange,
        validarNuevoEstado,
        editarEstadoProyecto,
        agregarEstadoProyecto
    } = useGestionarEstados(idEstadoFocus ? idEstadoFocus.current : null);


    const handleEditarEstadoProyecto = (e)=>{
        e.preventDefault();
        editarEstadoProyecto( idEstadoFocus.current );
        setshowFormEstado(false);
        idEstadoFocus.current = null;
    }


    const handleAgregarEstado = (e)=>{
        e.preventDefault();
        agregarEstadoProyecto();
    }
  return (
        <tr>
            <td className='col'>
                {new Date(Date.now()).toDateString()}
            </td>
            <td className='col'>
                <Campo 
                    type='select'
                    name='tipoEstado'
                    etiqueta='Tipo'
                    handlecampochange={handleNuevoEstadoChange}
                    handleblur={validarNuevoEstado}
                    value={camposNuevoEstado['tipoEstado']}
                    valido={validacionesNuevoEstado['tipoEstado']}
                    opciones={estadosSelect }
                    required={true}
                />
            </td>
            <td className='col'>
                <Campo 
                    type='textarea'
                    name='nuevoEstado'
                    etiqueta='Ingrese nuevo estado.(max. 1000)'
                    handlecampochange={handleNuevoEstadoChange}
                    handleblur={validarNuevoEstado}
                    value={camposNuevoEstado['nuevoEstado'] }
                    valido={validacionesNuevoEstado['nuevoEstado']}
                    maxLength={1000}
                    required={true}
                />
            </td>
            <td className='col-2'>
                
                <button className='btn btn-outline-primary'
                    title='Guadar cambios'
                    onClick={(e)=> idEstadoFocus ? handleEditarEstadoProyecto(e) :handleAgregarEstado(e)}
                > <FontAwesomeIcon icon={faSave} size={'1x'} />
                </button>

                <button className='btn btn-outline-secondary'
                    onClick={()=>{setshowFormEstado(false)}}
                > cancelar
                </button>
            </td>
        </tr>

        
     )
}
