import { mostrarAlerta } from "../helpers/mostrarAlerta";
import { types } from "../types/types";
import { abrirModalAsigProyProgExt, abrirModalComisionEvaluadora, cerrarModal } from "./ui";



export const startGetComsiones = ()=>async(dispatch)=>{

    const resp = await fetch(`${process.env.REACT_APP_API_URL}/com/all`);

    const data = await resp.json();

    if(data.ok){

        dispatch(editarComisiones( data.data))

    }else {
        alert(data.error);
    }
}
export const startGetProyAsigProgExt = ()=>async(dispatch)=>{
    const resp = await fetch(`${process.env.REACT_APP_API_URL}/com/prog/all`);

    const data = await resp.json();

    if(data.ok){

        dispatch(editarProyAsigPRogExt( data.data))

    }else {
        alert(data.error);
    }
}

export const startActualizarListaComision = (_id)=> async(dispatch,getState)=>{
    console.log(_id)
    const {listaComision,comisionFocus} = getState().comisionReducer;

    dispatch(editarComisiones(
        listaComision.map( comision => comision.id === _id ? comisionFocus : comision )
    ));

}

export const startSetFocusComision = (_id)=>async(dispatch,getState)=>{
    const {listaComision,comisionFocus} = getState().comisionReducer;

    console.log(_id)
    const comision = listaComision.find( comision => comision.id === _id);
    dispatch(editarComision(comision));
    
    if( comisionFocus &&  comision.tipo === 'PE'){
         dispatch(abrirModalAsigProyProgExt());
    }else {

       comisionFocus && dispatch(abrirModalComisionEvaluadora());
    }
}
export const startEditarComision = (data = {})=> async(dispatch)=>{

    dispatch( editarComision(data) );

}

export const startPostComision = ()=>async(dispatch,getState)=>{

    const { comisionFocus} = getState().comisionReducer;

    const resp = await fetch(`${process.env.REACT_APP_API_URL}/com`,{
        method : 'POST',
        headers: {
            'Content-Type' : 'application/json'
        },
        body :JSON.stringify({
            ...comisionFocus
        })
    });

    const data = await resp.json();

    if(data.ok) {
        alert(`comision cagada correctamente`);
        dispatch(resetComision());
        dispatch(cerrarModal());
    }else {
        alert(data.error)
    }

}

export const startEliminarComision = (_id,history)=>async()=>{

    mostrarAlerta('¿Desea eliminar la comision?','Comision Evaluadora',true)
        .then( async resp => {
            if(resp.isConfirmed){
                const resp = await fetch(`${process.env.REACT_APP_API_URL}/com`,{
                    method : 'DELETE',
                    headers: {
                        'Content-Type' : 'application/json'
                    },
                    body :JSON.stringify({
                        idComision : _id
                    })
                });
            
                const data = await resp.json();
            
                if(data.ok) {
                    alert(`comision eliminada correctamente`);
                    history.push(`/evaluacion`)
                }else {
                    alert(data.error)
                }
            }
        })

   

}

export const startAsigProyProgExt = ()=>async(dispatch,getState)=>{
    const {comisionFocus} = getState().comisionReducer;

    const resp = await fetch(`${process.env.REACT_APP_API_URL}/com/prog`,{
        method : 'POST',
        headers: {
            'Content-Type' : 'application/json'
        },
        body :JSON.stringify({
            listaP : comisionFocus.listaP
        })
    });

    const data = await resp.json();

    if(data.ok) {
        alert(`poryectos asignados correctamente`);
    }else {
        alert(data.error)
    }
}

export const resetComision = ()=>({
    type : types.comisionReset
});

const editarComision = (data = {})=>({
    type : types.comisionEditar,
    payload : data
})

const editarComisiones = ( listaActualizada = {} )=>({
    type : types.comisionGetLista,
    payload : listaActualizada
})
const editarProyAsigPRogExt = ( listaActualizada = {} )=>({
    type : types.comisionGetListaProgExt,
    payload : listaActualizada
})

