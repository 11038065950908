import { types } from "../types/types";


const initialState = {
    listaP : null,
    eipcaFocus : null
}

export const eipcaReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.eipcaGetLista:
            return {
                ...state,
                listaP : [...action.payload]
            }
        case types.eipcaGetData : 
            return {
                ...state,
                eipcaFocus : {...action.payload}
            }
        case types.eipcaEditar : 
            return {
                ...state,
                eipcaFocus : {
                    ...state.eipcaFocus,
                    ...action.payload
                }
            }
        case types.eipcaReset : 
            return {
                ...state,
                eipcaFocus : initialState.eipcaFocus
            };
        default:
            return state;
    }

}
