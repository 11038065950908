import React from 'react';
import { useRef } from 'react';
import {  useSelector } from 'react-redux';
import { useGestionarEstados } from '../../../hooks/useGestionarEstados';

import { FilaTabla } from '../../Tabla/FilaTabla';
import { Tabla } from '../../Tabla/Tabla';

import { FormEstadoProyecto } from './FormEstadoProyecto';


export const TablaHistorialAvance = ({_tipoUsuario = ''}) => {

   
    const { tipoUsuario} = useSelector( state => state.usrReducer.usrFocus);
    const idEstadoFocus = useRef(null);
   

    const {
        historialEstados,
        showFormEstado,
        setshowFormEstado,
        eliminarEstadoProyecto    
    } = useGestionarEstados();
    
 

    const handleEliminarEstado = (e,_id)=>{
        e.preventDefault();
        eliminarEstadoProyecto(_id);
    }
    const handleEditarEstadoProyecto = (e,_id)=>{
        e.preventDefault();
        idEstadoFocus.current = _id;
        setshowFormEstado(true);
    }
    
  return (
    <Tabla 
        _tipo={'historial'}
        _gestionable = {_tipoUsuario === 'estandar' ? false : true} 
        _gestiona={{
            _handleBorrar : tipoUsuario === 'admin'  ? (e,_id)=>{handleEliminarEstado(e,_id) }: null,
            _handleEditar : tipoUsuario === 'admin'  ? (e,_id)=>{handleEditarEstadoProyecto(e,_id) }: null,
            _handleCrear :tipoUsuario === 'admin' ? ()=>{setshowFormEstado(true)} : null
        }}  
    >
    <colgroup>
        
            <col span='1' style={{width: '10%'}}></col>
            <col span='1' style={{width: '20%'}}></col>
            <col span='1' style={{width: '60%'}}></col>
    </colgroup>
    <thead className='table bg-primary text-white'>
            <FilaTabla key={'estado-cabecera-1'} _id={1} _cabecera={true} _titulo={true}>
                <th className='text-center' colSpan={4}>HISTORIAL DE ESTADO</th>
                
            </FilaTabla>
    </thead>
    <thead className='table bg-primary text-white'>
            <FilaTabla key={'estado-cabecera-2'} _id={1} _cabecera={true}>
                <th>FECHA</th>
                <th>ESTADO</th>
                <th>AVISOS</th>
            </FilaTabla>
    </thead>
    <tbody className='table'>
        {
            
            historialEstados &&
            historialEstados.map( (estado,i) => {
                const fechaCreacion = new Date(estado.fechaCreacion);
                return (showFormEstado && idEstadoFocus.current && idEstadoFocus.current === estado.id)
                    ? <FormEstadoProyecto 
                            idEstadoFocus={idEstadoFocus} 
                            setshowFormEstado={setshowFormEstado}  
                        />
                
                    :<FilaTabla 
                        key={`estado-${i}`} 
                        _id={estado.id} 
                        _cabecera={false}
                      >
                        <td>{`${fechaCreacion.getDate()}/${fechaCreacion.getMonth()+1}/${fechaCreacion.getFullYear()}`}</td>
                        <td>{estado.tipoEstado}</td>
                        <td>{estado.desc}</td>
                    </FilaTabla>
                }
                )
        }
        {
            showFormEstado && (!idEstadoFocus.current) &&
             <FormEstadoProyecto 
                    idEstadoFocus={null} 
                    setshowFormEstado={setshowFormEstado}  
                />
        }

    </tbody>

    </Tabla>
  )
}
