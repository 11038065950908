import { types } from "../types/types";



const initialState = {
    convFocus : {
        idConvocatoria : 0,
        anio : 0,
        estados : [{tipoEstado : ''}]
    }
    
}

export const convReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.convGetData : 
            return {
                ...state,
                convFocus : {...action.payload}
            }
        case types.convEditar : 
            return {
                ...state,
                convFocus : {
                    ...state.pFocus,
                    ...action.payload
                }
            }
        case types.convReset : 
            return {
                ...state,
                convFocus : initialState.convFocus
            };
        default:
            return state;
    }

}
