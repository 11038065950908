import React from 'react'
import { FilaActividad } from './FilaActividad'

export const FilaObjetivoEspecifico = ({objetivo = '',lActividad = [], resEsp=''}) => {
  return (
    <div className='col border-bottom border-secondary'>
        <div className='row'>
            <div className='col-2'>{objetivo}</div>
            <div className='col-2 border-start border-end  border-secondary'>{resEsp}</div>
            <div className='col-8'>

                <div className='row row-cols-1'>
                {
                    lActividad && 
                    lActividad.map( ({desc, CronogramaA1s, CronogramaA2s },i) => 
                        <FilaActividad 
                            key={`TablaAct-${i}`}
                            actividad={desc} 
                            oCronogramaAnio1={CronogramaA1s} 
                            oCronogramaAnio2={CronogramaA2s} 
                        />
                    )
                }
                </div>

            </div>
            


        </div>
    </div>
    
  )
}
