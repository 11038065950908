import { useDispatch, useSelector } from 'react-redux'
import { startGuardarFormProyectoDatosGenerales } from '../../../../actions/formp';
import { mostrarAlerta } from '../../../../helpers/mostrarAlerta';
import { useForm } from '../../../../hooks/useForm';
import { useGestionarEstados } from '../../../../hooks/useGestionarEstados';
import { useGestionarUsuario } from '../../../../hooks/useGestionarUsuario';

export const useFormProyectoSec1 = () => {
  
    const dispatch = useDispatch();
  const {pFocus} = useSelector( state => state.pReducer );

  const {
    lUnidadesAcademicas,
    lProgramasExtension,
    lLineasTematicas,
    lTipoProyecto
  } = useSelector( state => state.basesReducer);

  const {
    bianual,
    ProgramaExtension_id_ProgramaExtensions,
    LineaTematica_id_LineaTematicas,
    tipoProyecto,
    unidadAcademica 
  } = pFocus;

  const { 
    campos,
    validaciones,
    handleCampoChange,
    validarCampo,
    validarForm
  } = useForm({
    bianual : bianual ? Number(bianual) : 0 ,
    ProgramaExtension_id_ProgramaExtensions : ProgramaExtension_id_ProgramaExtensions ? ProgramaExtension_id_ProgramaExtensions : '' ,
    LineaTematica_id_LineaTematicas : LineaTematica_id_LineaTematicas ? LineaTematica_id_LineaTematicas : '' ,
    tipoProyecto : tipoProyecto ? tipoProyecto : '' ,
    unidadAcademica : unidadAcademica ? unidadAcademica : ''  
  },'p');
  
  const {
    bloquearFormularioProyectoPorEstadoEvaluacion,
    bloquearFormularioPorProyectoCerrado
  } = useGestionarEstados();

  const {usuarioEsAdmin , usuarioEsDueñoDelProyecto} = useGestionarUsuario();

  const formularioBloqueado = ( bloquearFormularioProyectoPorEstadoEvaluacion() || bloquearFormularioPorProyectoCerrado() );

  const handleSubmitDatosGenerales = (e)=>{
    e.preventDefault();

    if( (!usuarioEsDueñoDelProyecto()) && (!usuarioEsAdmin())  ){
            
        alert('No posee permisos para realizar esta acción');

        return;
    }

    if(bloquearFormularioProyectoPorEstadoEvaluacion()){

        alert('No posee permisos para realizar esta acción');

        return;
    }

    if(
        validarForm([
          'bianual',
          'ProgramaExtension_id_ProgramaExtensions',
          'LineaTematica_id_LineaTematicas',
          'tipoProyecto',
          'unidadAcademica',
        ]) 
        && pFocus['PalabraClave_id_PalabraClaves']
        && pFocus['PalabraClave_id_PalabraClaves'].length > 2
        && pFocus['PalabraClave_id_PalabraClaves'].length < 6

      ) {

        mostrarAlerta('¿Desea guardar la sección ?','Guardar Sección',true)
            .then( resp => {
                    if( resp.isConfirmed){
                        dispatch( startGuardarFormProyectoDatosGenerales() );
                    }
                } 
            )

        } else {
            alert('No se puede guardar aún, revise los campos del formulario.');
      
        }

  }

  const lLineasTematicasPorAnioDelProyecto = ()=> lLineasTematicas.filter( lt => {
    const anioDelProyecto = 2000 + Number(pFocus.codigo.split('-')[0]);
    const anioActual = new Date(lt.createdAt).getFullYear(); 
    return anioDelProyecto === anioActual;
  })

  return {
    handleSubmitDatosGenerales,
    formularioBloqueado,
    campos,
    validaciones,
    handleCampoChange,
    validarCampo,
    lUnidadesAcademicas,
    lProgramasExtension,
    lLineasTematicasPorAnioDelProyecto,
    lTipoProyecto,
    pFocus
  }

}
