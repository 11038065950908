import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { abrirModalInstitucionInterviniente } from '../../../../actions/ui';
import { useGestionarEstados } from '../../../../hooks/useGestionarEstados';
import { useGestionarUsuario } from '../../../../hooks/useGestionarUsuario';
import { FilaInstitucion } from './FilaInstitucion';

export const ListaInstitucionesIntervinientes = () => {
    const dispatch = useDispatch();

    const {InstitucionIntervinientes} = useSelector(state => state.pReducer.pFocus);
    const showMsgError = InstitucionIntervinientes && InstitucionIntervinientes.length < 1;

    const {
      bloquearFormularioProyectoPorEstadoEvaluacion,
      proyectoEnEstadoRecepProyecto
    } = useGestionarEstados();

    const {

      usuarioEsAdmin,

      usuarioEsDueñoDelProyecto

    } = useGestionarUsuario();

    const handleAgregarInstitucion = (e)=>{
        e.preventDefault();

        if(  (!usuarioEsAdmin()) && (!usuarioEsDueñoDelProyecto()) ){
                
            alert('No posee permisos para realizar esta acción');
            return;
        }
        if(bloquearFormularioProyectoPorEstadoEvaluacion()){
            alert('No posee permisos para realizar esta acción');
            return;
        }
        dispatch( abrirModalInstitucionInterviniente() );
        
        return;
    }
  return (
    <div className='row row-cols-1 mb-5'>
      <div className='col'>
          <div className='row'>
              <div className='col-8'>{ showMsgError && <span className='text-danger' > Debe cargar al menos una </span>}</div>
              <div className='col-4 text-end'> 
              {
                (usuarioEsAdmin() || usuarioEsDueñoDelProyecto()) && proyectoEnEstadoRecepProyecto()
                ?<button className='btn btn-primary' onClick={handleAgregarInstitucion} >
                  Agregar institución
                </button> 
                :<></>
              }
                 
              </div>
          </div>
      </div>
      <div className='col mt-3'>
        <div className='row row-cols-3 bg-light p-3'>
          <div className='col-8'>INSTITUCIONES INTERVINIENTES</div>
          <div className='col-4 text-center'>ACCIONES</div>
        </div>
          {
            InstitucionIntervinientes && 
            InstitucionIntervinientes.map( 
              (institucion,i) => <FilaInstitucion key={`inst-${i}`} _data={institucion} _id={i}/> )
          }
      </div>
      
    </div>
  )
}
