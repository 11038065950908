import { types } from "../types/types";
import { startResetListaProyectos } from "./p";
import { finalizarCargaConvocatoria, informarEstadoQuery, iniciarCargaConvocatoria } from "./ui";

export const startGetConvocatoria = ()=>async(dispatch)=>{
   
    dispatch(startResetListaProyectos());
    dispatch(informarEstadoQuery('cargando convocatoria..'));
    dispatch(iniciarCargaConvocatoria());
    const anioActual = new Date(Date.now()).getFullYear().toString();

    const resp = await fetch(`${process.env.REACT_APP_API_URL}/conv/${anioActual}`);

    if(resp.ok) {
        const data = await resp.json();

        if(data.ok){

            dispatch(setFocusConvocatoria( data.data));
            dispatch(finalizarCargaConvocatoria());
        }else {
            alert(data.error);
            dispatch(finalizarCargaConvocatoria());
        }
    } 
    else {
        console.log(resp.error);
        dispatch(finalizarCargaConvocatoria());
    }

    
}



const setFocusConvocatoria = ( data )=>({
    type : types.convGetData,
    payload : data
})