import React from 'react';

import opciones from './opciones';
import { useSelector } from 'react-redux';
import { Campo } from '../Formulario/Campo';
import { Spiner } from '../Ui/Spiner';

import { useRegisterUsuario } from './useRegisterUsuario';



const {listaUUAA, tiposUsuario } = opciones;

export const FormRegister = ({_admin = false}) => {

  const {cargandoForm} = useSelector( state => state.uiReducer); 
  
  const {
    handleCampoChange,
    handleSubmit,
    validarCampo,
    campos,
    validaciones
  } = useRegisterUsuario();
  
  return (
    <div className='row row-cols-1'>
       {
        cargandoForm 
            ? <Spiner />
            :
            <>
                <div className='col'>
                    <Campo
                        disabled={ cargandoForm ? true : false}
                        required={true}
                        handlecampochange={handleCampoChange}
                        handleblur={validarCampo}
                        value={campos['dni']}
                        valido={validaciones['dni']}
                        name='dni'
                        etiqueta='DNI'
                        msgayuda={'Sin puntos, con 0 adelante si tiene menos de 8 dígitos'}
                        pattern={'[0-9]{8}'}
                        type='text'
                        minLength={7}
                        maxLength={8}
                    />
                    <Campo
                        disabled={ cargandoForm ? true : false}
                        required={true}
                        handlecampochange={handleCampoChange}
                        handleblur={validarCampo}
                        value={campos['ape']}
                        valido={validaciones['ape']}
                        name='ape'
                        etiqueta='Apellido'
                        type='text'
                        minLength={1}
                        maxLength={255}
                    />
                    <Campo
                        disabled={ cargandoForm ? true : false}
                        required={true}
                        handlecampochange={handleCampoChange}
                        handleblur={validarCampo}
                        value={campos['nom']}
                        valido={validaciones['nom']}
                        name='nom'
                        etiqueta='Nombre'
                        type='text'
                        minLength={1}
                        maxLength={255}
                    />
                
                    
                    <Campo
                        disabled={ cargandoForm ? true : false}
                        required={true}
                        handlecampochange={handleCampoChange}
                        handleblur={validarCampo}
                        value={campos['tel']}
                        valido={validaciones['tel']}
                        name='tel'
                        etiqueta='Teléfono'
                        type='text'
                        minLength={1}
                        maxLength={255}
                        />
                    
                    <Campo
                        disabled={ cargandoForm ? true : false}
                        required={true}
                        handlecampochange={handleCampoChange}
                        handleblur={validarCampo}
                        value={campos['email']}
                        valido={validaciones['email']}
                        name='email'
                        etiqueta='Correo electrónico' 
                        type='email' 
                    
                    />
                
                    <Campo
                        disabled={ cargandoForm ? true : false}
                        required={true}
                        handlecampochange={handleCampoChange}
                        handleblur={validarCampo}
                        value={campos['unidadAcademica']}
                        valido={validaciones['unidadAcademica']}
                        name='unidadAcademica'
                        etiqueta='UU.AA.'
                        type='select'
                        opciones={listaUUAA}
                    
                    />
                    
                            
                    {
                        _admin === true 
                        ? <Campo
                            disabled={ cargandoForm ? true : false}
                            required={true}
                            handlecampochange={handleCampoChange}
                            handleblur={validarCampo}
                            value={campos['tipoUsuario']}
                            valido={validaciones['tipoUsuario']}
                            name='tipoUsuario'
                            etiqueta='Tipo usuario'
                            type='select'
                            opciones={tiposUsuario}
                        
                        />
                        : <></>
                    }
                    <Campo
                        disabled={ cargandoForm ? true : false}
                        required={true}
                        handlecampochange={handleCampoChange}
                        handleblur={validarCampo}
                        value={campos['pass']}
                        valido={validaciones['pass']}
                        name='pass'
                        etiqueta='Contraseña' 
                        type='password' 
                        minLength={6}
                        maxLength={6}
                    />   
                    <Campo
                        disabled={ cargandoForm ? true : false}
                        required={true}
                        handlecampochange={handleCampoChange}
                        handleblur={validarCampo}
                        value={campos['repetir']}
                        valido={validaciones['repetir']}
                        name='repetir'
                        etiqueta='Confirme contraseña' 
                        type='password' 
                        minLength={6}
                        maxLength={6}
                    />
                </div>
                <div className='col d-flex justify-content-center'>
                    {
                        cargandoForm 
                            ? <Spiner />
                            : <button className='btn btn-primary' onClick={handleSubmit}>
                                Registrar Nuevo Usuario
                            </button>  
                    }
                </div>
            </>
        }
        </div>
    )
 }