import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { startAltaBecarioInforme } from '../../actions/informe';
import { cerrarModal } from '../../actions/ui';
import { startSelecIntegEquipo } from '../../actions/bases';

export const ModalInformeAvanceBecario = ({esFinal = false}) => {
  const dispatch = useDispatch();
  const {
    lUnidadesAcademicas,
    lRolInteg,
    integEquipoSelec
  } =  useSelector(state => state.basesReducer);

  const {Becarios} = useSelector( state => esFinal ? state.infFinalReducer.infFinalFocus : state.infAvReducer.infAvFocus)

  const rolBecario = lRolInteg.find( rol => rol.codigo === 'becar');
  const rolVoluntario = lRolInteg.find( rol => rol.codigo === 'vol');
  
  const [formFields, setFormFields] = useState({
    Rol_id : 0,
    EquipoExtension_id : 0,
    Persona_id : 0,
    Persona : {
        id: 0,
        dni: '',
        ape: '',
        nom: '',
        email : '',
        tel :'',
        dom : '',
        unidadAcademica : '',
        periodoLectivo : ''
    },
    observ : ''
   });

   useEffect(() => {
    if(integEquipoSelec !== null){
        setFormFields(Becarios[integEquipoSelec]);
    }
  
    return () => {
        setFormFields(formFields);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integEquipoSelec])
  

  

  const handleChange = (e)=>{
    e.preventDefault();
    if( e.currentTarget.name === 'Rol_id' ) {
        setFormFields({
            ...formFields,
            Persona : {
                ...formFields.Persona, 
                unidadAcademica : Number(e.currentTarget.value) === rolBecario.id ? formFields.Persona.unidadAcademica : '',
                periodoLectivo : Number(e.currentTarget.value) === rolBecario.id ? formFields.Persona.periodoLectivo : '',
            },
            Rol_id : Number(e.currentTarget.value)
        });
    } 
    else if ( e.currentTarget.name === 'observ' ){
        setFormFields({
            ...formFields,
            observ : e.currentTarget.value
        });
    }
    else {
        setFormFields({
            ...formFields,
            Persona : {
                ...formFields.Persona,
                [e.currentTarget.name] : e.currentTarget.value
            }
        });
    }

  }

  const validarCampos = ()=>{
    let salida = true;
    if(formFields.Persona.dni.length < 8) {
        salida = false;
        alert('dni inválido');
    }
    if(formFields.Persona.ape.length < 2) {
        salida = false;
        alert('ape inválido');
    }
    if(formFields.Persona.nom.length < 2){
        salida = false;
        alert('nom inválido');
    }
    
    return salida;
  }

  const handleSubmit = (e)=>{
    e.preventDefault();
    if(validarCampos()){
        dispatch(startAltaBecarioInforme(formFields, esFinal));
        dispatch(startSelecIntegEquipo(null));
        dispatch(cerrarModal());
    }
  }

  const handleCerrarModal = (e)=>{
    e.preventDefault();
    dispatch(startSelecIntegEquipo(null));
 
    dispatch(cerrarModal());
   
  }
  return (
    <div className='container-md' style={{maxWidth : '50em'}}>
        <form onSubmit={handleSubmit}>
        <div className='row row-cols-1'>
           
            <div className='col  border-bottom '>
                    <fieldset>
                        <legend style={{fontSize : '1.2em'}}> Datos personales</legend>
                        <div className='row mb-2'>
                        <div className='col'>
                            <small className='text-secondary'> dni sin puntos, con 0 adelante si tiene 7 dígitos</small>
                            
                            <div className='input-group'>
                                <span className='input-group-text' style={{ width : '4.5em',  textAlign : 'left',fontSize : '0.8em'}} htmlFor='dni'> DNI </span>
                                <input id={'dni'} className='form-control form-control-sm' name='dni' value={formFields.Persona.dni} type='text' minLength={1} maxLength={8} style={{maxWidth: '13em'}} onChange={handleChange} required={true}/>
                            </div>
                            {/* <small className='text-secondary'>mínimo 3 caracteres.</small> */}
                           <div className='input-group'>
                                <span className='input-group-text' style={{ width : '4.5em', textAlign : 'left', fontSize : '0.8em'}} htmlFor='ape'> APE </span>
                                <input id={'ape'} className='form-control form-control-sm' name='ape' value={formFields.Persona.ape} type='text' minLength={1} maxLength={150} onChange={handleChange} required={true} />
                            </div>
                            {/* <small className='text-secondary'>mínimo 3 caracteres.</small> */}
                            <div className='input-group'>
                                <span className='input-group-text' style={{ width : '4.5em', textAlign : 'left', fontSize : '0.8em'}} htmlFor='nom'> NOM </span>
                                <input id={'nom'} className='form-control form-control-sm' name='nom' value={formFields.Persona.nom} type='text' minLength={1} maxLength={150} onChange={handleChange} required={true} />
                            </div>
                            <div className='input-group'>
                                <span className='input-group-text' style={{ width : '4.5em', textAlign : 'left', fontSize : '0.8em'}} htmlFor='nom'> TEL </span>
                                <input id={'tel'} className='form-control form-control-sm' name='tel' value={formFields.Persona.tel} type='text' maxLength={150} onChange={handleChange}/>
                            </div>
                            
                        </div>
                        <div className='col '>
                          
                            <div >
                                <label className='form-label'  htmlFor='email'> CORREO ELECTRÓNICO </label>
                                <input id={'email'} className='form-control form-control-sm' name='email' value={formFields.Persona.email} max={250} minLength={1} type='email' onChange={handleChange}/>
                            </div>
                            <div >
                                <label className='form-label'  htmlFor='dom'> DOMICILIO </label>
                                <input id={'dom'} className='form-control form-control-sm' name='dom' value={formFields.Persona.dom} type='text'  maxLength={150}  onChange={handleChange}/>
                            </div>
                        </div>
                        </div>
                    </fieldset>
                </div>
            <div className='col mt-2'>
                <div className='input-group'>
                    <span className='input-group-text' style={{ minWidth : '9em', fontSize : '0.8em'}} htmlFor='Rol_id'> ROL EN EQUIPO </span>
                    <select className='form-select form-select-sm' id='Rol_id' name='Rol_id' value={formFields.Rol_id} style={{maxWidth : '20em'}} onChange={handleChange} required={true} >
                        <option id='' value=''>Seleccione</option>
                        <option id={rolBecario.id} value={rolBecario.id}>{rolBecario.nom}</option>
                        <option id={rolVoluntario.id} value={rolVoluntario.id}>{rolVoluntario.nom}</option>
                    </select>
                </div>
            </div>
            {/* <div className='col  mt-2 border-top ' style={{display : Number(formFields.Rol_id) !== lRolInteg.find(rol => rol.codigo === 'becar').id ? 'none' : undefined }}> */}
            <div className='col  mt-2 border-top ' >
                <fieldset>
                    <legend style={{fontSize : '1.2em'}}> Datos académicos</legend>
                    <div className='input-group' >
                        <span className='input-group-text' style={{ minWidth : '9em', fontSize : '0.8em'}} htmlFor='periodoLectivo'> CICLO LECTIVO </span>
                        <select className='form-select form-select-sm' id='periodoLectivo' name='periodoLectivo'  onChange={handleChange} style={{maxWidth : '10em'}} value={formFields.Persona.periodoLectivo}>
                            <option id='' value=''>Seleccione</option>
                            <option id='ini' value='ini'>Inicial</option>
                            <option id='sup' value='sup'>Superior</option>
                            
                        </select>
                        <span className='input-group-text' style={{ minWidth : '9em', fontSize : '0.8em'}} htmlFor='unidadAcademica'> U.A. </span>
                        <select className='form-select form-select-sm' id='unidadAcademica' name='unidadAcademica'  onChange={handleChange}  value={formFields.Persona.unidadAcademica}>
                            <option id=''>Seleccione</option>
                            {
                                lUnidadesAcademicas.map( ua => <option id={ua.id} value={ua.id}>{ua.nom}</option>)
                            }
                            
                        </select>
                    </div>
                    <div className='input-group' >
                        
                    </div>
                </fieldset>
            </div>
            <div className='col'>
                <label className='form-label'  htmlFor='email'> Observaciones </label>
                <textarea id={'observ'} className='form-control form-control-sm' name='observ' value={formFields.observ} type='observ' onChange={handleChange}/>
                           
            </div>
            <div className='col mt-2 p-2 '>
               <div className='row gx-1 justify-content-center'>
                  <div className='col-2'>
                  <input className='btn btn-primary ' type='submit' value={'Guardar'}/>
            
                  </div>
                  <div className='col-2'>
                  <button className='btn btn-outline-secondary'  onClick={handleCerrarModal}>Cancelar</button>
            
                  </div>
               </div>
            </div>
            
        </div>
        </form>
    </div>
  )
}
