import React from 'react'
import { useReactToPrint } from 'react-to-print'
import { CabeceraFormulario } from './CabeceraFormulario';
import { useSelector } from 'react-redux';
import { ContextForm } from './context/ContextForm';
import { BotonImprimir } from '../Botones/BotonImprimir';
import { PieDeFormulario } from './PieDeFormulario';



const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
    .contenido-sin-corte {
      display: block;
      page-break-inside: avoid;
    }
  }`;



export const Formulario = ({

      children,
      componenteVistaPrevia,
      refVistaPrevia,
      idForm = null,
      titulo = '',
      imprimible = true,
      handleSubmit = ()=>{},
      tipoFormulario = 'ip',
      tipoEvaluacion = ''

    } ) => {

    const {activarVistaPrevia} = useSelector(state => state.uiReducer);

    const valueContextFormInicial = {
      activarVistaPrevia : activarVistaPrevia,
      idForm : idForm,
      titulo : titulo,
      imprimible : imprimible,
      handleSubmit : handleSubmit,
      tipoFormulario : tipoFormulario,
      tipoEvaluacion : tipoEvaluacion
    }

    const handlePrint = useReactToPrint({
      pageStyle : pageStyle,
      content : () => refVistaPrevia.current,
    })

    return (
      <ContextForm.Provider value={valueContextFormInicial}>
        <div className='row row-cols-1 animate__animated animate__fadeIn bg-secondary bg-opacity-10 p-2 '>

          <CabeceraFormulario  />

            { 
                activarVistaPrevia === true && 
                <>
                  <BotonImprimir handlePrint={handlePrint} />
                   {componenteVistaPrevia}
                </>
                
            } 
            {
                (!activarVistaPrevia) && children
            }

            <hr className='mb-2'/>


             <PieDeFormulario /> 

        </div>
      </ContextForm.Provider>
    )
};
