import React from 'react';
import Proptypes from 'prop-types';
import { ContextTabla } from './ContextTabla';
import {Link} from 'react-router-dom';

/**
 * 
 * @param {*} _gestiona 'Usuarios' | 'Personas' | 'IdeasProyectos' 
 * @returns JSX.element <Tabla></Tabla>
 */
export const Tabla = ({ 
  children,
  _tipo = 'usr',
  _gestionable = true,
  _linkForm = null,
  _gestiona = { 
    _handleCrear : ()=>{},
    _handleEditar : ()=>{},
    _handleBorrar: ()=>{}
  }
}) => {

  return (
    <ContextTabla.Provider value={{
      tipo : _tipo,
      gestionable : _gestionable,
      linkForm : _linkForm,
      handleEditar : _gestiona._handleEditar,
      handleBorrar : _gestiona._handleBorrar,  
    }}>
      <div className='row  row-cols-1'  style={{overflow : 'auto',height:'30em'}}>
        <div className='col  animate__animated animate__fadeIn  table-responsive-md'>
          <table className='table table-bordered border-secondary ' >
              { children }
          </table>
        </div>
        {
        _gestionable &&
        <div className='col'>
          <div className='row d-flex align-items-center justify-content-end me-4' style={{height : '100%'}}>

            {
              (_tipo === 'proyectos' || _tipo === 'usuarios') 
                ?   <Link 
                      className='col col-1 btn btn-outline-primary'
                      to={_linkForm ? _linkForm : '/'}
                      onClick={_gestiona._handleCrear}
                      hidden = { _gestiona._handleCrear === null }
                    > 
                      + 
                    </Link>
                : <button className='col col-1 btn btn-outline-primary'
                    title='Crear'
                    onClick={_gestiona._handleCrear}
                    hidden = {_gestiona._handleCrear === null}
                  >
                    +
                  </button>
            }
            </div>
        </div>
      }
      </div>
     
    </ContextTabla.Provider >
  )
}

Tabla.propTypes = {
   _tipo : Proptypes.string,
   _gestionable : Proptypes.bool,
   _gestiona : Proptypes.shape({
    _handleCrear : Proptypes.func,
    _handleEditar : Proptypes.func,
    _handleBorrar : Proptypes.func
   })
}
