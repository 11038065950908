import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startAsigProyProgExt } from '../../actions/comsion';
import { useForm } from '../../hooks/useForm';
import { Campo } from '../Formulario/Campo';

export const ModalProgExt = () => {
    const dispatch = useDispatch();
    const {listaP} = useSelector(state => state.pReducer);
    const {listaComision} = useSelector( state => state.comisionReducer);

    const { 
      campos, 
      validaciones, 
      handleCampoChange, 
      validarCampo, 
      validarForm
  } = useForm( {
        listaP :  listaComision 
          ?  listaComision
                .filter( comision => comision.tipo === 'PE')
                .reduce( (salida,comision) => [
                    ...salida, 
                    ...comision.listaP.map(p => p.codigo)
                  ] , 
                  [] 
                )
          : '' ,
        
        tipoComision:'PE'
       },
     
    'com');
  
  const listaPTolistaSelect = ()=> listaP 
          ? listaP.listaProyectosUsuario.map( p => ({id : p.codigo, nom : `${p.codigo}-${p.titulo}`})) 
          : [];

  const handleGuardarComision = (e)=>{
    e.preventDefault();
    if(validarForm(['listaP'])){
      dispatch(startAsigProyProgExt());
    }
  }

  
  return (
    <div className='container'>
        <div className='row row-cols-1'>
            <div className='col'>
                <h4> Proyectos Asignados a Comision N°__ </h4>
                <hr />
            </div>
            <div className='col'>
                <Campo 
                    etiqueta={'Seleccione los proyectos a asignar:'}
                    type='select'
                    name='listaP'
                    multiple={true}
                    handlecampochange={handleCampoChange}
                    handleblur={validarCampo}
                    required={true}
                    opciones={ listaPTolistaSelect() || []}
                    value={campos['listaP']}
                    valido={validaciones['listaP']}
                    size={10}
                />
            </div>
            

            <div className='col'>
                <button className='btn btn-primary' onClick={handleGuardarComision}>Guardar cambios</button>
            </div>
        </div>
    </div>
  )
}
