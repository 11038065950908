import React from 'react'
import { useSelector } from 'react-redux';
import { imprimirCampoRecursivo } from '../../helpers/imprimirCampoRecursivo';

export const VistaPreviaEvaluacion = React.forwardRef((props, ref) =>  { 

    const {secciones,duracion} = props;
    const {pFocus } = useSelector( state => state.pReducer);
    const {ipFocus } = useSelector( state => state.ipReducer);
    const { pcalFocus} = useSelector( state => state.pcalReducer);


    const evaluadoresCalidad = pcalFocus 
    ? pFocus.lFormsEval.filter( formEval => formEval.tipo.includes('cal') ).map( formEval => formEval.evaluador)
    : undefined

    const verEvalInterno = (evaluadores)=> {
      const evaluador = evaluadores.find( _eval => _eval.tipoUsuario.includes('eval_int'));
      return `${evaluador.ape}, ${evaluador.nom}`
    };

    const verEvalExterno1 = (evaluadores)=> {
      const evaluador = evaluadores.find( _eval => _eval.tipoUsuario === 'eval_ext');
      return `${evaluador.ape}, ${evaluador.nom}`
    };

    const verEvalExterno2 = (evaluadores)=> {
      const evaluador = evaluadores.at(evaluadores.lastIndexOf( _eval => _eval.tipoUsuario === 'eval_ext'));
      return `${evaluador.ape}, ${evaluador.nom}`
    };

    const imprimirTipoPertinencia = (tipoPertinencia)=> {
      let salida = <></>

      if(tipoPertinencia === 'pert') salida = <p>Pertinente</p>
      if(tipoPertinencia === 'nopert') salida = <p>No pertinente</p>
      if(tipoPertinencia === 'obs') salida = <p>Pertinente con observaciones</p>

      return salida;
    }

    const imprimirTipoSeleccion = (tipSeleccion)=> {
      let salida = <></>

      if(tipSeleccion === 'total') salida = <p>Si</p>
      if(tipSeleccion === 'no') salida = <p>No </p>
      if(tipSeleccion === 'parcial') salida = <p>Si, parcialmente</p>

      return salida;
    }

    const imprimirResultado = (resultado)=>{
      let salida =<></>
      if(resultado === 1) salida = <p>Aprobado</p>
      if(resultado === 2) salida = <p>No aprobado</p>
      return salida;
    }

    const imprimirValorCampo = (nomSeccion , nomCampo)=>{
      let salida = '';
      if(secciones[nomSeccion][nomCampo]){
        if(['total','parcial','no'].some( value => `${secciones[nomSeccion][nomCampo]}`.includes(value)) ){
          salida = imprimirTipoSeleccion( secciones[nomSeccion][nomCampo] );
        }
        else if(nomSeccion === ''){
          salida = imprimirResultado( secciones[nomSeccion][nomCampo] );
        }
        else if(nomSeccion.includes('Resultado')){
          salida = imprimirTipoPertinencia( secciones[nomSeccion][nomCampo] );
        }
        else salida = imprimirCampoRecursivo( secciones[nomSeccion][nomCampo], 4 );
       
      }
      return salida;
    }

  return (
    <div id='vista_previa_form_proy' className='container' ref={ref}>
        <style  type='text/css' media='print'>{`
           @media print {
              html, body {
                height: initial !important;
                overflow: initial !important;
                -webkit-print-color-adjust: exact;
              }
              .contenido-sin-corte {
                display: block;
                page-break-inside: avoid;
              }
            }
  
        `}</style>
        <div className='row row-cols-1'>
            <div className='col mb-4'>
                <ul className='list-group'>
                <li className='list-group-item '> <span className='fw-bold'>Título :     </span> {ipFocus.titulo}   </li>
                <li className='list-group-item '> <span className='fw-bold'>UU.AA. :     </span> {pFocus.codigo.split('-')[3]}  </li>
                <li className='list-group-item '> <span className='fw-bold'>Modalidad : </span>  {pFocus.codigo.split('-')[2]}  </li>
                {
                  pcalFocus
                  ?
                  <>
                    <li className='list-group-item '> <span className='fw-bold'>Duración :     </span> {duracion}   </li>
                   {
                    evaluadoresCalidad
                    ?
                    <>
                      <li className='list-group-item '> <span className='fw-bold'>Evaluador Interno :  </span> {verEvalInterno(evaluadoresCalidad)}  </li>
                      <li className='list-group-item '> <span className='fw-bold'>Evaluador Externo 1: </span>  {verEvalExterno1(evaluadoresCalidad)}  </li>
                      <li className='list-group-item '> <span className='fw-bold'>Evaluador Externo 2: </span>  {verEvalExterno2(evaluadoresCalidad)}  </li>
                      
                    </>
                    :undefined
                  }
                  </>
                  :<li className='list-group-item'> <b className='fs-5'>Responsable de la propuesta:</b> {`${pFocus.director.ape}, ${pFocus.director.nom}`} </li>
                }
                </ul>
            </div>
        {
             Object.keys(secciones).map( 

                nomSeccion =>  {
                    return nomSeccion ? 
                        <>
                          <h3 > { nomSeccion } </h3>
                          <div className='contenido-sin-corte'>
                              <div className={`col mb-4 mt-4 `} >
                                {
                                  Object.keys(secciones[nomSeccion]).map(
                                    nomCampo => <>
                                        <p > { nomCampo } </p>
                                        <div className='border border-secondary m-2 p-2'>
                                          {
                                            imprimirValorCampo(nomSeccion,nomCampo)
                                          }
                                          
                                        </div>
                                  
                                    </>
                                  )
                                }

                            
                            </div>
                          </div>
                       
                          
                        </>
                    : undefined
                       
                       
                } 
                  
             )
        }
         
        </div>
    </div>
  )
})
