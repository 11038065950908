import React from 'react'
import { Campo } from '../../../components/Formulario/Campo';
import { ListaParticipantesDirectos } from './ParticipanteDirecto/ListaParticipantesDirectos';
import { TablaCoherencia } from './CoherenciaInterna/TablaCoherencia';
import { useFormProyectoSec3 } from './hooks/useFormProyectoSec3';
import { Formulario } from '../../../components/Formulario/Formulario';
import { VistaPreviaFormProyecto } from '../VistaPreviaFormProyecto';

export const FormProyectoSec3 = ({_refVistaPrevia, _componenteVistaPrevia}) => {
    

    const {
        handleSubmitProblematicaMetodologiaSintesis,
        campos,
        validaciones,
        handleAbrirEnlace,
        handleCampoChange,
        validarCampo,
        formularioBloqueado,
        listaProyUlt3Anios,
        tipoProyecto,
        pFocus
      } = useFormProyectoSec3();

  return (
    <Formulario
        idForm={pFocus.FormularioProyecto_id}
        titulo={'Problemática y Metodología'}
        handleSubmit={handleSubmitProblematicaMetodologiaSintesis}
        tipoFormulario='p'
        refVistaPrevia={_refVistaPrevia}
        componenteVistaPrevia={<VistaPreviaFormProyecto ref={_refVistaPrevia} />}
    >
        <div className='container-lg'>
            <div className='row row-cols-1'>
            <div className='col mt-2'>
            <h4>{'6) Problema a abordar'}</h4>
            <hr/>
            <Campo
                disabled={ formularioBloqueado ? formularioBloqueado : undefined} 
                name='sintesisProblemaAbordar' 
                type='textarea'  
                etiqueta='6.1) Síntesis del problema (máx. 2000 caracteres). ' 
                maxLength={2000} 
                minLength={1} 
                value={campos['sintesisProblemaAbordar']} 
                valido={validaciones['sintesisProblemaAbordar']}
                handlecampochange={handleCampoChange}
                handleblur={validarCampo}
                required={true}
                style={{resize:'none'}}
                rows={10}
            />
            <Campo
                disabled={ formularioBloqueado ? formularioBloqueado : undefined} 
                name='detalleProblemaAbordar' 
                type='textarea'  
                etiqueta='6.2.a) Descripción del contexto social (máx. 4000 caracteres).' 
                maxLength={4000}     
                minLength={1} 
                value={campos['detalleProblemaAbordar']} 
                valido={validaciones['detalleProblemaAbordar']}
                handlecampochange={handleCampoChange}
                handleblur={validarCampo}
                required={true}
                style={{resize:'none'}}
                rows={20}
            />
            <Campo
                disabled={ formularioBloqueado ? formularioBloqueado : undefined} 
                name='geolocalizacionProblemaAbordar' 
                type='url'  
                etiqueta='6.2.b) Geolocalización' 
                msgayuda={
                    <>
                        Utilice la herramienta de <a onClick={handleAbrirEnlace} href='https://www.google.com.ar/maps'>Google Maps</a> para insertar el enlace
                        de dicha ubicación.<br />
                        Si necesita ayuda para compartir el enlace , consulte 
                            <a onClick={handleAbrirEnlace} href='https://www.youtube.com/watch?v=KoN9aRs6a4E'> en este video.</a>
                    </>}
                maxLength={2048}     
                minLength={1} 
                value={campos['geolocalizacionProblemaAbordar']} 
                valido={validaciones['geolocalizacionProblemaAbordar']}
                handlecampochange={handleCampoChange}
                handleblur={validarCampo}
                required={true}
                style={{resize:'none'}}
                rows={20}
            />
            <Campo
               disabled={ formularioBloqueado ? formularioBloqueado : undefined} 
                name='ProyectoRelacionados' 
                type='select'  
                etiqueta='6.3.a) Proyectos de extensión ejecutados o en ejecución relacionados al tema (dentro de los últimos tres años).'  
                value={campos['ProyectoRelacionados']} 
                valido={validaciones['ProyectoRelacionados']}
                opciones={listaProyUlt3Anios}
                msgayuda={<>{'Se encuentra disponible el listado de proyectos '}<a onClick={handleAbrirEnlace} href='https://www.unl.edu.ar/extension/wp-content/uploads/sites/9/2018/10/Proyectos-2019-2022.xlsx-Table-1.pdf'> aquí </a></>}
                multiple={true}
                size = {10}
                handlecampochange={handleCampoChange}
                handleblur={validarCampo}
                required={true}

            />
            
            <Campo
               disabled={ formularioBloqueado ? formularioBloqueado : undefined} 
                name='proyectosCAID' 
                type='textarea'  
                etiqueta='6.3.b) Proyectos de investigación CAI+D relacionados al tema.'  
                msgayuda={<> {'Puede consultar los proyectos'} <a onClick={handleAbrirEnlace} href='https://bibliotecavirtual.unl.edu.ar:8443/handle/11185/5925'>en este enlace</a> </>}
                value={campos['proyectosCAID']} 
                valido={validaciones['proyectosCAID']}
                multiple={true}
                rows={5}
                handlecampochange={handleCampoChange}
                handleblur={validarCampo}
                required={false}
            />
        </div>
        <div className='col mt-2'>
            <h4>{'7) Coherencia interna'}</h4>
            <hr/>
            <TablaCoherencia bianual={pFocus.bianual}/>
        </div>
        <div className='col mt-2'>
            <h4>{'8) Propuesta metodológica'}</h4>
            <hr/>
            <Campo
                disabled={ formularioBloqueado ? formularioBloqueado : undefined} 
                name='propuestaMetodologica' 
                type='textarea'  
                etiqueta='8.1) Explicar la metodología que se implementará para el desarrollo del proyecto (máx. 2000 caracteres).' 
                maxLength={2000} 
                minLength={1} 
                value={campos['propuestaMetodologica']} 
                valido={validaciones['propuestaMetodologica']}
                handlecampochange={handleCampoChange}
                handleblur={validarCampo}
                required={true}
                style={{resize:'none'}}
                rows={13}
            />
          
            {
                tipoProyecto === 'PEIS' &&
               <>
               
                <ListaParticipantesDirectos />
                <Campo
                   disabled={ formularioBloqueado ? formularioBloqueado : undefined} 
                    name='instanciasCapacitacionDetalle' 
                    type='textarea'
                    etiqueta='8.3) Si el proyecto contempla instancias de formación y capacitación, detallar la propuesta (máx. 2000 caracteres).' 
                    value={campos['instanciasCapacitacionDetalle']} 
                    valido={validaciones['instanciasCapacitacionDetalle']}
                    handlecampochange={handleCampoChange}
                    handleblur={validarCampo}
                    style={{resize:'none'}}
                    rows={6}
                    maxLength={2000} 
                />
                <Campo
                    disabled={ formularioBloqueado ? formularioBloqueado : undefined} 
                    name='accionesCoordinacion' 
                    type='textarea'  
                    etiqueta='8.4) Acciones de coordinación (máx. 2000 caracteres).' 
                    maxLength={2000} 
                    minLength={1} 
                    value={campos['accionesCoordinacion']} 
                    valido={validaciones['accionesCoordinacion']}
                    handlecampochange={handleCampoChange}
                    handleblur={validarCampo}
                    required={true}
                    style={{resize:'none'}}
                    rows={6}
                />
               </>
            }
            {
                tipoProyecto === 'AET' &&
                <>
                    <Campo
                        disabled={ formularioBloqueado ? formularioBloqueado : undefined} 
                        name='perfilAgentesMultip' 
                        type='textarea'  
                        etiqueta='8.2.1) Perfil de los agentes multiplicadores (máx. 2000 caracteres).' 
                        minLength={1} 
                        value={campos['perfilAgentesMultip']} 
                        valido={validaciones['perfilAgentesMultip']}
                        handlecampochange={handleCampoChange}
                        handleblur={validarCampo}
                        required={true}
                        style={{resize:'none'}}
                        rows={6}
                        maxLength={2000} 
                    />
                    
                    <Campo
                        disabled={ formularioBloqueado ? formularioBloqueado : undefined} 
                        name='planifCapacitacionAgentes' 
                        type='textarea'
                        etiqueta='8.2.2) Enuncie la planificación prevista para capacitar agentes multiplicadores (talleres, cursos, etc.) (máx. 2000 caracteres)' 
                        value={campos['planifCapacitacionAgentes']} 
                        valido={validaciones['planifCapacitacionAgentes']}
                        handlecampochange={handleCampoChange}
                        handleblur={validarCampo}
                        required={true}
                        style={{resize:'none'}}
                        rows={6}
                        maxLength={2000} 
                    />
                </>
            }
        </div>
        <div className='col mt-2'>
            <h4>{'9) Vinculación con políticas públicas'}</h4>
            <hr/>
            <Campo
                disabled={ formularioBloqueado ? formularioBloqueado : undefined} 
                name='politicasPublicas' 
                type='textarea'  
                etiqueta='9.1) Mencionar las políticas públicas vinculadas a la temática abordada. 
                Especificar si se encuentran activas en el territorio de intervención (máx. 2000 caracteres).'
                maxLength={2000} 
                minLength={1} 
                value={campos['politicasPublicas']} 
                valido={validaciones['politicasPublicas']}
                handlecampochange={handleCampoChange}
                handleblur={validarCampo}
                required={true}
                style={{resize:'none'}}
                rows={6}
            />
        </div>
            </div>
        </div>
    </Formulario>
  )
}
