import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { resetInfFinal, startMostrarInforme, startPutInforme } from '../../actions/informe';
import { BotonVolver } from '../Botones/BotonVolver';
import { useGestionarEstados } from '../../hooks/useGestionarEstados';
import { mostrarAlerta } from '../../helpers/mostrarAlerta';
import { useHistory } from 'react-router-dom';
import { Interdisciplinaridad } from '../FormularioInforme/Interdisciplinaridad';
import { AgentesMultiplicadores } from '../FormularioInforme/AgentesMultiplicadores';
import { Becarios } from '../FormularioInforme/Becarios';
import { MaterialesEducativos } from '../FormularioInforme/MaterialesEducativos';
import { PoliticasPublicas } from '../FormularioInforme/PoliticasPublicas';
import { ParticipantesDirectos } from '../FormularioInforme/ParticipantesDirectos';
import { InstitucionesCargadas } from '../FormularioInforme/InstitucionesCargadas';
import { ActividadesCargadas } from '../FormularioInforme/ActividadesCargadas';

import { startFinishLoadingForm, startLoadingForm } from '../../actions/ui';
import { Spiner } from '../Ui/Spiner';
const SECCIONES = {
  ACT_CARGADAS : 'ACT_CARGADAS',
  INSTITUCIONES : 'INSTITUCIONES',
  PART_DIR : 'PART_DIR',
  POL_PUB: 'POL_PUB',
  MAT_EDUC : 'MAT_EDUC',
  INTERDISCIP : 'INTERDISICP',
  BECAS : 'BECAS',
  VOLUNTARIADO : 'VOLUNTARIADO',
  AGENTES_MULT : 'AGENTES_MULT'
}

export const FormularioInformeFinalAET = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [seccionFocus, setSeccionFocus] = useState('');
  const {
    cargandoForm,
    formInformeFinalCargado,
    guardandoForm
  } = useSelector(state => state.uiReducer);

  const {pFocus} = useSelector( state => state.pReducer);

  const {lMeses} = useSelector( state => state.basesReducer );

  const {director,titulo} = useSelector( state => state.infFinalReducer.infFinalFocus);

  const {chequearProyectoNuevo} = useGestionarEstados();

   
  useEffect( ()=>{ 
   
    if(!formInformeFinalCargado) {
       dispatch(startLoadingForm());

        dispatch( startMostrarInforme(true) ) ;
    } 
    
    return () => {
      dispatch(startFinishLoadingForm());
    }

  }, [formInformeFinalCargado,dispatch]  );


  const handleGuardarInforme = (e )=>{
    e.preventDefault();
    dispatch(startPutInforme(true));
  }

  const handleSalir =(e)=>{
    e.preventDefault();
    
    mostrarAlerta(
      '¿Desea salir del formulario? Se perderán todos los campos que no haya guardado',
      'Volver', 
      true
    )
      .then( resp => {
        if(resp.isConfirmed  ){
          history.push(`${chequearProyectoNuevo() ? '/gestor'  : `/gestor/panel/${pFocus.id}` }`) ;
          dispatch(resetInfFinal());
          // dispatch(resetCargaInfAvance())
        }
        
      }
          );
    }

  return (
   <>
    {
      (cargandoForm)
      ? <Spiner />
      : 
        formInformeFinalCargado &&
        <div className='container-xxl bg-light' style={ { height : '56em' }}>
          <div className='row'>
            <div className='col-10'><h2>INFORME FINAL</h2> </div>
            <div className='col-2'> <BotonVolver handleSalir={handleSalir}/> </div>
          </div>
          <div className='row'>
              <div className='col'>
            
                <ul className='list-group list-group-flush'>
                  <li className='list-group-item '> <span className='fw-bold'> AET  </span> {titulo}  </li>
                  <li className='list-group-item '> <span className='fw-bold'> CODIGO     </span> {pFocus.codigo}  </li>
                  <li className='list-group-item '> <span className='fw-bold'> Director/a    </span> {director.ape}, {director.nom}  </li>
                  {/* <li className='list-group-item '> <span className='fw-bold'> Provincia    </span> <input className='form-control' id={'provincia'}onChange={handleOnChange}type={'text'} name={'provincia'} value={provincia}/>  </li>
                  
                  <li className='list-group-item '> <span className='fw-bold'> Ciudad    </span> <input className='form-control' id={'ciudad'}onChange={handleOnChange}type={'text'} name={'ciudad'} value={ciudad}/>   </li>
                  <li className='list-group-item '> <span className='fw-bold'> Barrio    </span> <input className='form-control' id={'barrio'}onChange={handleOnChange}type={'text'} name={'barrio'} value={barrio}/>  </li>
                  <li className='list-group-item '> <span className='fw-bold'> Distrito Municipal    </span>  <input  className='form-control' id={'distritoMunicipal'}onChange={handleOnChange} type={'text'}name={'distritoMunicipal'} value={distritoMunicipal}/>  </li>
                  */}
                </ul>
              </div>
          </div>
         
         <div className='row mt-3'>
              <div className='col'>
                <div className='row'> 
                    <div className='col-md-11'>
                      <ul className='list-group list-group-horizontal-md flex-wrap'>
                        <li className={`list-group-item ${seccionFocus === SECCIONES.ACT_CARGADAS ? 'bg-secondary text-light' : '' }`} onClick={()=>setSeccionFocus(SECCIONES.ACT_CARGADAS)}> ACTIVIDADES CARGADAS</li>
                        {/* <li className={`list-group-item ${seccionFocus === SECCIONES.ACT_NUEVAS ? 'bg-secondary text-light' : '' }`} onClick={()=>setSeccionFocus(SECCIONES.ACT_NUEVAS)}> ACTIVIDADES NUEVAS</li> */}
                        <li className={`list-group-item ${seccionFocus === SECCIONES.INSTITUCIONES ? 'bg-secondary text-light' : '' }`} onClick={()=>setSeccionFocus(SECCIONES.INSTITUCIONES)}> INSTITUCIONES</li>
                        <li className={`list-group-item ${seccionFocus === SECCIONES.BECAS ? 'bg-secondary text-light' : '' }`} onClick={()=>setSeccionFocus(SECCIONES.BECAS)}>BECAS Y VOLUNTARIADO</li>
                        
                        <li className={`list-group-item ${seccionFocus === SECCIONES.AGENTES_MULT ? 'bg-secondary text-light' : '' }`} onClick={()=>setSeccionFocus(SECCIONES.AGENTES_MULT)}>AGENTES MULTIPLICADORES</li>
                        
                        <li className={`list-group-item ${seccionFocus === SECCIONES.POL_PUB ? 'bg-secondary text-light' : '' }`} onClick={()=>setSeccionFocus(SECCIONES.POL_PUB)}> POLÍTICAS PÚBLICAS</li>
                        <li className={`list-group-item ${seccionFocus === SECCIONES.MAT_EDUC ? 'bg-secondary text-light' : '' }`} onClick={()=>setSeccionFocus(SECCIONES.MAT_EDUC)}>COMUNICACIÓN Y MATERIALES</li>
                        <li className={`list-group-item ${seccionFocus === SECCIONES.INTERDISCIP ? 'bg-secondary text-light' : '' }`} onClick={()=>setSeccionFocus(SECCIONES.INTERDISCIP)}>INTERDISICPLINARIDAD</li>
                     
                      </ul>
                    </div>
                    <div className='col-1'>
                      <button className=' btn btn-primary' onClick={handleGuardarInforme} disabled={guardandoForm}>GUARDAR INFORME</button>
                    </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    {seccionFocus === SECCIONES.ACT_CARGADAS && <ActividadesCargadas lMeses={lMeses} esFinal={true} />}
                    {seccionFocus === SECCIONES.INSTITUCIONES && <InstitucionesCargadas esFinal={true} />}
                    {seccionFocus === SECCIONES.PART_DIR && <ParticipantesDirectos  esFinal={true}/>}
                    {seccionFocus === SECCIONES.POL_PUB && <PoliticasPublicas  esFinal={true}/>}
                    {seccionFocus === SECCIONES.MAT_EDUC && <MaterialesEducativos  esFinal={true}/>}
                    {seccionFocus === SECCIONES.INTERDISCIP && <Interdisciplinaridad  />}
                    {seccionFocus === SECCIONES.BECAS && <Becarios  esFinal={true}/>}
                    {seccionFocus === SECCIONES.AGENTES_MULT && <AgentesMultiplicadores  />}
                    
                  </div>
                </div>
            
           
              </div>
         </div>
         
      </div>
        
      
    }
   
   </>
  )
}
