
import { startRegistrarUsuario } from '../../actions/usr';
import { useDispatch } from 'react-redux';
import { useForm } from '../../hooks/useForm';
import { mostrarAlerta } from '../../helpers/mostrarAlerta';
import { useEffect, useRef } from 'react';

export const useRegisterUsuario = () => {
    const dispatch = useDispatch();

    const initialState = useRef({
      dni : '',
      ape : '',
      nom : '',
      tel : '',
      unidadAcademica : '',
      tipoUsuario : '',
      email : '',
      pass : '',
      repetir : ''
    });

    const validacionesAdicionales= {
      repetir : {
        validacion : initialState.current['pass'].includes(initialState.current['repetir']),
        msgError : 'Debe coincidir con la contraseña ingresada'
      }
    }
  
    const { 
          campos, 
          validaciones,
          handleCampoChange, 
          validarCampo,
          validarForm, 
          reset 
      } = useForm(initialState.current ,null, validacionesAdicionales );

      useEffect(() => { initialState.current = campos }, [campos,initialState])
      
      
      const handleSubmit = (e)=>{
          e.preventDefault();
          if(!validarForm( ['dni','ape','nom','email','unidadAcademica','pass','repetir']) ) {
            mostrarAlerta('Revise los campos del formulario','Error',false);
            return;
          }
          
          dispatch(startRegistrarUsuario(campos));
          
          reset();
          
          return;
      }

    return {
        handleSubmit,
        handleCampoChange,
        validarCampo,
        campos,
        validaciones
    }
}
