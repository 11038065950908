import React from 'react';
import { mostrarAlerta } from '../../helpers/mostrarAlerta';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { presionarVistaPrevia } from '../../actions/ui';
import { BotonVolver } from '../Botones/BotonVolver';
import { useContextForm } from './hooks/useContextForm';
import { BotonActivarVistaPrevia } from '../Botones/BotonActivarVistaPrevia';
import { useGestionarEstados } from '../../hooks/useGestionarEstados';

export const CabeceraFormulario = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const {pFocus} = useSelector(state => state.pReducer);


    const {
        activarVistaPrevia,
        titulo,
        imprimible
    } = useContextForm();

    const {chequearProyectoNuevo} = useGestionarEstados();

    const handleSalir =(e)=>{
      e.preventDefault();

      mostrarAlerta('¿Desea salir del formulario? Se perderán todos los campos que no haya guardado','Volver', true)
        .then( resp => resp.isConfirmed  ? history.push(`${chequearProyectoNuevo() ? '/gestor' : `/gestor/panel/${pFocus.id}` }`) : null );

   }
    
   const handleActivarVistaPrevia = (e)=>{
        e.preventDefault();

        dispatch( presionarVistaPrevia() );
   }

  return (
    <div className='col-md border-bottom border-gray mb-2'>
      <div className='row row-cols-3'>
        <div className='col-5 d-flex justify-content-start align-items-center text-wrapp'>
              <div className = 'row row-cols-1'>
                <div className = 'col  fs-4 fw-bold'>{titulo} </div>
              </div>
        </div>
        
        <div className='col-5 d-flex align-items-center'  >
            {
                imprimible &&

                <BotonActivarVistaPrevia 
                    activarVistaPrevia = {activarVistaPrevia} 
                    handleActivarVistaPrevia={handleActivarVistaPrevia}
                />
            }
        
        </div>
        
        <div className='col-2'>
          <BotonVolver handleSalir={handleSalir} />
        </div>
      </div>   
        
    </div>
  )
};
