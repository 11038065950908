import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'

const FilaAgente = ({ 
  index,
  perfilAgente,
  cantAgentes,
  observ,
  handleDelete = ()=>{}
 })=>
  <div className='row  text-center gx-0 '>
        <div className='col-2 border-end'>
          <input className='form-control' name='cantAgentes' value={cantAgentes} type={'number'} />
        </div>
        
        <div className='col-5 border-end'>
            <textarea className='form-control' name='perfilAgente' value={perfilAgente} rows={4} style={{resize : 'none'}}/>
        </div>
        <div className='col-4'> 
          <textarea className='form-control' name='observ' value={observ} rows={4} style={{resize : 'none'}}/>
        </div>
        <div className='col-1'>
          <button  className='btn btn-outline-danger' onClick={e => handleDelete(e,index)}><FontAwesomeIcon icon={faTrash} /></button>
        </div>
    </div>

export const AgentesMultiplicadores = () => {
  const [cAgentes, setCAgentes] = useState([
    { 
      perfilAgente : 'Agentes comunitarios  seleccionados por el Gobierno de la Ciudad',
      cantAgentes : 2,
      observ : ''
    }
  ]);

  const handleAgregar = (e)=>{
    e.preventDefault();
    setCAgentes([
      ...cAgentes,
      { 
        perfilAgente : '',
        cantAgentes : 0,
        observ : ''
      }
    ])
  }

  const handleDelete = (e,index)=>{
    e.preventDefault();
    setCAgentes(cAgentes.slice(0,index).concat(cAgentes.slice(index+1)));
  }

  return (
    <div className='row row-cols-1 m-3' >
      <div className='col'>
        <div className='row'>
          <div className='col-3'>
            <h5> Agentes Multiplicadores </h5>
          </div>
          <div className='col-2'>
            <button className='btn btn-outline-primary' onClick={handleAgregar}> Agregar </button>
          </div>
        </div>
      </div>
   
      <div className='col'>
          <div className='row bg-primary p-2 mb-1 text-light text-center'>
          <div className='col-2 border-end'>Cantidad </div>
          <div className='col-5 border-end'>Perfil</div>
          <div className='col-5'> Observaciones </div>

      </div>
      <div className='col' style={{maxHeight : '23em' , overflowY : 'scroll'}}>
        {
          cAgentes.map( (
            {
              perfilAgente,
              cantAgentes,
              observ
            },
            index
            ) => 
            <FilaAgente 
              index={index}
              perfilAgente={perfilAgente} 
              cantAgentes={cantAgentes}
              observ={observ}
              handleDelete={handleDelete}
            />)
        }
      </div>
      
    </div>

  </div>
  )
}
