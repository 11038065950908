import React from 'react'
import { useTablasProyectos } from './hooks/useTablasProyectos'
import { Tabla } from '../../components/Tabla/Tabla';
import { FilaTabla } from '../../components/Tabla/FilaTabla';

export const TablaProyectosUA = () => {

    const {
        handleEditar,
        listaP,
        printFechaDiaMesAnio
    } = useTablasProyectos();

  return (
    <Tabla
            _linkForm={`/ficha/crear`}
            _gestiona={{
                _handleCrear : null,
                _handleEditar :handleEditar,
                _handleBorrar :null
            }}
            _tipo = 'p'
        >
           <colgroup>
              <col span='1' style={{width: '2%'}}></col>
              <col span='1' style={{width: '13%'}}></col>
              <col span='1' style={{width: '5%'}}></col>
              <col span='1' style={{width: '50%'}}></col>
              <col span='1' style={{width: '10%'}}></col>
              <col span='1' style={{width: '10%'}}></col>
            </colgroup>
            <thead className='table bg-primary text-white'>
                <FilaTabla _id={3} _cabecera={true} _titulo={true}>
                    <th className='text-center' colSpan={7}>  PROYECTOS DE UU.AA.  </th>
                </FilaTabla>
                <FilaTabla _cabecera={true} _id={0}>
                      <th>ID</th>
                      <th>CODIGO</th>
                      <th>FECHA CREACIÓN</th>
                      <th>TÍTULO</th>
                      <th>RESPONSABLE</th>
                      <th>ESTADO</th>
                </FilaTabla>
            </thead>
            <tbody>
                {
                  listaP && 
                  listaP.listaProyectosUa &&
                  listaP.listaProyectosUa.map( (proy,i) => 
                  <FilaTabla key={`filaProy-${i}`} _cabecera={false} _id={proy.id}>
                    <td>{proy.id}</td>
                    <td ><small>{proy.codigo}</small></td>
                    <td>{printFechaDiaMesAnio(new Date(proy.fechaCreacion))}</td>
                    <td>{proy.titulo}</td>
                    <td>{proy.responsable}</td>
                    <td>{proy.estado}</td>
                    </FilaTabla> )
                }
            </tbody>
          </Tabla>
  )
}
