import { mostrarAlerta } from "../helpers/mostrarAlerta";
import { types } from "../types/types";
import { startFinishLoadingForm, startLoadingForm } from "./ui";



export const startLoguearUsuario = (email = '', pass = '')=>{
    return async(dispatch)=>{
        dispatch( startLoadingForm() )
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/usr/login`,{
            method : 'POST',
            headers: {
                'Content-Type' : 'application/json'
            },
            body :JSON.stringify({
                email : email,
                pass : pass
            })
        });

        const data = await resp.json();

        if(data.ok) {
            dispatch( loguearUsuario(data.data) );

            dispatch( startGetPermisos());

        }else {
            alert(data.error)
        }

        setTimeout(() => {
            dispatch( startFinishLoadingForm() )
        }, 1500);
    }
    
}

export const startRegistrarUsuario = ( campos = {} )=>{
    return async(dispatch)=>{
        dispatch( startLoadingForm() )
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/usr/register`,{
            method:'POST',
            headers: {
                'Content-Type' : 'application/json'
            },
            body :JSON.stringify({...campos})
        })

        const data = await resp.json();

        if(data.ok){
            mostrarAlerta(data.data,'Registro de usuario',false);
        }else {
            mostrarAlerta(data.error,'Registro de usuario',false);
        }

        setTimeout(() => {
            dispatch( startFinishLoadingForm() )
        }, 1500);

    }
}

export  const salirDelSistema = (history)=>{
    return async(dispatch)=>{
        mostrarAlerta('¿Desea salir del sistema?','Salir',true)
            .then( resp => {
                if(resp.isConfirmed ) {
                    dispatch(salir());
                    history.go('/');
                }
            } ) 
            
    }
}

export const startGetPermisos = ()=>{
    return async(dispatch,getState)=>{

        const {usrFocus} = getState().usrReducer;
        const {id} = usrFocus;
        const resp = await fetch( `${process.env.REACT_APP_API_URL}/usr/permisos/${id}` );

        const data = await resp.json();

        if( data.ok ){
            dispatch( editarPermisos({
                ...usrFocus,
                permisos : data.data
            })  )
        }

    }
}
const editarPermisos= ( permisos = {})=>({
    type : types.usrEditar,
    payload : permisos
})

const salir = ()=>({
    type : types.usrReset
})

const loguearUsuario = ( data = {} )=>({
    type : types.usrGetData,
    payload : data
})