import React from 'react'
import { Spiner } from '../../components/Ui/Spiner'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetIp } from '../../actions/ip'
import { resetProyecto, startMostrarListaProyectos } from '../../actions/p'
import { TablaMisProyectos } from '../../components/TablaProyecto/TablaMisProyectos'
import { TablaProyectosEval } from '../../components/TablaProyecto/TablaProyectosEval'
import { TablaProyectosUA } from '../../components/TablaProyecto/TablaProyectosUA'
import { resetInfAv } from '../../actions/informe'

export const TablaProyectos = () => {

  const dispatch = useDispatch();

  const {tipoUsuario} = useSelector( state => state.usrReducer.usrFocus);
  const {listaP} = useSelector( state => state.pReducer); 
  const {anio , estados} = useSelector( state => state.convReducer.convFocus)

  useEffect(()=> { dispatch(resetProyecto())},[dispatch]);
  useEffect(()=> { dispatch(resetIp())},[dispatch]);
  useEffect(()=>{ dispatch(resetInfAv())},[dispatch]);

  useEffect(() => {  
    (!listaP ) && 
    (anio > 0) && 
    (estados.length) && dispatch(startMostrarListaProyectos())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

 
  

  return (
    <div className='container'>
      {
      (!listaP)
        ? <Spiner />
        : <>
      
          {
            tipoUsuario !== 'eval_prog' && 
            tipoUsuario !== 'eval_ext' && 
            tipoUsuario !== 'sec_unl' &&
            tipoUsuario !== 'cs' &&
            <TablaMisProyectos />
          }
          { 
            tipoUsuario !== 'admin' && 
            tipoUsuario !== 'gestion' && 
            tipoUsuario !== 'estandar' &&
            <TablaProyectosEval />
          }

          {
            tipoUsuario === 'sec_unl' &&
            <TablaProyectosUA />
          }
        </>
        }
    </div>
  )
}
