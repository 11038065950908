
import React from 'react'
import { FormInstitucionNueva } from './FormInstitucionNueva';


export const ModalInstitucionInterviniente = ({_titulo = ''}) => {


    return (
        <>
            <div className='col'> <h4> { _titulo  } </h4> </div>
            <hr/>
            
            
            <div className='col'>
                <FormInstitucionNueva  />     
            </div>
           
        </>
  )
}
