import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startAltaActividadInforme } from '../../actions/informe';
import { cerrarModal } from '../../actions/ui';

export const ModalActividadNuevaInforme = ({esFinal = false}) => {
  const dispatch = useDispatch();

  const { lMeses,  }=  useSelector(state =>state.basesReducer);
  const { infAvFocus } = useSelector( state => state.infAvReducer);
  const { infFinalFocus } = useSelector( state => state.infFinalReducer);

  const {ObjetivoEspecificos} = esFinal ?  infFinalFocus.CoherenciaInterna : infAvFocus.CoherenciaInterna ;

  const handleSubmit = (e)=>{
    e.preventDefault();

    dispatch( startAltaActividadInforme( formFields, esFinal ) );
    dispatch( cerrarModal())
  }

  const [formFields, setFormFields] = useState({
    idObjEspFocus: ObjetivoEspecificos ? ObjetivoEspecificos[0].id : 0,
    id : 0,
    desc : '',
    estado : 1,
    resultados : '',
    CronogramaA1s : [],
    CronogramaA2s : []
  });

  
  return (
    <div className='container-md'>
    <form className='col ' onSubmit={handleSubmit}>
        <div className='row row-cols-1'>
           <div className='col'>
                <div className='row'>
                    <div className='col-1 me-2'>
                        <label className='form-label ' htmlFor='selectObjetivo' style={{fontSize : '0.9em'}}> Objetivo </label>
                    </div>
                    <div className='col-10'>
                    
                        <select className='form-select form-select-sm overflow-hidden' 
                            id={'selectObjetivo'} 
                            value={formFields.idObjEspFocus}
                            onChange={(e)=>{ setFormFields({...formFields, idObjEspFocus : Number(e.target.value)})}}
                        >
                            {
                                ObjetivoEspecificos &&
                                ObjetivoEspecificos.map( (obj,i) => 
                                    <option className='' id={obj.id} value={obj.id} >{ `${i+1}- ${obj.desc}`  }</option>  
                                )
                            }
                        </select>
                    </div>
                </div>
           </div>
           <div className='col mt-2'>
              <div className='row row-cols-3'>
                <fieldset className='col-4'>
                    <legend className='fs-5'>Actividad</legend>
                    <label for="desc" class="form-label"    style={{fontSize: '0.8em'}}>Descripción</label>
                    <textarea id='desc' nom='desc' className='form-control' rows={11} cols={50}
                        onChange={(e)=>{setFormFields({...formFields, desc : e.target.value})}}
                        value={formFields.desc}
                    />
                </fieldset>
                <fieldset className='col-4'>
                    <legend className='fs-5'>Periodo</legend>
                    <div className='row'>
                        <div className='col'>
                            <label for="cronogramaA1" class="form-label"   style={{fontSize: '0.8em'}}>Año 1</label>
                            <select id='cronogramaA1' nom='cronogramaA1' className='form-select'
                            onChange={(e)=>{setFormFields({...formFields,CronogramaA1s : [...e.target.selectedOptions].map(opt => Number(opt.value))})}}
                                value={formFields.CronogramaA1s}
                            multiple size={12}>
                                
                                {lMeses.map(({id,nom},index) => <option key={index} value={id}>{nom}</option>)}
                            </select>
                    
                        </div>
                        <div className='col'>
                            <label for="cronogramaA2" class="form-label"  style={{fontSize: '0.8em'}}>Año 2</label>
                            <select id='cronogramaA2' nom='cronogramaA2' className='form-select'
                                value={formFields.CronogramaA2s}
                                onChange={(e)=>{setFormFields({...formFields,CronogramaA2s : [...e.target.selectedOptions].map(opt => Number(opt.value))})}}
                            multiple size={12}>
                                
                                {lMeses.map(({id,nom},index) => <option key={index} value={id}>{nom}</option>)}
                            </select>
                        </div>
                    </div>
                </fieldset>
                <fieldset className='col-4'>
                    <legend className='fs-5'>Estado</legend>
                    <label for="estado" class="form-label" style={{fontSize: '0.8em'}}>Estado</label>
                    <select id='estado' nom='estado' className='form-select form-select-sm'  
                        onChange={(e)=>{setFormFields({...formFields,estado : e.target.value})}}
                    value={formFields.estado}>
                        <option value={''}>Seleccione</option>
                        <option value={1}>Pendiente</option>
                        <option value={2}>Finalizada</option>
                        <option value={3}>Suspendida</option>

                    </select> 
                    <label for="resultados" class="form-label" style={{fontSize: '0.8em'}}>Resultados / Observaciones</label>
                    <textarea 
                        onChange={(e)=>{setFormFields({...formFields, resultados : e.target.value})}}
                        id='resultados' nom='resultados' className='form-control' rows={8} cols={50} 
                        value={formFields.resultados}
                        />
                </fieldset>
              </div>
           </div>
        </div>
        <hr/>
     <div className='col d-flex justify-content-center'>
        <input className='btn btn-primary ps-3 pe-3' type='submit' value={'Agregar'} />
     </div>
    </form>
     
    </div>
  )
}
