
export const obtenerCamposAValidarModalEquipoExtension = ( tipoIntegrante = '') => {
    let salida = [];
    switch (tipoIntegrante) {
        case 'otro':
            salida = ['dni','ape','nom','tel','dom','email','tipoIntegrante']
            break;
        case 'doc':
            salida = ['dni','ape','nom','tel','dom','tieneTarjeta','unidadAcademica','categoriaDocente','email','titulo','categoriaDedicacion','tipoIntegrante'];
            break;
        case 'nodoc':
            salida = ['dni','ape','nom','tel','dom','tieneTarjeta','email','tipoIntegrante']
            break;
        case 'grad':
            salida = ['dni','ape','nom','tel','dom','tieneTarjeta','unidadAcademica','titulo','email','tipoIntegrante']
            break;
        case 'est':
            salida = ['dni','ape','nom','tel','dom','unidadAcademica','periodoLectivo','email','tipoIntegrante']
            break;
        default:
            salida = ['dni','ape','nom','tipoIntegrante']
            break;
    }
    return salida;
}

