import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { startEliminarComision, startSetFocusComision } from '../../actions/comsion'
import { abrirModalComisionEvaluadora } from '../../actions/ui'
import { FilaTabla } from '../Tabla/FilaTabla'
import { Tabla } from '../Tabla/Tabla'
import { FilaComisionEvaluadora } from './FilaComisionEvaluadora'
import { useEffect } from 'react'

export const TablaComisionEvaluadora = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const {listaComision} = useSelector(state => state.comisionReducer);
    const [anios, setAnios] = useState([]);
    const [anioSelec, setAnioSelec] = useState('');
    const [cLocalComisiones, setcLocalComisiones] = useState( [] )

       
    useEffect(() => {
        if(anios.length < 1 ){
           const mProyectosXAnio = new Map();
           listaComision.filter( c => c.tipo !== 'PE')
           .forEach( c => c.listaP.forEach(p => mProyectosXAnio.set(`20${p.codigo.split('-')[0]}`,p) ) );

           setAnios([...mProyectosXAnio.keys()]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listaComision]);

    useEffect(() => {
        if(anioSelec !== '' ){
          setcLocalComisiones(
            listaComision.filter( c => c.tipo !== 'PE')
                .filter( c => c.listaP.some( p => `20${p.codigo.split('-')[0]}` === anioSelec))
          );
        }  
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [anioSelec]);
    
    const handleFiltrar = (e)=>{
        e.preventDefault();
        const anio = e.currentTarget.value;
        setAnioSelec(anio);

    }


    


  return (
    <Tabla 
        _tipo={'comision'} 
        
        _gestionable = {true} 
        _gestiona={{
            _handleBorrar : (e,_id)=>{
                e.preventDefault();
                dispatch(startEliminarComision(_id,history));
             
            },
            _handleEditar : (e,_id)=>{
                e.preventDefault();
                dispatch(startSetFocusComision(_id));
            },
            _handleCrear : ()=>{dispatch(abrirModalComisionEvaluadora())}
        }}  
    >
         <colgroup>
                            
            <col span='1' style={{width: '10%'}}></col>
            <col span='1' style={{width: '25%'}}></col>
            <col span='1' style={{width: '25%'}}></col>
            <col span='1' style={{width: '10%'}}></col>
            
        </colgroup>
        <thead className='table bg-primary text-white'>
            <FilaTabla key={`comision-cabecera-1`} _id={3} _cabecera={true} _titulo={true}>
                <th className='text-center' colSpan={5}> COMISIONES EVALUADORAS </th>
               
            </FilaTabla>
            <FilaTabla key={`comision-cabecera-3`} _id={4} _cabecera={true} _titulo={true}>
                <th colSpan={5}>
                    <select 
                        name={'filtro'}
                        className='form-select bg-white'
                        onChange={handleFiltrar} 
                        value={anioSelec} 
                    >
                        {anios.map(a => <option id={a} value={a}>{a}</option>)}
                    </select>
                </th>
            </FilaTabla>
            <FilaTabla key={`comision-cabecera-2`} _cabecera={true} _id={1} >
                <th>ID</th>
                <th>INTEGRANTES</th>
                <th>PROYECTOS ASIGNADOS</th>
                <th>TIPO COMISION</th>
            </FilaTabla>
        </thead>
        <tbody>
           
        {
            cLocalComisiones.map( (comision,i) => {

                if(comision.tipo !== 'PE'){
                    return <FilaTabla key={`${Date.now()}-comision-${i}`} _cabecera={false} _id={comision.id}>
                                <FilaComisionEvaluadora 
                                        key={`${Date.now()}-comision-${i}`}
                                        _idComision =  {comision.id}
                                        _tipoComision = {comision.tipo}
                                        _listaEvaluadores = {comision.listaEval}
                                        _listaProyectos  = {comision.listaP}
                                    />
                            </FilaTabla>
                }
                return undefined;
            }
                
            )
        }
           
            
        </tbody>
    </Tabla>
  )
}
