import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { 
   Redirect,
   BrowserRouter as Router,
    Switch 
   } from 'react-router-dom';
import { RutaPrivada } from './RutaPrivada';
import { RutaPublica } from './RutaPublica';

import { Ayuda } from '../pages/Ayuda/Ayuda';
import { Inicio } from '../pages/Inicio/Inicio';
import { LoginUsuario } from '../pages/LoginUsuario';
import { RegistroUsuario } from '../pages/RegistroUsuario';


export const AppRouter = () => {


   const {logueado,usrFocus} = useSelector(state => state.usrReducer);

   useEffect(() => { (!logueado) && window.history.pushState({},'','/') }, [logueado])
   
   // const {esperandoBackend} = useSelector(state => state.uiReducer);

   // eslint-disable-next-line react-hooks/exhaustive-deps
   // useEffect(() => { 
   
   //    if( localStorage.getItem('token') !== null) {
         
   //       dispatch( startActualizarToken()  );
   //    }
    
   //  }, [])
      

  return (

     
      <Router>
         <Switch>

            <RutaPublica path='/ayuda' > <Ayuda rutaPublica={true} /> </RutaPublica>  
            
            <RutaPublica  path='/login' > <LoginUsuario /> </RutaPublica>
         
            <RutaPublica path='/register' > <RegistroUsuario /> </RutaPublica>
         
            <RutaPrivada path='/gestor' > <Inicio _tipo={ usrFocus ? usrFocus.tipoUsuario : 'estandar'} /> </RutaPrivada> 
            
            <Redirect to={'/login'} />
            
         </Switch>
        
      </Router>

    
      
      
      
  )
}
