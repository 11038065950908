

export const CampoTextArea = ({...campoProps}) => {
  
    const {handlecampochange,handleblur,value,valido,...restProps} =campoProps; 
   
  
    return <textarea className={`form-control ${(valido  && (!valido.valido)) ? 'border border-danger' : ''}`}
        {...restProps} 
        onBlur={handleblur} 
        onChange={handlecampochange} 
        value={value}
        /> 
            
}