import React from 'react'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEquipoExtension } from '../hooks/useEquipoExtension';

export const FilaIntegrante = ({_data = {}, _indice }) => {

    const {dni, ape,nom,unidadAcademica,area,tel,email,observ} = _data ;
    const {
        usuarioEsAdmin,
        usuarioEsDueñoDelProyecto,
        hanldeEditarIntegrante,
        handleBorrarIntegrante,
        proyectoEnEstadoRecepProyecto
    } = useEquipoExtension();
  
    return (
    <div className='row row-cols-2 mt-2'>
        <div className='col-10' style={{fontSize : '0.9rem'}}>
            {`${ape},${nom} | ${dni} | ${tel} | ${email} | ${unidadAcademica ? unidadAcademica : `${area ? 'OTRA' : 'NO UNIV.'} (${observ})` }`}
        </div>
        {
            (usuarioEsAdmin() || usuarioEsDueñoDelProyecto()) && proyectoEnEstadoRecepProyecto() 
            ? <div className='col-2'>
                <div className='row'>
                    <div className='col-4 btn btn-outline-secondary text-center justify-center'
                        onClick={(e)=>hanldeEditarIntegrante(e,_indice)} 
                    >
                        <FontAwesomeIcon icon={faEdit} />    
                    </div>
                    <div className='col-4 btn btn-outline-danger text-center justify-center' 
                        onClick={(e)=>handleBorrarIntegrante(e,dni)} 
                    >
                        <FontAwesomeIcon icon={faTrash} />    
                    </div>
                </div>
            </div>
            : <></>
        }
        

    </div>
  )
}
