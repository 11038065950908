import React, { useEffect, useState } from 'react';

import { ListaDocumentacionRequerida } from './ListaDocumentacionRequerida/ListaDocumentacionRequerida';
import { ListaDocumentacionPresentada } from './ListaDocumentaciónPresentada/ListaDocumentacionPresentada';
import { FormAdjuntarDocumento } from './FormAdjuntarDocumento';
import { useFormAdjuntarDocumentos } from './hooks/useFormAdjuntarDocumentos';
import { BotonVolver } from '../../../components/Botones/BotonVolver';
import { useSelector } from 'react-redux';


const CartelProyPresentado = ()=> 
  <div className='alert alert-success text-center'>
    <h3> ¡ Proyecto presentado ! </h3>
  </div>

export const SeccionAdjuntarDocumentos = () => {
  const {pFocus} = useSelector(state => state.pReducer);
  const [documentacionCompleta, setDocumentacionCompleta] = useState(false);
 

  const { lDocAdjuntos, validarDocumentacionCompleta,handleSalir } = useFormAdjuntarDocumentos();
  
  useEffect(() => {
    lDocAdjuntos&&
    lDocAdjuntos.length > 0 &&
    setDocumentacionCompleta( validarDocumentacionCompleta() )
  
    return () => {
      setDocumentacionCompleta(false)
    }
  }, [lDocAdjuntos,setDocumentacionCompleta,validarDocumentacionCompleta])
  
 

  return (
   <div className='container mt-3 p-4 bg-secondary bg-opacity-10'> 
        <div className='row justify-content-between align-items-center  mb-3 border-bottom'>
            <div className='col-10 text-wrapp '>
              <div className='row row-cols-1'>
                <div className='col'>
                  <h3><b>Documentación del proyecto </b> </h3>
                </div>
                <div className='col'>
                  {pFocus.codigo} | {pFocus.director.ape}, {pFocus.director.nom}
                </div>
              </div>
            </div>
            <div className='col-2'>
              <BotonVolver handleSalir={handleSalir} />
            </div>
        </div>
        <div className='row row-cols-1'>
          <div className='col'><ListaDocumentacionRequerida /></div>
       
          <div className='col'><FormAdjuntarDocumento /></div>

          <div className='col'><ListaDocumentacionPresentada /></div>

          <div className='col'>{ documentacionCompleta && <CartelProyPresentado />  } </div>
        </div>
        
    </div>
  
    )   
}
