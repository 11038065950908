import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startEditarComision } from '../actions/comsion';
import { startEditarEvaluacionCalidad } from '../actions/epcal';
import { startEditarEvaluacionPertinencia } from '../actions/eppert';
import { startEditarEvaluacionIpCA } from '../actions/evipca';
import { startEditarEvaluacionIpPE } from '../actions/evippe';
import { startEditarFormIp } from '../actions/ip';
import { startEditarFormProyecto } from '../actions/formp';
import { armarValidacionesForm } from '../helpers/armarValidacionesForm';

/**
 * 
 * @param {*} _estadoInicial , JSON con los campos del formulario.
 *                          useForm llama a armarEstructuraDataForm , 
 *                          cada [clave] : <valor> -> [clave] : { value : <valor> , valido : { valido : true | false , msgError : string}}
 * @returns [campos,handleCampoChange,reset]
 * 
 */


export const useForm = ( 
    _estadoInicial = {} , 
    _prefReducer = '' ,
    _validacionesAdicionales = {} ) => {

    const dispatch = useDispatch();
    
    const prefReducer = useRef(_prefReducer);

    const {pFocus} = useSelector(state => state.pReducer);
    const {comisionFocus} = useSelector(state => state.comisionReducer);
    
    const [campos, setCampos] = useState( _estadoInicial );

    const [validaciones, setValidaciones] = useState( 
        armarValidacionesForm(_estadoInicial) 
    );

    const EquipoExtension = pFocus ? pFocus.EquipoExtension : undefined;
    const IntegranteEquipos = EquipoExtension ? EquipoExtension.IntegranteEquipos : undefined;
     

    useEffect(() => {
        
        if( prefReducer.current === 'ip'){
          
           Object.keys(campos).length > 0 && dispatch(startEditarFormIp(campos));
        }}, [campos,dispatch])

    useEffect(() => {
        
        if( prefReducer.current === 'p'){
         
           Object.keys(campos).length > 0 && dispatch(startEditarFormProyecto(campos));
        }}, [campos,dispatch])

    useEffect(() => {   
       if( prefReducer.current === 'eipca'){
  
           Object.keys(campos).length > 0 && dispatch(startEditarEvaluacionIpCA(campos));
        }}, [campos,dispatch])

    useEffect(() => {
        if( prefReducer.current === 'eippe'){
  
           Object.keys(campos).length > 0 && dispatch(startEditarEvaluacionIpPE(campos));
        }}, [campos,dispatch])
    
    useEffect(() => {
        if( prefReducer.current === 'pcal'){

           Object.keys(campos).length > 0 && dispatch(startEditarEvaluacionCalidad(campos));
        }}, [campos,dispatch])

    useEffect(() => {
        if( prefReducer.current === 'ppert'){
  
           Object.keys(campos).length > 0 && dispatch(startEditarEvaluacionPertinencia(campos));
        }}, [campos,dispatch])

    useEffect(() => {   
        if(
            prefReducer.current === 'eq' && EquipoExtension
        ){
           Object.keys(campos).length > 0 && dispatch(startEditarFormProyecto({
                EquipoExtension : {
                    ...EquipoExtension,
                    ...campos
                }
            }))
        }
        // eslint-disable-next-line
    }, [campos,dispatch]);

    
    useEffect(() => {   
        if(
            prefReducer.current === 'rol'
        ){ 
           Object.keys(campos).length > 0 && dispatch(startEditarFormProyecto({
                EquipoExtension : {
                    ...EquipoExtension,
                    IntegranteEquipos : IntegranteEquipos
                        ? IntegranteEquipos.map(
                            integ => ({
                                ...integ , 
                                lrol : Object.keys(campos)
                                    .filter( rol => 
                                        campos[rol] instanceof Array 
                                            ? campos[rol].find( _dni => _dni === integ.dni ) 
                                            : campos[rol] === integ.dni 
                                        )
                                }
                            )
                        )
                        : []
                }
            }))
        }
        // eslint-disable-next-line
    }, [campos,dispatch]);
    
    
    useEffect(()=>{
        if(prefReducer.current === 'com'){

           Object.keys(campos).length > 0 && dispatch(startEditarComision({
            
                    ...comisionFocus,
                    ...campos
             
            }))
        }
         // eslint-disable-next-line
    }, [campos,dispatch])

    const validarCampo = (e)=>{
        const {
            patternMismatch,
            rangeOverflow,
            rangeUnderflow,
            stepMismatch,
            tooLong,
            tooShort,
            typeMismatch,
            valid,
            valueMissing,
        } = e.currentTarget.validity;
        
        let error = '';

        error += patternMismatch ? 'Caracteres no válidos' : ''; 
        error += rangeOverflow ? 'Valor muy alto': '';
        error += rangeUnderflow ? 'Valor muy bajo': '';
        error += (typeMismatch || stepMismatch) ? 'valor inválido': '';
        error += tooLong ? 'demasiados caracteres': '';
        error += tooShort ? 'faltan caracteres': '';
        error += valueMissing ? 'campo obligatorio': '' ;
        
        if( Object.keys(_validacionesAdicionales).find( nomCampo => nomCampo === e.currentTarget.name ) ){
            error += (!_validacionesAdicionales[e.currentTarget.name].validacion) 
                ? _validacionesAdicionales[e.currentTarget.name].msgError 
                : '';
        }

        setValidaciones ({
            ...validaciones,
            [e.currentTarget.name] : {
                valido : error !== '' ? false : valid,
                msgError : error 
            }
        })
    }


    const obtenerValor = (value,type,selectedOptions,name)=>{
        if(type === 'select-one') return value.length < 2 ? Number(value) : value;
        if(type === 'select-multiple') return [...selectedOptions].map(op => op.value.length < 2 ? Number(op.value) : op.value); 
        if(type === 'radio' ) return Number(value) ;
        return value;
    }

    const handleCampoChange = (e)=>{
        const {name,value,type,selectedOptions} = e.currentTarget;
        setCampos({
            ...campos,
            [name] : obtenerValor(value,type,selectedOptions,name),
        });
        return validarCampo(e);
    }
    

    const validarForm = (nomCampos = [])=> {
        return nomCampos.reduce((salida,nomCampo)=> salida && validaciones[nomCampo].valido ,true);
    } 

    const reset = ()=>{ setCampos(_estadoInicial) }


    return {campos,validaciones,handleCampoChange,setCampos,validarCampo,validarForm,reset};
}
