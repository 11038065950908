export const imprimirCampoRecursivo = ( campo , nivel )=> {
    if( campo === undefined ) return '';
    if( campo instanceof Array) {
         return (
             <ul>
             {
                 campo.map( item => <li>{imprimirCampoRecursivo(item , nivel++)} </li>)
             }
             </ul>
         )
    }
    if( campo instanceof Object ) {
         return Object.keys(campo).map( 
             key => 
                 <>
                     <p className="text-break" > <span >{ key } </span>  : {imprimirCampoRecursivo( campo[key], nivel++ )} </p>
                     {/* <p className='border-light ms-2'>{}</p> */}
                 </>
         )
    }
    return campo;
 }