

import { useDispatch, useSelector } from 'react-redux';
import { startGuardarFormProyectoEquipoExtension } from '../../../../actions/formp';
import { mostrarAlerta } from '../../../../helpers/mostrarAlerta';
import { useForm } from '../../../../hooks/useForm';
import { useGestionarEstados } from '../../../../hooks/useGestionarEstados';
import { useGestionarUsuario } from '../../../../hooks/useGestionarUsuario';

export const useFormProyectoSec2 = () => {
    const dispatch = useDispatch();
    const {pFocus} = useSelector( state => state.pReducer ) ;
    const {lCategEquipo,lProyUlt3Anios} = useSelector( state => state.basesReducer);
  
    const {
      EquipoExtension,
      solicitaBecarioJustif,
      solicitaVoluntarioDet,
      solicitaBecario,
      InstitucionIntervinientes
    } = pFocus;

    const  { ProyectoPrevios, categoriaEquipo } = EquipoExtension;

    const {
      campos : camposEquipoExtension,
      handleCampoChange :handleCampoChangeEquipoExtension ,
      validarCampo :validarCampoEquipoExtension ,
      validarForm :validarFormEquipoExtension ,
    } = useForm({
      ProyectoPrevios : ProyectoPrevios || [] ,
      categoriaEquipo : categoriaEquipo || ''
    },'eq');

    const {
        bloquearFormularioProyectoPorEstadoEvaluacion,
      bloquearFormularioPorProyectoCerrado
    } = useGestionarEstados();

    const {usuarioEsAdmin , usuarioEsDueñoDelProyecto} = useGestionarUsuario();
  
    const formularioBloqueado = ( bloquearFormularioProyectoPorEstadoEvaluacion() || bloquearFormularioPorProyectoCerrado()  );

    const {
      campos,
      validaciones,
      handleCampoChange,
      validarCampo,
      validarForm
    } = useForm({
      solicitaBecario : solicitaBecario || 0,
      solicitaBecarioJustif : solicitaBecarioJustif || '-' ,
      solicitaVoluntarioDet : solicitaVoluntarioDet || '-',
    },'p');

    const definirHabilitacionProyectosPrevios = ()=> formularioBloqueado ? formularioBloqueado : Number(categoriaEquipo) < 2 ;
    const definirRequeridoProyectosPrevios = ()=>Number(categoriaEquipo) > 1;
    const definirHabilitacionSolicitaBecarioJustif = ()=> formularioBloqueado ? formularioBloqueado :  campos['solicitaBecario'] === 0 ;



    const handleSubmitEquipoExtension = (e)=>{
      e.preventDefault();
      if( (!usuarioEsDueñoDelProyecto()) && ( !usuarioEsAdmin() ) ){
                
            alert('No posee permisos para realizar esta acción');
            return;
        }

      if(bloquearFormularioProyectoPorEstadoEvaluacion()){
            alert('No posee permisos para realizar esta acción');
            return;
        }

      if( !validarForm(['solicitaBecarioJustif','solicitaVoluntarioDet'])){
        alert('Error en campos 4.1.b o 4.2, revise los campos ');
        return;
      }
        
      if( categoriaEquipo > 1 && (!validarFormEquipoExtension(['ProyectoPrevios','categoriaEquipo']))) {
        alert(' Error en los campos, revise los campos 3.1 y 3.2')
        return;
      }

      if( (!EquipoExtension.IntegranteEquipos) || EquipoExtension.IntegranteEquipos.length < 1){
        alert(' Error en los campos, el equipo de extensión no puede estar vacío')
        return;
      }

      if( InstitucionIntervinientes.length < 1 ){
        alert(' Error en los campos, debe tener al menos una institución interviniente')
        return;
      }

      if(
          (EquipoExtension.IntegranteEquipos.length > 0) &&
          (
            (! EquipoExtension.IntegranteEquipos.some( integrante => integrante.lrol && integrante.lrol.some( rol => rol === 'dir') )) ||
            (! EquipoExtension.IntegranteEquipos.some( integrante => integrante.lrol && integrante.lrol.some( rol => rol === 'finan')))
          )
        ){
          alert(' Falta asignar los roles de director/a y de responsable financiero/a ')
          return;
        }

      if(
         !( EquipoExtension.IntegranteEquipos.length > 0 && EquipoExtension.categoriaEquipo && InstitucionIntervinientes.length > 0)
        ) {
          let msgError = '';
          if(EquipoExtension.IntegranteEquipos.length < 1) msgError += 'Revise que tenga al menos 1 integrante en el equipo \n';
          if(InstitucionIntervinientes.length < 1) msgError += 'Revise que al menos tenga 1 institucion interviniente \n';
          if(!EquipoExtension.categoriaEquipo) msgError += 'Debe asignar una categoría al equipo';
        
          alert(msgError);
          return;
      }

      mostrarAlerta('¿Desea guardar la sección ?','Guardar Sección',true)
      .then( resp => {
        resp.isConfirmed &&
          dispatch( startGuardarFormProyectoEquipoExtension() );
        
      } )


    }


  return {
      pFocus,
      handleSubmitEquipoExtension,
      definirHabilitacionProyectosPrevios,
      definirRequeridoProyectosPrevios,
      definirHabilitacionSolicitaBecarioJustif,
      campos,
      validaciones,
      handleCampoChange,
      validarCampo,
      formularioBloqueado,
      camposEquipoExtension,
      handleCampoChangeEquipoExtension ,
      validarCampoEquipoExtension ,
      lCategEquipo,
      lProyUlt3Anios 
    }
}
