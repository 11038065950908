
const imprimirLineaIntegrante = ({dni,ape,nom,dom,titulo,tel,unidadAcademica,area,observ}) => `${dni} | ${ape} , ${nom} | ${titulo} | ${tel} | ${dom} | ${ unidadAcademica ? unidadAcademica : area ? 'OTRA' : 'NO UNIV.'} - Observ. : ${observ}`;
const imprimirLineaInstInterv = ({Institucion,Responsable}) => {
    const {nom : nomInst,dom : domInst,email :emailInst,tel : telInst} = Institucion;
    const {dni,ape,nom,dom,email,tel} = Responsable;
    return `${`${nomInst} | ${domInst} | ${telInst} | ${emailInst} `} - Responsable a cargo : ${dni} | ${ape}, ${nom} | ${tel} | ${dom} | ${email}`
};
const compararIdCategEq =  (categ,pFocus) => categ.id === pFocus.EquipoExtension.categoriaEquipo;


const imprimirCamposComunes = (pFocus,basesReducer)=>{

    const {
        lProyUlt3Anios,
        lMaterialesEducativos,
        lCategEquipo,
        lMeses
    }= basesReducer
  
    
    const dir = pFocus.EquipoExtension.IntegranteEquipos 
        ? pFocus.EquipoExtension.IntegranteEquipos.find(integ => integ.lrol.find( rol => rol === 'dir')) 
        : undefined;
    const codir = pFocus.EquipoExtension.IntegranteEquipos  
        ?   pFocus.EquipoExtension.IntegranteEquipos.find(integ => integ.lrol.find( rol => rol === 'codir')) 
        : undefined;
    const coord = pFocus.EquipoExtension.IntegranteEquipos  
        ?  pFocus.EquipoExtension.IntegranteEquipos.find(integ => integ.lrol.find( rol => rol === 'coord')) 
        : undefined;
    const finan = pFocus.EquipoExtension.IntegranteEquipos 
        ?  pFocus.EquipoExtension.IntegranteEquipos.find(integ => integ.lrol.find( rol => rol === 'finan')) 
        : undefined;
    const esp = pFocus.EquipoExtension.IntegranteEquipos  
        ?  pFocus.EquipoExtension.IntegranteEquipos.filter(integ => integ.lrol.find( rol => rol === 'esp')) 
        : undefined;
    
    return {
        '1) Duración' :pFocus.bianual &&  pFocus.bianual ? 'Bianual' : 'Anual',
        '2) Palabras clave' : pFocus.PalabraClave_id_PalabraClaves && pFocus.PalabraClave_id_PalabraClaves.join( ' - '),
        '3) Equipo de Extensión' : {
            '3.1)Categoría del equipo' :pFocus.EquipoExtension.categoriaEquipo && lCategEquipo.find ( categ => compararIdCategEq(categ,pFocus) ).nom,
            '3.2)Proyectos previos del equipo' : pFocus.EquipoExtension.ProyectoPrevios && pFocus.EquipoExtension.ProyectoPrevios,
            '3.2)Equipo de extensión' : pFocus.EquipoExtension.IntegranteEquipos && pFocus.EquipoExtension.IntegranteEquipos.map( imprimirLineaIntegrante ),
            'Roles del equipo' : {
                'DIRECTOR/A' : dir && `${dir.ape},${dir.nom}`,
                'CO-DIRECTOR/A' :codir && `${codir.ape},${codir.nom}`,
                'COORDINADOR/A' :coord && `${coord.ape},${coord.nom}`,
                'RESPONSABLE FINANCIERO/A' : finan && `${finan.ape},${finan.nom}`,
                'ESPCIALISTA/S ASOCIADO/S' :esp && esp.map( _esp => `${_esp.ape},${_esp.nom}` )
            }
        },  
        '4) Becarios y Voluntariados' : {
            '4.1.a) Solicita becario ?' : pFocus.solicitaBecario ? 'Si' : 'No',
            [`4.1.b) Justifique el pedido, detalle el perfil y mencione las actividades que desarrollará.`] : pFocus.solicitaBecarioJustif && pFocus.solicitaBecarioJustif,
            [`4.2) Voluntariado: número de voluntarios requeridos, perfil y actividades en las que participarán.`] : pFocus.solicitaVoluntarioDet
        },
        '5) Instituciones Intervinientes' : pFocus.InstitucionIntervinientes && pFocus.InstitucionIntervinientes.map( imprimirLineaInstInterv  ),
        '6) Problema a Abordar' :{
            '6.1)Síntesis del problema' :pFocus.sintesisProblemaAbordar && pFocus.sintesisProblemaAbordar,
            '6.2.a)Descipción del contexto social ' : pFocus.detalleProblemaAbordar && pFocus.detalleProblemaAbordar,
            '6.2.b)Geolocalización ' : pFocus.geolocalizacionProblemaAbordar && pFocus.geolocalizacionProblemaAbordar,
            
            '6.3)Proyectos ejecutados o en ejecución relacionados al tema' : pFocus.ProyectoRelacionados ? pFocus.ProyectoRelacionados.map( proy => lProyUlt3Anios.find( _proy => _proy.id === proy ).nom ) : [],
            
        },
        '7) Coherencia Interna' : pFocus.CoherenciaInterna && {
            'Finalidad' : pFocus.CoherenciaInterna.finalidad,
            'Obj. Gral.' : pFocus.CoherenciaInterna.objetivoGeneral,
            'Obj. Específicos' : pFocus.CoherenciaInterna.ObjetivoEspecificos.map(
                (objEsp,i) => ({ 
                    [`OE-${i+1}`] : objEsp.desc,
                    'Res. Esp.' : objEsp.resEsp,
                    'Actividades' : objEsp.ActividadObjetivos.map( 
                        (actObj,i) => ({
                            [`A-${i+1}`] : actObj.desc,
                            'cronograma' : pFocus.bianual 
                                    ? `Año 1 : [${ actObj.CronogramaA1s ?  actObj.CronogramaA1s.map( ({Mes_id}) => lMeses.find(_mes => _mes.id === Number(Mes_id)).nom.slice(0,3)) : '' }] - Año 2 : [${actObj.CronogramaA2s ? actObj.CronogramaA2s.map( ({Mes_id}) => lMeses.find(_mes => _mes.id === Number(Mes_id)).nom.slice(0,3)) : ''}]`
                                    : `[${ actObj.CronogramaA1s.map( ({Mes_id}) => lMeses.find(_mes => _mes.id === Mes_id).nom) } ]`
                                }))
                })
            )
        },
        '8) Propuesta Metodológica' : {
            '8.1)Metodología que implementará para el desarrollo del proyecto, incluyendo el marco teórico de trabajo' : pFocus.propuestaMetodologica && pFocus.propuestaMetodologica,
        },
        '9) Vinculación con las políticas públicas' :pFocus.politicasPublicas && pFocus.politicasPublicas,
        '10) Comunicación. Acciones y materiales.' : {
            '10.1)Enunciar acciones de comunicación' :pFocus.accionesComunicacion && pFocus.accionesComunicacion,
            '10.2)El proyecto producirá materiales educativos y/o de comunicación?' :pFocus.MaterialEducativo_id_MaterialEducativos && pFocus.MaterialEducativo_id_MaterialEducativos.map( idMaterial => lMaterialesEducativos.find( material => material.id === idMaterial).nom )
        }
    }
}

export const pFocusToJson = ( pFocus = {}, basesReducer = {})=>{

    const {
        lIntegracion,
        lInstanciasCapacitacion
        
    } = basesReducer;

    const camposComunes = imprimirCamposComunes(pFocus,basesReducer);
    return pFocus.tipoProyecto === 'AET'
    ?
        {
            ...camposComunes,
            '8) Propuesta Metodológica' : {
                ...camposComunes['8) Propuesta Metodológica'],
                [`8.2.1) Perfil de los agentes multiplicadores.`] : pFocus.perfilAgentesMultip,
                [`8.2.2) Enuncie la planificación prevista para capacitar agentes multiplicadores (talleres, cursos, etc.).`] : pFocus.planifCapacitacionAgentes,
            },
            '11) Capacitaciones que le interese realizar al equipo' :pFocus.InstanciaCapacitacion_id_InstanciaCapacitacions && pFocus.InstanciaCapacitacion_id_InstanciaCapacitacions.map( cap => lInstanciasCapacitacion.find( instancia => instancia.id === cap).nom  ) ,
            '12) Sustentabilidad' :pFocus.sustentabilidad && pFocus.sustentabilidad ,
            '13) Síntesis del proyecto de extension' :pFocus.sintesis && pFocus.sintesis
        }
        
    :
    {
        ...camposComunes,
        '8) Propuesta Metodológica' : {
            ...camposComunes['8) Propuesta Metodológica'],
            '8.2)Participantes Directos' :pFocus.ParticipanteDirectos && pFocus.ParticipanteDirectos.map( ({nom}) => ({nom})),
        },
        '11) Integralidad de funciones.' :  {
            '11.1) Tipo de integralidad' :pFocus.tipoIntegracion && lIntegracion.find( tipoInteg => tipoInteg.id === pFocus.tipoIntegracion).nom,
            '11.2) Según selección. Explicar de que forma se realizará.' :pFocus.integracionDesc && pFocus.integracionDesc
        },
        '12) Capacitaciones que le interese realizar al equipo' :pFocus.InstanciaCapacitacion_id_InstanciaCapacitacions && pFocus.InstanciaCapacitacion_id_InstanciaCapacitacions.map( cap => lInstanciasCapacitacion.find( instancia => instancia.id === cap).nom  ) ,
        '13) Sustentabilidad' :pFocus.sustentabilidad && pFocus.sustentabilidad ,
        '14) Síntesis del proyecto de extension' :pFocus.sintesis && pFocus.sintesis
    }
}