import React from 'react'
import { ItemPresupuestoProyecto } from './ItemPresupuestoProyecto'
import { ItemEquipoExtension } from './ItemEquipoExtension'
import { ItemInstitucionesIntervinientes } from './ItemInstitucionesIntervinientes'
import { ItemConstanciaDirector } from './ItemConstanciaDirector'
import { ItemConstanciaJefeCatedra } from './ItemConstanciaJefeCatedra'

export const ListaDocumentacionRequerida = () => {
  return (
    <div style={{fontSize:'15px'}}>

        <p>Documentación requerida para la presentación: </p>

        
        <ul className='list-group'>
            <ItemPresupuestoProyecto />
       
            <ItemEquipoExtension />

            <ItemInstitucionesIntervinientes />

            <ItemConstanciaDirector />
            <ItemConstanciaJefeCatedra />
        </ul>
       
    </div>
    
  )
}
