import React from 'react'

export const FilaComisionEvaluadora = ({ 
    _idComision = '' , 
    _tipoComision = '',
    _listaEvaluadores = [], 
    _listaProyectos = []
}) => {


  return (
    <>
        <td>{_idComision}</td>
        <td>
            <ul>
               {
                   _listaEvaluadores.map( (evaluador,i) => <li key={`eval-${i}`}>{evaluador.ape}, {evaluador.nom}</li> )
               }
            </ul>
        </td>
       
        <td>
            <ul>
                {
                    _listaProyectos.map( (proy,i) => <li key={`proyAsig-${i}`}>{proy.codigo}</li>)
                }
            </ul>
        </td>
        <td>
            {_tipoComision}
        </td>
    </>
  )
}
