

import React   from 'react';


export const CampoSelect = (props) => {
    const {
        metodoSort,
        opciones ,
        value,
        ...campoProps
    } = props;

    const {handlecampochange,handleblur,valido,...restProps} =campoProps; 
    
    const handleResetSelect = (e)=>{ 
        e.preventDefault();
        
        (!restProps.disabled) && handlecampochange({
            currentTarget : {
                name : campoProps.name,
                value : [],
                type : 'select-multiple',
                selectedOptions : [],
                validity : {
                    valid : restProps.required === undefined ? true : (!restProps.required),
                    valueMissing : restProps.required
                }
            }
        });

    }

    const ordenarPorNombre = (opca,opcb) => opca.nom.localeCompare(opcb.nom) ;
   
    return (
    <div className='row' >
        <div className='col text-muted'>
        {
            restProps.multiple &&
            <>
                <small>Para seleccionar  más de una opción consecutiva , haga clic y arrastre el puntero. </small>
                <small>Para seleccionar elementos puntuales, presione CTRL y haga clic en los ítems deseados. </small>
            </>
        }
        <select className={`form-select ${(valido && (!valido.valido)) ? 'border border-danger' : ''}`} 
            style={{fontSize: `${props.secRoles ? '0.8rem' : '1rem'}`}}
            value = { value ? value : campoProps.multiple ? [] : '' }
            onChange={handlecampochange}
            onBlur={handleblur}
            {...restProps} 
            
        >
            { campoProps.multiple ? undefined :  <option key={`${campoProps.name}-00`} value={''}>Seleccione</option>}
            {
                opciones && opciones.length > 0 
                    ? opciones.sort( !metodoSort ? ordenarPorNombre : metodoSort ).map( (opc,i) => <option key={`${campoProps.name}-${i}`} value={opc.id}>{opc.nom}</option>)
                    : <></>
            }

        </select>
        {
            campoProps.multiple &&
            <>
                <p>Seleccionado:</p>

                <div >
                    <ul> 
                        { 
                            value && 
                            value.map((item,i) => 
                                <li key={`opc-selected-${i}`}>
                                    {opciones.find(opc => String(opc.id) === String(item)).nom || ''}
                                </li>
                            )
                        }
                    </ul>
                    {
                        (!restProps.disabled) &&
                        <button className='btn btn-link'  onClick={handleResetSelect} >  Vaciar Selección </button>
                    }
                   
                </div>
            
               
            </>
        }
        </div>
    </div>
  )
}



