import React, { useState } from 'react'
import { Spiner } from '../Ui/Spiner';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActividadesCargadas } from '../FormularioInforme/ActividadesCargadas';
import { InstitucionesCargadas } from '../FormularioInforme/InstitucionesCargadas';
import { ParticipantesDirectos } from '../FormularioInforme/ParticipantesDirectos';
import { MaterialesEducativos } from '../FormularioInforme/MaterialesEducativos';
import { PoliticasPublicas } from '../FormularioInforme/PoliticasPublicas';
import { resetInfAv, startMostrarInforme, startPutInforme} from '../../actions/informe';
import { BotonVolver } from '../Botones/BotonVolver';
import { useGestionarEstados } from '../../hooks/useGestionarEstados';
import { mostrarAlerta } from '../../helpers/mostrarAlerta';
import { useHistory } from 'react-router-dom';
import { resetCargaInfAvance, startFinishLoadingForm, startLoadingForm } from '../../actions/ui';
import { Becarios } from '../FormularioInforme/Becarios';



const SECCIONES = {
  ACT_CARGADAS : 'ACT_CARGADAS',
  INSTITUCIONES : 'INSTITUCIONES',
  PART_DIR : 'PART_DIR',
  POL_PUB: 'POL_PUB',
  MAT_EDUC : 'MAT_EDUC',
  BECARIOS : 'BECARIOS'
}

export const FormularioInformeAvance = () => {
  const dispatch = useDispatch();
  const {cargandoForm,formInformeAvanceCargado,guardandoForm} = useSelector(state => state.uiReducer);

  const { pFocus } = useSelector(state =>state.pReducer);
  const { lMeses } = useSelector( state => state.basesReducer)
  const { infAvFocus } = useSelector(state => state.infAvReducer);
  const { codigo } = useSelector( state => state.pReducer.pFocus );

  const history = useHistory();

  const [seccionFocus, setSeccionFocus] = useState('')
  
  useEffect( ()=>{ 
   
    if(!formInformeAvanceCargado) {
       dispatch(startLoadingForm());

        dispatch( startMostrarInforme(false) ) ;
    } 
    
    return () => {
      dispatch(startFinishLoadingForm());
    }

  }, [formInformeAvanceCargado,dispatch]  );

  const {titulo, director} = infAvFocus;
  
  const handleGuardarInforme = (e )=>{
    e.preventDefault();
    dispatch(startPutInforme());
  }

  const {chequearProyectoNuevo} = useGestionarEstados();

  const handleSalir =(e)=> {
    e.preventDefault();
    
    mostrarAlerta( '¿Desea salir del formulario? Se perderán todos los campos que no haya guardado','Volver',  true)
      .then( resp => {
        if(resp.isConfirmed  ){
          history.push(`${chequearProyectoNuevo() ? '/gestor'  : `/gestor/panel/${pFocus.id}` }`) ;
          dispatch(resetInfAv());
          dispatch(resetCargaInfAvance())
        }
        
      });
  }
  


  return (
   <>
    {
      ( cargandoForm )
      ? <Spiner />
      : 
        formInformeAvanceCargado &&
        <div className='container-xxl bg-light' style={ { height : '56em' }}>
          <div className='row'>
            <div className='col-10'><h2>INFORME DE AVANCE</h2> </div>
            <div className='col-2'> <BotonVolver handleSalir={handleSalir}/> </div>
          </div>
          <div className='row'>
              <div className='col'>
            
                <ul className='list-group list-group-flush'>
                  <li className='list-group-item '> <span className='fw-bold'> PEIS     </span> "{titulo ? titulo : '' }"   </li>
                  <li className='list-group-item '> <span className='fw-bold'> UA     </span> {codigo ? codigo.split('-')[3] : ''}  </li>
                  <li className='list-group-item '> <span className='fw-bold'> Director/a    </span>  {director ? `${director.ape}, ${director.nom}` : ''}  </li>
                  {
                  /* 
                    <li className='list-group-item '> <span className='fw-bold'> Provincia    </span> <input className='form-control' id={'provincia'} onChange={handleOnChange}type={'text'} name={'provincia'} value={provincia}/>  </li>
                    <li className='list-group-item '> <span className='fw-bold'> Ciudad    </span> <input className='form-control' id={'ciudad'} onChange={handleOnChange}type={'text'} name={'ciudad'} value={ciudad}/>   </li>
                    <li className='list-group-item '> <span className='fw-bold'> Barrio    </span> <input className='form-control' id={'barrio'} onChange={handleOnChange}type={'text'} name={'barrio'} value={barrio}/>  </li>
                    <li className='list-group-item '> <span className='fw-bold'> Distrito Municipal    </span>  <input  className='form-control' id={'distritoMunicipal'} onChange={handleOnChange} type={'text'}name={'distritoMunicipal'} value={distritoMunicipal}/>  </li>
                  */
                  }
                </ul>
              </div>
          </div>
         
         <div className='row row-cols-1 mt-3'>
              <div className='col'>
                <div className='row'>
                  <div className='col-9'>
                    <ul className='list-group list-group-horizontal'>
                      <li className={`list-group-item btn rounded-0  ${seccionFocus === SECCIONES.ACT_CARGADAS ? 'bg-secondary text-light' : '' }`} onClick={()=>setSeccionFocus(SECCIONES.ACT_CARGADAS)}> ACTIVIDADES CARGADAS</li>
                      <li className={`list-group-item btn rounded-0  ${seccionFocus === SECCIONES.BECARIOS ? 'bg-secondary text-light' : '' }`} onClick={()=>setSeccionFocus(SECCIONES.BECARIOS)}> BECAS Y VOLUNT.</li>
                      <li className={`list-group-item btn rounded-0  ${seccionFocus === SECCIONES.INSTITUCIONES ? 'bg-secondary text-light' : '' }`} onClick={()=>setSeccionFocus(SECCIONES.INSTITUCIONES)}> INSTITUCIONES</li>
                      <li className={`list-group-item btn rounded-0  ${seccionFocus === SECCIONES.PART_DIR ? 'bg-secondary text-light' : '' }`} onClick={()=>setSeccionFocus(SECCIONES.PART_DIR)}> PARTICIPANTES DIRECTOS</li>
                      <li className={`list-group-item btn rounded-0  ${seccionFocus === SECCIONES.POL_PUB ? 'bg-secondary text-light' : '' }`} onClick={()=>setSeccionFocus(SECCIONES.POL_PUB)}> POLÍTICAS PÚBLICAS</li>
                      <li className={`list-group-item btn rounded-0  ${seccionFocus === SECCIONES.MAT_EDUC ? 'bg-secondary text-light' : '' }`} onClick={()=>setSeccionFocus(SECCIONES.MAT_EDUC)}>COMUNICACIÓN Y MATERIALES</li>
                      
                    </ul>
                  </div>
                  <div className='col-3 d-flex align-content-center justify-content-end'>
                    <button className=' btn btn-primary' onClick={handleGuardarInforme} disabled={guardandoForm}>GUARDAR INFORME</button> 
                  </div>
                </div>
               
              </div>
              <div className='col'>
                {seccionFocus === SECCIONES.ACT_CARGADAS && <ActividadesCargadas lMeses={lMeses} />}
                {seccionFocus === SECCIONES.INSTITUCIONES && <InstitucionesCargadas />}
                {seccionFocus === SECCIONES.PART_DIR && <ParticipantesDirectos />}
                {seccionFocus === SECCIONES.POL_PUB && <PoliticasPublicas />}
                {seccionFocus === SECCIONES.MAT_EDUC && <MaterialesEducativos />}
                {seccionFocus === SECCIONES.BECARIOS && <Becarios />}
                   
              </div>
         </div>
         
      </div>
        
      
    }
   
   </>
  )
}
