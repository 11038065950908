import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useGestionarConvocatoria } from '../../hooks/useGestionarConvocatoria'
import { useSelector } from 'react-redux'
import { AyudaUsuarioLogueado } from './AyudaUsuarioLogueado'

export const Ayuda = ({ rutaPublica=false }) => {

    const history = useHistory();

    const {anioConvocatoria} = useGestionarConvocatoria();
    const {logueado} = useSelector(state => state.usrReducer);
    const handleVolver  = (e)=>{
        e.preventDefault();
        history.goBack();
      }

    const  handleRedirigir = (e)=>{
        e.preventDefault();

        window.open(e.target.href,'_blank');

    }
  return (
    <div className='container animate__animated animate__fadeIn'>
        {
            (rutaPublica) &&
            <>
             < div className='row' >
                <div className='col d-flex justify-content-end'>
                   <span className='btn btn-outline-primary'>
                   <FontAwesomeIcon 
                        title={'Volver a inicio'}
                        onClick={handleVolver} 
                        icon={faArrowLeftLong} 
                        size={'3x'}
                    />
                   </span>
                </div>
            </ div>
            <hr />
            </>
        }
       
        <div className='row row-cols-1 ms-4'>
            <div className='col-9'>
                <p>En esta sección se encuentran instructivos y manuales relacionados al uso del Gestor de 
                    Proyectos de Extensión , como también reglamentos , resoluciones, cronogramas y demás 
                    documentos relacionados a la convocatoria vigente de proyectos de extensión AET y PEIS.
                </p>
                
            </div>
            <div className='col-9'>
                <li className='text-primary fs-4'>Disposiciones generales y reglamentos</li>
                <p>
                    En la página de la UNL, se encuentra tanto las disposiciones generales
                    de la convocatoria vigente donde figura el cronograma y marco teórico de la 
                    información solicitada en los formularios de carga.
                </p>
                <p>
                    Por otro lado también se encuentra el reglamento del Sistema Integrado de Programas, Prácticas y 
                    Proyectos de Extensión (S.I.P.P.P.E), donde están las condiciones de aceptación de una idea proyecto.
                </p>
                <p>Puede acceder a esta documentación siguiendo este enlace:  
                    <a onClick={handleRedirigir} className='ms-2' href='https://www.unl.edu.ar/extension/categorias/proyectos/convocatorias/'> 
                        Disposiciones generales de la Convocatoria {anioConvocatoria} </a>
                </p>
            </div>
           
            {
                logueado  ? <AyudaUsuarioLogueado />  :<></>
            }

        </div> 
        
            
           
            
        <div className='col-10'>
                <div className='row alert alert-secondary'>
                    <p> Por consultas relacionadas al gestor de proyectos comunicarse con <b>Mesa de Ayuda</b> enviando un correo a: 
                        <a className='ms-3' href='mailto:gestor.extunl@gmail.com'>gestor.extunl@gmail.com </a> 
                    </p>
                </div>
                <div className='row'>
                    <p>Por consultas relacionadas a la convocatoria comunicarse con: </p>
                    <div className='col-4 border-end border-secondary'>
                        <p>Área Proyectos de Extensión</p>
                    </div>
                    <div className='col-6'>
                        <li>Tel: <>{'(0342) 4575136 Int. 108 /109'}</></li>
                        <li>Correo Electrónico : <a className='ms-3' href='mailto:proyectosextensionunl@gmail.com'>proyectosextensionunl@gmail.com</a></li>
                    </div>
                </div>
               
            </div>
  
    </div>
  )
}
