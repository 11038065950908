
import validator from 'validator'

export const validarCamposIntegranteEquipo = (tipoInteg = '', campos = {})=>{

    let salida = true;
    console.log(tipoInteg)
    salida = salida && campos.dni && validator.isNumeric(campos.dni) && campos.dni.length > 7 && campos.dni.length < 9;
    console.log('dni',salida);
    salida = salida && campos.ape &&  validator.isAlpha(campos.ape,'es-ES',{ignore: ' \''}) && validator.isLength(campos.ape,{min : 1});
    console.log('ape',salida);
    salida = salida && campos.nom &&  validator.isAlpha(campos.nom,'es-ES',{ignore: ' \''}) && validator.isLength(campos.nom,{min : 1});
    console.log('nom',salida);
    salida = salida && campos.tel &&  validator.isAlphanumeric(campos.tel,'es-ES',{ignore : '-()+,. '}) && validator.isLength(campos.tel,{min : 1});
    console.log('tel',salida);
    salida = salida && campos.dom &&  validator.isAlphanumeric(campos.dom,'es-ES',{ignore : '-(),. '}) && validator.isLength(campos.dom,{min : 1});
    console.log('dom',salida);
    salida = salida && campos.email && validator.isEmail(campos.email);
    console.log('email',salida);
    if(tipoInteg !== 'est' && tipoInteg !== 'otro') salida = salida && (campos.tieneTarjeta === 1 || campos.tieneTarjeta === 0 );  
    console.log('tieneTarjeta',salida);
    if(tipoInteg !== 'otro' && campos.unidadAcademica) salida = salida &&  validator.isAlpha(campos.unidadAcademica)  ; 
    console.log('unidadAcademica',salida);
    
    if(tipoInteg ==='nodoc') salida = salida && (campos.ubicacion === 0 || campos.ubicacion === 1) ;
    console.log('ubicacion',salida);
    if(tipoInteg === 'nodoc') salida = salida &&  (validator.isAlpha(campos.area) || validator.isAlpha(campos.unidadAcademica));  
    console.log('area o unidadAcademica',salida);
    if(tipoInteg === 'doc') salida = salida &&  validator.isAlpha(campos.categoriaDocente); 
    console.log('categoriaDocente',salida);
    if(tipoInteg !== 'nodoc' && tipoInteg !== 'otro') salida = salida &&  validator.isAlpha(campos.titulo,'es-ES',{ignore : ' -.,/'}); 
    console.log('titulo',salida);
    if(tipoInteg === 'doc') salida = salida &&  validator.isAlpha(campos.categoriaDedicacion); 
    console.log('categoriaDedicacion',salida);
    if(tipoInteg === 'est') salida = salida &&  validator.isAlpha(campos.periodoLectivo) ; 

    console.log('periodoLectivo',salida);

    return salida;
}