import { createContext } from 'react'

export const ContextForm =  createContext({
    activarVistaPrevia : false,
    idForm : null,
    imprimible : false,
    handleSubmit : ()=>{},
    titulo : '',
    tipoFormulario : '',
    tipoEvaluacion : ''
})