import React from 'react'
import { useSelector } from 'react-redux'

export const Spiner = () => {
  const {estadoQuery} = useSelector(state => state.uiReducer);

  return (
    <>
      <div id='loading_spiner' className="mt-5 d-flex justify-content-center animate__animated animate__fadeIn">
        <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
           
        </div>

     </div>
     <div className='mt-5 d-flex justify-content-center animate__animated animate__fadeIn'>
      {estadoQuery && <span className="animate__animated animate__fadeInLeft">{estadoQuery}</span>}
     </div>
    </>
  )
}
