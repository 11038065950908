
import React, { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { VistaPreviaEvaluacion } from './VistaPreviaEvaluacion';
import { formatearCamposParaImpresion } from '../../helpers/formatearCamposParaImpresion';
import { useDispatch, useSelector } from 'react-redux';
import { Spiner } from '../Ui/Spiner';
import { Formulario } from '../Formulario/Formulario';
import { startGetEstructuraFormEvaluacion } from '../../actions/bases';
import { obtenerEstructuraEvaluacion } from './obtenerEstructuraEvaluacion';
import { CabeceraEvaluacion } from './CabeceraEvaluacion';
import { EstructuraFormEvaluacion } from './EstructuraFormEvaluacion';
import { useForm } from '../../hooks/useForm';



export const FormEvaluacion = ( {
    titulo= '',
    imprimible = true,
    handleSubmitEvaluacion = ()=>{},
    _tipoEvaluacion = ''
} ) => {

    const dispatch = useDispatch();

    const refVistaPrevia = useRef();

    const {ipFocus} = useSelector(state => state.ipReducer);
    const {pFocus} = useSelector(state => state.pReducer);
    const {cargandoForm} = useSelector(state => state.uiReducer);
    const evalFocus = useSelector(state => state[`${_tipoEvaluacion}Reducer`][`${_tipoEvaluacion}Focus`]);
    const {id : idForm} = evalFocus;

    const { duracion : bianual} = pFocus;

    const [estructura, setEstructura] = useState(null);

    const [camposParaImpresion, setCamposParaImpresion] = useState(null);

 
    const {
        campos , 
        validaciones , 
        handleCampoChange , 
        validarCampo ,
    } = useForm( evalFocus ,_tipoEvaluacion);


    const formEval = pFocus 
        ? pFocus.lFormsEval.find( _form => _form.id === idForm) 
        : null;



    useEffect(() => {
        (!estructura) && dispatch(startGetEstructuraFormEvaluacion(estructura,setEstructura,_tipoEvaluacion));
    }, [_tipoEvaluacion,estructura,setEstructura,dispatch]);


    useEffect(() => {
        estructura && campos && setCamposParaImpresion(obtenerEstructuraEvaluacion(estructura,campos))
    }, [estructura,campos])
    

    
      

  return (
   <>
        {
            (!estructura) || (cargandoForm) || (!pFocus.lFormsEval) ||(!formEval)
            ? <Spiner />
            :
            <Formulario
                idForm={idForm}
                imprimible={imprimible}
                titulo={titulo}
                componenteVistaPrevia={
                    <VistaPreviaEvaluacion 
                        evaluadores={
                            pFocus && 
                            pFocus.lFormsEval &&
                            pFocus.lFormsEval
                                .filter(({tipo}) => tipo.startsWith('eipca'))
                                .map(({evaluador}) =>   evaluador  )
                        } 
                        idForm={idForm}
                        tipoEvaluacion={_tipoEvaluacion}
                        secciones = { formatearCamposParaImpresion(camposParaImpresion) } 
                        ref={refVistaPrevia}  
                        duracion={bianual ? 'Bianual' : 'Anual'}
                    /> 
                
                }
                tipoFormulario={_tipoEvaluacion}
                tipoEvaluacion={_tipoEvaluacion}
                handleSubmit={handleSubmitEvaluacion}
                refVistaPrevia={refVistaPrevia}
            >
            
                <CabeceraEvaluacion titulo={ipFocus.titulo} codigo={pFocus.codigo} duracion={bianual ? 'Bianual' : 'Anual'} />
                {
                    _tipoEvaluacion === 'ppert' &&
                    <div className='alert alert-secondary mt-3 mb-3'>
                        <p >
                            {'Según lo establecido por el Reglamento SIPPPE de la Secretaría de Extensión y Cultura (Res. CS 16/2019), está a cargo del Consejo Asesor de la Secretaría, en esta instancia de evaluación es importante tener en cuenta:'}
                        </p><br/>
                        <p className='ms-2 mt-2 mb-2'>{' De la pertinencia propiamente dicha: '}</p><br/>
                        <p >
                            {'Comprendiendo que la pertinencia hace mención a lo perteneciente o correspondiente a algo o a aquello que viene a propósito, lo que es congruente o adecuado con aquello que se espera, se analizará el grado en que los objetivos son coherentes con las necesidades, el contexto, las políticas y la finalidad. '}
                        </p>
            
                        {
                         `
                          Para la evaluación se les solicita a los evaluadores sus consideraciones respecto de los ítems planteados que deberán ser traducidos en PERTINENTE, PERTINENTE CON OBSERVACIONES, o NO PERTINENTE. 
                          Si la comisión considerara que el proyecto no es pertinente deberá fundamentar la decisión.  
                          La Comisión evaluadora podrá realizar sugerencias en relación a los ítems planteados en pos de mejorar la calidad del proyecto.
                          `
                        }
                    </div>
                }
                {
                    
                    campos && 
                    
                    <EstructuraFormEvaluacion 
                        estructura={estructura} 
                        campos={campos} 
                        handleCampoChange={handleCampoChange}
                        validarCampo={validarCampo} 
                        validaciones={validaciones} 
                        tipoForm={_tipoEvaluacion}  
                    />
                }
                

            </Formulario>
        }
       
    </>   
  )
};
