

export const armarValidacionesForm = ( campos )=> Object.keys(campos).reduce ( 
        (estructuraData , nomCampo )=>({
            ...estructuraData,
            [nomCampo] : {
                valido : campos[nomCampo] !== '' ,
                msgError : null
                
            }
        })
    ,{} 
);
