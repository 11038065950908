import React, { useEffect,useState,useRef } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { Spiner } from '../../components/Ui/Spiner';
import { startMostrarFormProyecto } from '../../actions/formp';
import { FormProyectoSec1 } from '../../components/FormProyecto/FormProyectoSec1/FormProyectoSec1';
import { FormProyectoSec2 } from '../../components/FormProyecto/FormProyectoSec2/FormProyectoSec2';
import { FormProyectoSec3 } from '../../components/FormProyecto/FormProyectoSec3/FormProyectoSec3';
import { FormProyectoSec4 } from '../../components/FormProyecto/FormProyectoSec4/FormProyectoSec4';
import { SeccionAdjuntarDocumentos } from '../../components/FormProyecto/FormProyectoSec5/SeccionAdjuntarDocumentos';
import { NavFormProyecto } from '../../components/FormProyecto/NavFormProyecto';




export const FormProyecto = () => {

    const dispatch = useDispatch();
    const refVistaPrevia = useRef();
    
    const {cargandoForm,formProyectoCargado} = useSelector(state => state.uiReducer);
    const {pFocus} = useSelector(state => state.pReducer);
    const {ipFocus} = useSelector(state => state.ipReducer);
    
    

    const {lIntegrantesEquipo} = pFocus && pFocus.oEquipoExt ? pFocus.oEquipoExt : [];
  
    const secciones = pFocus.tipoProyecto === 'PEIS'
    ? [
      'Datos Generales',
      'Equipo Extensión',
      'Problemática y Metodología',
      'Comunic./Capacit. /Integ./Sustent. /Síntesis',
      'Documentación'
    ] 
    :[
      'Datos Generales',
      'Equipo Extensión',
      'Problemática y Metodología',
      'Comunic./Capacit. /Sustent. /Síntesis',
      'Documentación'
    ] ;

    const [seccionElegida, setSeccionElegida] = useState(1);

    useEffect( ()=>{ (!cargandoForm) && (!formProyectoCargado) &&  dispatch( startMostrarFormProyecto() ) 
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [formProyectoCargado,dispatch] )

  return (
 
    <div className='container-xxl'>
      <div className='row'>
        <div className='col d-flex justify-content-center '><h4> { pFocus.codigo } | { pFocus.director.ape}, {pFocus.director.nom} | { ipFocus.titulo }</h4></div>

        
      </div>
      <NavFormProyecto _seccionElegida={seccionElegida} _secciones={secciones} _setSeccionElegida={setSeccionElegida} />
      {
          (!cargandoForm)
            ?
              <>
              {seccionElegida === 1 && <FormProyectoSec1 _refVistaPrevia={refVistaPrevia} />}
              {seccionElegida === 2 && <FormProyectoSec2 _refVistaPrevia={refVistaPrevia} />}
              {seccionElegida === 3 && <FormProyectoSec3 _refVistaPrevia={refVistaPrevia} />}
              {seccionElegida === 4 && <FormProyectoSec4 _refVistaPrevia={refVistaPrevia} />}
              {seccionElegida === 5 &&  <SeccionAdjuntarDocumentos lIntegrantesEquipo={lIntegrantesEquipo}/>   }
              </>
            : <Spiner />
      }
         
    </div>
    
              
  )
}
