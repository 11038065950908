import { configureStore } from '@reduxjs/toolkit';

import {applyMiddleware, combineReducers, compose} from 'redux';
import thunk from 'redux-thunk';
import { basesReducer } from '../reducers/basesReducer';
import { comisionReducer } from '../reducers/comisionReducer';
import { eipcaReducer } from '../reducers/eipcaReducer';
import { eippeReducer } from '../reducers/eippeReducer';
import { pcalReducer } from '../reducers/epcalReducer';
import { ipReducer } from '../reducers/ipReducer';
import { ppertReducer } from '../reducers/ppertReducer';
import {convReducer} from '../reducers/convReducer'
import { pReducer } from '../reducers/pReducer';
import { uiReducer } from '../reducers/uiReducer';
import { usrReducer } from '../reducers/usrReducer';
import { infAvReducer } from '../reducers/infAvReducer';
import { infFinalReducer } from '../reducers/infFinalReducer';
/** Configuracion para trabajar con funciones asincronas en nuestra app */


const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const reducers = combineReducers({
    comisionReducer,
    ipReducer,
    pReducer,    
    uiReducer,
    basesReducer,
    eipcaReducer,
    eippeReducer,
    pcalReducer,
    ppertReducer,
    usrReducer,
    convReducer,
    infAvReducer,
    infFinalReducer
});

export const store = configureStore({
    reducer: reducers,
    middleware : [thunk],
    composeEnhancers : composeEnhancers( applyMiddleware( thunk ))
  })
