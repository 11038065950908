import React from 'react'
import { useListaPalabras } from './hooks/useListaPalabras'

export const BotonBorrarPalabrasClave = () => {
   const {usuarioEsDueñoDelProyecto, usuarioEsAdmin,handleBorrarPalabrasClave}  =  useListaPalabras()
  return (
    <>
    {
        ( usuarioEsDueñoDelProyecto() || usuarioEsAdmin() ) &&
        <button className='btn btn-link' onClick={handleBorrarPalabrasClave} > Borrar Palabas Clave </button>
    }
    </>
  )
}
