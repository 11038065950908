import { types } from "../types/types";


const initialState = {
    listaInfAv : null,
    infAvFocus : {
        tituloProy : null,
        estrategiasComunicacion : null,
        valoracionEstrategiasComunicacion : null,
        politicasPublicasEstablecidas : null,
        materialesEducativos : null,
        provincia : null,
        ciudad : null,
        barrio : null,
        distritoMunicipal : null,
        
        ProgramaExtension_id_ProgramasExtensions : null,
        CoherenciaInterna : {
            ObjetivoEspecificos : []
        },
        InstitucionIntervinientes : [],
        EquipoExtension : {
            IntegranteEquipos : []
        }
    }
}

export const infAvReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.infAvGetLista : 
            return {
                ...state ,
                listaInfAv : action.payload
            }
       
        case types.infAvGetData : 
            return {
                ...state,
                infAvFocus : {...action.payload}
            }
        case types.infAvCrear : 
            return {
                ...state,
                listaInfAv : [...state.listaInfAv,action.payload]
            }
        case types.infAvEditar : 
            return {
                ...state,
                infAvFocus : {
                    ...state.infAvFocus,
                    ...action.payload
                }
            }
        case types.infAvReset : 
            return {
                ...state,
                infAvFocus : initialState.infAvFocus
            };
        default:
            return state;
    }

}
