import { types } from "../types/types";


export const startMostrarEvaluacionCalidad = (idForm = null)=>{
    return async(dispatch,getState)=>{
        const {tipoProyecto,codigo} = getState().pReducer.pFocus;
        
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/pcal/data/${codigo}/${tipoProyecto}/${idForm}`);
     
        const data = await resp.json()
         
         if(data.ok){
             dispatch(setEvaluacionCalidad(data.data));
         }else{
             alert(data.error);
         }
     }
}

export const startEditarEvaluacionCalidad = (campos = {})=>{
    return async(dispatch)=>{
        dispatch(editarEvaluacionCalidad(campos));
    }
}

export const startGuardarEvaluacionCalidad = (_idForm = null)=>{
    return async(dispatch,getState)=>{
        const items = getState().pcalReducer.pcalFocus;
        const {id : idUsr} = getState().usrReducer.usrFocus;
        const {id : idProy} = getState().pReducer.pFocus;
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/pcal/`,{
            method : 'PUT',
            headers: {
                'Content-Type' : 'application/json'
            },
            body :JSON.stringify({
                idUsr : idUsr,
                idProy : idProy,
                id : _idForm,
                dataEvaluacion : items
            })
        });

        const data = await resp.json();

        setTimeout(() => {
            if(data.ok) {
                alert(`datos guardados con exito`);
            } else {
                alert(data.error);
            } 
        });
    }
}

const setEvaluacionCalidad = (data ={})=>({
    type : types.epcalGetData,
    payload : data
})

const editarEvaluacionCalidad = (data ={})=>({
    type : types.epcalEditar,
    payload : data
})

export const resetEpcal = ()=>({
    type : types.epcalReset
})