import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEditarInforme } from '../../actions/informe';

export const MaterialesEducativos = ({esFinal = false}) => {
    const dispatch = useDispatch();

    const {infAvFocus} = useSelector(state => state.infAvReducer);
    const {infFinalFocus} = useSelector(state => state.infFinalReducer);
    const dataInforme = esFinal ? infFinalFocus : infAvFocus
    const {
        estrategiasDeComunicacion,
        valoracionEstrategiasComunicacion,
        materialesEducativos
    } = dataInforme;
  const handleOnChange = (e)=>{
    e.preventDefault();

    dispatch(startEditarInforme({
       ...dataInforme,
       [e.target.name] :  e.target.value
    }));

  }

return (
    <div className='row row-cols-1 m-2'>
        <div className='col'>
            <h5>En lo que se refiere a las estrategias de comunicación y materiales utilizados: </h5>
        </div>
        <div className='col'>
            <p> 
                Describa qué tipo de materiales se produjeron (folleto, cuadernillo, audiovisual, micro radial, podcast, etc.) <br/> 
                y el modo de circulación (se entregó en mano, se envió por correo electrónico, se difundió en redes, en páginas web, etc.).
            </p>
            <textarea className='form-control'  onChange={handleOnChange} name={'estrategiasDeComunicacion'} rows={4} style={{resize : 'none'}}  value={estrategiasDeComunicacion}/>
        
        </div>
        <div className='col'>
            <p className='mt-1'>
            ¿Se considera que fueron de utilidad para los destinatarios y para los  usuarios potenciales?
            </p>
            <textarea className='form-control mb-3'  onChange={handleOnChange} name={'materialesEducativos'} rows={4} style={{resize : 'none'}} value={materialesEducativos} />
            
        </div>
        <div className='col'>
            <h5 >Valoración de estrategias de comunicación</h5>
        </div>
        <div className='col'>
        
         <p>¿Se considera que estas estrategias resultan suficientes? Explicitar los motivos.</p>
        <textarea className='form-control mb-3' onChange={handleOnChange} name={'valoracionEstrategiasComunicacion'}  rows={4} style={{resize : 'none'}} value={valoracionEstrategiasComunicacion} />
        
        </div>
        {/* <div className='col mt-3'>
            <div className='row bg-primary text-light p-2'>
                <div className='col-3'> Nuevo material </div>
                <div className='col-4  border-end border-start'> Destinatarios </div>
                <div className='col-4  border-end border-start'> Modo de circulación </div>

            </div>
           
            <div className='row mt-2 mb-3'> 
                <div className='col-1'>
                    <button className='btn btn-primary' onClick={()=>{dispatch(abrirModalMaterialEducativoInforme())}}>Agregar</button>
                </div> 
            </div>
         </div> */}
    </div>
  )
}
