
import { useDispatch, useSelector } from 'react-redux';
import { startGuardarFormProyectoProblematicaMetodologiaSintesis } from '../../../../actions/formp';
import { mostrarAlerta } from '../../../../helpers/mostrarAlerta';
import { useForm } from '../../../../hooks/useForm';
import { useGestionarEstados } from '../../../../hooks/useGestionarEstados';
import { useGestionarUsuario } from '../../../../hooks/useGestionarUsuario';


export const useFormProyectoSec3 = () => {

    const dispatch = useDispatch();
    const {pFocus} = useSelector( state => state.pReducer ) || {};
    const {lProyUlt3Anios} = useSelector( state => state.basesReducer );
    
    const {
        detalleProblemaAbordar,
        ProyectoRelacionados,
        propuestaMetodologica,
        sintesisProblemaAbordar,
        accionesCoordinacion,
        proyectosCAID,
        geolocalizacionProblemaAbordar,
        politicasPublicas,
        instanciasCapacitacionDetalle,
        planifCapacitacionAgentes,
        perfilAgentesMultip,
        tipoProyecto,
        ParticipanteDirectos,
        CoherenciaInterna
    } = pFocus ? pFocus : {}

    const listaProyUlt3Anios = lProyUlt3Anios ? lProyUlt3Anios.filter( proy => Number(proy.id.split('-')[0]) > 19 ) : []

    const {

        bloquearFormularioProyectoPorEstadoEvaluacion,
        bloquearFormularioPorProyectoCerrado

      } = useGestionarEstados();

    const handleAbrirEnlace = (e)=>{
        e.preventDefault();
        window.open(e.target.href,'_blank');
    }

    const {usuarioEsAdmin , usuarioEsDueñoDelProyecto} = useGestionarUsuario();
      
    const formularioBloqueado = ( 
      bloquearFormularioProyectoPorEstadoEvaluacion() || bloquearFormularioPorProyectoCerrado()  
    );

    const {
        campos,
        validaciones,
        handleCampoChange,
        validarCampo,
        validarForm
      } = useForm(
        tipoProyecto === 'PEIS' 
        ? {
            detalleProblemaAbordar : detalleProblemaAbordar , 
            ProyectoRelacionados : ProyectoRelacionados, 
            proyectosCAID : proyectosCAID,
            geolocalizacionProblemaAbordar : geolocalizacionProblemaAbordar,
            propuestaMetodologica : propuestaMetodologica , 
            sintesisProblemaAbordar : sintesisProblemaAbordar , 
            accionesCoordinacion : accionesCoordinacion , 
            politicasPublicas : politicasPublicas , 
            instanciasCapacitacionDetalle : instanciasCapacitacionDetalle , 
          }
        : {
            detalleProblemaAbordar : detalleProblemaAbordar , 
            ProyectoRelacionados : ProyectoRelacionados, 
            proyectosCAID : proyectosCAID,
            geolocalizacionProblemaAbordar : geolocalizacionProblemaAbordar,
            propuestaMetodologica : propuestaMetodologica , 
            sintesisProblemaAbordar : sintesisProblemaAbordar , 
            politicasPublicas : politicasPublicas , 
            planifCapacitacionAgentes:planifCapacitacionAgentes,
            perfilAgentesMultip:perfilAgentesMultip
        }      
      ,'p');

    const handleSubmitProblematicaMetodologiaSintesis = (e)=>{
        e.preventDefault();
        if(  (!usuarioEsAdmin()) && (!usuarioEsDueñoDelProyecto()) ){
                
            alert('No posee permisos para realizar esta acción');
            return;
        }
    
        if(bloquearFormularioProyectoPorEstadoEvaluacion()){
            alert('No posee permisos para realizar esta acción');
            return;
        }
        if( tipoProyecto === 'PEIS') {
          if(! validarForm( 
                [
                    'detalleProblemaAbordar',
                    'ProyectoRelacionados',
                    'propuestaMetodologica',
                    'sintesisProblemaAbordar',
                    'accionesCoordinacion',
                    'politicasPublicas'
                ])
            ) {
              const msgError = Object.keys(validaciones).map( campo => `${validaciones[campo].msgError ? `${campo}:${validaciones[campo].msgError}\n`: ''}` ).join('') ;
    
              alert( msgError );
              return;
            }
            
          if( ParticipanteDirectos.length < 1 ) {
              alert('Revise que tenga al menos 1 participante cargado'); 
            return;
          };
        } 
         if( tipoProyecto === 'AET' ){
          if(! validarForm( [
                'detalleProblemaAbordar',
                'ProyectoRelacionados',
                'propuestaMetodologica',
                'sintesisProblemaAbordar',
                'politicasPublicas',
                'planifCapacitacionAgentes',
                'perfilAgentesMultip'
                ])
            ){
              const msgError = Object.keys(validaciones).map( campo => `${validaciones[campo].msgError ? `${campo}:${validaciones[campo].msgError}\n`: ''}` ).join('') ;
    
              alert( msgError );
              return;
            } 
         }
           
        
        if( !CoherenciaInterna ) {
          alert('Debe tener cargada la coherencia interna del proyecto'); 
          return;
        };



        mostrarAlerta('¿Desea guardar la sección ?','Guardar Sección',true)
        .then( resp => {
            if( resp.isConfirmed){
              dispatch(startGuardarFormProyectoProblematicaMetodologiaSintesis());
            }
          } 
        )
            
    }


  return {
    handleSubmitProblematicaMetodologiaSintesis,
    campos,
    validaciones,
    handleAbrirEnlace,
    handleCampoChange,
    validarCampo,
    formularioBloqueado,
    listaProyUlt3Anios,
    pFocus,
    tipoProyecto
  }
}
