import React from 'react';
import { Campo } from '../../../../components/Formulario/Campo';
import { useModalEquipoExtension } from './hooks/useModalEquipoExtension';
import { ordenarUnidadesAcademicas } from '../../../../helpers/ordenarUnidadesAcademicas';


export const ModalEquipoExtension = ({_titulo = ''}) => {

    const {
        handleSubmitModalEquipoExtension,
        campos, 
        handleTipoIntegChange,
        chequearVisibilidad,
        validaciones,
        handleCampoChange, 
        validarCampo,
        integSelec,
        lCategDedicacion,
        lCategDocente,
        lTipoInteg,
        lUnidadesAcademicas,
        lPeriodoLectivo,
        lAreas,
        setShowMsgFormInvalido,
        showMsgFormInvalido,
        handleUbicacion,
        tipoIntegrante,
    } = useModalEquipoExtension();

   
    return (
     
        
            <div className='row row-cols-1'>
                
                <div className='col border-bottom'><h4>{_titulo  } </h4></div>
              
                <div className='col'>
                {showMsgFormInvalido && 
                    <p className='alert alert-info d-flex justify-content-between p-1'>
                        Hay campos inválidos, evite el uso de puntos, comas o signos. 
                        <button className='btn btn-link ' onClick={()=>setShowMsgFormInvalido(false)}>
                            x
                        </button>
                    </p>
                }
                </div>
                <div className='col' >
                    <div className='row'>
                        <div className='col'>
                            <Campo
                                enModal={true}
                                id={'tipoIntegrante'}
                                etiqueta={'Tipo de integrante'} 
                                name='tipoIntegrante' 
                                type = {'select'} 
                                handlecampochange={ handleTipoIntegChange}
                                handleblur={validarCampo}
                                opciones={lTipoInteg} 
                                value={campos['tipoIntegrante']}
                                valido={validaciones['tipoIntegrante']} 
                                required={true} 
                
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <Campo
                                enModal={true}
                                id={'dni'}
                                etiqueta={'DNI'} 
                                msgayuda={'Sin puntos, con 0 adelante si tiene menos de 8 dígitos'}
                                name='dni' 
                                type = {'text'}
                                pattern = {'[0-9]{8}'}
                                handlecampochange={handleCampoChange}
                                handleblur={validarCampo} 
                                value={campos['dni']}
                                valido={validaciones['dni']} 
                                minLength={7}
                                maxLength={8}
                                required={true} 
                                hidden = { !chequearVisibilidad('dni') }
                            />
                            <Campo
                                enModal={true}
                                id={'ape'}
                                etiqueta={'Apellido'} 
                                name='ape' 
                                type = {'text'}
                                minLength ={1} 
                                maxLength={255}
                                handlecampochange={handleCampoChange}
                                handleblur={validarCampo} 
                                value={campos['ape']}
                                valido={validaciones['ape']} 
                                required={true} 
                                hidden = { !chequearVisibilidad('ape') }
                            />
                            <Campo
                                enModal={true}
                                id={'nom'}
                                etiqueta={'Nombre'} 
                                name='nom' 
                                type = {'text'}
                                minLength ={1} 
                                maxLength={255}
                                handlecampochange={handleCampoChange}
                                handleblur={validarCampo} 
                                value={campos['nom']}
                                valido={validaciones['nom']} 
                                required={true} 
                                hidden = { !chequearVisibilidad('nom') }
                                />
                            <Campo
                                enModal={true}
                                id={'tel'}
                                etiqueta={'Tel./Cel.'} 
                                name='tel' 
                                type = {'text'}
                                minLength ={1} 
                                maxLength={255}
                                handlecampochange={handleCampoChange}
                                handleblur={validarCampo} 
                                value={campos['tel']}
                                valido={validaciones['tel']} 
                                required={true} 
                                hidden = { !chequearVisibilidad('tel') }
                                />
                            <Campo
                                enModal={true}
                                id={'dom'}
                                etiqueta={'Domicilio'} 
                                name='dom' 
                                type = {'text'}
                                minLength ={1} 
                                maxLength={255}
                                handlecampochange={handleCampoChange}
                                handleblur={validarCampo} 
                                value={campos['dom']}
                                valido={validaciones['dom']} 
                                required={true} 
                                hidden = { !chequearVisibilidad('dom') }
                                />
                            <Campo
                                enModal={true}
                                id={'email'}
                                etiqueta={'Correo electrónico'} 
                                name='email' 
                                type = {'email'}
                                minLength ={1} 
                                maxLength={255}
                                handlecampochange={handleCampoChange}
                                handleblur={validarCampo} 
                                value={campos['email']}
                                valido={validaciones['email']} 
                                required={true} 
                                hidden = { !chequearVisibilidad('email') }
                                />
                            <Campo
                                enModal={true}
                                id={'tieneTarjeta'}
                                etiqueta={'Posee tarjeta precargable?'} 
                                name='tieneTarjeta' 
                                type = {'booleano'} 
                                nomopc1={'si'}
                                nomopc2={'no'} 
                                valueopc1={1} 
                                valueopc2={0} 
                                handlecampochange={handleCampoChange}
                                handleblur={validarCampo} 
                                value={campos['tieneTarjeta']}
                                valido={validaciones['tieneTarjeta']} 
                                required={false} 
                                hidden={!chequearVisibilidad('tieneTarjeta')}
                            />
                        </div>
                        <div className='col'>
                            <Campo
                                enModal={true}
                                id={'categoriaDocente'}
                                etiqueta={'Categoría docente'} 
                                name='categoriaDocente' 
                                type = {'select'} 
                                handlecampochange={handleCampoChange}
                                handleblur={validarCampo} 
                                value={chequearVisibilidad('categoriaDocente') ? campos['categoriaDocente'] : ''}
                                valido={validaciones['categoriaDocente']} 
                                opciones={lCategDocente}
                                required={tipoIntegrante === 'doc'} 
                                hidden={!chequearVisibilidad('categoriaDocente')}
                            />
                
                            <Campo
                                enModal={true}
                                id={'titulo'}
                                etiqueta={'Título'} 
                                name='titulo' 
                                type = {'text'}
                                minLength ={1} 
                                maxLength={255}
                                pattern={'[\\D]{1,255}'}
                                handlecampochange={handleCampoChange}
                                handleblur={validarCampo} 
                                value={chequearVisibilidad('titulo') ? campos['titulo'] : ''}
                                valido={validaciones['titulo']} 
                                hidden={  !chequearVisibilidad('titulo') }
                                required={ tipoIntegrante === 'doc' || tipoIntegrante === 'grad'}

                                />
                            <Campo
                                enModal={true}
                                id={'categoriaDedicacion'}
                                etiqueta={'Dedicación'} 
                                name='categoriaDedicacion' 
                                type = {'select'} 
                                handlecampochange={handleCampoChange}
                                handleblur={validarCampo} 
                                value={ chequearVisibilidad('categoriaDedicacion') ? campos['categoriaDedicacion'] : ''}
                                valido={validaciones['categoriaDedicacion']} 
                                opciones={lCategDedicacion}
                                required={ tipoIntegrante === 'doc'} 
                                hidden={ !chequearVisibilidad('categoriaDedicacion')}
                                />
                            <Campo
                                enModal={true}
                                id={'ubicacion'} 
                                etiqueta={'Seleccione'} 
                                name='ubicacion' 
                                type = {'booleano'} 
                                nomopc1={'Área UNL'}
                                nomopc2={'UA'} 
                                valueopc1={1} 
                                valueopc2={0} 
                                handlecampochange={handleUbicacion}
                                handleblur={validarCampo} 
                                value={chequearVisibilidad('ubicacion') ? campos['ubicacion'] : ''}
                                valido={validaciones['ubicacion']} 
                                required={tipoIntegrante === 'nodoc'} 
                                hidden={!chequearVisibilidad('ubicacion')}
                            />
                            <Campo
                                enModal={true}
                                id={'unidadAcademica'}
                                etiqueta={'UA'} 
                                name='unidadAcademica' 
                                type = {'select'} 
                                handlecampochange={handleCampoChange}
                                handleblur={validarCampo} 
                                value={chequearVisibilidad('unidadAcademica') ? campos['unidadAcademica'] : ''}
                                valido={validaciones['unidadAcademica']}
                                opciones={lUnidadesAcademicas}
                                metodoSort={ ordenarUnidadesAcademicas }
                                required={tipoIntegrante === 'nodoc' ? campos['ubicacion'] === 0 : tipoIntegrante !== 'otro' } 
                                hidden = { !chequearVisibilidad('unidadAcademica') } 
                            />
                            <Campo
                                enModal={true}
                                id={'area'}
                                etiqueta={'Área UNL'} 
                                name='area' 
                                type = {'select'} 
                                handlecampochange={handleCampoChange}
                                handleblur={validarCampo} 
                                value={chequearVisibilidad('area') ? campos['area'] : ''}
                                valido={validaciones['area']}
                                opciones={lAreas}
                                required={ tipoIntegrante === 'nodoc' && campos['ubicacion'] === 1 } 
                                hidden = { !chequearVisibilidad('area') } 
                            />
                            <Campo
                                enModal={true}
                                id={'periodoLectivo'} 
                                etiqueta={'Periodo Lectivo'} 
                                name='periodoLectivo' 
                                type = {'select'} 
                                handlecampochange={handleCampoChange}
                                handleblur={validarCampo} 
                                value={chequearVisibilidad('periodoLectivo') ? campos['periodoLectivo'] : ''}
                                valido={validaciones['periodoLectivo']}
                                opciones={lPeriodoLectivo}
                                required={!chequearVisibilidad('est')} 
                                hidden = { !chequearVisibilidad('periodoLectivo')} 
                            />
                            <Campo
                                enModal={true}
                                id={'observ'} 
                                etiqueta={'Observaciones'} 
                                name='observ' 
                                maxLength={255}
                                type = {'text'} 
                                handlecampochange={handleCampoChange}
                                handleblur={validarCampo} 
                                value={chequearVisibilidad('observ') ? campos['observ'] : ''}
                                valido={validaciones['observ']}
                                required={false} 
                                hidden = { !chequearVisibilidad('observ') }
                            
                            />
                    
                        </div>
                    </div>
                    
                    
                  
                </div>
                <div className='col d-flex justify-content-center'>
                    <button className='btn btn-primary ' 
                        onClick={handleSubmitModalEquipoExtension}>
                            { integSelec ? 'Guardar cambios' : 'Agregar integrante'}
                    </button>
                </div>
            </div>        
            
    )
    
}
