import React from 'react';

import { Link } from 'react-router-dom';

import { FormRegister } from '../components/Usuario/FormRegister';


export const RegistroUsuario = ({_admin = false}) => {

  return (
    <section className='container animate__animated animate__fadeIn'>
        
       <FormRegister _admin={_admin} />
        {
            _admin === false &&
            <div className='row d-flex justify-content-center mt-3'>
                <div className='col col-7'>
                    <p >Una vez registrado, se le enviará un mail para validar el registro</p>
                    <p >Ya tiene un usuario ?  <Link to = '/login'>Ingrese al sistema</Link> </p>
                </div>
            </div>
        }
    

    </section>
  )
}
