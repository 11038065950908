
import React from 'react'
import { useListaPalabras } from './hooks/useListaPalabras';
import { ItemPalabra } from './ItemPalabra';

export const ListaPalabras = () => {
  const { handleEliminarPalbra, pFocus }= useListaPalabras();

  return (
    <p>{ 
      pFocus && 
      pFocus['PalabraClave_id_PalabraClaves'] && 
      pFocus['PalabraClave_id_PalabraClaves']
        .map( (palabra,i) => 
          <ItemPalabra key={`PalabraClave-${i}`} palabra={palabra} indice={i} handleEliminarPalbra={handleEliminarPalbra} />
        )

      }
    </p>
  )
}
