import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { abrirModalParticipanteDirecto } from '../../../../actions/ui';
import { useGestionarEstados } from '../../../../hooks/useGestionarEstados';
import { useGestionarUsuario } from '../../../../hooks/useGestionarUsuario';
import { FilaParticipanteDirecto } from './FilaParticipanteDirecto';

export const ListaParticipantesDirectos = () => {
    const dispatch = useDispatch();
    
    const {tipoUsuario} = useSelector( state => state.usrReducer.usrFocus);
    const {ParticipanteDirectos} = useSelector(state => state.pReducer.pFocus);
    const {listaP,pFocus} = useSelector( state => state.pReducer);

    const showMsgError = ParticipanteDirectos && ParticipanteDirectos.length === 0;
    
    const {
      chequearProyectoNuevo,
      bloquearFormularioProyectoPorEstadoEvaluacion
    } = useGestionarEstados();

    const {usuarioEsAdmin,usuarioEsDueñoDelProyecto } = useGestionarUsuario();

    const handleAgregarParticipanteDirecto = (e)=>{
        e.preventDefault();
        if( (!chequearProyectoNuevo()) && 
            listaP.listaProyectosUsuario &&
            (!listaP.listaProyectosUsuario.find( proy => proy.id === pFocus.id )) &&
            (tipoUsuario !== 'admin') 
        ){
                
            alert('No posee permisos para realizar esta acción');
            return;
        }
        
        if(bloquearFormularioProyectoPorEstadoEvaluacion()){
            alert('No posee permisos para realizar esta acción');
            return;
        }
         
        dispatch( abrirModalParticipanteDirecto() )
        
        return;
    }


  return (
    <div className='row row-cols-1 mb-5'>
        <div className='col'>
            <div className='row mt-3 bg-secondary bg-opacity-10'>
                <div className='col-8 '>
                  <span className='fs-5'>{'8.2) Participantes directos'}</span> 
                { showMsgError && <span className='text-danger' > {'(cargar al menos uno)'} </span>}</div>
                <div className='col-4 text-end'> 
                  {
                      
                    (usuarioEsAdmin() || usuarioEsDueñoDelProyecto()) && 
                     <button className='btn btn-primary'
                        onClick={handleAgregarParticipanteDirecto} 
                      >
                        Agregar participantes
                      </button> 
                  }
                    
                </div>
            </div>
        </div>
        <div className='col mt-3'>
          <div className='row row-cols-3 bg-light p-3'>
            <div className='col-5'>PARTICIPANTES</div>
            <div className='col-5'>ACTIVIDAD</div>
            <div className='col-2'>ACCIONES</div>
          </div>
            {
              ParticipanteDirectos && 
              ParticipanteDirectos.map( (participante,i) => 
                    <FilaParticipanteDirecto 
                      key={`partDir-${i}`} 
                      id={i} 
                      participante={participante.nom} 
                      actividad={participante.ActividadParticipantes} 
                    /> 
                  )
            }
        </div>
    </div>
  )
}
