import React from 'react'; 

import { Campo } from '../../../../components/Formulario/Campo';
import { useFormInstitucionNueva } from './hooks/useFormInstitucionNueva';


export const FormInstitucionNueva = () => {

    const {
        campos,
        filtro,
        handleSubmitModalInstitucionInterviniente,
        handleAbrirEnlace,
        handleChangeNomInst,
        handleCampoChange,
        validaciones,
        validarCampo,
        showMsgFormInvalido,
        handleCerrarMsgError
      } = useFormInstitucionNueva();
    

  return (
    <div className='row row-cols-1 '>
       <div className='col'>
       {
            showMsgFormInvalido.estado && 
            <p className='alert alert-info d-flex justify-content-between p-1'>
                {showMsgFormInvalido.msg}
                <button className='btn btn-link ' onClick={handleCerrarMsgError}>x</button>
            </p>
        }
       </div>
        
       < div className='col'>
            <div className='row row-cols-2'>
                <div className='col'>
                <h5>Institución</h5>
                <Campo
                    enModal={true} 
                        etiqueta={'Nombre'} 
                        name='nomInst' 
                        type = {'text'}
                        maxLength={255}
                        minLength ={1} 
                        handlecampochange={handleChangeNomInst}
                        handleblur={validarCampo} 
                        value={campos['nomInst']}
                        valido={validaciones['nomInst']} 
                        required={true}
                        list='lInstitucionesCargadas'
                       
                />
                <datalist id='lInstitucionesCargadas'>
                    {
                        filtro.slice(0,10).map( (i,index) => <option key={`icar-${index}`} value={i.nom} >{i.nom}</option>)
                    }
                </datalist>
                <Campo
                    enModal={true} 
                        etiqueta={'Domicilio'} 
                        name='domInst' 
                        type = {'text'}
                        maxLength={255}
                        minLength ={1} 
                        handlecampochange={handleCampoChange}
                        handleblur={validarCampo} 
                        value={campos['domInst']}
                        valido={validaciones['domInst']} 
                        required={true}
                />
                <Campo
                    enModal={true} 
                        etiqueta={'Tel./Cel.'} 
                        name='telInst' 
                        type = {'text'}
                        maxLength={255}
                        minLength ={1} 
                        handlecampochange={handleCampoChange}
                        handleblur={validarCampo} 
                        value={campos['telInst']}
                        valido={validaciones['telInst']} 
                        required={true}
                />
                <Campo
                    enModal={true} 
                        etiqueta={'Correo electrónico'} 
                        name='emailInst' 
                        type = {'email'}
                        maxLength={255}
                        minLength ={1} 
                        handlecampochange={handleCampoChange}
                        handleblur={validarCampo} 
                        value={campos['emailInst']}
                        valido={validaciones['emailInst']} 
                        required={true}
                />
                <Campo
                    enModal={true} 
                        etiqueta={'Geolocalización'} 
                        msgayuda={ <>
                            Utilice la herramienta de <a onClick={handleAbrirEnlace} href='https://www.google.com.ar/maps'>Google Maps</a> para insertar el enlace
                            de dicha ubicación.<br />
                            Si necesita ayuda para compartir el enlace , consulte <a onClick={handleAbrirEnlace} href='https://www.youtube.com/watch?v=KoN9aRs6a4E'>en este video.</a>
                        </>}
                        name='geolocalizacion' 
                        type = {'text'}
                        maxLength={255}
                        minLength ={1} 
                        handlecampochange={handleCampoChange}
                        handleblur={validarCampo} 
                        value={campos['geolocalizacion']}
                        valido={validaciones['geolocalizacion']} 
                        required={true}
                />
                </div>
                <div className='col'>
                    <h5>Autoridad a Cargo</h5>
                    <Campo
                        enModal={true}
                        etiqueta={'DNI'} 
                        name='dni' 
                        type = {'text'}
                        msgayuda={'Sin puntos, con 0 adelante si tiene menos de 8 dígitos'}
                        maxLength={8}
                        minLength ={7} 
                        handlecampochange={handleCampoChange}
                        handleblur={validarCampo} 
                        value={campos['dni']}
                        valido={validaciones['dni']} 
                        pattern={'[0-9]{8}'}
                        required={true} 
                        />
                    <Campo
                        enModal={true}
                        etiqueta={'Apellido'} 
                        name='ape' 
                        type = {'text'}
                        maxLength={255}
                        minLength ={1} 
                        handlecampochange={handleCampoChange}
                        handleblur={validarCampo} 
                        value={campos['ape']}
                        valido={validaciones['ape']} 
                        required={true} 
                        />
                    <Campo
                        enModal={true}
                        etiqueta={'Nombre'} 
                        name='nom' 
                        type = {'text'}
                        maxLength={255}
                        minLength ={1} 
                        handlecampochange={handleCampoChange}
                        handleblur={validarCampo} 
                        value={campos['nom']}
                        valido={validaciones['nom']} 
                        required={true} 
                        />
                    <Campo
                        enModal={true}
                        etiqueta={'Tel./Cel.'} 
                        name='tel' 
                        type = {'text'}
                        maxLength={255}
                        minLength ={1} 
                        handlecampochange={handleCampoChange}
                        handleblur={validarCampo} 
                        value={campos['tel']}
                        valido={validaciones['tel']} 
                        required={true} 
                        />
                    <Campo
                        enModal={true}
                        etiqueta={'Domicilio'} 
                        name='dom' 
                        type = {'text'}
                        maxLength={255}
                        minLength ={1} 
                        handlecampochange={handleCampoChange}
                        handleblur={validarCampo} 
                        value={campos['dom']}
                        valido={validaciones['dom']} 
                        required={true} 
                        />
                    <Campo
                        enModal={true}
                        etiqueta={'Correo electrónico'} 
                        name='email' 
                        type = {'email'}
                        maxLength={255}
                        minLength ={1} 
                        handlecampochange={handleCampoChange}
                        handleblur={validarCampo} 
                        value={campos['email']}
                        valido={validaciones['email']} 
                        required={true} 
                        />
                    <Campo
                        enModal={true} 
                        etiqueta={'Inicio de gestión'} 
                        name='inicioGestion' 
                        type = {'date'}
                        handlecampochange={handleCampoChange}
                        handleblur={validarCampo} 
                        value={campos['inicioGestion']}
                        valido={validaciones['inicioGestion']} 
                        required={true}
                    />
                    <Campo
                        enModal={true} 
                        etiqueta={'Fin de gestión'} 
                        name='finGestion' 
                        type = {'date'}
                        handlecampochange={handleCampoChange}
                        value={campos['finGestion']}
                        valido={{valido:true}} 
                        required={false}
                    />
            </div>

            </div>
    
           
            
        </div>
       
          
        <div className='col d-flex justify-content-center '>
            <button className='btn btn-primary ' 
                onClick={handleSubmitModalInstitucionInterviniente}
            >Guardar Institución</button>
        </div>
    </div>
  )
}
