import { types } from "../types/types";



const initialState = {
    listaComision : [],
    comisionFocus : null
}

export const comisionReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.comisionGetLista:
            return {
                ...state,
                listaComision : [...action.payload]
            }
        case types.comisionGetListaProgExt:
            return {
                ...state,
                listaProyAsigProgExt : [...action.payload]
            }
        case types.comisionGetData : 
            return {
                ...state,
                comisionFocus : {...action.payload}
            }
        case types.comisionEditar : 
            return {
                ...state,
                comisionFocus : {
                    ...state.comisionFocus,
                    ...action.payload
                }
            }
        case types.comisionReset : 
            return {
                ...state,
                comisionFocus : initialState.comisionFocus
            };
        default:
            return state;
    }

}
