import React from 'react'

import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormAdjuntarDocumentos } from '../hooks/useFormAdjuntarDocumentos';

export const ListaDocumentacionPresentada = () => {
    const {lDocAdjuntos,handleDescargarDocumento} = useFormAdjuntarDocumentos();

  return (
    <ul className='list-group mt-4 bg-transparent'>
        <li>{'Documentación presentada (disponible para descargar) : '}</li>
        {
            lDocAdjuntos &&
            lDocAdjuntos.map( ({name}) => 
                <li className='list-group-item bg-transparent'>
                    <b>{name}</b>   
                    <FontAwesomeIcon className='ms-4' 
                        icon={faDownload}
                        title={'Descargar'}
                        size={'2x'}  
                        color={ 'blue' }  
                        onClick={(e)=>handleDescargarDocumento(e,name)}
                        /> 
                </li>
            )
        }
    </ul>
  )
}
