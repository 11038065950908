import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startEditarFormIp } from '../../actions/ip';
import { useForm } from '../../hooks/useForm';
import { Campo } from '../Formulario/Campo';

export const ModalInstitucionIp = () => {
    const dispatch = useDispatch();
    const {Institucion_id_Institucions} = useSelector(state => state.ipReducer.ipFocus);
    
    const [showMsgFormInvalido, setShowMsgFormInvalido] = useState(false);
    
    const { 
        campos, 
        validaciones,
        handleCampoChange, 
        validarCampo,
        validarForm, 
        reset 
    } = useForm({nom : ''});


    const handleSubmitInstitucion = (e)=>{
        e.preventDefault();
        if(validarForm(['nom'])){
            dispatch(startEditarFormIp({
                Institucion_id_Institucions :Institucion_id_Institucions
                ? [...Institucion_id_Institucions,{...campos}]
                : [{...campos}]
            }));
            reset();
        }else {
            setShowMsgFormInvalido( true );
        }
    }
  return (
    <div className='row row-cols-1'>
        <div className='col'>
            <h4>Institución involucrada </h4>
            <hr />
            {showMsgFormInvalido && 
                <p className='alert alert-info d-flex justify-content-between p-1'>
                    Error al guardar la institución, revise que haya puesto el nombre y 
                    cumpla con los requisitos. 
                    <button className='btn btn-link ' 
                        onClick={()=>setShowMsgFormInvalido(false)}> x
                    </button>
                </p>
            }
        </div>
        <div className='col'>
            <Campo 
                name='nom'
                etiqueta='NOMBRE:'
                type='text'
                valido={validaciones['nom']}
                value={campos['nom']}
                handlecampochange={handleCampoChange}
                handleblur={validarCampo}
                required={true}
                minLength={2}
                maxLength={255}
            />
        </div>
        <div className='col d-flex justify-content-center'>
           
           <button className='btn btn-primary ' onClick={handleSubmitInstitucion}>Guardar Institución</button>
       </div>
    </div>
  )
}
