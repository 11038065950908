import React from 'react';
import { useGestionarEstados } from '../../hooks/useGestionarEstados';
import { useGestionarUsuario } from '../../hooks/useGestionarUsuario';
import { CampoBooleano } from './CampoBooleano';
import { CampoSelect } from './CampoSelect';
import { CampoTextArea } from './CampoTextArea';


/**
 * 
 * @param {*} restProps resto de los atributos que reciba el campo
 * @returns JSX Label , con el input o select o textarea segun corresponda
 */
export const Campo = (props) => {

  const {
    handlecampochange,
    handleblur, 
    ...restProps
  } = props


  const {usuarioEsAdmin,usuarioEsDueñoDelProyecto} = useGestionarUsuario();

  const {chequearProyectoNuevo} = useGestionarEstados();

  const campoDesabilitado = 
    (restProps.disabled !== undefined) 
      ? restProps.disabled
      : (!usuarioEsAdmin()) && 
        (!usuarioEsDueñoDelProyecto()) && 
        (!chequearProyectoNuevo())
    
    

  return(
    <>
    {
        (!restProps.hidden) &&
        <div className='row mt-3 gx-1'>
          
            <div className='col-md-2 text-wrap'>
                <label className={`form-label `} style={{fontSize : `${restProps.enModal || restProps.secRoles ? '0.8rem' : '1rem'}`}}> { restProps.etiqueta }</label>
            </div>
            <div className={`col-md-9 `}>
            { 
              (restProps.valido)&&(!restProps.valido.valido) && <p className='text-danger '> {restProps.valido.msgError} </p> 
            }
            
            { restProps.type === 'booleano' && <CampoBooleano   {...props} disabled = {campoDesabilitado } />}        
            { restProps.type === 'textarea' &&  <CampoTextArea  {...props}  disabled = {campoDesabilitado } />}
            { restProps.type === 'select' && <CampoSelect  {...props} disabled = {campoDesabilitado } />}
            { 
              restProps.type !== 'textarea' && 
              restProps.type !== 'select' && 
              restProps.type !== 'booleano' &&

              <input className={`form-control form-control-sm ${(restProps.valido  && (!restProps.valido.valido)) ? 'border border-danger' : ''}`} 

                {...restProps} 

                onChange={handlecampochange} 

                onBlur={handleblur} 
                
                    
              /> 
            }
            
            <div className='text-muted'>{  restProps.msgayuda}</div>
            </div>
        </div>
      }
    </>
      
     
   
  )
}

