import React from 'react'
import { useListaPalabras } from './hooks/useListaPalabras'
import { BotonAgregarPalabraClave } from './BotonAgregarPalabraClave';
import { ListaPalabras } from './ListaPalabras';
import { BotonBorrarPalabrasClave } from './BotonBorrarPalabrasClave';


export const SeccionPalabrasClave = () => {
  
  const { showMsgError  }= useListaPalabras();

  return (
    <div className='row row-cols-1 '>
        <div className='col mt-4'>
          <div className='row'>
              <div className='col-9'>
                  

                  <p className='mt-3'>{'2.1) Ingrese de 3 a 5 '}</p>
                  {
                    showMsgError && 
                    <span className='text-danger'>
                        Debe ingresar al menos 3
                    </span>
                  }
              </div>
              <div className='col-3'>
                  <BotonAgregarPalabraClave /> 
              </div>
          </div>
        </div>
        <div className='col m-3 p-3'>
          <div className='row row-cols-2'>
            <div className='col mt-2'>
                <ListaPalabras />
            </div>
            <div className='col '>
                <BotonBorrarPalabrasClave />
            </div>
          </div>
        </div>
    </div>
    
  )
}