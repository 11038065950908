import React from 'react'
import { Link } from 'react-router-dom';
import {faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export const IconoFormulario = ({
    _idForm = null,
    _indice = null,
    _leible = false,
    _nomUsr = '',
    _ruta = '',
    _visible = true,
    _handleClick = ()=>{},
   _mostrarNom = true
}) => {
    
  return (
    <div key={`divLink-${_idForm}${_indice}`} className='col' hidden={!_visible}>
        {
            (_leible === true )
            ?
                <Link
                    to={`${_ruta }/${_idForm}`}
                    onClick = {(e)=> _handleClick(e,_ruta,_idForm) } 
                >
                    <FontAwesomeIcon 
                        className='ms-5' 
                        icon={faFile}  
                        size={'3x'} 
                    />
                
                </Link>
            :
            <></>
    

        }    
        {_mostrarNom ? <p>{  _nomUsr }</p> : undefined }
    </div>
  )
}
