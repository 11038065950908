import React from 'react'
import { useSelector } from 'react-redux'
import { BotonSalir } from '../Botones/BotonSalir';

export const CabeceraPanel = () => {

  const {ipFocus } = useSelector(state => state.ipReducer);
  const {pFocus} = useSelector( state => state.pReducer);

  const director = pFocus.director ? pFocus.director : { ape : '', nom : '' }
  const codigo = pFocus.codigo ? pFocus.codigo : '';
  return (
    <div className='row d-flex justify-content-around'>
        <div className='col-10'>
          <div className='row row-cols-1'>
            <div className='col'>
              <h5 className='text-primary'>
                <div className='row '>
                  <div className='col-2 '> <b>Proyecto : </b></div>
                  <div className='col '> {codigo} | { ipFocus ? ipFocus.titulo : '' } </div>
                </div>
              </h5>
            </div>
            <div className='col'><h5> Director:  { director.ape }, {director.nom} </h5></div>
           </div>
        </div>
        <div className='col-1 me-2 d-flex justify-content-end'>
           <div className='row' >
              <div className='col'>
              <BotonSalir />
              </div>
           </div>
        </div>
    </div>
  )
}
