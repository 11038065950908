import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startSelecInstInterv } from '../../../../../actions/bases';
import { startEditarFormProyecto } from '../../../../../actions/formp';
import { cerrarModal } from '../../../../../actions/ui';
import { useForm } from '../../../../../hooks/useForm';
import { useGestionarUsuario } from '../../../../../hooks/useGestionarUsuario';
import { validarCamposInstInterv } from '../helpers/validacionesModalInstInterv';


    

export const useFormInstitucionNueva = () => {
    const vValidacion = [
        'dni' ,'ape' ,'nom' ,'tel' ,'dom' ,'email' ,'inicioGestion' ,'nomInst' ,'telInst' ,'domInst' ,'emailInst' ,'geolocalizacion' 
    ];
    const dispatch = useDispatch();

    const {pFocus} = useSelector( state => state.pReducer);
      
    const {instIntervSelec,lInstitucionesCargadas} = useSelector( state => state.basesReducer);

    const [showMsgFormInvalido, setShowMsgFormInvalido] = useState({
        estado : false,
        msg : 'Error al guardar la institución, revise los campos cargados.'
    });

    const instSelec = useRef(
        (instIntervSelec !== undefined) && pFocus.InstitucionIntervinientes  
            ? pFocus.InstitucionIntervinientes[instIntervSelec] 
            : undefined
    );

    const currentInstSelec = instSelec.current;

    

    const {usuarioEsAdmin, usuarioEsDueñoDelProyecto} = useGestionarUsuario();
    
   
    const [filtro,setFiltro] = useState([]);
    const [matchInst, setMatchInst] = useState(null);
    const { 
        campos,
        validaciones,
        handleCampoChange,
        setCampos,
        validarCampo,
        validarForm,
        reset

    } = useForm({
        Institucion_id : currentInstSelec ? currentInstSelec.Institucion_id : 0,
        FormularioProyecto_id : pFocus.FormularioProyecto_id,
        Persona_id : currentInstSelec ? currentInstSelec.Responsable.Persona_id : 0,
        dni : currentInstSelec ? currentInstSelec.Responsable.dni :  '' ,
        ape : currentInstSelec ? currentInstSelec.Responsable.ape :  '' ,
        nom : currentInstSelec ? currentInstSelec.Responsable.nom :  '' ,
        tel : currentInstSelec ? currentInstSelec.Responsable.tel :  '' ,
        dom : currentInstSelec ? currentInstSelec.Responsable.dom :  '' ,
        email : currentInstSelec ? currentInstSelec.Responsable.email :  '' ,
        inicioGestion : currentInstSelec ? currentInstSelec.Responsable.inicioGestion :  '',
        finGestion : currentInstSelec ? currentInstSelec.Responsable.finGestion :  '',
        nomInst : currentInstSelec ? currentInstSelec.Institucion.nom :  '',
        telInst : currentInstSelec ? currentInstSelec.Institucion.tel :  '',
        domInst : currentInstSelec ? currentInstSelec.Institucion.dom :  '',
        emailInst : currentInstSelec ? currentInstSelec.Institucion.email :  '',
        geolocalizacion : currentInstSelec ? currentInstSelec.Institucion.geolocalizacion :  ''
    });

    useEffect(() => {

        if(campos.nomInst !== '' ){
           setFiltro(lInstitucionesCargadas.filter( i => i.nom.includes(campos.nomInst)));
        }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [campos.nomInst]);

     useEffect(() => {
        if(matchInst !== null){
            setCampos({
              ...campos,
              Institucion_id : matchInst.id,
              nomInst : matchInst.nom,
              domInst : matchInst.dom,
              emailInst : matchInst.email,
              telInst : matchInst.tel,
              geolInst : matchInst.geol,
          });
        }
      
        return () => {
            setCampos(campos);
        }
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [matchInst]);

    const handleChangeNomInst = (e)=>{
        e.preventDefault();
        
        const match =Array.from(document.getElementById('lInstitucionesCargadas').options).find(o => o.value === e.currentTarget.value);
        if(match) {
            setMatchInst(filtro.find( i => i.nom === match.value));
        }
        setCampos({
            ...campos,
            nomInst : e.currentTarget.value
        });

    }

    const handleAbrirEnlace = (e)=>{
        e.preventDefault();
        window.open(e.target.href,'_blank');
    
    }

    const handleCerrarMsgError = (e)=>{
        e.preventDefault();
        setShowMsgFormInvalido ({...showMsgFormInvalido,estado : false});
    }
    
    const handleSubmitModalInstitucionInterviniente = (e)=>{
        e.preventDefault();
        console.log('validar form')
        if(!validarForm(vValidacion)) {
            setShowMsgFormInvalido ({...showMsgFormInvalido,estado : true});
            return;
        }
        console.log('validar campos')
        const validacionCampos = validarCamposInstInterv(campos);
        if(!validacionCampos.condicion){
            console.log('set show')
            setShowMsgFormInvalido ({
                estado : true,
                msg : validacionCampos.msg
            });
            return;
        }

        setShowMsgFormInvalido ({...showMsgFormInvalido,estado : false});

        if(instIntervSelec === null){
            
            dispatch( startEditarFormProyecto({
                InstitucionIntervinientes :
                    [
                    ...pFocus['InstitucionIntervinientes'],
                    {
                        FormularioProyecto_id : campos.FormularioProyecto_id,
                        Institucion_id :  campos.Institucion_id,
                        Institucion : {
                            id : campos.Institucion_id,
                            nom : campos.nomInst, 
                            tel : campos.telInst, 
                            dom : campos.domInst, 
                            email : campos.emailInst, 
                            geolocalizacion : campos.geolocalizacion
                        },
                        Responsable: {
                            Persona_id : campos.Persona_id,
                            dni : campos.dni,
                            ape : campos.ape,
                            nom : campos.nom,
                            tel : campos.tel,
                            dom : campos.dom,
                            email : campos.email,
                            inicioGestion : campos.inicioGestion,
                            finGestion : campos.finGestion,
                        }
                    }
                    ]
                })
            )
            
        }

        if( instIntervSelec !== null){
            
            dispatch( startEditarFormProyecto({
                InstitucionIntervinientes: 
                    pFocus['InstitucionIntervinientes']
                        .map( (inst,i) => i === instIntervSelec 
                            ? 
                            {
                                FormularioProyecto_id : campos.FormularioProyecto_id,
                                Institucion_id :  campos.Institucion_id,
                                Institucion : {
                                    nom : campos.nomInst, 
                                    tel : campos.telInst, 
                                    dom : campos.domInst, 
                                    email : campos.emailInst, 
                                    geolocalizacion : campos.geolocalizacion
                                },
                                Responsable: {
                                    dni : campos.dni,
                                    ape : campos.ape,
                                    nom : campos.nom,
                                    tel : campos.tel,
                                    dom : campos.dom,
                                    email : campos.email,
                                    inicioGestion : campos.inicioGestion,
                                    finGestion : campos.finGestion,
                                }
                            } 
                            : inst 
                        )
                })
            )
            dispatch(startSelecInstInterv(null));
            
        }
        
        dispatch(cerrarModal());
        reset();
       
      }
    
     
    

  return {
    campos,
    filtro,
    handleAbrirEnlace,
    handleCampoChange,
    handleChangeNomInst,
    handleSubmitModalInstitucionInterviniente,
    usuarioEsAdmin, 
    usuarioEsDueñoDelProyecto,
    validaciones,
    validarCampo,
    showMsgFormInvalido, 
    handleCerrarMsgError
  }
}
