import React from 'react'
import { useListaPalabras } from './hooks/useListaPalabras';

export const ItemPalabra = ({palabra = '', indice, handleEliminarPalbra = ()=>{}}) => {
    
    const {usuarioEsDueñoDelProyecto,usuarioEsAdmin} = useListaPalabras();
  return (
    <span>
        
        <button className='btn btn-outline-secondary' 
            onClick={(e)=>
                handleEliminarPalbra(e,indice)
            } 
        > 
        {palabra}
        { (usuarioEsDueñoDelProyecto() || usuarioEsAdmin() ) &&<span className='text-danger'> x </span> }
        </button> 
        
  </span>
  )
}
