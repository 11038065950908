
import React from 'react'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFilaInstitucion } from './hooks/useFilaInstitucion';


export const FilaInstitucion = ({_data = {},_id=null}) => {

  const {
    Institucion,
    Responsable
  } = _data;

  const {ape,nom,tel} = Responsable;

  const { 
    nom : nomInst,
    dom : domInst,
    tel : telInst
  } = Institucion;
  const {
    usuarioEsAdmin,
    usuarioEsDueñoDelProyecto,
    handleEditarInstitucion,
    handleBorrarInstitucion,
    proyectoEnEstadoRecepProyecto

  } = useFilaInstitucion();
  
  return (
    <div className='row row-cols-2'>
        <div className={`col-8 ms-3 text-start `} >
          <div className='row row-cols-1 border-bottom mb-3' style={{fontSize : '0.9em'}}>
            <div className='col' >
              <b>{ `${nomInst} | ${domInst} | ${telInst}`}</b>
            </div>
            <div className='col'>
              { `Responsable : ${ape},${nom} - Tel/Cel: ${tel ? tel : ''} `}
            </div>
          </div>
          

         
        </div>
        {
          proyectoEnEstadoRecepProyecto() 
           ? <div className='col-2 d-flex justify-content-center aling-items-center' >
              <div className='row'> 
                <div className='col'>
                  {  
                  
                    (usuarioEsAdmin() || usuarioEsDueñoDelProyecto()) &&
                      <FontAwesomeIcon className='btn btn-outline-secondary' 
                          icon={faEdit} 
                          title={'editar inst.'} 
                          onClick={(e)=> handleEditarInstitucion(e,_id)}
                      /> 
                  
                  }
                  
                </div>
                <div className='col'>
                  {
                    (usuarioEsAdmin() || usuarioEsDueñoDelProyecto()) &&
                      <FontAwesomeIcon className='btn btn-outline-danger' 
                        icon={faTrash} 
                        color={'red'} 
                        title={'eliminar inst.'} 
                        onClick={(e)=> handleBorrarInstitucion(e,_id)}
                      />
                  }
                 
                </div>
              </div>
            </div>
            : <></>
        }
        
    </div>
  )
}
