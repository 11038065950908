import { types } from "../types/types";



const initialState = {
    cargandoProy : false,
    proyCargado : false,
    cargandoIdeaProy : false,
    ideaProyCargado : false,
    guardandoForm : false,
    cargandoConvocatoria : false,
    cargandoBases : false,
    cargandoForm : false,
    cargandoFormsEvaluacion : false,
    cargandoProyUlt3Anios : false,
    cargandoInstCargadas : false,
    cargandoEvaluadores : false,
    activarVistaPrevia :false,
    convocatoriaCargada : false,
    formProyectoCargado : false,
    formsEvaluacionCargados : false,
    formInformeAvanceCargado : false,
    formInformeFinalCargado : false,
    listaInfAvanceCargada : false,
    listaInfFinalCargada : false,
    estadoQuery : null,
    esFinal : false,
    showModal: false,
    tipoModal : null,
    basesCargada : false,
    proyUlt3AniosCargados :false,
    instCargadasOk : false,
    evaluadoresCargados : false,
    parametroElegido : null,
    idObjEspFocus : null,
    parametrosPanel : [
        {
            _nom : 'idea_proyecto', 
            _linkForm:[
                {
                    _ruta: '/ip' , 
                    _nom :'Formulario IP'
                },
                {
                    _ruta: '/eipca_def' , 
                    _nom :'Evaluación Final - Comisión  '
                },
                {
                    _ruta: '/eipca' , 
                    _nom :'Evaluación - Comisión  '
                },
                {
                    _ruta : '/eippe' , 
                    _nom :'Evaluación - Programas de Extensión'
                }
            ] 
        },
        {   
            _nom : 'proyecto', 
            _linkForm:[
                {
                    _ruta: '/p' , 
                    _nom : 'Formulario Proyecto'
                },
                {
                    _ruta: '/ppert' , 
                    _nom : 'Evaluación de Pertinencia',
                },
                {
                    _ruta: '/ppert_def' , 
                    _nom : 'Evaluación Final - Pertinencia ',
                },
                {
                    _ruta: '/pcal' , 
                    _nom : 'Evaluación de Calidad '
                },
                {
                    _ruta: '/pcal_def' , 
                    _nom : 'Evaluación Final - Calidad '
                }
            ]

        },
        {
            _nom : 'informe_avance', 
            _linkForm: [
                {
                    _ruta : '/inf_avance',
                    _nom : 'Informe de Avance'
                }
            ]
        },
        {
            _nom : 'informe_final', 
            _linkForm:[
                {
                    _ruta : '/inf_final',
                    _nom : 'Informe Final'
                }
            ]
        }
    ]
}

export const uiReducer = (state = initialState, action) => {

    switch (action.type) {
       
        case types.uiResetInfAvance :
            return {
                ...state,
                formInformeAvanceCargado : false
            }
        case types.uiSetIdObjEspModalActCargadas :
            return {
                ...state,
                idObjEspFocus : action.payload 
            } 

        case types.uiSavingForm : 
            return {
                ...state,
                guardandoForm : true
            }
        case types.uiFinishSavingForm : 
            return {
                ...state,
                guardandoForm : false
            }
            
        case types.uiToggleVistaPrevia : 
            return {
                ...state,
                activarVistaPrevia : !state.activarVistaPrevia
            }
        case types.uiFinishLoadingForm:
            return {
                ...state,
                cargandoForm : false,
                formProyectoCargado : true
            }
        
        case types.uiFinishCargaFormProy:
            return {
                ...state,
                formProyectoCargado : true
            }
        case types.uiFinishCargaFormInfAV:
            return {
                ...state,
                formInformeAvanceCargado : true
            }
        case types.uiFinishCargaFormInfFinal:
                return {
                    ...state,
                    formInformeFinalCargado : true
                }
        case types.uiStartCargaFormsEval:
                return {
                    ...state,
                    cargandoFormsEvaluacion : true,
                    formsEvaluacionCargados : false
                }
        case types.uiFinishCargaFormsEval:
            return {
                ...state,
                cargandoFormsEvaluacion : false,
                formsEvaluacionCargados : true,
            }
        case types.uiLoadingForm: 
            return {
                ...state,
                cargandoForm : true,
                formProyectoCargado : false
            }

        case types.uiBackPennding : 
            return  {
                ...state,
                estadoQuery : action.payload
            }
        case types.uiShowModalPalabrasClave:
            return {
                ...state,
                showModal: true,
                tipoModal : 'PalabraClave'
            }
      
        case types.uiShowModalIntegrante :
            return {
                ...state,
                showModal : true,
                tipoModal : 'EquipoExtension'
            }
        case types.uiShowModalActividadNuevaInforme : 
            return {
                ...state ,
                showModal : true,
                tipoModal : 'ActividadNuevaInforme',
                esFinal : action.payload
            }
        case types.uiShowModalInformeInsitucionNueva : 
            return {
                ...state ,
                showModal : true,
                tipoModal : 'InstitucionNuevaInforme',
                esFinal : action.payload
            }
        case types.uiShowModalMaterialEducativoInforme : 
            return {
                ...state ,
                showModal : true,
                tipoModal : 'MaterialEducativoInforme',
                esFinal : action.payload
            }
        case types.uiShowModalInstitucionInterviniente:
            return {
                ...state ,
                showModal : true,
                tipoModal : 'InstitucionInterviniente'
            }
        case types.uiShowModalCoherenciaInterna:
            return {
                ...state ,
                showModal : true,
                tipoModal : 'CoherenciaInterna'
            }
        case types.uiShowModalParticipanteDirecto : 
            return {
                ...state ,
                showModal : true,
                tipoModal : 'ParticipanteDirecto'
            }
        case types.uiShowModalVistaPrevia : 
            return {
                ...state ,
                showModal : true,
                tipoModal : 'VistaPrevia'
            }
        case types.uiShowModalComisionEvaluadora : 
            return {
                ...state ,
                showModal : true,
                tipoModal : 'ComisionEvaluadora'
            }
        case types.uiShowModalAsigProyProgExt : 
            return {
                ...state ,
                showModal : true,
                tipoModal : 'AsigProyProgExt'
            }
        case types.uiShowModalIntegranteEquipoIp : 
            return {
                ...state ,
                showModal : true,
                tipoModal : 'IntegranteEquipoIp'
            }
        case types.uiShowModalInstitucionIp : 
            return {
                ...state ,
                showModal : true,
                tipoModal : 'InstitucionIp'
            }
        case types.uiShowModalBecarioInforme : 
            return {
                ...state,
                showModal : true,
                tipoModal : 'BecarioInforme',
                esFinal : action.payload
            }
        case types.uiShowModalInstitucionInforme : 
            return {
                ...state,
                showModal : true,
                tipoModal : 'InstitucionInforme',
                esFinal : action.payload
            }
        case types.uiShowModalPartDirectInforme : 
            return {
                ...state,
                showModal : true,
                tipoModal : 'PartDirectInforme'
            }
        case types.uiHideModal : 
            return {
                ...state ,
                showModal : false
            }
        case types.uiFinishGetDataBase : 
            return {
                ...state,
                cargandoBases : false,
                basesCargada : true
            }
        case types.uiFinishGetProyUlt3Anios : 
            return {
                ...state,
                proyUlt3AniosCargados : true,
                cargandoProyUlt3Anios : false
            }
        case types.uiFinishGetInstCargadas : 
            return {
                ...state,
                instCargadasOk : true,
                cargandoInstCargadas : false,
            }
        case types.uiFinisGetEvaluadores : 
            return {
                ...state,
                evaluadoresCargados : true,
                cargandoEvaluadores : false
            }
        case types.uiSetParamElegido : 
            return {
                ...state,
                parametroElegido : state.parametrosPanel.find( param => param._nom === action.payload)
            }
        case types.uiFinishGetListaInfAv :
            return {
                ...state,
                listaInfAvanceCargada: true
            }
        case types.uiSetPermisos : 
            return {
                ...state,
                parametrosPanel : [
                    ...action.payload 
                ]
            }
        case types.uiStartCargaProy : 
            return {
                ...state,
                cargandoProy : true,
                proyCargado : false
            }
        case types.uiFinishCargaProy : 
            return {
                ...state,
                cargandoProy : false,
                proyCargado : true
            }
        case types.uiStartCargaIdeaProy : 
            return {
                ...state,
                cargandoIdeaProy : true,
                ideaProyCargado : false
            }
        case types.uiFinishCargaIdeaProy : 
            return {
                ...state,
                cargandoIdeaProy : false,
                ideaProyCargado : true
            }
        case types.uiStartCargaBases :  
            return {
                ...state ,
                cargandoBases : true,
                basesCargada : false
            }
        case types.uiStartCargaProyUlt3Anios : 
            return {
                ...state ,
                cargandoProyUlt3Anios : true,
                proyUlt3AniosCargados : false
            }
        case types.uiStartCargaInstCargadas : 
            return {
                ...state ,
                cargandoInstCargadas : true,
                instCargadasOk : false
            }
        case types.uiFinishCargaConvocatoria : {
            return {
                ...state,
                cargandoConvocatoria : false,
                convocatoriaCargada  :true
            }
        }
        case types.uiStartCargaConvocatoria : {
            return {
                ...state,
                cargandoConvocatoria : true,
                convocatoriaCargada : false
            }
        }
        
        case types.uiPanelReset : 
            return {
                ...state,
                formsEvaluacionCargados : false,
                formProyectoCargado : false,
                proyCargado : false,
                ideaProyCargado : false,
                listaInfAvanceCargada : false,
                listaInfFInalCargada : false,
                formInformeAvanceCargado : false,
                formInformeFinalCargado : false,
                cargandoFormsEvaluacion : false,
                cargandoProy : false
            }
        case types.uiReset : 
            return initialState
        default:
            return state;
    }

}
