import { types } from "../types/types"

const initialState = {
    listaP : null,
    ipFocus : {
        tipoProyecto : null,
        titulo : null,
        bianual : null,
        campoTematico : null,
        categoriaProyecto : null,
        problemaAbordar : null,
        finalidad : null,
        unidadAcademica : null,
        politicasPublicas : null,
        participantesSociales : null,
        potencialesActividades : null,
        ProgramaExtension_id_ProgramaExtensions : null,
        LineaTematica_id_LineaTematicas : null,
    },
    tipoForm : 'crear'
}

export const ipReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.ipGetData : 
            return {
                ...state,
                ipFocus : {...action.payload}
            }
        case types.ipSetTipo : 
            return {
                ...state,
                tipoForm : action.payload
            }
        case types.ipEditar : 
            return {
                ...state,
                ipFocus : {
                    ...state.ipFocus,
                    ...action.payload
                }
            }
        case types.ipReset : 
            return {
                ...state,
                ipFocus : initialState.ipFocus
            };
        default:
            return state;
    }

}