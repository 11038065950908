import React from 'react';
import { Campo } from '../Formulario/Campo';

import { useSelector } from 'react-redux';
import { useLoginUsuario } from './useLoginUsuario';

/**
 * Formulario para el ingreso al sistema o , registrar un nuevo usuario
 * @returns 
 */
export const FormLogin = () => {
  const {cargandoForm} = useSelector( state => state.uiReducer); 
  const {
    campos,
    handleCampoChange,
    validarCampo,
    validaciones,
    handleSubmit
  } = useLoginUsuario();
  
  return (
    <div className='col'>
       <Campo 
         name='email'
         type='email'
         etiqueta='Correo Electrónico'
         minLength= {1}
         maxLength={255}
         handlecampochange={handleCampoChange}
         handleblur={validarCampo}
         value={campos['email']}
         valido={validaciones['email']}
         required={true}
         disabled={false}
       />
        <Campo
          name='pass'
          type='password'
          etiqueta='Contraseña'
          minLength={1}
          maxLength={6}
          handlecampochange={handleCampoChange}
          handleblur={validarCampo}
          value={campos['pass']}
          valido={validaciones['pass']}
          required={true}
          disabled={false}
       />   
       <div className='col d-flex justify-content-center'>
         <button className='btn btn-primary' onClick={handleSubmit} disabled={cargandoForm}>Ingresar</button>  
       </div>  
    </div>
      
   
  )
}
