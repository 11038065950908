
import { useEffect,  useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startEditarFormProyecto } from '../../../../../actions/formp';
import { useForm } from '../../../../../hooks/useForm';
import { abrirModalCoherenciaInterna, cerrarModal } from '../../../../../actions/ui';
import { useGestionarUsuario } from '../../../../../hooks/useGestionarUsuario';
import { useGestionarEstados } from '../../../../../hooks/useGestionarEstados';

export const useCoherenciaInterna = () => {
    const dispatch = useDispatch();

    const {pFocus} = useSelector( state => state.pReducer);
    const {lMeses} = useSelector( state => state.basesReducer);

    const {CoherenciaInterna} = pFocus;
 
    const [mostrarFormObjEsp, setmostrarFormObjEsp] = useState(false);

    const [mostrarFormActObj, setmostrarFormActObj] = useState(false);


    const [objetivosEspecificosLocal, setobjetivosEspecificosLocal] = useState(CoherenciaInterna.ObjetivoEspecificos);
    
    const [cantObjeivos, setCantObjeivos] = useState(objetivosEspecificosLocal.length);
    
   

    const [idObj, setIdObj] = useState(null);
    const [error, setError] = useState(null);

    const {usuarioEsAdmin,usuarioEsDueñoDelProyecto} = useGestionarUsuario();
    

    const { bloquearFormularioProyectoPorEstadoEvaluacion,proyectoEnEstadoRecepProyecto } = useGestionarEstados();
    

    const { 
        campos, 
        validaciones,
        handleCampoChange, 
        validarCampo,
        validarForm
    } = useForm({
        finalidad : CoherenciaInterna.finalidad,
        objetivoGeneral : CoherenciaInterna.objetivoGeneral
      });
    


    useEffect(() => {
      if(objetivosEspecificosLocal.length !== cantObjeivos){
        setCantObjeivos(objetivosEspecificosLocal.length);
      }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objetivosEspecificosLocal])
    


    const {finalidad,objetivoGeneral} = campos;
    
    const handleSubmitCoherenciaInterna = (e)=>{
        e.preventDefault();
        
        if(!validarForm(['finalidad','objetivoGeneral'])){
            
            setError('Debe tener una Finalidad y un Objetivo general');
            return;
        }
        if((!objetivosEspecificosLocal) || (objetivosEspecificosLocal.length < 1)){
            setError('Debe tener al menos 1 objetivo específico');
            return;
        }

        dispatch( 
            startEditarFormProyecto({
                CoherenciaInterna : {
                    ...CoherenciaInterna,
                    finalidad : finalidad,
                    objetivoGeneral : objetivoGeneral,
                    ObjetivoEspecificos : objetivosEspecificosLocal
                }
            }) );
        dispatch( cerrarModal());
    }

   
    const handleCargarCoherenciaInterna = (e)=>{
        e.preventDefault();
        if( (!usuarioEsAdmin()) && (!usuarioEsDueñoDelProyecto()) ){
                
            alert('No posee permisos para realizar esta acción');
            return;
        }
    
        if(bloquearFormularioProyectoPorEstadoEvaluacion()){
            alert('No posee permisos para realizar esta acción');
            return;
        }
    
      
          dispatch(abrirModalCoherenciaInterna());
        
          return;
      
       
      }

    const handleEliminarObjetivoEspecifico = (e,_i)=>{
        e.preventDefault();
        console.log(_i);
       setobjetivosEspecificosLocal(objetivosEspecificosLocal.filter((obj,i)=>i !== _i)  ); 
        
    }

    const handleMostrarFormActividad = (e,_i)=>{
        e.preventDefault();

        setIdObj(_i);
        setmostrarFormActObj(true);
    }

    const handleGuardarActividadObjetivo = (index,idObj,desc,cronogA1,cronogA2)=>{
       setobjetivosEspecificosLocal(
        objetivosEspecificosLocal.map(
            (objEsp , i)=>
                (i === idObj) 
                ? {
                    ...objEsp,
                    ActividadObjetivos : objEsp.ActividadObjetivos.map(
                        (aoe , j)=> 
                            j === index 
                            ? ({
                                ...aoe,
                                desc : desc,
                                CronogramaA1s : cronogA1.map( m => ({Mes_id : m, ActividadObjetivo_id: aoe.id})),
                                CronogramaA2s : cronogA2.map( m => ({Mes_id : m, ActividadObjetivo_id: aoe.id}))
                            }) 
                            : aoe 
                    )
                }
                : objEsp
            )
      
       )
        
    }
    const handleEliminarActividadOBjEsp = (e,_i,_j)=>{
        e.preventDefault();
        console.log([_i,_j]);
        setobjetivosEspecificosLocal(
            objetivosEspecificosLocal.map(
                (obj,index) => {
                    if(index === _i){
                        obj.ActividadObjetivos = obj.ActividadObjetivos.filter( (act,jndex) => jndex !== _j )
                    }
                    return obj;
                }
            )
        )
    }

    const handleAgregarObjetivoEspecifico = (e)=>{
        e.preventDefault();

        setobjetivosEspecificosLocal(
            [
                ...objetivosEspecificosLocal,
                {
                    id : 0,
                    desc : '',
                    resEsp : '',
                    ActividadObjetivos : []
                }
            ]
        )
    }
    const handleAgregarActividadObjetivo = (e,index)=>{
        e.preventDefault();

       setobjetivosEspecificosLocal(
        objetivosEspecificosLocal.map(
            (oe,_index)=> {
                if(_index === index){
                    oe.ActividadObjetivos = 
                    [
                        ...oe.ActividadObjetivos,
                        {
                            id : 0,
                            ObjetivoEspecifico_id : oe.id,
                            desc : '',
                            CronogramaA1s : [],
                            CronogramaA2s : []
                        }
                    ]
                }
                return oe;
            }
        )
       )

    }

    const handleGuardarObjetivoEspecifico = (index,desc,resEsp)=>{
        setobjetivosEspecificosLocal(
            objetivosEspecificosLocal.map( (oe,i) => {
                if(i === index) {
                    oe.desc = desc;
                    oe.resEsp = resEsp;
                }
                return oe;
            } )
        )
    }
    const lMesesIdToNom = (lmesesId)=>lmesesId.map( idMes => lMeses.find(_mes => _mes.id ===  Number(idMes) ).nom ).join(',')

  return {
    cantObjeivos,
    objetivosEspecificos : objetivosEspecificosLocal,
    campos,
    handleAgregarObjetivoEspecifico,
    handleAgregarActividadObjetivo,
    CoherenciaInterna,
    handleCargarCoherenciaInterna,
    handleCampoChange,
    handleEliminarActividadOBjEsp,
    handleEliminarObjetivoEspecifico,
    handleMostrarFormActividad,
    handleGuardarActividadObjetivo,
    handleGuardarObjetivoEspecifico,
    handleSubmitCoherenciaInterna,
    validaciones,
    validarCampo,
    mostrarFormActObj,
    mostrarFormObjEsp,
    idObj,
    setmostrarFormActObj,
    setmostrarFormObjEsp,
    lMesesIdToNom,
    pFocus,
    error,
    setError,
    usuarioEsAdmin,
    usuarioEsDueñoDelProyecto,
    proyectoEnEstadoRecepProyecto
  }
}
