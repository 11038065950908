import { mostrarAlerta } from "../helpers/mostrarAlerta";
import { types } from "../types/types";
import { resetEpcal } from "./epcal";
import { resetEppert } from "./eppert";
import { resetEipca } from "./evipca";
import { resetEippe } from "./evippe";
import { FinalizarCargaProyecto,  iniciarCargaProyecto, resetPanelUi } from "./ui";



export const startMostrarProyecto = (id = '')=>{
    return async(dispatch)=>{
       dispatch(iniciarCargaProyecto());
       const resp = await fetch(`${process.env.REACT_APP_API_URL}/p/${id}`)
    
       const data = await resp.json();
        
        if(data.ok){
            dispatch(setProyecto(data.data));
        }else{
            alert(data.error);
        }
        
        dispatch(FinalizarCargaProyecto());

    }
}

export const startMostrarListaProyectos = ()=>{
    return async(dispatch,getState)=>{

        const {id,tipoUsuario} = getState().usrReducer.usrFocus;
        const { anio , estados} = getState().convReducer.convFocus;
        console.log(anio);
        const resp = 
            (tipoUsuario === 'admin') || (tipoUsuario === 'gestion')
            ? await fetch(`${process.env.REACT_APP_API_URL}/p/all/${id}`)
            : await fetch(`${process.env.REACT_APP_API_URL}/p/all/usr/${id}/${anio}/${estados[estados.length-1].tipoEstado}`)
       const data = await resp.json();
        
        if(data.ok){
            dispatch(setListaProyectos(data.data));
        }else{
            alert(data.error);
        }


    }
}

export const startAgregarEstadoProyecto = (estado = {})=>{
    return async(dispatch,getState)=>{

        const {pFocus} = getState().pReducer;
        const {id : idUsr} = getState().usrReducer.usrFocus;

        const {id,historialEstados} = pFocus;
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/p/add/estado/`,{
            method : 'POST',
            headers: {
                'Content-Type' : 'application/json'
            },
            body :JSON.stringify({
                id : id,
                idUsr : idUsr,
                tipoEstado : estado.tipoEstado,
                desc : estado.desc
            })
        })
    
       const data = await resp.json();
        
        if(data.ok){

            dispatch(editarFormProyecto({
                historialEstados : [...historialEstados, data.data]
            }));
            alert('Estado agregado con exito');
        }else{
            alert(data.error);
        }


    }
}

export const startEliminarEstadoProyecto = (_id)=>{
    return async(dispatch,getState)=>{

        const { id : idProy , historialEstados } = getState().pReducer.pFocus;
        const {id : idUsr} = getState().usrReducer.usrFocus;

        const resp = await fetch(`${process.env.REACT_APP_API_URL}/p/del/estado/`,{
            method : 'DELETE',
            headers: {
                'Content-Type' : 'application/json'
            },
            body :JSON.stringify({
                idProy : idProy,
                idUsr : idUsr,
                idEstado : _id
            })
        })

        const data = await resp.json();

        if(data.ok){
            dispatch(editarFormProyecto({
                historialEstados : historialEstados.filter( (estado)=> estado.id !== _id )
            }));
            alert('estado eliminado correctamente');
        }else{
            alert(data.error);
        }
    }


}

export const startEditarEstadoProyecto = (_id,  dataEstado = {})=>{
    return async(dispatch,getState)=>{

        const { id : idProy , historialEstados } = getState().pReducer.pFocus;
        const {id : idUsr} = getState().usrReducer.usrFocus;

        const resp = await fetch(`${process.env.REACT_APP_API_URL}/p/put/estado/`,{
            method : 'PUT',
            headers: {
                'Content-Type' : 'application/json'
            },
            body :JSON.stringify({
                idProy : idProy,
                idUsr : idUsr,
                idEstado : _id,
                dataEstado : dataEstado
            })
        })

        const data = await resp.json();

        if(data.ok){
            dispatch(editarFormProyecto({
                EstadoProyectos : historialEstados.map( 
                    (estado) => estado.id === _id 
                        ? {
                            ...dataEstado,
                            fechaCreacion : new Date(Date.now())
                        }
                        : estado  
                )
            }));
            alert('estado modificado correctamente');
        }else{
            alert(data.error);
        }
    }
}

export const startBorrarProyecto = (_id)=>{
    return async( dispatch, getState )=>{
        
    let resp = await mostrarAlerta( 
        '¿Desea eliminar el proyecto? Todos los formularios serán eliminados del sistema',
        'Eliminar proyecto',
        true
    )
    
    if(resp.isConfirmed){
        resp = await fetch(`${process.env.REACT_APP_API_URL}/p/`,{
            method : 'DELETE',
            headers : {
                'Content-Type' : 'application/json'
            },
            body : JSON.stringify({
                idProy : _id
            })
        });



        const data = await resp.json();

        if(data.ok) {
            alert('Proyecto eliminado correctamente! ');

            dispatch(
                setListaProyectos({
                ...getState().pReducer.listaP,
                listaProyectosUsuario : [...getState().pReducer.listaP.listaProyectosUsuario.filter( p => p.id !== _id )]
            }))
            
        }else {
            alert (data.error)
        }
        
    }


    }
}

export const startResetPanelProyecto = (_id)=>{
    return async(dispatch)=>{
       
        dispatch(resetEipca());
        dispatch(resetEippe());
        dispatch(resetEpcal());
        dispatch(resetEppert());
        dispatch(resetPanelUi());
    }
}

export const startResetListaProyectos = ()=>{
    return async( dispatch)=>{ dispatch(setListaProyectos(null)); }
}


export const resetProyecto = ()=>({
    type : types.pReset
})


const setProyecto = ( data = {})=>({
    type : types.pGetData,
    payload : data
})

const editarFormProyecto = (campos ={})=>({
    type : types.pEditar,
    payload : campos
})

const setListaProyectos=(listaProyectos )=>({
    type : types.pGetLista,
    payload : listaProyectos
})