import React from 'react'
import { useEquipoExtension } from './hooks/useEquipoExtension'

export const BotonAgregarIntegrante = () => {

    const {usuarioEsAdmin,usuarioEsDueñoDelProyecto,handleAgregarIntegrante} = useEquipoExtension();

  return (
    <>
    {
        (usuarioEsAdmin() || usuarioEsDueñoDelProyecto()) &&
        <button className='btn btn-primary' onClick={handleAgregarIntegrante} >
            Agregar integrante
        </button> 
    }
    </>
  )
}
