import React from 'react'
import { Campo } from '../../../components/Formulario/Campo';
import { Formulario } from '../../../components/Formulario/Formulario';
import { useFormProyectoSec1 } from './hooks/useFormProyectoSec1';
import { VistaPreviaFormProyecto } from '../VistaPreviaFormProyecto';
import { SeccionPalabrasClave } from './SeccionPalabrasClave/SeccionPalabrasClave';

export const FormProyectoSec1 = ({_refVistaPrevia}) => {
  
  
  const {
    handleSubmitDatosGenerales,
    formularioBloqueado,
    campos,
    validaciones,
    handleCampoChange,
    validarCampo,
    lUnidadesAcademicas,
    lProgramasExtension,
    lLineasTematicasPorAnioDelProyecto,
    lTipoProyecto,
    pFocus
  } = useFormProyectoSec1();
  
  return (
   <>
      {
        pFocus && lUnidadesAcademicas && lProgramasExtension && lLineasTematicasPorAnioDelProyecto && lTipoProyecto &&
        
          
          <Formulario
             idForm={pFocus.FormularioProyecto_id}
             tipoFormulario='p'
             titulo='Datos generales'
             handleSubmit={handleSubmitDatosGenerales}
             refVistaPrevia={_refVistaPrevia}
             componenteVistaPrevia={<VistaPreviaFormProyecto ref={_refVistaPrevia} />}
          >
           
              <Campo
                disabled = {formularioBloqueado ? formularioBloqueado : undefined} 
                handleblur={validarCampo}
                etiqueta='Modalidad'
                name='tipoProyecto' 
                type='select' 
                value = {campos['tipoProyecto']}
                opciones={lTipoProyecto || []} 
                valido = {validaciones['tipoProyecto']}
                handlecampochange = {handleCampoChange}
                required={true}
              />
              <Campo
                disabled = {formularioBloqueado ? formularioBloqueado : undefined} 
                handleblur={validarCampo}
                etiqueta='Línea/s Temática/s'
                name='LineaTematica_id_LineaTematicas' 
                type='select' 
                multiple={true} 
                size={10}
                value = {campos['LineaTematica_id_LineaTematicas'] || []}
                opciones={ lLineasTematicasPorAnioDelProyecto() || []} 
                valido = {validaciones['LineaTematica_id_LineaTematicas']}
                handlecampochange = {handleCampoChange}
                required={true}
              />
              <Campo
                disabled = {formularioBloqueado ? formularioBloqueado : undefined} 
                handleblur={validarCampo}
                etiqueta='Programas de extensión'
                name='ProgramaExtension_id_ProgramaExtensions' 
                type='select' 
                multiple={true} 
                size={10}
                value = {campos['ProgramaExtension_id_ProgramaExtensions'] || []}
                opciones={lProgramasExtension || []} 
                valido = {validaciones['ProgramaExtension_id_ProgramaExtensions']}
                handlecampochange = {handleCampoChange}
                required={true}
              />
              <Campo
                disabled = {formularioBloqueado ? formularioBloqueado : undefined} 
                handleblur={validarCampo}
                etiqueta='Unidad académica'
                name='unidadAcademica' 
                type='select' 
                value={campos['unidadAcademica']}
                opciones={lUnidadesAcademicas || []} 
                metodoSort={(a,b)=>( a.id.localeCompare(b.id) + Number(a.id === 'OTRO')  )}
                valido={validaciones['unidadAcademica']}
                handlecampochange = {handleCampoChange}
                required={true}
              />

              <div className='col mb-4 mt-5'>
                <h4>{'1) Duración del proyecto'}</h4>
                <hr />
                <Campo
                  handleblur={validarCampo}
                  etiqueta='1.1) Seleccione' 
                  name='bianual' 
                  nomopc1='bianual' 
                  nomopc2='anual'  
                  valueopc1={1}
                  valueopc2={0}
                  type='booleano' 
                  value={0}
                  valido = {validaciones['bianual']}
                  handlecampochange = {handleCampoChange}
                  disabled = {true} 

                  required={true}
                />
              </div>
              
              <div className='col mb-4 mt-5'>
                <h4>{'2) Palabras clave'}</h4>
                <hr />
                <SeccionPalabrasClave />
              </div>
           
          </Formulario>
      
       
      }
   </>
  )
}
