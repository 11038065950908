import React, { useState} from 'react'
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit,  faSave, faTrash } from '@fortawesome/free-solid-svg-icons'

const FormCronograma = ({ _name = '', _disabled = false, _cronograma = [], handleSetMes = ()=>{}})=>{

    return (
        <ul className='list-group list-group-horizontal'  style={{fontSize : '0.7em'}}>
            <li style={{cursor : 'pointer'}} onClick={e => _disabled ? null : handleSetMes(e,_name,1)} className={   `list-group-item p-1 ${  _cronograma.some(mes => Number(mes) === 1) ? 'bg-primary text-light' :  _disabled ? 'bg-transparent' : 'bg-none' } `}> E</li>
            <li style={{cursor : 'pointer'}} onClick={e => _disabled ? null : handleSetMes(e,_name,2)} className={   `list-group-item p-1 ${  _cronograma.some(mes => Number(mes) === 2) ? 'bg-primary text-light' :  _disabled ? 'bg-transparent' : 'bg-none' } `}> F</li>
            <li style={{cursor : 'pointer'}} onClick={e => _disabled ? null : handleSetMes(e,_name,3)} className={   `list-group-item p-1 ${  _cronograma.some(mes => Number(mes) === 3) ? 'bg-primary text-light' :  _disabled ? 'bg-transparent' : 'bg-none' } `}> M</li>
            <li style={{cursor : 'pointer'}} onClick={e => _disabled ? null : handleSetMes(e,_name,4)} className={   `list-group-item p-1 ${  _cronograma.some(mes => Number(mes) === 4) ? 'bg-primary text-light' :  _disabled ? 'bg-transparent' : 'bg-none' } `}> A</li>
            <li style={{cursor : 'pointer'}} onClick={e => _disabled ? null : handleSetMes(e,_name,5)} className={   `list-group-item p-1 ${  _cronograma.some(mes => Number(mes) === 5) ? 'bg-primary text-light' :  _disabled ? 'bg-transparent' : 'bg-none' } `}> M</li>
            <li style={{cursor : 'pointer'}} onClick={e => _disabled ? null : handleSetMes(e,_name,6)} className={   `list-group-item p-1 ${  _cronograma.some(mes => Number(mes) === 6) ? 'bg-primary text-light' :  _disabled ? 'bg-transparent' : 'bg-none' } `}> J</li>
            <li style={{cursor : 'pointer'}} onClick={e => _disabled ? null : handleSetMes(e,_name,7)} className={   `list-group-item p-1 ${  _cronograma.some(mes => Number(mes) === 7) ? 'bg-primary text-light' :  _disabled ? 'bg-transparent' : 'bg-none' } `}> J</li>
            <li style={{cursor : 'pointer'}} onClick={e => _disabled ? null : handleSetMes(e,_name,8)} className={   `list-group-item p-1 ${  _cronograma.some(mes => Number(mes) === 8) ? 'bg-primary text-light' :  _disabled ? 'bg-transparent' : 'bg-none' } `}> A</li>
            <li style={{cursor : 'pointer'}} onClick={e => _disabled ? null : handleSetMes(e,_name,9)} className={   `list-group-item p-1 ${  _cronograma.some(mes => Number(mes) === 9) ? 'bg-primary text-light' :  _disabled ? 'bg-transparent' : 'bg-none' } `}> S</li>
            <li style={{cursor : 'pointer'}} onClick={e => _disabled ? null : handleSetMes(e,_name,10)} className={  `list-group-item p-1 ${  _cronograma.some(mes => Number(mes) === 10) ? 'bg-primary text-light' : _disabled ? 'bg-transparent' : 'bg-none' } `}>O</li>
            <li style={{cursor : 'pointer'}} onClick={e => _disabled ? null : handleSetMes(e,_name,11)} className={  `list-group-item p-1 ${  _cronograma.some(mes => Number(mes) === 11) ? 'bg-primary text-light' : _disabled ? 'bg-transparent' : 'bg-none' } `}>N</li>
            <li style={{cursor : 'pointer'}} onClick={e => _disabled ? null : handleSetMes(e,_name,12)} className={  `list-group-item p-1 ${  _cronograma.some(mes => Number(mes) === 12) ? 'bg-primary text-light' : _disabled ? 'bg-transparent' : 'bg-none' } `}>D</li>
        </ul>
    )
}

const MsgError = ()=><div className='alert alert-danger' style={{fontSize : '0.7em'}}> Debe ingresar una descripción de la actividad. </div>

export const FormularioActividadObjetivo = ({
    _desc = '',
    _CronogramaA1s = [],
    _CronogramaA2s = [],
    idObj = 0,
    index = 0,
    handleEliminarActividadOBjEsp = ()=>{},
    handleGuardarActividadObjetivo = ()=>{}

}) => {

    const {pFocus} = useSelector( state => state.pReducer);
    const [showMsgFormInvalido, setShowMsgFormInvalido] = useState(false);

    const [editando, setEditando] = useState(_desc === '');
    
    const [campos, setCampos] = useState({
        desc : _desc,
        CronogramaA1s : _CronogramaA1s.map( mes => mes.Mes_id),
        CronogramaA2s : _CronogramaA2s.map( mes => mes.Mes_id)
      });
    
 

    const {desc,CronogramaA1s,CronogramaA2s} = campos;

    const handleCampoChange = (e)=>{
        e.preventDefault();

        if(e.currentTarget.name === 'desc'){
            setCampos({
                ...campos,
                desc : e.currentTarget.value
            });
        }
        else if(e.currentTarget.name === 'CronogramaA1s'){
            setCampos({
                ...campos,
                CronogramaA1s : e.currentTarget.value
            });
        }
        else if(e.currentTarget.name === 'CronogramaA2s'){
            setCampos({
                ...campos,
                CronogramaA2s : e.currentTarget.value
            });
        }
    }
    
    const handleGuardar = (e)=>{
        e.preventDefault();
        if(desc === '') { setShowMsgFormInvalido(true);}
        else {
            setShowMsgFormInvalido(false);
            handleGuardarActividadObjetivo(index,idObj,desc,CronogramaA1s,CronogramaA2s);
            setEditando(false);
        }
       
    }

    const handleCronogramaChange = (e,name,id)=>{
        e.preventDefault();
        if(name === 'CronogramaA1s' && CronogramaA1s.every( mes => mes !== id)){

            handleCampoChange({
                currentTarget : {
                    validity : {valid : true},
                    name ,
                    type : 'cronograma',
                    value : [...CronogramaA1s ,Number(id)]
                },
                preventDefault : e.preventDefault
            })
        }
        else if(name === 'CronogramaA2s' && CronogramaA2s.every( mes => mes !== id)){
            handleCampoChange({
                currentTarget : {
                    validity : {valid : true},
                    name ,
                    type : 'cronograma',
                    value : [...CronogramaA2s, Number(id) ]
                },
                preventDefault : e.preventDefault
            })
        }
        else {
            handleCampoChange({
                currentTarget : {
                    validity : {valid : true},
                    name ,
                    type : 'cronograma',
                    value : campos[name].filter(mes => mes !== Number(id))
                },
                preventDefault : e.preventDefault
            })
        }
    }


    const handleEditar = (e)=>{
        e.preventDefault();
        setEditando(true);
    }


  return (
    <div className='row row-cols-3 border-start border-end border-bottom p-1' key={`form-obj-esp-${index}`} id={`form-obj-esp-${index}`} >
    <div className='col-3'>
           <textarea className='form-control'
               id={`ta-${idObj}${index}`}
               name='desc' 
               type = {'textarea'}
               minLength ={1} 
               maxLength ={1000}
               onChange={handleCampoChange}
               rows={5}
               value={desc}
               required={true} 
               disabled={!editando}
               style={{resize:'none',fontSize : '0.7em',overflow : 'auto'}}
           />
       </div>
       <div className='col-4'>
           <FormCronograma _name='CronogramaA1s' _disabled={!editando} _cronograma={CronogramaA1s} handleSetMes={handleCronogramaChange} />
           {showMsgFormInvalido ? <MsgError /> : <></>}
       </div>
       {
            pFocus.bianual ?
            <div className='col-4'>
             <FormCronograma _name='CronogramaA2s' _disabled={!editando} _cronograma={CronogramaA2s} handleSetMes={handleCronogramaChange} />
            </div>
            : <></>
       }
       <div className={pFocus.bianual ? 'col-1 d-flex align-items-center' : 'col-2 d-flex align-items-center' }>
           {
             
               <div className='row'>
                   {
                       (!editando) &&
                       <div className='col' >
                           <button className='btn btn-outline-secondary btn-sm' title='Editar Actividad' style={{fontSize : '0.8em'}} onClick={handleEditar} > <FontAwesomeIcon icon={faEdit}  /></button>
                       </div>
                   }
                   {
                       (!editando) &&
                       <div className='col'>
                           <button className='btn btn-outline-danger btn-sm' title='Eliminar Actividad' style={{fontSize : '0.8em'}} onClick={e=>handleEliminarActividadOBjEsp(e,idObj,index)} ><FontAwesomeIcon icon={faTrash} /></button>
                       
                       </div>
                   }
                   {
                        editando &&
                        <div className='col-5'>
                               <small className='btn btn-outline-secondary btn-sm' title='Guardar Actividad' onClick={handleGuardar} ><FontAwesomeIcon icon={faSave}  /></small>
                        </div>
                   }
                 
                 
               </div>
           }
           
           
       </div>
    </div>
  )
}
