
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useFormAdjuntarDocumentos } from '../hooks/useFormAdjuntarDocumentos'
export const ItemConstanciaJefeCatedra = () => {
    const {validarConstanciaJefeCatedra,handleVerAval} = useFormAdjuntarDocumentos();
  return (
    <li className='list-group-item bg-transparent ' > 
    <span className='ms-4'>{'Si es necesario, constancia de Jefe/a de cátedra. (formato .pdf). '} </span>
    <a href='https://drive.google.com/file/d/150kgCsXwQbzmh7dlRkevdEwBcVckyLHC/view?usp=share_link'
      onClick={handleVerAval}
    > Descargue el modelo aquí </a>
    {  validarConstanciaJefeCatedra() &&  <FontAwesomeIcon className='ms-4' icon={faCheck}size={'2x'} color={ 'green' }  />  }
</li>
  )
}
