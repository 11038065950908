import React from 'react'
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export const BotonVolver = ({handleSalir = ()=>{}}) => {
  return (
    <div className='col-4 d-flex justify-content-end'>
        <button className='btn btn-primary mt-2 p-2 mb-2' 
            title='Volver' 
            onClick={handleSalir}
        > 
            <FontAwesomeIcon icon={faArrowLeftLong} size={'3x'}/>
        </button>
    </div>
  )
}
