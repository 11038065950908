import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { startEditarInforme } from '../../actions/informe';
import { abrirModalInstitucionInforme } from '../../actions/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';


export const InstitucionesCargadas = ({esFinal = false}) => {
    
    const dispatch = useDispatch();
    const {cargandoForm} = useSelector( state => state.uiReducer);
    
    const dataInforme = useSelector( state => esFinal ? state.infFinalReducer.infFinalFocus : state.infAvReducer.infAvFocus);

    const {InstitucionIntervinientes} = dataInforme;
     
    const handleOnChange = (e,_Institucion_id)=>{
        e.preventDefault();
 
        
        if(e.target.name === 'valoracion' || e.target.name ===  'observ' ){
          const data = {[e.target.name] : e.target.value}
          dispatch(startEditarInforme(
            {
                ...dataInforme,
                InstitucionIntervinientes : InstitucionIntervinientes.map( 
                        inst => inst.Institucion_id === _Institucion_id 
                        ? {...inst,...data} 
                        : inst
                )
            },
            esFinal
        ))
        }
        if(e.target.name === 'observInstituciones'){
            dispatch(startEditarInforme(
                {
                    ...dataInforme,
                    observInstituciones : e.target.value
                },
                esFinal
            ))
        }
       
       
      }


    const handleAgregarInstitucion = (e)=>{
        e.preventDefault();
        dispatch(abrirModalInstitucionInforme(esFinal));
      };

    const handleDeleteInst = (e,_Institucion_id)=>{
        e.preventDefault();
        dispatch(
            startEditarInforme(
                {
                    ...dataInforme,
                    InstitucionIntervinientes : InstitucionIntervinientes.map(
                        inst => inst.Institucion_id !== _Institucion_id 
                    )
                },
                esFinal
            )
        )
    }
  
  return (
    <div className='row row-cols-1 m-2'>
        <div className='col'>
            <div className='row p-1'>
                <div className='col-4'><h4 className='mt-2'>INSTITUCIONES CARGADAS</h4></div>
                <div className='col-1'><button className='btn btn-outline-primary' onClick={handleAgregarInstitucion} >Agregar</button></div>
            </div>
        </div>
        <div className='col'>
            <div className='row bg-primary text-light p-2'>
                <div className='col-4'> Nombre de la organización </div>
                <div className='col-3  border-end border-start'> 
                   {` Valoración de la participación `}
                </div>
                <div className='col-4 '> Observaciones </div>
            </div>
        </div>
        <div className='col' style={{minHeight : '18em',maxHeight : '18em', overflow : 'scroll'}}>
            
            {
                (!cargandoForm) &&
                InstitucionIntervinientes &&
                InstitucionIntervinientes.map(
                    inst => 
                    <div className='row border-bottom mb-2 mt-2'>
                        <div className='col-4'>
                            {inst.Institucion.nom}
                        </div>
                        <div className='col-3'>
                        <select className='form-select'
               
                            onChange={e => handleOnChange(e,inst.Institucion_id)}
                            name={'valoracion'}
                            value={inst.valoracion}
                        >
                            <option key={`opvalInst-1`} value={''}>Seleccione</option>
                            <option key={`opvalInst-2`} value={1}>Muy activa</option>
                            <option key={`opvalInst-3`} value={2}>Activa</option>
                            <option key={`opvalInst-4`} value={3}>Pasiva</option>
                            <option key={`opvalInst-5`} value={4}>Obstructiva</option>
                        </select> 
                        </div>
                        <div className='col-4 mb-1'>
                           <textarea className='form-control'
               
                            onChange={e => handleOnChange(e,inst.Institucion_id)} 
                            name={'observ'}
                            rows={4}
                             style={{resize : 'none'}}
                             value={inst.observ}
                             />
                        </div>
                        {
                            inst.esNueva &&
                            <div className='col-1'>
                            <button className='btn btn-outline-secondary' onClick={handleDeleteInst}>
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                        </div>
                        }
                    </div>
                )
            }
            
        </div>
        <div className='col mt-3'>
            <h4>Observaciones generales</h4>
            <p> Resumen general del desempeño de las instituciones. </p>
            <textarea className='form-control'
               id='observInstituciones'
               onChange={e => handleOnChange(e)} 
               name={'observInstituciones'}
               rows={4}
               style={{resize : 'none'}}
               value={dataInforme.observInstituciones}
            />
        </div>
        
    </div>
  )
}
