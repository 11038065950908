import React from 'react';
import Proptypes from 'prop-types';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useContextTabla } from '../../hooks/useContextTabla';


export const FilaTabla = ({
  children , 
  _cabecera = false , 
  _titulo = false,
  _id = null
} ) => {


  
  const { 
    handleBorrar,  
    handleEditar, 
    esRedireccionable,
    gestionable,
    path,
    sePuedeBorrar
  } = useContextTabla();

  return (
    <>
      { 
        (_cabecera)
        ?
        <tr style={ gestionable && { position : 'sticky', zIndex : '1000' } }>
          {children} 
          {
            gestionable && (!_titulo) && <th>ACCIONES</th>
          }
        </tr>
        : 
        <tr>
          {children} 
          {
            gestionable && 
            <td>
                <span className=' me-2'
                  onClick={(e)=>{handleEditar(e,_id)}} 
                > 
                  <Link to={ esRedireccionable() ? `${path.current}/panel/${_id}` : ''} className='btn btn-outline-primary'  >
                    <FontAwesomeIcon icon={faEdit} title={'Editar'} />
                  </Link>
                </span>
                {
                  sePuedeBorrar() &&  
                  <span className='btn btn-outline-danger '
                      onClick={ (e)=>{  handleBorrar(e,_id) }} 
                      hidden={ (handleBorrar === null) }>
                        <FontAwesomeIcon 
                          icon={faTrash} 
                          color={'red'} 
                          title={'Eliminar'}
                        />
                    </span>
                
                }
               
            </td>
          }
        </tr> 
      }
    </>
  )
}

FilaTabla.propTypes = {
  _cabecera : Proptypes.bool.isRequired,
  _id : Proptypes.number.isRequired
}