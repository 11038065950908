import React from 'react'
import { useSelector } from 'react-redux';
import { imprimirCampoRecursivo } from '../../helpers/imprimirCampoRecursivo'



export const VistaPreviaIdeaProyecto = React.forwardRef((props, ref) =>  {

    const {ipFocus} = useSelector ( state => state.ipReducer); 
    const {pFocus} = useSelector ( state => state.pReducer); 
    const {lCategEquipo,lProgramasExtension,lLineasTematicas} = useSelector( state => state.basesReducer);

    const camposForm = {
      'Título (Max. 250)' : ipFocus.titulo && ipFocus.titulo,
      'Duración(anual o bianual)' : ipFocus.tipoProyecto ? ipFocus.bianual ? 'bianual' : 'anual' : '' ,
      'Campo Temático(Max. 1500)' : ipFocus.campoTematico && ipFocus.campoTematico,
      'Problema a abordar(Max. 4000)' : ipFocus.problemaAbordar && ipFocus.problemaAbordar,
      'Finalidad(Max. 4000)' : ipFocus.finalidad && ipFocus.finalidad,
      'Políticas Públicas(Max. 4000)' : ipFocus.politicasPublicas && ipFocus.politicasPublicas,
      'Participantes sociales directos(Max. 4000)' : ipFocus.participantesSociales && ipFocus.participantesSociales,
      'Potenciales Actividades(Max. 4000)' : ipFocus.potencialesActividades && ipFocus.potencialesActividades,
      'Instituciones Involucradas( c/u : Nombre  )' : 
            ipFocus.Institucion_id_Institucions &&
            ipFocus.Institucion_id_Institucions.length > 0 && 
            ipFocus.Institucion_id_Institucions.map( ({nom}) => nom ),
      'Participantes del proyecto (c/u : DNI, Nombre , Apellido , UU.AA.)' : 
            ipFocus.Persona_id_Personas && 
            ipFocus.Persona_id_Personas.length > 0 &&
            ipFocus.Persona_id_Personas.map( ({dni,ape,nom,unidadAcademica}) => `${dni} - ${ape},${nom} - ${unidadAcademica}` )
    };


    const modalidad = ipFocus ? ipFocus.tipoProyecto : '';
    const unidadAcademica = ipFocus ? ipFocus.unidadAcademica  : '';
    const categoria = lCategEquipo.find( categ => categ.id === ipFocus.categoriaProyecto );
    const porgramasExension = ipFocus && ipFocus.ProgramaExtension_id_ProgramaExtensions
          ? ipFocus.ProgramaExtension_id_ProgramaExtensions.map( 
                  prog => lProgramasExtension.find(
                    _prog => _prog.id === prog
                  ).nom 
            )
          : [];
    const lineasTematicas = ipFocus && ipFocus.LineaTematica_id_LineaTematicas
          ? ipFocus.LineaTematica_id_LineaTematicas.map( 
              linea => lLineasTematicas.find(
                _linea => _linea.id === linea
              ).nom 
            )
          : [];
    
    const responsableDeLaPropuesta = pFocus.director
            ?`${pFocus.director.ape}, ${pFocus.director.nom}`
            : ''

    
  return (
    <div id='vista_previa_form_proy' className='container' ref={ref}>
        <style  type='text/css' media='print'>{`
           @media print {
              html, body {
                height: initial !important;
                overflow: initial !important;
                -webkit-print-color-adjust: exact;
              }
              .contenido-sin-corte {
                display: block;
                page-break-inside: avoid;
              }
            }
  
        `}</style>
        <div className='row row-cols-1'>
            <div className='col mb-4'>
                <ul className='list-group'>
                    <li className='list-group-item'> <b className='fs-5'>Modalidad : </b> {modalidad}  </li>
                    <li className='list-group-item'> <b className='fs-5'>UU.AA. : </b>{unidadAcademica} </li>
                    <li className='list-group-item'> <b className='fs-5'>Categoría :</b> {categoria ? categoria.nom : ''} </li>
                    <li className='list-group-item'> <b className='fs-5'>Programas de extensión</b> {porgramasExension.join(', ')} </li>
                    <li className='list-group-item'> <b className='fs-5'>Línea/s Temática/s</b> {lineasTematicas.join(', ')} </li>
                    <li className='list-group-item'> <b className='fs-5'>Responsable de la propuesta:</b> {responsableDeLaPropuesta} </li>
                </ul>
            </div>
        {
             Object.keys(camposForm).map( 

                key => {
                    return (
                        <>
                          <div className='contenido-sin-corte'>
                              <div className={`col mb-4 mt-4 `} >
                                
                                <h3 > { key } </h3>
                              
                                <div className='border border-secondary m-2 p-2'>
                                  {
                                    camposForm[key] &&  imprimirCampoRecursivo( camposForm[key], 4 )
                                  }
                                </div>
                            
                            </div>
                          </div>
                       
                          
                        </>
                    )
                       
                       
                }
                  
             )
        }
        </div>
    </div>
  )
})
