import React from 'react'
import {useGestionarUsuario} from '../../hooks/useGestionarUsuario'
import {useGestionarConvocatoria} from '../../hooks/useGestionarConvocatoria'

export const AyudaUsuarioLogueado = () => {
    const {
        usuarioEsEvaluadorExterno,
        usuarioEsAdmin,
        usuarioHabilitadoCrearProyectos
    } = useGestionarUsuario()
    
    const {anioConvocatoria} = useGestionarConvocatoria();
    
    const  handleRedirigir = (e)=>{
        e.preventDefault();

        window.open(e.target.href,'_blank');

    }

  return (
    <>
        {
            usuarioHabilitadoCrearProyectos() || usuarioEsAdmin()
            ?
            <>
                <div className='col'>
                    <li className='text-primary fs-4'>Guía de presentación</li>
                    <p>Esta guía cuenta con toda la información relacionada a como registrarse en el gestor y 
                    como cargar una IP al ingresar a la plataforma. 
                    </p>
                    <p>Acceda al manual de carga de ideas proyecto en el siguiente enlace: 
                        <a  className='ms-2'
                            onClick={handleRedirigir}  
                            href='https://drive.google.com/file/d/1hT_G4Vr5c1gSXG7KDIV7rCX4Oa3VmvMW/view?usp=share_link'
                        >
                            Guía de presentación {anioConvocatoria}
                        </a>
                    </p>
                    <p className='text-primary fs-5'>Instructivos de presentación PEIS y AET</p>
                    <p>En esta sección se encuentran las guías de presentación de proyectos para cada modalidad</p>
                    <div className='row row-cols-1'>
                        
                        <div className='col-7 d-flex align-items-center mb-2'>
                            Acceda al instructivo en el siguiente enlace
                            <a  className='ms-2'
                                onClick={handleRedirigir}  
                                href='https://drive.google.com/file/d/1MMjuqDRAF5lQR7z1nomZFJeONvzpf1sO/view?usp=drive_link'
                            >
                                Instructivo de presentación PEIS
                            </a>
                        </div>
                        <div className='col-7 d-flex align-items-center mb-2'> 
                            Acceda al instructivo en el siguiente enlace
                            <a  className='ms-2'
                                onClick={handleRedirigir}  
                                href='https://drive.google.com/file/d/1plVp1IawWvluXFsD9XLKdM8sRXDZgURf/view?usp=drive_link'
                            >
                                Instructivo de presentación AET
                            </a>
                        </div>
                    </div>
                    
            
                </div>
                <div className='col-9'>
                    <li className='text-primary fs-4'>Inicio de los Proyectos de Extensión {anioConvocatoria}-</li>
                    <p>
                        <a className='ms-2 mt-2 mb-2 fs-5' 
                            onClick={handleRedirigir} 
                            href='https://drive.google.com/file/d/1fav89fNGNJ91fp3QS1TIkoHrVJ3ICeit/view?usp=drive_link' 
                        > Información general de PE - Conv. {anioConvocatoria}
                        </a>
                    </p>
                    
                    <p> Los siguientes modelos de notas son editables. <br/> Para poder editar, debe descargar el modelo en su computadora. </p>
                
                    <p>
                        <a className='ms-2' 
                            onClick={handleRedirigir} 
                            href='https://drive.google.com/file/d/1b1KBVhO9sHs0qy4YcOc9iI-d4TH-rVXO/view?usp=drive_link' 
                        > Modelo de solicitud de prórroga de PE
                        </a>
                    </p>
                    <p>
                        <a className='ms-2' 
                            onClick={handleRedirigir} 
                            href='https://drive.google.com/file/d/1_QrFvIU9mcXZuKYqbrHDGMGToXlG4lur/view?usp=drive_link' 
                        > Modelo de nota para cambios de rubros
                        </a>
                    </p>
                    <p>
                        <a className='ms-2' 
                            onClick={handleRedirigir} 
                            href='https://drive.google.com/file/d/1tHVK2VHxIHb1qNHifVxZgjf3Ewv6yB1d/view?usp=drive_link' 
                        > Modelo de cuadro para cambios de rubros
                        </a>
                    </p>
                    <p>
                        <a className='ms-2' 
                            onClick={handleRedirigir} 
                            href='https://drive.google.com/file/d/1lzsK5bZs20KSEplgadQNkuy5_kOAQdnk/view?usp=drive_link' 
                        > MODELO DE CARTA DE INTENCIÓN - Entidades privadas
                        </a>
                    </p>
                    <p>
                        <a className='ms-2' 
                            onClick={handleRedirigir} 
                            href='https://drive.google.com/file/d/1yEbjDpCTekWgc2ZP3QSGapaGP7thFp4O/view?usp=drive_link' 
                        > Solicitud de convocatoria de beca de extensión 
                        </a>
                    </p>
                </div>
            </>
            :<></>
        }
            
            {
             usuarioEsEvaluadorExterno() || usuarioEsAdmin()
             ?<div className='col'>
                    <li className='text-primary fs-4'>Instructivos de evaluación de calidad PEIS y AET</li>
                    <p>En esta sección se encuentran las guías de evaluación de calidad para cada modalidad</p>
                    <div className='row'>
                      
                        <div className='col-7 d-flex align-items-center mb-2'>
                            Acceda al instructivo en el siguiente enlace
                            <a  className='ms-2'
                                onClick={handleRedirigir}  
                                href='https://drive.google.com/file/d/1BI8fOZ8WSh_X4bPW07GSDP0g2nWKHrtN/view?usp=drive_link'
                            >
                                 Instructivo de evaluación AET
                            </a>
                        </div>
                    </div>
                    <div className='row'>
                       
                        <div className='col-7 d-flex align-items-center mb-2'> 
                            Acceda al instructivo en el siguiente enlace
                            <a  className='ms-2'
                                onClick={handleRedirigir}  
                                href='https://drive.google.com/file/d/19ifS6HuuKA9wOsM-QXKMF4qfBheFnt33/view?usp=drive_link'
                            >
                                 Instructivo de evaluación PEIS
                            </a>
                        </div>
                    </div>
                   
                </div>  
                : <></>
            }

    
    </>
  )
}
