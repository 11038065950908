import React from 'react'

export const CampoBooleano = ( props) => {

  const {
    
    name,
    handlecampochange,
    handleblur,
    nomopc1,
    nomopc2,
    valueopc1 , 
    valueopc2,
    value,
    disabled

  } = props;
 

  return (
    <div className={`row ${(props.valido && (!props.valido.valido)) ? 'border border-danger' : ''}`}>
        <div className='col form-check'>
            <label className='form-check-label' htmlFor={nomopc1}> {nomopc1.toLowerCase()} </label>
            <input className='form-check-input'
                id= {nomopc1} 
                name={name} 
                checked = {value === valueopc1} 
                value={valueopc1} 
                type='radio' 
                onBlur={handleblur}  
                disabled = {disabled}
                onChange={handlecampochange}
                />
        </div>
        <div className='col form-check'>
            <label className='form-check-label' htmlFor={nomopc2} > {nomopc2.toLowerCase()}  </label>
            <input className='form-check-input'
                id={nomopc2} 
                name={name} 
                checked = {value === valueopc2} 
                value={valueopc2} 
                type='radio' 
                onBlur={handleblur} 
                onChange={handlecampochange}
                disabled = {disabled}
              />
        </div>
    </div>
  )
}
