import { types } from "../types/types";
import { startSelecInstInterv } from "./bases";
import { 
    abrirModalInstitucionInterviniente, 
    cerrarModal,
    finalizarCargaFormsEvaluacion,
    iniciarCargaFormsEvaluacion,
    startFinishLoadingForm,
    startFinishSavingForm,
    startLoadingForm,
    startSavingForm
} from "./ui";



export const startEditarFormProyecto = (campos = {})=>{
    return async( dispatch)=>{   
      dispatch( editarFormProyecto(campos));
    }
}

export const startMostrarFormProyecto = ()=>{
    return async(dispatch)=>{

       dispatch(startLoadingForm());
       
       dispatch(startMostrarFormProyectoSec1());

  
     
    }
}
const startMostrarFormProyectoSec1 = ()=>{
    return async(dispatch,getState)=>{
       
       const {codigo} = getState().pReducer.pFocus;
       const resp = await fetch(`${process.env.REACT_APP_API_URL}/p/form/1/${codigo}`)
    
       const data = await resp.json()
        
        if(data.ok){
            dispatch(editarFormProyecto(data.data));
            dispatch(startMostrarFormProyectoSec2());
        }else{
            alert(data.error);
            dispatch(startFinishLoadingForm());
        }

    }
}
const startMostrarFormProyectoSec2 = ()=>{
    return async(dispatch,getState)=>{
       
       const {codigo} = getState().pReducer.pFocus;
       const resp = await fetch(`${process.env.REACT_APP_API_URL}/p/form/2/${codigo}`)
    
       const data = await resp.json()
        
        if(data.ok){
            dispatch(editarFormProyecto(data.data));
            dispatch(startMostrarFormProyectoSec3());
        }else{
            alert(data.error);
            dispatch(startFinishLoadingForm());
        }

    }
}
const startMostrarFormProyectoSec3 = ()=>{
    return async(dispatch,getState)=>{
       
       const {codigo} = getState().pReducer.pFocus;
       const resp = await fetch(`${process.env.REACT_APP_API_URL}/p/form/3/${codigo}`)
    
       const data = await resp.json()
        
        if(data.ok){
            dispatch(editarFormProyecto(data.data));
            dispatch(startMostrarFormProyectoSec4());
        }else{
            alert(data.error);
            dispatch(startFinishLoadingForm());
        }

    }
}
const startMostrarFormProyectoSec4 = ()=>{
    return async(dispatch,getState)=>{
       
       const {codigo} = getState().pReducer.pFocus;
       const resp = await fetch(`${process.env.REACT_APP_API_URL}/p/form/4/${codigo}`)
    
       const data = await resp.json()
        
        if(data.ok){
            dispatch(editarFormProyecto(data.data));
            dispatch(startMostrarFormProyectoSec5());
        }else{
            alert(data.error);
            dispatch(startFinishLoadingForm());
        }

    }
}
const startMostrarFormProyectoSec5 = ()=>{
    return async(dispatch,getState)=>{
       
       const {codigo} = getState().pReducer.pFocus;
       const resp = await fetch(`${process.env.REACT_APP_API_URL}/p/form/5/${codigo}`)
    
       const data = await resp.json()
        
        if(data.ok){
            dispatch(editarFormProyecto(data.data));
        }else{
            alert(data.error);
            
        }
        dispatch(startFinishLoadingForm());
    }
}


export const startGuardarFormProyectoDatosGenerales = ()=>{
    return async(dispatch,getState) => {
        try {
            dispatch( startSavingForm());
        const {
            codigo ,
            bianual,
            ProgramaExtension_id_ProgramaExtensions,
            LineaTematica_id_LineaTematicas,
            PalabraClave_id_PalabraClaves,
            tipoProyecto,
            unidadAcademica 
        } = getState().pReducer.pFocus;

        const { id} = getState().usrReducer.usrFocus;

        const resp = await fetch(`${process.env.REACT_APP_API_URL}/p/`,{
            method : 'PUT',
            headers: {
                'Content-Type' : 'application/json'
            },
            body :JSON.stringify({
                codigo : codigo,
                idUsr : id,
                dataProyecto : {
                    bianual,
                    ProgramaExtension_id_ProgramaExtensions,
                    LineaTematica_id_LineaTematicas,
                    PalabraClave_id_PalabraClaves,
                    tipoProyecto,
                    unidadAcademica 
                }
            })
        });

        if(!resp.ok) throw resp.statusText;
        
        const data = await resp.json();

        setTimeout(() => {
            if(data.ok) {
                alert('datos guardados con exito');
            } else {
                alert(data.error);
            } 
            dispatch( startFinishSavingForm());
        }, 500);

        } catch (error) {
            alert(error);
            dispatch( startFinishSavingForm());
        }
    }
}

export const startGuardarFormProyectoEquipoExtension = ()=>{
    return async(dispatch,getState)=>{
        try {
            dispatch( startSavingForm());
        const {
            codigo,
            EquipoExtension,
            InstitucionIntervinientes,
            solicitaBecarioJustif,
            solicitaVoluntarioDet,
            solicitaBecario
        } = getState().pReducer.pFocus;

        const { id} = getState().usrReducer.usrFocus;

        const resp = await fetch(`${process.env.REACT_APP_API_URL}/p/`,{
            method : 'PUT',
            headers: {
                'Content-Type' : 'application/json'
            },
            body :JSON.stringify({
                codigo : codigo,
                idUsr : id,
                dataProyecto : {
                    EquipoExtension,  
                    InstitucionIntervinientes,
                    solicitaBecarioJustif,
                    solicitaVoluntarioDet,
                    solicitaBecario
                }
            })
        });

        if(!resp.ok) throw resp.statusText;

        const data = await resp.json();

        setTimeout(() => {
            if(data.ok) {
                alert('datos guardados con exito');
                
            } else {
                alert(data.data.error);
            } 
            dispatch( startFinishSavingForm());
        }, 500);
        } catch (error) {
            alert(error);
            dispatch( startFinishSavingForm());
        }
    }
}

export const startGuardarFormProyectoProblematicaMetodologiaSintesis = ()=>{
    return async(dispatch,getState)=>{
        dispatch( startSavingForm());
        const {
            codigo,
            accionesCoordinacion,
            CoherenciaInterna,
            detalleProblemaAbordar,
            geolocalizacionProblemaAbordar,
            instanciasCapacitacionDetalle,
            perfilAgentesMultip,
            ParticipanteDirectos,
            planifCapacitacionAgentes,
            politicasPublicas,
            propuestaMetodologica,
            ProyectoRelacionados,
            proyectosCAID,
            sintesisProblemaAbordar,
            tipoProyecto
        } = getState().pReducer.pFocus;
        const { id } = getState().usrReducer.usrFocus;
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/p/`,{
            method : 'PUT',
            headers: {
                'Content-Type' : 'application/json'
            },
            body :JSON.stringify({
                codigo : codigo,
                idUsr : id,
                dataProyecto : tipoProyecto === 'PEIS' 
                ?{
                    CoherenciaInterna,
                    detalleProblemaAbordar,
                    ProyectoRelacionados,
                    propuestaMetodologica,
                    sintesisProblemaAbordar,
                    ParticipanteDirectos,
                    accionesCoordinacion,
                    politicasPublicas,
                    proyectosCAID,
                    geolocalizacionProblemaAbordar,
                    instanciasCapacitacionDetalle
                }
                :{
                    CoherenciaInterna,
                    detalleProblemaAbordar,
                    ProyectoRelacionados,
                    propuestaMetodologica,
                    sintesisProblemaAbordar,
                    politicasPublicas,
                    planifCapacitacionAgentes,
                    perfilAgentesMultip,
                    proyectosCAID,
                    geolocalizacionProblemaAbordar,
                }
            })
        });

        const data = await resp.json();

        setTimeout(() => {
            if(data.ok) {
                alert('datos guardados con exito');
                
            } else {
                alert(data.error);
            } 
            dispatch( startFinishSavingForm());
        }, 500);
    }
}

export const startGuardarUltimaSeccion = ()=>{
    return async(dispatch,getState)=>{
        dispatch( startSavingForm());
        const {
            codigo,
            accionesComunicacion,
            MaterialEducativo_id_MaterialEducativos,
            tipoIntegracion,
            integracionDesc,
            InstanciaCapacitacion_id_InstanciaCapacitacions,
            sustentabilidad,
            sintesis
        } = getState().pReducer.pFocus;
        const { id} = getState().usrReducer.usrFocus;
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/p/`,{
            method : 'PUT',
            headers: {
                'Content-Type' : 'application/json'
            },
            body :JSON.stringify({
                codigo : codigo,
                idUsr : id,
                dataProyecto : {
                    accionesComunicacion,
                    MaterialEducativo_id_MaterialEducativos,
                    tipoIntegracion,
                    integracionDesc,
                    InstanciaCapacitacion_id_InstanciaCapacitacions,
                    sustentabilidad,
                    sintesis
                }
            })
        });


        const data = await resp.json();

        setTimeout(() => {
            if(data.ok) {
                alert('datos guardados con exito');
                
            } else {
                alert(data.error);
            } 
            dispatch( startFinishSavingForm());
        }, 500);
    }
}

export const startGetFormsEvalDelProyecto = ( _id )=>{
    return async(dispatch)=>{
        dispatch(iniciarCargaFormsEvaluacion());
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/p/eval/all/${_id}`);

        const data = await resp.json();

        if(data.ok ) {
            dispatch(editarFormProyecto({
                lFormsEval : data.data
            }));
          
            dispatch(finalizarCargaFormsEvaluacion());
        }else {
            alert(data.error);
        }
        
    }
}

export const startEditarInstitucionInterv = (_indice) => {
    return async(dispatch)=>{
        dispatch(startSelecInstInterv(_indice));
        dispatch(abrirModalInstitucionInterviniente());
    }
}

export const startSubirDocAdjunto = (formData)=>{
    return async (dispatch,getState)=>{
      
        const {pFocus} = getState().pReducer;
        const {codigo} = pFocus;
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/p/upload/${codigo}`,{
            method : 'POST',
            body : formData
        });
       
        const data = await resp.json();

       
        if(data.ok) {
            alert('doc cargado correctamente !');
            dispatch(editarFormProyecto({
                lDocAdjuntos : pFocus.lDocAdjuntos ? [...pFocus.lDocAdjuntos, {...data.data}] : [{...data.data}]
            }));
            
            dispatch(cerrarModal())
        } else {
            alert(data.error)
        }
    }
}

const editarFormProyecto = (campos ={})=>({
    type : types.pEditar,
    payload : campos
})
