import React from 'react'
import { useTablasProyectos } from './hooks/useTablasProyectos'
import { Tabla } from '../../components/Tabla/Tabla';
import { FilaTabla } from '../../components/Tabla/FilaTabla';

export const TablaMisProyectos = () => {

    const {
        convocatoriaEnEstadoRecepcionIP,
        handleCrear,
        handleEditar,
        handleBorrar,
        handleFiltrar,
        handleOrdenarPorId,
        handleOrdenarPorCodigo,
        handleOrdenarPorTitulo,
        handleOrdenarPorResponsable,
        handleOrdenarPorEstado,
        listaProyectosUsuario,
        printFechaDiaMesAnio
    } = useTablasProyectos();


    const printListaProyectosUsuario = ()=>
        listaProyectosUsuario.length > 0 
        ? listaProyectosUsuario
            .map( (proy,i) => 
                <FilaTabla 
                    key={`filaProy-${i}`} 
                    _cabecera={false} 
                    _id={proy.id}
                >
                    <td >{proy.id}</td>
                    <td ><small>{proy.codigo}</small></td>
                    <td>{printFechaDiaMesAnio(new Date(proy.fechaCreacion))}</td>
                    <td >{proy.titulo}</td>
                    <td >{proy.responsable}</td>
                    <td >{proy.estado}</td>
                </FilaTabla> 
                )
        :<></>;

  return (
    <Tabla
        _linkForm={`/ficha/crear`}
        _gestiona={{
            _handleCrear  : convocatoriaEnEstadoRecepcionIP() ? handleCrear  : null ,
            _handleEditar : handleEditar,
            _handleBorrar : convocatoriaEnEstadoRecepcionIP() ? handleBorrar : null
        }}
        _tipo = 'p'
    >
        <colgroup>

            <col span='1' style={{width: '2%'}}></col>
            <col span='1' style={{width: '13%'}}></col>
            <col span='1' style={{width: '5%'}}></col>
            <col span='1' style={{width: '40%'}}></col>
            <col span='1' style={{width: '10%'}}></col>
            <col span='1' style={{width: '10%'}}></col>
        </colgroup>
        <thead className='bg-primary text-white'>
        
            <FilaTabla _id={3} _cabecera={true} _titulo={true}>
                <th className='text-center' colSpan={7}> MIS PROYECTOS </th>
            </FilaTabla>
            <FilaTabla _cabecera={true} _titulo={true}>
                <th colSpan={7}> 
                    <div className='row'>
                        <div className='col-1'>FILTRAR :</div>
                        <div className='col-2'>
                            <select className='form-control' onChange={handleFiltrar} > 
                                <option value=''>TODOS</option> 
                                <option value='23'>2023</option> 
                                <option value='24'>2024</option>
                            </select> 
                        </div>
                    </div>
                </th>
            </FilaTabla>
            <FilaTabla _cabecera={true} _id={0}>
           




                <th style={ {cursor : 'pointer'} } onClick={handleOrdenarPorId}>ID</th>
                <th style={ {cursor : 'pointer'} } onClick={handleOrdenarPorCodigo}>CÓDIGO</th>
                <th >FECHA CREACIÓN</th>
                <th style={ {cursor : 'pointer'} } onClick={handleOrdenarPorTitulo}>TÍTULO</th>
                <th style={ {cursor : 'pointer'} } onClick={handleOrdenarPorResponsable}>RESPONSABLE</th>
                <th style={ {cursor : 'pointer'} } onClick={handleOrdenarPorEstado}>ESTADO</th>
            </FilaTabla>
        </thead>
        <tbody >
            {
              printListaProyectosUsuario()
            }
        </tbody>

        
    </Tabla>
  )
}
