import React from 'react';
import { Campo } from '../../components/Formulario/Campo';
import { VistaPreviaIdeaProyecto } from '../../components/FormularioIdeaProyecto/VistaPreviaIdeaProyecto';
import { ListaInstitucionesIp } from '../../components/FormularioIdeaProyecto/ListaInstitucionesIp';
import { ListaIntegrantesIdeaProyecto } from '../../components/FormularioIdeaProyecto/ListaIntegrantesIdeaProyecto';
import { Formulario } from '../../components/Formulario/Formulario';
import { useFormIdeaProyecto } from '../../components/FormularioIdeaProyecto/hooks/useFormIdeaProyecto';


export const FormIdeaProyecto = () => {
  
  const {
    campos , 
    validaciones , 
    handleCampoChange , 
    validarCampo , 
    ipFocus,
    formularioBloqueado,
    lCategEquipo,
    lTipoProyecto,
    lProgramasExtension,
    lLineasTematicas,
    lUnidadesAcademicas,
    handleAbrirPestaña,
    handleSubmitIdeaProyecto,
    refVistaPrevia
  } = useFormIdeaProyecto();

  return (
  
      <div className='container-xl'>
        {
          ipFocus && 

          <Formulario 
            idForm={ipFocus.id}
            titulo={'Formulario Idea Proyecto'}
            imprimible={true}
            tipoEvaluacion={undefined}
            tipoFormulario='ip'
            handleSubmit = {handleSubmitIdeaProyecto}
            componenteVistaPrevia={ <VistaPreviaIdeaProyecto ref={refVistaPrevia}  /> }
            refVistaPrevia={refVistaPrevia}
          >
              <div className='col'>

                <Campo
                  disabled={formularioBloqueado ? formularioBloqueado : undefined} 
                  name='tipoProyecto'
                  etiqueta='Modalidad del proyecto'
                  msgayuda={
                    <>
                      Seleccione la modalidad de la I.P. , puede consultar las modalidades en : 
                      <a onClick={handleAbrirPestaña} href='https://www.unl.edu.ar/extension/categorias/proyectos/modalidades/'>
                      https://www.unl.edu.ar/extension/categorias/proyectos/modalidades/ </a>
                    </>
                  }
                  handlecampochange={handleCampoChange}
                  handleblur={validarCampo}
                  type='select' 
                  value = {campos['tipoProyecto']}
                  opciones={lTipoProyecto || []} 
                  valido = {validaciones['tipoProyecto']}
                  required={true}
                />


                <Campo
                  disabled={formularioBloqueado ? formularioBloqueado : undefined} 
                  name='unidadAcademica'
                  etiqueta='UU.AA.'
                  msgayuda={
                    'Seleccione la unidad académica a la que pertenece el proyecto'
                  }
                  handlecampochange={handleCampoChange}
                  handleblur={validarCampo}
                  type='select' 
                  value = {campos['unidadAcademica']}
                  opciones={lUnidadesAcademicas.filter( ua => ua.id !== 'OTRA') || []} 
                  valido = {validaciones['unidadAcademica']}
                  required={true}
                />

                  <Campo
                    handleblur={validarCampo}
                    etiqueta='Duración del proyecto' 
                    name='bianual' 
                    nomopc1='bianual' 
                    nomopc2='anual'  
                    valueopc1={1}
                    valueopc2={0}
                    type='booleano' 
                    value={0}
                    valido = {validaciones['bianual']}
                    handlecampochange = {handleCampoChange}
                    disabled={true}
                    required={true}
                  />

                <Campo
                  disabled={formularioBloqueado ? formularioBloqueado : undefined} 
                  name='categoriaProyecto'
                  etiqueta='Categoría de idea proyecto'
                  msgayuda={
                    <>
                      Según los equipos que lo conformen:<br/>
                      <p style={{fontSize : '10px'}}>
                        En todas las propuestas será una condición la interdisciplinariedad, cada equipo podrá seleccionar la categoría que considere pertinente:<br/>
                        * Iniciación en la Extensión Universitaria: Incluye todos aquellos equipos de trabajo recientemente constituidos o sin experiencia previa que quieran presentar proyectos. Los proyectos que se enmarquen en esta categoría deberán prever acciones de capacitación y fortalecimiento del equipo, brindados por la SESyC.<br/>
                        * Con experiencia previa en extensión universitaria: incluye a todos aquellos equipos que vienen trabajando con proyectos y/o prácticas de extensión aprobados por el C.S. hasta 2 convocatoria en Proyectos de extensión bianuales y hasta 3 convocatorias en caso de proyectos y prácticas de extensión anuales.<br/>
                        * Con trayectoria en extensión universitaria: incluye a todos aquellos equipos con trayectoria y experiencia en la extensión universitaria, que vienen trabajando con proyectos y/o prácticas de extensión aprobados por el CS en más de cuatro convocatorias. Los proyectos que se enmarquen en esta categoría deberán prever instancias de sistematización de la experiencia y coordinación de acciones específicas con los programas de extensión referentes a la temática que se trabaje/plantee. <br/>
                      </p>
                    </>
                  }
                  type='select'
                  opciones={lCategEquipo || []}
                  value={campos['categoriaProyecto']}
                  valido={validaciones['categoriaProyecto']}
                  required={true}
                  handlecampochange={handleCampoChange}
                  handleblur={validarCampo}
                />
                  <Campo
                    disabled={formularioBloqueado ? formularioBloqueado : undefined} 
                  
                  name='ProgramaExtension_id_ProgramaExtensions'
                  type='select'
                  etiqueta='Programas de extensión relacionados'
                  multiple={true}
                  msgayuda={ ` `}
                  value={campos['ProgramaExtension_id_ProgramaExtensions']}
                  valido={validaciones['ProgramaExtension_id_ProgramaExtensions']}
                  opciones={lProgramasExtension || []}
                  size={10}
                  required={true}
                  handlecampochange={handleCampoChange}
                  handleblur={validarCampo}
                />
                <div className='col m-3'>
                  <h4>Campo temático y linea/s temática/s</h4>
                  <hr />
                  <Campo
                    disabled={formularioBloqueado ? formularioBloqueado : undefined} 
                      
                      name='LineaTematica_id_LineaTematicas'
                      type='select'
                      etiqueta='Linea/s Temática/s'
                      multiple={true}
                      value={campos['LineaTematica_id_LineaTematicas']}
                      valido={validaciones['LineaTematica_id_LineaTematicas']}
                      opciones={lLineasTematicas || []}
                      size={10}
                      required={true}
                      handlecampochange={handleCampoChange}
                      handleblur={validarCampo}
                    />
                    <Campo
                      disabled={formularioBloqueado ? formularioBloqueado : undefined} 
                        style={{resize:'none'}}
                        name='campoTematico'
                        etiqueta='Campo Temático (Máx. 1500 caracteres)'
                        type='textarea'
                        value={campos['campoTematico']}
                        valido={validaciones['campoTematico']}
                        required={true}
                        rows={8}
                        minLength = {1}
                        maxLength = {1500}
                        handlecampochange={handleCampoChange}
                        handleblur={validarCampo}
                    />
                    <small className='mb-3 text-muted'> 
                      En este ítem se pretende describir la línea temática de interés más relevante en el que se enmarca la IP. Mencionar si el equipo ya ha realizado algún otro proyecto de extensión que se vincule con la temática
                      Se deben citar los proyectos relacionados con la temática e indicar año de ejecución, considerar como
                      marco temporal los últimos 3 años. {'(Disponibles'} en <a onClick={handleAbrirPestaña} href='https://www.unl.edu.ar/extension/proyectos-vigentes/'>https://www.unl.edu.ar/extension/proyectos_última_convocatoria</a>
                    </small>
                </div>
                
                <ListaIntegrantesIdeaProyecto />
                <ListaInstitucionesIp />
              
              
                  <Campo
                    disabled={formularioBloqueado ? formularioBloqueado : undefined} 
                    style={{resize:'none'}}
                    name='titulo'
                    etiqueta='Título. (Máx. 250 caracteres)'
                    msgayuda={`
                      El título debe ser conciso, claro y pertinente a la temática (problemática) del proyecto.
                    `}
                    type='textarea'
                    value={campos['titulo']}
                    valido={validaciones['titulo']}
                    required={true}
                    minLength={1}
                    maxLength={250}
                    rows={3}
                    handlecampochange={handleCampoChange}
                    handleblur={validarCampo}
                />
                <Campo
                    disabled={formularioBloqueado ? formularioBloqueado : undefined} 
                    style={{resize:'none'}}
                    name='problemaAbordar'
                    etiqueta='Problema a abordar(Máx. 4000 caracteres)'
                    msgayuda={
                      `
                        Se debe explicitar de manera clara y concreta el problema que el proyecto abordará en el espacio socio-cultural en el que 
                        se intervendrá. ¿Cuál es el problema que el proyecto abordará en ese espacio territorial? ¿Cómo se manifiesta el problema? 
                        ¿A quiénes afecta centralmente? ¿Es un problema sentido para esa comunidad? ¿Cuáles son sus causas y sus consecuencias?
                        ¿Disponen de datos, estudios e información fehaciente y válida que permita dimensionar el problema?
                        Éstas son algunas de las preguntas que se sugieren como orientadoras para la identificación del problema. 
                        En este punto se debe explicitar el espacio geográfico en el que se desarrollará el proyecto
                      `
                    }
                    type='textarea'
                    value={campos['problemaAbordar']}
                    valido={validaciones['problemaAbordar']}
                    required={true}
                    rows={15}
                    minLength = {1}
                    maxLength = {4000}
                    handlecampochange={handleCampoChange}
                    handleblur={validarCampo}
                />
                <Campo
                  disabled={formularioBloqueado ? formularioBloqueado : undefined} 
                    style={{resize:'none'}}
                    name='finalidad'
                    etiqueta='Finalidad(Máx. 4000 caracteres)'
                    msgayuda={
                    `Marca el horizonte del proyecto, es su direccionalidad. Cabe aclarar, entonces, 
                    que la finalidad es el horizonte de sentido en el que se inscribe el proyecto, es el fin último.`
                    }
                    type='textarea'
                    value={campos['finalidad']}
                    valido={validaciones['finalidad']}
                    required={true}
                    rows={15}
                    minLength = {1}
                    maxLength = {4000}
                    handlecampochange={handleCampoChange}
                    handleblur={validarCampo}
                />
                <Campo
                    disabled={formularioBloqueado ? formularioBloqueado : undefined} 
                    name='politicasPublicas'
                    etiqueta='Políticas Públicas(Máx. 4000 caracteres)'
                    msgayuda={
                    `Identificar las políticas públicas vigentes que se vinculan con la temática planteada.`
                    }
                    type='textarea'
                    value={campos['politicasPublicas']}
                    valido={validaciones['politicasPublicas']}
                    required={true}
                    rows={9}
                    minLength = {1}
                    maxLength = {4000}
                    handlecampochange={handleCampoChange}
                    handleblur={validarCampo}
                />
                <Campo
                    disabled={formularioBloqueado ? formularioBloqueado : undefined} 
                    style={{resize:'none'}}
                    name='participantesSociales'
                    etiqueta='Participantes sociales directos(Máx. 4000 caracteres)'
                    msgayuda={
                      `Mencionar cuáles son los participantes sociales directos “socios” en el proyecto.
                      (Por participantes sociales directos se entiende a aquellas personas que trabajarán con
                          el equipo extensionista universitario, para lograr el desarrollo del proyecto)`
                    }
                    type='textarea'
                    value={campos['participantesSociales']}
                    valido={validaciones['participantesSociales']}
                    required={true}
                    rows={8}
                    minLength = {1}
                    maxLength = {4000}
                    handlecampochange={handleCampoChange}
                    handleblur={validarCampo}
                />
                <Campo
                    disabled={formularioBloqueado ? formularioBloqueado : undefined} 
                    style={{resize:'none'}}
                    name='potencialesActividades'
                    etiqueta='Potenciales actividades(Máx. 4000 caracteres)'
                    msgayuda={
                      `Describir las posibles actividades a desarrollar, con el fin de contribuir a la resolución de la finalidad del
                      proyecto según la modalidad seleccionada (PEIS/AET). Ej: talleres participativos, capacitaciones, y
                      demás actividades en el marco de la intervención propuesta.`
                    }
                    type='textarea'
                    value={campos['potencialesActividades']}
                    valido={validaciones['potencialesActividades']}
                    required={true}
                    rows={10}
                    minLength = {1}
                    maxLength = {4000}
                    handlecampochange={handleCampoChange}
                    handleblur={validarCampo}
                />
                

              
            </div>
          

            </Formulario>
        }

      </div>
    
       
  )
}
