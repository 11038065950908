import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { startGuardarEvaluacionCalidad } from '../../actions/epcal'

import { FormEvaluacion } from '../../components/FormularioEvaluacion/FormEvaluacion'
import { FormularioFirmaDigital } from '../../components/FormularioEvaluacion/FormularioFirmaDigital'
import { mostrarAlerta } from '../../helpers/mostrarAlerta'
import { useGestionarEstados } from '../../hooks/useGestionarEstados'
import { useGestionarUsuario } from '../../hooks/useGestionarUsuario'


export const FormEvaluacionCalidad = ({_tipoProyecto = '',_tipoUsuario = ''}) => {
    const dispatch = useDispatch();
    
    const params = useParams();


    const {pcalFocus} = useSelector(state => state.pcalReducer);
    const {pFocus} = useSelector( state => state.pReducer);
    const {id : idUsr,tipoUsuario } = useSelector(state => state.usrReducer.usrFocus);
    
    const {activarVistaPrevia} = useSelector(state => state.uiReducer);

    const {
        usuarioEsDueñoDelProyecto,
        usuarioEsEvaluadorCalidad
    } = useGestionarUsuario()
    const {esFormularioDefinitivo} = useGestionarEstados();
  

    const handleSubmitEvaluacionCalidad = (e,reset)=>{
        e.preventDefault();
        if( tipoUsuario !== 'admin' &&
            pFocus.lFormsEval.find( _form => _form.id === pcalFocus.id).evaluador.id !== idUsr) {

            alert('No es el evaluador correspondiente a este formulario');
            return;
        }
        mostrarAlerta('¿Desea guardar los cambios?','Guardar Evalucion',true)
            .then( resp => {
                if(resp.isConfirmed){
                    dispatch( startGuardarEvaluacionCalidad(params.idForm));
                    reset();
                }
            } )
    }




    return (
        <div className='container bg-secondary bg-opacity-10'>   
            <div className='row row-cols-1 animate__animated animate__fadeIn'>
                {
                    pcalFocus &&
                    
                    <FormEvaluacion 
                        titulo='Evaluación de Calidad'
                        handleSubmitEvaluacion={handleSubmitEvaluacionCalidad}
                        _tipoEvaluacion = 'pcal'
                        _tipoUsuario={_tipoUsuario}
                    />
                }
                {   
                    pcalFocus &&

                    (!activarVistaPrevia) && 
                    (!usuarioEsDueñoDelProyecto()) &&
                    usuarioEsEvaluadorCalidad() &&
                    esFormularioDefinitivo(pcalFocus.id) &&

                    <FormularioFirmaDigital _tipoForm='pcal' />
                }
             
            </div>
        </div>
    );
}
  

