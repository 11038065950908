import { types } from "../types/types"


export const startLoadingForm = ()=>({
    type : types.uiLoadingForm
});
export const startFinishLoadingForm = ()=>({
    type : types.uiFinishLoadingForm
});

export const startSavingForm = ()=>({
    type : types.uiSavingForm
});
export const startFinishSavingForm = ()=>({
    type : types.uiFinishSavingForm
});
export const iniciarCargaBases = ()=>({type : types.uiStartCargaBases});
export const iniciarCargaProyUlt3Anios = ()=>({type : types.uiStartCargaProyUlt3Anios});
export const iniciarCargaInstCargadas = ()=>({type : types.uiStartCargaInstCargadas});

export const iniciarCargaProyecto = ()=>({type : types.uiStartCargaProy});
export const iniciarCargaIdeaProyecto = ()=>({type : types.uiStartCargaIdeaProy});
export const iniciarCargaConvocatoria = ()=>({type : types.uiStartCargaConvocatoria});

export const iniciarCargaFormsEvaluacion = ()=>({type : types.uiStartCargaFormsEval});
export const abrirModalPalabrasClave = ()=> ({type : types.uiShowModalPalabrasClave});
export const abrirModalEquipoExtension = ()=>({type : types.uiShowModalIntegrante});
export const abrirModalInstitucionInterviniente = ()=>({type : types.uiShowModalInstitucionInterviniente});
export const abrirModalCoherenciaInterna = ()=>({type : types.uiShowModalCoherenciaInterna});
export const abrirModalParticipanteDirecto = ()=>({type : types.uiShowModalParticipanteDirecto});
export const abrirModalVistaPrevia = ()=>({type : types.uiShowModalVistaPrevia});
export const abrirModalComisionEvaluadora = ()=>({type : types.uiShowModalComisionEvaluadora});
export const abrirModalAsigProyProgExt = ()=>({type : types.uiShowModalAsigProyProgExt});
export const abrirModalIntegranteEquipoIp = ()=>({type : types.uiShowModalIntegranteEquipoIp});
export const abrirModalInstitucionIp = ()=>({type : types.uiShowModalInstitucionIp});
export const abrirModalBecarioInforme = (esFinal = false)=>({type : types.uiShowModalBecarioInforme,payload : esFinal});
export const abrirModalInstitucionInforme = (esFinal = false)=>({type : types.uiShowModalInstitucionInforme,payload : esFinal});
export const abrirModalPartiDirectInforme = (esFinal = false)=>({type : types.uiShowModalPartDirectInforme,payload : esFinal});
export const abrirModalMaterialEducativoInforme = ()=>({type: types.uiShowModalMaterialEducativoInforme});
export const abrirModalActividadNuevaInforme = (esFinal = false)=>({type: types.uiShowModalActividadNuevaInforme,payload : esFinal});


export const presionarVistaPrevia = ()=>({type : types.uiToggleVistaPrevia})
export const editarPermisos = (_nuevosPermisos)=>({
    type : types.uiSetPermisos,
    payload : _nuevosPermisos
})

export const cerrarModal = ()=>({type : types.uiHideModal})

export const setParamElegido = ( parametro = '')=>({type : types.uiSetParamElegido, payload : parametro })
export const FinalizarCargaProyecto = ()=>({type : types.uiFinishCargaProy});
export const FinalizarCargaIdeaProyecto = ()=>({type : types.uiFinishCargaIdeaProy});
export const finalizarCargaFormProy = ()=>({type : types.uiFinishCargaFormProy})
export const finalizarCargaDataBase = ()=>({type : types.uiFinishGetDataBase})
export const finalizarCargaProyUlt3Anios = ()=>({type : types.uiFinishGetProyUlt3Anios})
export const finalizarCargaInstCargadas = ()=>({type : types.uiFinishGetInstCargadas})
export const finalizarCargaEvaluadores = ()=>({type : types.uiFinisGetEvaluadores})
export const finalizarCargaInfAvance = ()=>({type : types.uiFinishCargaFormInfAV})
export const finalizarCargaFormsEvaluacion = ()=>({type : types.uiFinishCargaFormsEval})
export const finalizarCargaListaINfAv = ()=>({type : types.uiFinishGetListaInfAv});
export const finalizarCargaInfFinal = ()=>({type : types.uiFinishCargaFormInfFinal})
export const finalizarCargaListaINfFinal = ()=>({type : types.uiFinishGetListaInfFinal});
export const resetCargaInfAvance = ()=>({type : types.uiResetInfAvance})

export const finalizarCargaConvocatoria = ()=>({type : types.uiFinishCargaConvocatoria});
export const resetUi = ()=>({type : types.uiReset})
export const resetPanelUi = ()=>({
    type : types.uiPanelReset  
})
export const informarEstadoQuery = (msg = '')=>({
    type : types.uiBackPennding,
    payload : msg
})