import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEditarInforme } from '../../actions/informe';


export const PoliticasPublicas = ({esFinal = false}) => {
  const dispatch = useDispatch();

  const {infAvFocus} = useSelector(state => state.infAvReducer);
  const {infFinalFocus} = useSelector(state => state.infFinalReducer);
  const dataInforme =  esFinal ? infFinalFocus : infAvFocus;;
  const{politicasPublicasEstablecidas} =dataInforme;

  const handleOnChange = (e)=>{

    e.preventDefault();
    dispatch(startEditarInforme({
      ...dataInforme,
      politicasPublicasEstablecidas :  e.target.value
    }));

  }
  return (
    <div className='row row-cols-1 m-2'>
      <div className='col'>
      <h5>¿Se lograron establecer relaciones con las políticas públicas identicadas en la formulación del proyecto? </h5>
      <p> Especificar con cuáles. En caso de que no se hayan establecido, detallar los motivos.</p>
      </div>
      <div className='col'>
        
    
        <textarea className='form-control'  style={{resize : 'none'}}
          id={'politicasPublicasEstablecidas'}
          name={'politicasPublicasEstablecidas'}
          value={politicasPublicasEstablecidas}
          rows={6} 
          onChange={handleOnChange}
          />
      </div>
    </div>
  )
}
