import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { abrirModalActividadNuevaInforme } from '../../actions/ui';
import { setIdObjEspModalActCargdas, startEditarInforme } from '../../actions/informe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';


const ColumnaEstado = ({actObj , obj , handleOnChange = ()=>{}})=>{
  return (
    <div className='col-2  border-end border-start'> 
      {
        actObj &&
        <select className='form-select'
          id={actObj.id}
          name={'estado'}
          value={ actObj.estado || '' }
          onChange={e => handleOnChange(e,{idAct : actObj.id, idObj : obj.id})}
        >
          <option value={''}>Seleccione</option>
          <option value={2}>Finalizada</option>
          <option value={1}>Pendiente</option>
          <option value={3}>Suspendida</option>

        </select> 
      }
    </div>
  )
}

const ColumnaDescripcion = ({ i , j, actObj})=>{
  return (
    <div className='col-4  border-end border-start'>{ `${i}.${j} - ${actObj.desc}`  } </div>
  )
}

const CronogramaA1 = ({actObj, lMeses = []})=>{
  return (
    <>
    {
      `Año 1 : [
        ${ actObj.CronogramaA1s 
          ?  actObj.CronogramaA1s.map( idMes => lMeses.find(_mes => _mes.id === Number(idMes)).nom.slice(0,3)).join(' ') 
          : '' 
      }]`
    }
    </>
  )
}

const CronogramaA2 = ({actObj, lMeses = []})=>{
  return (
    <>
      {
        `Año 2 : [
          ${actObj.CronogramaA2s 
              ? actObj.CronogramaA2s.map( idMes => lMeses.find(_mes => _mes.id === Number(idMes)).nom.slice(0,3)).join(' ') 
              : ''
            }]`
      }
    </>
  )
}

const ColumnaPeriodo = ({ actObj, lMeses=[] })=>{
  return (
    <div className='col-2 text-wrap'> 
      {
        actObj &&
        <>
          <p className='text-wrap'><CronogramaA1  actObj={actObj}  lMeses={lMeses}/></p>
          <p className='text-wrap'><CronogramaA2  actObj={actObj}  lMeses={lMeses}/></p>
        </>
      }
  </div>
  )
}


const ColumnaResultadoMotivo = ({actObj , obj , handleOnChange = ()=>{}})=>{
  return (
    <div className='col-3 '> 
       {
        actObj &&
        <textarea 
          className='form-control' 
          id={actObj.id} 
          name={'resultados'}
          onChange={e => handleOnChange(e,{idAct : actObj.id, idObj : obj.id})} 
          rows={4} 
          cols={20} 
          style={{resize:'none'}}
          value={actObj.resultados || ''}
        />
       }
    </div>
  )
}

export const FilaActividad = ({
  i = 1,
  j = 1,
  obj ,
  actObj , 
  lMeses = [], 
  handleOnChange = ()=>{}, 
  handleEliminarActividad = ()=>{}})=>{
  return (
    <div className='row border-bottom border-light pt-2 pb-2'>
      <ColumnaDescripcion i={i} j={j} actObj={actObj} />
      <ColumnaPeriodo actObj={actObj} lMeses={lMeses} />
      <ColumnaEstado  actObj={actObj} obj={obj} handleOnChange={handleOnChange}/>
      <ColumnaResultadoMotivo actObj={actObj} obj={obj} handleOnChange={handleOnChange} />
      <div className='col-1'>
        {
          actObj.id === 0 && <FontAwesomeIcon className='btn btn-outline-danger' icon={faTrash} title='Eliminar Actividad'  onClick={(e)=>handleEliminarActividad(e,actObj.id)}/>
        }
      
      </div>
    </div>
  )
}

export const ActividadesCargadas = ({
    lMeses = [],
    esFinal = false
}) => {
    const dispatch = useDispatch();
    const {infAvFocus} = useSelector( state => state.infAvReducer);
    const {infFinalFocus} = useSelector( state => state.infFinalReducer);

    const dataInforme = esFinal ? infFinalFocus : infAvFocus;

    const ObjetivoEspecificos = dataInforme.CoherenciaInterna ? dataInforme.CoherenciaInterna.ObjetivoEspecificos : undefined;

    const handleEliminarActividad = (e,_id)=>{
      e.preventDefault();
      const data = {
        ...dataInforme,
        CoherenciaInterna : {
         ...dataInforme.CoherenciaInterna,
             ObjetivoEspecificos : ObjetivoEspecificos.map( obj => ({
               ...obj,
               ActividadObjetivos : obj.ActividadObjetivos.filter(  act => act.id !== _id )
             })
          )}
      };
      dispatch( startEditarInforme(data,esFinal));

    }
    const handleAgregarActividad = (e)=>{
      e.preventDefault();
      dispatch(setIdObjEspModalActCargdas())
      dispatch(abrirModalActividadNuevaInforme(esFinal))
      };


    const handleOnChange = (e,_data)=>{
      e.preventDefault();

      const {idAct, idObj} = _data;
      let data = {};
      if(e.target.name === 'estado'){
        data = {estado : e.target.value}
      }
      if(e.target.name === 'resultados'){
        data = {resultados : e.target.value}
      }
      
     
      dispatch(
        startEditarInforme({
         ...dataInforme,
         CoherenciaInterna : {
          ...dataInforme.CoherenciaInterna,
              ObjetivoEspecificos : ObjetivoEspecificos.map( obj => (
                  obj.id === idObj
                    ? {
                    ...obj,
                    ActividadObjetivos : obj.ActividadObjetivos.map( 
                        act => act.id === idAct 
                            ? {...act,...data} 
                            : act
                        )
                    }
                    : obj
                )
             )
           }
        }));

    }



  return (
    <div className='row row-cols-1 m-2'>
    <div className='col p-2'>
      <div className='row'>
        <div className='col-3'> <h4 className='mt-2'>ACTIVIDADES CARGADAS</h4> </div>
        <div className='col-1'>
            <button className='btn btn-outline-primary'  onClick={handleAgregarActividad}>Agregar</button>
          </div> 
      </div>
    </div>
    <div className='col'>
      <div className='row bg-primary text-light p-2 '>
        <div className='col-3 '> Objetivo Estratégico </div>
        <div className='col-3  border-end border-start'> Actividad </div>
        <div className='col-1 me-5 '> Periodo </div>
        <div className='col-1 border-start me-5 '> Estado </div>
        <div className='col-2 border-start'> Resultado/Motivo</div>
      </div>
    </div>
    <div className='col' style={{maxHeight : '400px', overflow : 'scroll'}}>
      
      {
          // (!cargandoForm) &&
          ObjetivoEspecificos &&
          ObjetivoEspecificos.map(
            (obj, i) => 
            <div className='row border-bottom'>
              <div className='col-3'> { ` ${i+1} - ${ obj.desc}` } </div>
                <div className='col-9 border-end '>
                  { 
                    obj.ActividadObjetivos && 
                    obj.ActividadObjetivos.map(  
                      (actObj , j)=>
                        <FilaActividad 
                          i = {i+1}
                          j = {j+1}
                          obj={obj}
                          actObj={actObj} 
                          lMeses={lMeses} 
                          handleOnChange={handleOnChange} 
                          handleEliminarActividad={handleEliminarActividad} 
                        /> 
                    ) 
                  }
              </div>
          </div>
          )
        }
      
    </div>
    <hr className='mt-3'/>
</div>
  )
}
