import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEditarFormIp } from '../../actions/ip';
import { abrirModalIntegranteEquipoIp } from '../../actions/ui';
import { mostrarAlerta } from '../../helpers/mostrarAlerta';
import { useGestionarEstados } from '../../hooks/useGestionarEstados';
import { useGestionarUsuario } from '../../hooks/useGestionarUsuario';

export const ListaIntegrantesIdeaProyecto = () => {
    const dispatch = useDispatch();
    const { Persona_id_Personas } = useSelector( state => state.ipReducer.ipFocus);
    const {tipoUsuario} = useSelector( state => state.usrReducer.usrFocus);
    const {listaP,pFocus} = useSelector( state => state.pReducer);


    const {usuarioEsAdmin,usuarioEsDueñoDelProyecto} = useGestionarUsuario();
    
    const {
        bloquearFormularioIpPorEstadoEvaluacion,
         instanciaEvaluacionIpTerminada,
        chequearProyectoNuevo
    } = useGestionarEstados();

    const handleEliminarIntegrante = (e,_i)=>{
        e.preventDefault();
        if( (!chequearProyectoNuevo()) && 
            listaP.listaProyectosUsuario &&
            (!listaP.listaProyectosUsuario.find( proy => proy.id === pFocus.id )) &&
            (tipoUsuario !== 'admin')
        ){
                
            alert('No posee permisos para realizar esta acción');
            return;
        }
        
        if(bloquearFormularioIpPorEstadoEvaluacion() || instanciaEvaluacionIpTerminada()){
            alert('No posee permisos para realizar esta acción');
            return;
        }

        mostrarAlerta(`¿Desea eliminar el/la participante?`,'Eliminar participante',true)
        .then( resp => 
            resp.isConfirmed &&
            dispatch(
                startEditarFormIp({
                    Persona_id_Personas : Persona_id_Personas.filter( (pers,i)=> i !== _i)
                })
            )
         );

          
       
        return;
    }

    const handleMostrarModalIntegranteEquipo = (e)=>{
        e.preventDefault();
        if( (!chequearProyectoNuevo()) && 
            listaP.listaProyectosUsuario &&
            (!listaP.listaProyectosUsuario.find( proy => proy.id === pFocus.id )) &&
            (tipoUsuario !== 'admin')
        ){
            
            alert('No posee permisos para realizar esta acción');
            return;
        }
    
        if(bloquearFormularioIpPorEstadoEvaluacion() || instanciaEvaluacionIpTerminada()){
            alert('No posee permisos para realizar esta acción');
            return;
        }
        dispatch(abrirModalIntegranteEquipoIp());
        return;       
    }

  
    return (
    <div className='col mb-4'>
        <h4 className='mb-3 p-3 bg-light'>
            Participantes del proyecto 
            {
                 chequearProyectoNuevo()
                 ? <span className='btn btn-primary float-end' 
                        onClick={handleMostrarModalIntegranteEquipo}
                    >Agregar Participante
                    </span>
                 :  
                    (tipoUsuario !== 'admin') && 
                    (listaP.listaProyectosUsuario) &&
                    (listaP.listaProyectosUsuario.find( proy => proy.id === pFocus.id )) &&
                    <span className='btn btn-primary float-end' 
                        onClick={handleMostrarModalIntegranteEquipo}
                    >Agregar Participante
                    </span>
            }
            
        </h4>
        <hr />
        <ul className='list-group'>
            {
                Persona_id_Personas && Persona_id_Personas.map( ({dni,ape,nom,unidadAcademica},i) => 
                    <li className='list-group-item'>    
                        {dni} - {ape},{nom} - {unidadAcademica} 
                        {
                            chequearProyectoNuevo()
                            ? <span className='btn btn-outline-danger float-end' onClick={(e)=>handleEliminarIntegrante(e,i)}>x</span>
                            :(usuarioEsAdmin() || usuarioEsDueñoDelProyecto() ) &&
                            <span className='btn btn-outline-danger float-end' onClick={(e)=>handleEliminarIntegrante(e,i)}>x</span>
                        }
                        
                    </li>
                )
            }
        </ul>
        <p className='text-muted'>Nombre y Apellido de los potenciales integrantes del equipo de extensión y la/s unidad/es académica/s de la/s que forman parte los integrantes del equipo de extensión.(tener en cuenta la interdisciplinariedad en su composición).</p>
    </div>
  )
}
