import React from 'react'
import Modal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import { startSelecInstInterv, startSelecIntegEquipo, startSelecParticipDirecto,startCargarVistaPrevia } from '../../actions/bases'
import { resetComision } from '../../actions/comsion'
import { cerrarModal } from '../../actions/ui'

import { ModalPalabrasClave } from '../FormProyecto/FormProyectoSec1/ModalPalabrasClave'

import { ModalEquipoExtension } from '../FormProyecto/FormProyectoSec2/EquipoExtension/ModalEquipoExtension'
import { ModalInstitucionInterviniente } from '../FormProyecto/FormProyectoSec2/InstitucionesIntervinientes/ModalInstitucionInterviniente'
import { ModalParticipanteDirecto } from '../FormProyecto/FormProyectoSec3/ParticipanteDirecto/ModalParticipanteDirecto'
import { ModalCoherenciaInterna } from '../FormProyecto/FormProyectoSec3/CoherenciaInterna/_ModalCoherenciaInterna'

import { ModalInstitucionIp } from '../FormularioIdeaProyecto/ModalInstitucionIp'
import { ModalParticipanteEquipoIp } from '../FormularioIdeaProyecto/ModalParticipanteEquipoIp'
import { ModalVistaPreviaDoc } from '../Modals/ModalVistaPreviaDoc'
import { ModalComisionEvaluadora } from '../PanelComisiones/ModalComisionEvaluadora'
import { ModalProgExt } from '../PanelComisiones/ModalProgExt'
import { ModalActividadNuevaInforme } from '../Modals/ModalActividadNuevaInforme'
import { ModalInformeInstitucionNueva } from '../Modals/ModalInformeInstitucionNueva'
import { ModalInformeAvanceBecario } from '../Modals/ModalInformeAvanceBecario'
import { ModalPartDirectInforme } from '../Modals/ModalPartDirectInforme'





export const ModalBase = () => {
    const dispatch = useDispatch();
    const {showModal, tipoModal,esFinal} = useSelector( state => state.uiReducer);

    const handleCloseModal = (e)=>{
        e.preventDefault();
        dispatch(startSelecInstInterv(null));
        dispatch(startSelecIntegEquipo(null));
        dispatch(startSelecParticipDirecto(null));
        (tipoModal === 'ComisionEvaluadora' || tipoModal === 'AsigProyProgExt') && dispatch(resetComision());
        dispatch( startCargarVistaPrevia(null));
        dispatch(cerrarModal());
       
    }

    const definirAltura = ()=>{
      if(tipoModal === 'PalabraClave') return '23rem';
      if(tipoModal === 'InstitucionIp') return '23rem';
      if(tipoModal === 'IntegranteEquipoIp') return  '34rem';
      if(tipoModal === 'ActividadNuevaInforme') return  '34rem';
      if(tipoModal === 'BecarioInforme') return  '34rem';
      if(tipoModal === 'InstitucionInforme') return  '40rem';
      if(tipoModal === 'ParticipanteDirecto') return  '34rem';
      if(tipoModal === 'MaterialEducativoInforme') return '30rem';
      if(tipoModal === 'PartDirectInforme') return '37rem';
      return '39rem';
    }

    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        backgroundColor : 'rgba(235, 235, 235, 1)',
        height : definirAltura(),
        maxWidth: '1300px',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        overflowY: 'auto'
      },
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.20)',
        overflow : 'none'
      }
      
    };

  return (
    <Modal
        isOpen={showModal}
        style={ customStyles  }
        contentLabel="Example Modal"
    >
        <div className='container'>
          <div className='row'>
              <div className='col'>
                  <button className='btn btn-secondary btn-sm float-end' 
                      onClick={handleCloseModal}
                    > x
                    </button>
                </div>
          </div>
            <div className='row' >
                <div className='col'>
                    
                  {tipoModal === 'ActividadNuevaInforme' &&<ModalActividadNuevaInforme />}
                  {tipoModal === 'EquipoExtension' && <ModalEquipoExtension _titulo='Integrante'  />}
                  {tipoModal === 'InstitucionInterviniente' && <ModalInstitucionInterviniente  _titulo='Institución interviniente' />}
                  {tipoModal === 'PalabraClave' && <ModalPalabrasClave _titulo='Agregar palabra clave' />}
                  {tipoModal === 'CoherenciaInterna' && <ModalCoherenciaInterna _titulo='Coherencia interna' />}
                  {tipoModal === 'ParticipanteDirecto' && <ModalParticipanteDirecto _titulo='Participantes directos' />}
                  {tipoModal === 'VistaPrevia' && <ModalVistaPreviaDoc />}
                  {tipoModal === 'ComisionEvaluadora' && <ModalComisionEvaluadora />}
                  {tipoModal === 'AsigProyProgExt' && <ModalProgExt />}
                  {tipoModal === 'IntegranteEquipoIp' && <ModalParticipanteEquipoIp />}
                  {tipoModal === 'InstitucionIp' && <ModalInstitucionIp />}
                  {tipoModal === 'InstitucionNuevaInforme' && <ModalInformeInstitucionNueva esFinal={esFinal}/>}
                  {tipoModal === 'BecarioInforme' && <ModalInformeAvanceBecario esFinal={esFinal} />}
                  {tipoModal === 'InstitucionInforme' && <ModalInformeInstitucionNueva esFinal={esFinal}/>}
                  {tipoModal === 'PartDirectInforme' && <ModalPartDirectInforme esFinal={esFinal}/>}
                  
                </div>
                
            </div>
        </div>
    </Modal>
  )
}
