import React from 'react'
import { FilaIntegrante } from './FilaIntegrante'
import { useEquipoExtension } from '../hooks/useEquipoExtension';

export const ListaIntegrantesEquipoExtension = () => {

  const {IntegranteEquipos} = useEquipoExtension();
 
    

  return (
    <>
        <div className='row row-cols-2 bg-light p-3'>
            <div className='col-10'>INTEGRANTE/S</div>
            <div className='col-1'>ACCIONES</div>
        </div>
        {
            IntegranteEquipos && 
            IntegranteEquipos
                .sort( (integrante1,integrante2)=> integrante1.ape.localeCompare(integrante2.ape) )
                .map( (integrante,i) => <FilaIntegrante key={`integ-${i}`} _data={integrante} _indice={i} /> )
        }
    
    </>
  )
}
