import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useFormAdjuntarDocumentos } from '../hooks/useFormAdjuntarDocumentos'

export const ItemInstitucionesIntervinientes = () => {

  const {handleVerAval,validarDocInstIntervPresentada} = useFormAdjuntarDocumentos();
  
  return (
    <li className='list-group-item bg-transparent' >
        {'Aval institucional de presentación del Proyecto ante la Secretaría de Extensión y Cultura (formato .pdf). '}  
        <a href={`https://drive.google.com/file/d/1BbnuDJXAWslgbl4Xiepc0Dkt0cKFhHCB/view?usp=sharing`} 
           onClick={ handleVerAval }
        >
            Descargue el modelo aquí
        </a>
        { validarDocInstIntervPresentada() && <FontAwesomeIcon className='ms-4' icon={faCheck}size={'2x'} color={ 'green' }  /> }
    </li>
  )
}
