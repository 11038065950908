import React from 'react'
import { useListaPalabras } from './hooks/useListaPalabras';

export const BotonAgregarPalabraClave = () => {
    const {
        usuarioEsDueñoDelProyecto,
        usuarioEsAdmin,
        handleAgregarPalabraClave
    } = useListaPalabras();
  
    return (
    <>
    {
        (usuarioEsDueñoDelProyecto() || usuarioEsAdmin()) &&
        <button className='btn btn-primary' 
          onClick={handleAgregarPalabraClave}>
            Agregar Palabra Clave
        </button>
    }
    </>
  )
}
