import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEditarInforme } from '../../actions/informe';
import { abrirModalPartiDirectInforme } from '../../actions/ui';

export const ParticipantesDirectos = ({esFinal = false}) => {
  const dispatch = useDispatch();

  const {infAvFocus} =  useSelector ( state => state.infAvReducer)
  const {infFinalFocus} =  useSelector ( state => state.infFinalReducer)
    
  const dataInforme = esFinal ? infFinalFocus : infAvFocus;

  const ParticipanteDirectos = dataInforme ? dataInforme.ParticipanteDirectos : undefined;
  
  const handleOnChange = (e,_index,_indexAct)=>{
    e.preventDefault();

    if(e.target.name === 'valoracion'){
      const data = {valoracion : Number(e.target.value)}
      dispatch(startEditarInforme({
        ...dataInforme,
        ParticipanteDirectos : ParticipanteDirectos.map( (part,i) => i === _index 
             ? {
                 ...part,
                 ActividadParticipantes : part.ActividadParticipantes.map( 
                    (act,j) => j === _indexAct ? {...act,...data} : act
                 )
               } 
             : part
         )
       }))
    }
    else if(e.target.name === 'observParticipantesDirectos') {
        dispatch(startEditarInforme({
            ...dataInforme,
            observParticipantesDirectos : e.target.value
         }))
    }
    else if(e.target.name === 'observ') {
        dispatch(startEditarInforme({
            ...dataInforme,
            ParticipanteDirectos : ParticipanteDirectos.map( (part,i) => i === _index  ? { ...part, observ : e.target.value }  : part )
           }))
    }
  }

  const handleAbrirModalPartDirect = (e)=>{
    e.preventDefault();
    dispatch( abrirModalPartiDirectInforme() );
  }
  return (
    <div className='row row-cols-1 m-2'>
        <div className='col'>
            <div className='row'>
                <div className='col-3'><h4 className='mt-2'>PARTICIPANTES DIRECTOS</h4></div>
                <div className='col-2'><button className='btn btn-outline-primary' onClick={handleAbrirModalPartDirect}>Agregar</button></div>
            </div>
        </div>
        <div className='col'>
            <div className='row bg-primary text-light p-2'>
                <div className='col-5 border-end border-white'> Participantes </div>
                <div className='col-7'> 
                    <div className='row border-bottom border-white justify-content-center' >Actividades</div>
                    <div className='row'>
                        <div className='col-4 border-end border-white'> Actividad </div>
                        <div className='col-3 border-end border-white'>
                            <p>Valoración de la participación</p>
                        </div>
                        <div className='col-5'>
                            <p>Observaciones</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='col' style={{maxHeight : '15em', overflow : 'scroll'}}>
            
            {
                ParticipanteDirectos &&
                ParticipanteDirectos.map(
                    (part,index) => 
                    <div className='row border-bottom  mb-2 mt-2'>
                        <div className='col-5'>
                            {part.nom}
                        </div>
                        
                        <div className='col-4'>
                        {
                            part.ActividadParticipantes.map(
                                ({id, desc,valoracion},indexAct) => 
                                <div className='row mb-2'>
                                    <div className='col'>{desc || ""}</div>
                                    <div className='col'>
                                    <select className='form-select' name='valoracion' onChange={e => handleOnChange(e,index,indexAct)} value={valoracion}>
                                        <option value={''}>Seleccione</option>
                                        <option value={1}>Muy activa</option>
                                        <option value={2}>Activa</option>
                                        <option value={3}>Pasiva</option>
                                        <option value={4}>Obstructiva</option>
                                    </select> 
                                    </div>
                                </div>
                            )
                        }
                        </div>
                        <div className='col-3'>
                            <textarea className='form-control' 
                            name='observ' 
                            rows={3} 
                            onChange={e => handleOnChange(e,index)} 
                            value={part.observ}
                            />
                        </div>
                    </div>
                )
            }
            
        </div>
        <div className='col mt-3'>
            <h4>Observaciones generales</h4> 
            <p> Resumen general del desempeño de los participantes. </p>
            <textarea className='form-control'
                id='observParticipantesDirectos'
                onChange={e => handleOnChange(e)} 
                name={'observParticipantesDirectos'}
                rows={4}
                style={{resize : 'none'}}
                value={dataInforme.observParticipantesDirectos}
            />
        </div>
    </div>
  )
}
