import { types } from "../types/types";



const initialState = {
    listaP : null,
    pFocus : {
        lFormsEval : [],
        CoherenciaInterna : {
            finalidad : '',
            objetivoGeneral : '',
            ObjetivoEspecificos : []
        },
        EquipoExtension : {
            categoriaEquipo : null,
            IntegranteEquipos : []
        },
        ParticipanteDirectos : [],
        InstitucionIntervinientes : [],
        sintesisProblemaAbordar : '',
        detalleProblemaAbordar : '',
        geolocalizacionProblemaAbordar : '',
        politicasPublicas : '',
        propuestaMetodologica : '',
        accionesComunicacion : '',
        solicitaBecario : false,
        bianual : false,
        sustentabilidad : '',
        sintesis : '',
        tipoIntegracion : null,
        InstanciaCapacitacion_id_InstanciaCapacitacions : [],
        PalabraClave_id_PalabraClaves : [],
        ProyectoRelacionados : [],
        MaterialEducativo_id_MaterialEducativos : []
    }
}

export const pReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.pGetLista:
            return {
                ...state,
                listaP : action.payload
            }
        case types.pGetData : 
            return {
                ...state,
                pFocus : {...action.payload}
            }
        case types.pEditar : 
            return {
                ...state,
                pFocus : {
                    ...state.pFocus,
                    ...action.payload
                }
            }
        case types.pReset : 
            return {
                ...state,
                pFocus : initialState.pFocus
            };
        default:
            return state;
    }

}
