import React from 'react';
import Modal from 'react-modal';
import { Provider,  } from 'react-redux';
import { AppRouter } from './router/AppRouter';

import { store } from './store/store';

Modal.setAppElement('#root');
export const App = ()=> {

 
  
  return (
    <>
       <header className='row d-flex justify-content-center mt-5 mb-5'>
        <h1 className='text-center'>
          <img alt='img_header' src='images/gestor_superior_01.jpg' width={1200}/>
        </h1>
      </header>

     <Provider store={store}> 
        
        <AppRouter />
      
     </Provider>

      <footer className='row d-flex justify-content-center mt-5'>
         <h1 className="text-center">
            <img  alt='img_footer' className="rounded" src="images/gestor_inferior.jpg" width="1200" />
         </h1>
      </footer>
    </>
  );
}