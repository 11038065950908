import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';

/**
 * 
 * @param {*} _texto mensaje del alert
 * @param {*} _titulo cabecera del alert
 * @returns 
 */

export const mostrarAlerta = ( _texto = '', _titulo = '', _confirmar = false ) => {

    const alerta = withReactContent(Swal);

    const textoAMostrar = 
        _texto.includes('*')
        ?
        <p style={{whiteSpace:'pre-line'}}>
            {`${_texto.slice(0,_texto.indexOf('*'))}`}
            <b>{_texto.slice(_texto.indexOf('*')+1,_texto.lastIndexOf('*'))}</b>
            {`${_texto.slice(_texto.lastIndexOf('*')+1)}`}
        </p> 
        : <p style={{whiteSpace:'pre-line'}} >{_texto}</p>

    return  alerta.fire({
        width : '30em',
        position: 'top',
        title : <p>{_titulo}</p>,
        buttonsStyling: false,
        customClass: {
            confirmButton: 'btn btn-outline-primary me-2',
            cancelButton : 'btn btn-primary'
        },
        html : textoAMostrar,
        showClass:{
            popup : 'animate__animated animate__fadeInDown',
        },
        hideClass:{
            popup : 'animate__animated animate__fadeOutDown'
        },
        showCancelButton : true,
        cancelButtonText : 'Cerrar',
        showConfirmButton : _confirmar ,
        confirmButtonText : 'Aceptar',
        backdrop : false
    });

}
   