import React from 'react'
import { Campo } from '../../../components/Formulario/Campo';
import { SeccionEquipoExtension } from './EquipoExtension/SeccionEquipoExtension';
import { ListaInstitucionesIntervinientes } from './InstitucionesIntervinientes/ListaInstitucionesIntervinientes';
import { Formulario } from '../../../components/Formulario/Formulario';
import { useFormProyectoSec2 } from './hooks/useFormProyectoSec2';
import { VistaPreviaFormProyecto } from '../VistaPreviaFormProyecto';

export const FormProyectoSec2 = ({_refVistaPrevia}) => {

  const {
      handleSubmitEquipoExtension,
      definirHabilitacionProyectosPrevios,
      definirRequeridoProyectosPrevios,
      definirHabilitacionSolicitaBecarioJustif,
      campos,
      formularioBloqueado,
      validaciones,
      handleCampoChange,
      validarCampo,
      camposEquipoExtension,
      handleCampoChangeEquipoExtension ,
      validarCampoEquipoExtension ,
      lCategEquipo,
      lProyUlt3Anios,
      pFocus
    } = useFormProyectoSec2(); 
    
    

  return (
    <>
    {
        <Formulario
          idForm={pFocus.FormularioProyecto_id}
          titulo={'Equipo de extensión'}
          tipoFormulario='p'
          refVistaPrevia={_refVistaPrevia}
          componenteVistaPrevia={<VistaPreviaFormProyecto ref={_refVistaPrevia} />}
          handleSubmit={handleSubmitEquipoExtension}

        >
           <div className='col'>
              <h4>{'3) Datos del equipo'}</h4> 
              <hr />
              <Campo
                disabled={formularioBloqueado ? formularioBloqueado : undefined} 
                etiqueta='3.1.a) Categoría del equipo'
                handleblur={validarCampoEquipoExtension}
                name={'categoriaEquipo'}
                opciones={lCategEquipo || []}
                handlecampochange={handleCampoChangeEquipoExtension}
                type='select'
                value={camposEquipoExtension['categoriaEquipo'] }
                required={true}
              />
              <Campo
      
                etiqueta='3.1.b) Proyectos previos del equipo'
                handleblur={validarCampoEquipoExtension}
                name={'ProyectoPrevios'}
                opciones={lProyUlt3Anios || []}
                handlecampochange={handleCampoChangeEquipoExtension}
                type='select'
                multiple={true}
                size={10}
                value={camposEquipoExtension['ProyectoPrevios'] }
                required ={ definirRequeridoProyectosPrevios() }
                disabled={ definirHabilitacionProyectosPrevios() }
              />
              <SeccionEquipoExtension />
            </div>
            <div className='col'>
              <h4> {'4) Becas y voluntariado'} </h4> 
              <hr />
              <Campo
                disabled={formularioBloqueado ? formularioBloqueado : undefined} 
                etiqueta='4.1.a) ¿Solicita becario?'
                handleblur={validarCampo}
                name={'solicitaBecario'}
                handlecampochange={handleCampoChange}
                type='booleano'
                nomopc1='si' 
                nomopc2='no'  
                valueopc1={1}
                valueopc2={0}
                multiple={true}
                valido={validaciones['solicitaBecario']}
                value={campos['solicitaBecario'] }
                required={ true }
              />    
              <Campo
              
                etiqueta={`4.1.b) Justifique el pedido, detalle el perfil y 
                mencione las actividades que desarrollará (máx. 1000 caracteres).`} 
                name='solicitaBecarioJustif' 
                type={'textarea'}
                minLength={1} 
                maxLength={1000}
                handlecampochange={handleCampoChange}
                handleblur={validarCampo} 
                value={campos['solicitaBecarioJustif']}
                valido={validaciones['solicitaBecarioJustif']} 
                required={campos['solicitaBecario'] === true} 
                rows={5}
                style={{resize : 'none'}} 
                disabled={ definirHabilitacionSolicitaBecarioJustif() }
              />  
              <Campo
                  disabled={formularioBloqueado ? formularioBloqueado : undefined}
                  etiqueta={`4.2) Voluntariado: número de voluntarios requeridos, perfil
                      y actividades en las que participarán (máx. 2000 caracteres).`} 
                  name='solicitaVoluntarioDet' 
                  type={'textarea'}
                  minLength={1} 
                  maxLength={2000}
                  handlecampochange={handleCampoChange}
                  handleblur={validarCampo} 
                  value={campos['solicitaVoluntarioDet']}
                  valido={validaciones['solicitaVoluntarioDet']} 
                  required={campos['solicitaBecario'] === true}
                  rows={13}
                  style={{resize : 'none'}} 

                />
            </div>
            <div className='col mt-5'>
                <h4 className='mt-3'>{'5) Instituciones intervinientes'}</h4>      
                <hr />
                <ListaInstitucionesIntervinientes /> 
            </div> 
        </Formulario>
    }
    </>
  )
}
