import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startEditarFormIp } from '../../actions/ip';
import { cerrarModal } from '../../actions/ui';
import { useForm } from '../../hooks/useForm';
import { Campo } from '../Formulario/Campo';

export const ModalParticipanteEquipoIp = () => {
    const dispatch = useDispatch();
    const {Persona_id_Personas} = useSelector(state => state.ipReducer.ipFocus);
    const {lUnidadesAcademicas} = useSelector(state => state.basesReducer);    
    
    const [showMsgFormInvalido, setShowMsgFormInvalido] = useState(false);
    

    const { 
        campos, 
        validaciones,
        handleCampoChange, 
        validarCampo,
        validarForm, 
        reset 
    } = useForm({
        dni : '',
        ape : '',
        nom : '',
        unidadAcademica : ''
    });

    const handleSubmitIntegrante = (e)=>{
        e.preventDefault();
       if(
            validarForm(['dni','ape','nom','unidadAcademica']) && 
            (campos['dni'].trim().length > 6)
        ){
            dispatch(
                startEditarFormIp({
                    Persona_id_Personas : Persona_id_Personas 
                        ?[...Persona_id_Personas, {...campos}]
                        :[{...campos}]
                })
            );
            reset();
            dispatch(cerrarModal());
       }else{
            setShowMsgFormInvalido(true);
       }
    }
  
    return (
    <div className='row row-cols-1'>
        <div className='col'>
            <h4 className='text-center'>Participante del proyecto</h4>
            <hr />
            {showMsgFormInvalido && 
                <p className='alert alert-info d-flex justify-content-between p-1'>
                    Error al guardar el integrante, revise los campos cargados. 
                    <button className='btn btn-link ' 
                        onClick={()=>setShowMsgFormInvalido(false)}> x
                    </button>
                </p>
            }
        </div>
        <div className='col border-bottom pb-3'>
            <Campo 
                name='dni'
                type='text'
                etiqueta='DNI:'
                value={campos['dni']}
                valido={validaciones['dni']}
                msgayuda={'Sin puntos, con 0 adelante si tiene menos de 8 dígitos'}
                pattern={'[0-9]{8}'}
                required={true}
                minLength={7}
                maxLength={8}
                handlecampochange={handleCampoChange}
                handleblur={validarCampo}
                enModal={true}
            />
            <Campo 
                name='ape'
                type='text'
                etiqueta='APELLIDO:'
                value={campos['ape']}
                valido={validaciones['ape']}
                required={true}
                minLength={2}
                maxLength={150}
                handlecampochange={handleCampoChange}
                handleblur={validarCampo}
                enModal={true}
            />
            <Campo 
                name='nom'
                type='text'
                etiqueta='NOMBRE:'
                value={campos['nom']}
                valido={validaciones['nom']}
                required={true}
                minLength={2}
                maxLength={150}
                handlecampochange={handleCampoChange}
                 handleblur={validarCampo}
                 enModal={true}
            />
            <Campo 
                name='unidadAcademica'
                type='select'
                etiqueta='UU.AA.:'
                value={campos['unidadAcademica']}
                valido={validaciones['unidadAcademica']}
                opciones={lUnidadesAcademicas || []}
                required={true}
                handlecampochange={handleCampoChange}
                handleblur={validarCampo}
                enModal={true}
            />

        </div>
        <div className='col'>
            <div className='row justify-content-center'>
                <div className='col-3'>
                    <button className='btn btn-primary ' onClick={handleSubmitIntegrante}>Guardar Integrante</button>
                </div>
            </div>
        </div>
    </div>
  )
}
