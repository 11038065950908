import React,{useState,useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { startEliminarComision } from '../../actions/comsion';
import { abrirModalAsigProyProgExt } from '../../actions/ui';
import { FilaTabla } from '../Tabla/FilaTabla';
import { Tabla } from '../Tabla/Tabla';




export const TablaEvaluacionProgramas = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const {listaComision} = useSelector(state => state.comisionReducer);
    const [anios, setAnios] = useState([]);
    const [anioSelec, setAnioSelec] = useState('');
    const [cLocalComisiones, setcLocalComisiones] = useState( [] );

    useEffect(() => {
        if(anios.length < 1 ){
           const mProyectosXAnio = new Map();
           listaComision.filter( c => c.tipo === 'PE')
           .forEach( c => c.listaP.forEach(p => mProyectosXAnio.set(`20${p.codigo.split('-')[0]}`,p) ) );

           setAnios([...mProyectosXAnio.keys()]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listaComision]);

    useEffect(() => {
        if(anioSelec !== '' ){
          setcLocalComisiones(
            listaComision.filter( c => c.tipo === 'PE')
                .filter( c => c.listaP.some( p => `20${p.codigo.split('-')[0]}` === anioSelec))
                .map( c => ({...c , listaP : c.listaP.filter( p => `20${p.codigo.split('-')[0]}` === anioSelec) }) )
          );
        }  
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [anioSelec]);
    
    const handleFiltrar = (e)=>{
        e.preventDefault();
        const anio = e.currentTarget.value;
        setAnioSelec(anio);

    }

  return (
    <Tabla 
        _tipo={'programas'} 
        
        _gestionable = {true} 
        _gestiona={{
            _handleBorrar : (e,_id)=>{
                e.preventDefault();
                dispatch(startEliminarComision(_id,history))
            },
            _handleEditar : (e,_id)=>{
                e.preventDefault();
                dispatch(abrirModalAsigProyProgExt())
            //    dispatch(startSetFocusComision(_id))
            },
            _handleCrear : ()=>{dispatch(abrirModalAsigProyProgExt())}
        }}  
    >
         <colgroup>
                            
            <col span='1' style={{width: '40%'}}></col>
            <col span='1' style={{width: '40%'}}></col>
            
        </colgroup>
        <thead className='table bg-primary text-white position-sticky' >
            <FilaTabla  key={`eppe-cabecera-1`} _id={2} _cabecera={true} _titulo={true}>
                <th className='text-center' colSpan={4}> EVALUACION PROGRAMAS EXTENSION </th>
            </FilaTabla>
            <FilaTabla key={`comision-cabecera-3`} _id={4} _cabecera={true} _titulo={true}>
                <th colSpan={5}>
                    <select 
                        name={'filtro'}
                        className='form-select bg-white'
                        onChange={handleFiltrar} 
                        value={anioSelec} 
                    >
                        {anios.map(a => <option id={a} value={a}>{a}</option>)}
                    </select>
                </th>
            </FilaTabla>
            <FilaTabla key={`eppe-cabecera-3`}  _cabecera={true} _id={1} >
                <th>PROGRAMA EXTENSIÓN</th>
                <th>PROYECTOS ASIGNADOS</th>
            </FilaTabla>
        </thead>
        <tbody>
            {
                cLocalComisiones.filter( comision => comision.tipo === 'PE' )
                .map( (comision,i) => 
                    <FilaTabla key={`${Date.now()}-epppe-${i}`} _cabecera={false} _id={2}> 
                        <td>{comision.listaEval[0].nom}</td>
                        <td>
                        {
                            <ul key={`${Date.now()}-eippe-${i}+`} >  
                                {
                                    comision.listaP && 
                                    comision.listaP.length > 0 && 
                                    comision.listaP.map( (p,i) =>  <li key={`${Date.now()}-peippe-${i}`}>{p.codigo}</li>)
                                }    
                            </ul>
                        }
                        </td>
                    </FilaTabla>  
                 )   
            }
        
        </tbody>
    </Tabla>
  )
}
