import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useFormAdjuntarDocumentos } from '../hooks/useFormAdjuntarDocumentos';

export const ItemPresupuestoProyecto = () => {

  const {validarPresupuestoPresentado,handleVerAval} = useFormAdjuntarDocumentos();
  
  
  
  return (
    <li className='list-group-item bg-transparent' > 
        {'Presupuesto del Proyecto en una planilla de cálculo (formato .xlsx) '}
        <a href='https://docs.google.com/spreadsheets/d/1FMSjY_x684ujQ7q-J3ka1xr4LhycITpH/edit#gid=1983037758'
          onClick={handleVerAval}
        > 
          Descargue el modelo aquí 
        </a>
        { validarPresupuestoPresentado() && <FontAwesomeIcon className='ms-4' icon={faCheck}size={'2x'} color={ 'green' }  /> }
    </li>
  )
}
