import { types } from "../types/types"

const initialState = {
    listaP : null,
    eippeFocus : null
}

export const eippeReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.eippeGetLista:
            return {
                ...state,
                listaP : [...action.payload]
            }
        case types.eippeGetData : 
            return {
                ...state,
                eippeFocus : {...action.payload}
            }
        case types.eippeEditar : 
            return {
                ...state,
                eippeFocus : {
                    ...state.eippeFocus,
                    ...action.payload
                }
            }
        case types.eippeReset : 
            return {
                ...state,
                eippeFocus : initialState.eippeFocus
            };
        default:
            return state;
    }

}
