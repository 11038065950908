import React from 'react'
import {  Switch, Route} from 'react-router-dom';
import { Ayuda } from '../../Ayuda/Ayuda';
import { PanelProyecto } from '../../PanelProyecto/PanelProyecto';
import { TablaProyectos } from '../../TablasProyectos/TablaProyectos';
import { useSelector } from 'react-redux';

import { FormEvaluacionCalidad } from '../../FormularioEvaluacion/FormEvaluacionCalidad';
import { FormEvaluacionIpCA } from '../../FormularioEvaluacion/FormEvaluacionIpCA';
import { FormEvaluacionIpPE } from '../../FormularioEvaluacion/FormEvaluacionIpPE';
import { FormProyecto } from '../../FormularioProyecto/FormProyecto';
import { FormEvaluacionPertinencia } from '../../FormularioEvaluacion/FormEvaluacionPertinencia';
import { FormIdeaProyecto } from '../../FormularioIdeaProyecto/FormIdeaProyecto';
import { PanelComisiones } from '../../PanelComisiones/PanelComisiones';
import { FormularioInformeAvance } from '../../../components/FormularioInformeAvance/FormularioInformeAvance';
import { FormularioInformeFinalAET} from '../../../components/FormularioInformeFinalAET/FormularioInformeFinalAET';
import { FormularioInformeFinalPEIS  } from '../../../components/FormularioInformeFinalPEIS/FormularioInformeFinalPEIS';

export const InicioRouter = () => {

  const {pFocus} = useSelector( state => state.pReducer);
  
  const {tipoUsuario} = useSelector(state => state.usrReducer.usrFocus);

  return (
    <Switch>
        <Route path={`/gestor/ayuda`} ><Ayuda rutaPublica={false}/> </Route>
        <Route path={`/gestor/panel/:idProy`} ><PanelProyecto /> </Route> 
        <Route path={`/gestor/evaluacion`}><PanelComisiones /></Route>
        <Route path={`/gestor/form/ip`}> <FormIdeaProyecto /> </Route>
        <Route path={`/gestor/form/ip/:idForm`}> <FormIdeaProyecto /> </Route>
        <Route path={`/gestor/form/p/:idForm`}> <FormProyecto /> </Route>
        <Route path={`/gestor/form/eipca/:idForm`}> <FormEvaluacionIpCA _tipoUsuario={tipoUsuario} /></Route>
        <Route path={`/gestor/form/eipca_def/:idForm`}> <FormEvaluacionIpCA _tipoUsuario={tipoUsuario} /></Route>
        <Route path={`/gestor/form/eippe/:idForm`}> <FormEvaluacionIpPE _tipoUsuario={tipoUsuario}/></Route>
        <Route path={`/gestor/form/pcal/:idForm`}><FormEvaluacionCalidad _tipoProyecto={ pFocus ? pFocus.tipoProyecto : 'PEIS'} _tipoUsuario={tipoUsuario} /></Route>
        <Route path={`/gestor/form/pcal_def/:idForm`}> <FormEvaluacionCalidad _tipoProyecto={  pFocus ? pFocus.tipoProyecto : 'PEIS'} _tipoUsuario={tipoUsuario} /></Route>
        <Route path={`/gestor/form/ppert/:idForm`}> <FormEvaluacionPertinencia _tipoUsuario={tipoUsuario}/></Route>
        <Route path={`/gestor/form/ppert_def/:idForm`}><FormEvaluacionPertinencia _tipoUsuario={tipoUsuario} /></Route>
        <Route path={`/gestor/form/inf_avance/:idForm`}><FormularioInformeAvance /></Route>
        {
          pFocus && 
          pFocus.codigo && 
          pFocus.codigo.includes( 'PEIS') && 
          <Route path={`/gestor/form/inf_final/:idForm`}><FormularioInformeFinalPEIS /></Route>
        }
        {
          pFocus && 
          pFocus.codigo && 
          pFocus.codigo.includes( 'AET' )&& 
          <Route path={`/gestor/form/inf_final/:idForm`}><FormularioInformeFinalAET /></Route>
        }
        <Route exact path={'/gestor'} ><TablaProyectos /> </Route>
  </Switch>
  )
}
