import React from 'react'


import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'


export const BotonSalir = () =>  
    <Link to={'/'} className='btn btn-primary ' title='volver' > 
        <FontAwesomeIcon icon={faArrowLeftLong} size={'3x'}/>
    </Link>
