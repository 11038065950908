import React from 'react'
import { Cronograma } from './Cronograma'

export const FilaActividad = ({
    oCronogramaAnio1 = [],
    oCronogramaAnio2 =[],
    actividad = ''}) => {
  return (
    <div className='col'>
      <div className='row border-bottom mb-2' >
        <div className='col-4' style={{height : '5rem' , overflowY : 'auto'}} >{actividad}</div>
        <div className='col-4 border-start border-end' style={{fontSize : '0.7em'}} >{<Cronograma _cronograma={oCronogramaAnio1.map( mes => mes.Mes_id)} />}</div>
        <div className='col-4 ' style={{fontSize : '0.7em'}}>{<Cronograma _cronograma={oCronogramaAnio2.map( mes => mes.Mes_id)} />}</div>
        
      </div>
    </div>
  )
}

