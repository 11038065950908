

import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { startCrearProyecto, startGuardarIdeaProyecto } from '../../../actions/ip';
import { mostrarAlerta } from '../../../helpers/mostrarAlerta';
import { useForm } from '../../../hooks/useForm';
import { useGestionarEstados } from '../../../hooks/useGestionarEstados';
import { useGestionarUsuario } from '../../../hooks/useGestionarUsuario';

export const useFormIdeaProyecto = () => {

    const dispatch = useDispatch();
  
    const history = useHistory();
    const {pFocus} = useSelector(state => state.pReducer);
    const {ipFocus,tipoForm} = useSelector( state => state.ipReducer );
    const {anio} = useSelector(state => state.convReducer.convFocus)
    const refVistaPrevia = useRef();
  
    const { 
        lCategEquipo,
        lTipoProyecto,
        lProgramasExtension,
        lLineasTematicas,
        lUnidadesAcademicas
      } = useSelector(state => state.basesReducer);
      
      const { 
        historialEstados,
        chequearProyectoNuevo,
        bloquearFormularioIpPorEstadoEvaluacion,
        instanciaEvaluacionIpTerminada
      } = useGestionarEstados();
  
      const {usuarioEsDueñoDelProyecto,usuarioEsAdmin,usuarioHabilitadoCrearProyectos} = useGestionarUsuario();
  
    const {
      campos , 
      validaciones , 
      handleCampoChange , 
      validarCampo , 
      validarForm
    
    } =  useForm(
      {
        tipoProyecto : ipFocus.tipoProyecto ? ipFocus.tipoProyecto : '',
        titulo : ipFocus.titulo ? ipFocus.titulo : '',
        bianual : ipFocus.bianual ? ipFocus.bianual : 0,
        campoTematico : ipFocus.campoTematico ? ipFocus.campoTematico : '',
        categoriaProyecto : ipFocus.categoriaProyecto ? ipFocus.categoriaProyecto : '',
        problemaAbordar : ipFocus.problemaAbordar ? ipFocus.problemaAbordar : '',
        finalidad : ipFocus.finalidad ? ipFocus.finalidad : '',
        unidadAcademica : ipFocus.unidadAcademica ? ipFocus.unidadAcademica : '',
        politicasPublicas : ipFocus.politicasPublicas ? ipFocus.politicasPublicas : '',
        participantesSociales : ipFocus.participantesSociales ? ipFocus.participantesSociales : '',
        potencialesActividades : ipFocus.potencialesActividades ? ipFocus.potencialesActividades : '',
        ProgramaExtension_id_ProgramaExtensions : ipFocus.ProgramaExtension_id_ProgramaExtensions ? ipFocus.ProgramaExtension_id_ProgramaExtensions : '',
        LineaTematica_id_LineaTematicas : ipFocus.LineaTematica_id_LineaTematicas ? ipFocus.LineaTematica_id_LineaTematicas : ''
      } ,'ip');
    
   
  
      const formularioBloqueado = historialEstados &&
      (
        bloquearFormularioIpPorEstadoEvaluacion() || instanciaEvaluacionIpTerminada() 
      );
  
    const handleSubmitIdeaProyecto = (e)=>{
      e.preventDefault();
  
      if( (!chequearProyectoNuevo()) && (!usuarioEsAdmin()) && (!usuarioEsDueñoDelProyecto()) ){
                
            alert('No posee permisos para realizar esta acción');
            return;
        }
  
      if(bloquearFormularioIpPorEstadoEvaluacion()){
            alert('No posee permisos para realizar esta acción');
            return;
      }
      if(!usuarioHabilitadoCrearProyectos()) {
        alert('No posee permisos para realizar esta acción');
        return;
      }

       if(
        validarForm([
          'tipoProyecto',
          'titulo',
          'bianual',
          'campoTematico',
          'categoriaProyecto',
          'problemaAbordar',
          'finalidad',
          'unidadAcademica',
          'politicasPublicas',
          'participantesSociales',
          'potencialesActividades',
          'ProgramaExtension_id_ProgramaExtensions',
          'LineaTematica_id_LineaTematicas'
          ]) &&
          (ipFocus.Persona_id_Personas)&&
          (ipFocus.Institucion_id_Institucions) &&
          (ipFocus.Persona_id_Personas.length > 0) &&
          (ipFocus.Institucion_id_Institucions.length > 0)
        ){
  
            mostrarAlerta('¿Desea guardar los campos?','Guardar Idea Proyecto',true)
              .then( resp => {
                if(resp.isConfirmed){
                  
                  tipoForm === 'crear' && dispatch(startCrearProyecto(history));
                  tipoForm === 'editar' && dispatch(startGuardarIdeaProyecto());
                  
                }
              })
      
      }else {
        mostrarAlerta(
          ` Revise los campos del formulario , el listado de integrantes y 
            el listado de instituciones `,
          'Campos inválidos',
          false);
      }
      
    }
  
    const filtrarLineasTematicas = ()=>{
      let salida = [];  
      if(pFocus.codigo && pFocus.codigo.slice(0,2) !== `${anio}`.slice(2) ) {
         
        salida = lLineasTematicas.filter( lt => pFocus.codigo.slice(0,2) === `${lt.createdAt.split('-')[0]}`.slice(2)  )
        
      } else {
        salida = lLineasTematicas.filter( lt => `${ anio }` === lt.createdAt.split('-')[0]);
      }

      return salida;
    }
    const handleAbrirPestaña = (e)=>{
      e.preventDefault();
      window.open(e.target.href,'_blank');
    }


  return {
    campos , 
    validaciones , 
    handleCampoChange , 
    validarCampo , 
    formularioBloqueado,
    ipFocus,
    lCategEquipo,
    lTipoProyecto,
    lProgramasExtension,
    lLineasTematicas : filtrarLineasTematicas(),
    lUnidadesAcademicas,
    handleAbrirPestaña,
    handleSubmitIdeaProyecto,
    refVistaPrevia
  }
}
