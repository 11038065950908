import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { 
    startAgregarEstadoProyecto, 
    startEditarEstadoProyecto, 
    startEliminarEstadoProyecto 
} from "../actions/p";
import { mostrarAlerta } from "../helpers/mostrarAlerta";
import { useForm } from "./useForm";

export const useGestionarEstados = (idEstadoFocus = null) => {
  
  const dispatch = useDispatch();
  const {lTipoEstado} = useSelector( state => state.basesReducer);
  const {pFocus} = useSelector(state => state.pReducer);
  const {eipcaFocus} = useSelector(state => state.eipcaReducer);
  const {pcalFocus} = useSelector(state => state.pcalReducer);
  const {eippeFocus} = useSelector(state => state.eippeReducer);
  const {ppertFocus} = useSelector(state => state.ppertReducer);
  const {anio} = useSelector(state => state.convReducer.convFocus)

  const [showFormEstado, setshowFormEstado] = useState(false);

  const ultimoEstado = pFocus && pFocus.historialEstados 
      ? pFocus.historialEstados[pFocus.historialEstados.length -1].tipoEstado 
      : null;


  const {
    campos : camposNuevoEstado,
    validaciones : validacionesNuevoEstado,
    handleCampoChange : handleNuevoEstadoChange,
    validarCampo : validarNuevoEstado,
    validarForm : validarFormNuevoEstado,
    reset : resetFormNuevoEstado
  } = useForm(idEstadoFocus 
    ?{
      tipoEstado :  pFocus.historialEstados.find( estado => estado.id === idEstadoFocus ).tipoEstado,
      nuevoEstado : pFocus.historialEstados.find( estado => estado.id === idEstadoFocus ).desc,
    }
    :{
      tipoEstado : '',
      nuevoEstado : ''
    });

  const esFormularioDefinitivo = ( _idForm )=>
    pFocus.lFormsEval &&
    pFocus.lFormsEval.some( _form => (_form.id === _idForm) && _form.tipo.endsWith('_def'));

  const esFormularioEvaluacion = (_idForm,tipoForm) => 
    pFocus.lFormsEval && 
    pFocus.lFormsEval.some( _form => _form.id === _idForm) &&
    tipoForm !== 'ip' && 
    tipoForm !== 'p';

  const esFormularioProyecto = (_idForm,tipoForm) => pFocus.FormularioProyecto_id === _idForm && tipoForm === 'p';
  const esFormularioIdeaProyecto = (_idForm,tipoForm) => pFocus.IdeaProyecto_id === _idForm && tipoForm === 'ip';
  
  const editarEstadoProyecto = (_id)=> {

    if(validarFormNuevoEstado(['nuevoEstado','tipoEstado'])){

        dispatch( startEditarEstadoProyecto(_id,{
          tipoEstado : lTipoEstado.find( tipo => tipo.id === camposNuevoEstado['tipoEstado'] ).tipoEstado  ,
          desc : camposNuevoEstado['nuevoEstado']
        }));

      setshowFormEstado(false);

      resetFormNuevoEstado();

    }

  }

  const chequearProyectoConvocatoriaAnterior = ()=>pFocus && pFocus.codigo && pFocus.codigo.slice(0,2) !== `${anio}`.slice(2);

  const agregarEstadoProyecto= () => {
    mostrarAlerta('¿Desea agregar el nuevo estado?','Estado Proyecto', true)
    .then( resp => {
      
          if( 
              resp.isConfirmed && (!validarFormNuevoEstado(['nuevoEstado','tipoEstado']))
            ){
              mostrarAlerta('Error al agregar el estado , revise los campos','Agregar Estado',false);
            }

        dispatch( startAgregarEstadoProyecto( {
          desc : camposNuevoEstado['nuevoEstado'],
          tipoEstado:  lTipoEstado.find( tipo => tipo.id === camposNuevoEstado['tipoEstado'] ).tipoEstado
        }));
        resetFormNuevoEstado();
        setshowFormEstado(false);
    }  
      
   )
    
  }

  const eliminarEstadoProyecto = (_id)=>{
    mostrarAlerta('¿Desea eliminar el estado?','Estado Proyecto', true)
        .then( resp => resp.isConfirmed &&  
                dispatch(startEliminarEstadoProyecto(_id))
             )
   
  }

  const chequearProyectoNuevo = ()=> pFocus.FormularioProyecto_id === undefined;

  const chequearProyectoEnEvaluacion = ()=> 
    ['EVAL_IP','DEV_IP','ADM','PERT','CAL'].some( estado => estado === ultimoEstado );
  
  

  const chequearEstadoRecepIp = ()=>pFocus.historialEstados[pFocus.historialEstados.length - 1].tipoEstado === 'REC_IP';
    
  const chequearEstadoRecepProyecto = ()=>pFocus.historialEstados.some( estado => estado.tipoEstado === 'REC_P' );
  
  

  const bloquearFormularioProyectoPorEstadoEvaluacion = ()=>
        ultimoEstado
        ? (['ADM','PERT','CAL'].some( 
            tipoEstado => tipoEstado === ultimoEstado ))
        
        : false;

  const bloquearFormularioIpPorEstadoEvaluacion = ()=>
       ultimoEstado
        ? 
          (pFocus.historialEstados.length > 0) &&
          (['EVAL_IP','DEV_IP'].some(estado => estado === ultimoEstado))
        
        : false;          

  const bloquearEvaluacionPorInstanciaFueraDeEvaluacion = ()=> {
    if( pFocus && pFocus.historialEstados ){
      if(eipcaFocus) return pFocus.historialEstados[pFocus.historialEstados.length -1].tipoEstado !== 'EVAL_IP';
      if(eippeFocus) return pFocus.historialEstados[pFocus.historialEstados.length -1].tipoEstado !== 'EVAL_IP';
      if(ppertFocus) return pFocus.historialEstados[pFocus.historialEstados.length -1].tipoEstado !== 'PERT';
      if(pcalFocus) return pFocus.historialEstados[pFocus.historialEstados.length -1].tipoEstado !== 'CAL';
    }
    return false;
  }
  
  const proyectoEstaAprobado = ()=>ultimoEstado === 'OK';

  const bloquearFormularioPorProyectoCerrado = ()=> 
      ultimoEstado ? ['NO_PERT','OK'].some( estado => estado === ultimoEstado) : false;
  
  const instanciaEvaluacionIpTerminada = ()=>
      ultimoEstado ? (['REC_IP','EVAL_IP'].every( tipoEstado => tipoEstado !== ultimoEstado)) : false
  
  const instanciaEvaluacionPertTerminada = ()=> ultimoEstado ? ['CAL','OK','NO_PERT'].some( estado => estado === ultimoEstado ) : false;

  const instanciaEvaluacionCalTerminada = ()=>
    ultimoEstado ? ultimoEstado === 'OK' : false;
  
  const instanciaEvaluacionPertIniciada = ()=> pFocus.historialEstados.some( estado => estado.tipoEstado === 'PERT');

  const instanciaEvaluacionCalIniciada = ()=> pFocus.historialEstados.some( estado => estado.tipoEstado === 'CAL');
  
  const instanciaEvaluacionIpIniciada = ()=> pFocus.historialEstados.some( estado => estado.tipoEstado === 'EVAL_IP');
  
  const proyectoEnEstadoRecepProyecto = ()=>ultimoEstado === 'REC_P';
  
  return {
    agregarEstadoProyecto,
    bloquearFormularioProyectoPorEstadoEvaluacion,
    bloquearFormularioIpPorEstadoEvaluacion,
    bloquearFormularioPorProyectoCerrado,
    bloquearEvaluacionPorInstanciaFueraDeEvaluacion,
    camposNuevoEstado,
    chequearProyectoEnEvaluacion,
    chequearEstadoRecepProyecto,
    chequearProyectoConvocatoriaAnterior,
    chequearProyectoNuevo,
    editarEstadoProyecto,
    eliminarEstadoProyecto,
    esFormularioDefinitivo,
    esFormularioEvaluacion,
    esFormularioProyecto,
    esFormularioIdeaProyecto,
    handleNuevoEstadoChange,
    historialEstados : pFocus ? pFocus.historialEstados : [],
    instanciaEvaluacionIpTerminada,
    instanciaEvaluacionPertTerminada,
    instanciaEvaluacionCalTerminada,
    instanciaEvaluacionPertIniciada,
    instanciaEvaluacionCalIniciada,
    instanciaEvaluacionIpIniciada,
    proyectoEnEstadoRecepProyecto,
    chequearEstadoRecepIp,
    showFormEstado,
    setshowFormEstado,
    validacionesNuevoEstado,
    validarNuevoEstado,
    proyectoEstaAprobado
};
}
