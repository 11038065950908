import React from 'react'
import { FormLogin } from '../components/Usuario/FormLogin'
import { Link } from 'react-router-dom'

export const LoginUsuario = () => {
  return (
    <section className='container d-flex justify-content-center animate__animated animate__fadeIn'>
      <div className='row row-cols-1 d-flex justify-content-center w-50'>
       
       <FormLogin />      

       <div className='col ms-2 text-center'>
        <p className=' mt-3'>¿No tiene usuario? <Link to = '/register'>Crear uno nuevo</Link></p>

        <p className=' mt-3'>Si olvidó su contraseña, comuníquese con Mesa de Ayuda  
          <a className='ms-3' href='mailto:gestor.extunl@gmail.com'>gestor.extunl@gmail.com</a>
        </p>
        <p className=' mt-3'>
          Para mayor información, ingrese a <Link to='/ayuda'>Sección de ayuda</Link>
        </p>
       </div>
      
      </div>
      
    </section>
  )
}
