import React from 'react'
import { useForm } from '../../../../hooks/useForm'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FormularioActividadObjetivo } from './_FormularioActividadObjetivo'

const MsgError = ()=><div className='alert alert-danger'> Debe ingresar una descripción y resultado del objetivo. </div>

export const FormularioObjetivoEspecifico = ({
    
    index=0,
    esNuevo=false,
    _desc = '',
    _resEsp = '',
    _Actividades = [],
    handleEliminarObjetivoEspecifico=()=>{},
    handleGuardarObjetivoEspecifico = ()=>{},
    handleAgregarActividadObjetivo=()=>{},
    handleEliminarActividadOBjEsp=()=>{},
    handleGuardarActividadObjetivo=()=>{}
}) => {
    
    const [editando, setEditando] = useState(_desc === '');
    const [showMsgFormInvalido, setShowMsgFormInvalido] = useState(false);

    
    
    const { 
        campos, 
        handleCampoChange, 
        validarCampo,
    } = useForm({
        desc : _desc,
        resEsp : _resEsp
      });
    

    const {desc,resEsp} = campos;



    const handleGuardar = (e)=>{
        e.preventDefault();
        if(desc.length < 1 || resEsp.length < 1) { setShowMsgFormInvalido(true);  }
        else {
            setShowMsgFormInvalido(false);
            handleGuardarObjetivoEspecifico(index,desc,resEsp);
            
            setEditando(false);
        }
       
    }

    const handleEditar = (e)=>{
        e.preventDefault();
        setEditando(true);
    }

  return (
    <div className='row row-cols-3 m-2 border-bottom border-secondary' key={`form-obj-esp-${index}`} id={`form-obj-esp-${index}`} >
         <div className='col-2'>
                <textarea className='form-control'
                    name='desc' 
                    type = {'textarea'}
                    minLength ={1} 
                    maxLength ={500}
                    onChange={handleCampoChange}
                    onBlur={validarCampo} 
                    rows={8}
                    value={desc}
                    required={true} 
                    disabled={!editando}
                    style={{resize:'none',fontSize : '0.7em'}}
                />
               
            </div>
            <div className='col-2'>
                <textarea className='form-control'
                    name='resEsp' 
                    type = {'textarea'}
                    minLength ={1} 
                    maxLength ={500}
                    onChange={handleCampoChange}
                    onBlur={validarCampo} 
                    rows={8}
                    value={resEsp}
                    required={true} 
                    disabled={!editando}
                    style={{resize:'none',fontSize : '0.7em'}}
                />
            </div>
           
            <div className='col-7'>
                {
                    showMsgFormInvalido ? <MsgError /> : <></>
                }
                {
                    _Actividades.map(
                        (act,j) => <FormularioActividadObjetivo 
                            key={`${Date.now()}-${j}`}
                            index={j}
                            idObj={index}  
                            _desc={act.desc}
                            _CronogramaA1s={act.CronogramaA1s}
                            _CronogramaA2s={act.CronogramaA2s}
                            handleEliminarActividadOBjEsp={handleEliminarActividadOBjEsp}
                            handleGuardarActividadObjetivo={handleGuardarActividadObjetivo}
                        />
                    )
                }

            </div>
           
            <div className='col-1 d-flex align-items-center' >
                {
                  
                    <div className='row'>
                         {
                            (!editando) &&
                            <div className='col-4'>
                                <button className='btn btn-outline-success btn-sm' style={{fontSize : '0.8em'}} title='Agregar actividad' onClick={e=>handleAgregarActividadObjetivo(e,index)} ><FontAwesomeIcon icon={faPlus} /></button>
                            
                            </div>
                        }
                        {
                            (!editando) &&
                            <div className='col-4' >
                                <button className='btn btn-outline-secondary btn-sm' style={{fontSize : '0.8em'}} onClick={handleEditar} title='Editar objetivo'> <FontAwesomeIcon icon={faEdit}  /></button>
                            </div>
                        }
                        {
                            (!editando) &&
                            <div className='col-4'>
                                <button className='btn btn-outline-danger btn-sm' style={{fontSize : '0.8em'}} title='Eliminar objetivo' onClick={e=>handleEliminarObjetivoEspecifico(e,index)} ><FontAwesomeIcon icon={faTrash} /></button>
                            
                            </div>
                        }
                       
                        {
                             editando &&
                             <div className='col-5'>
                                    <small className='btn btn-outline-secondary btn-sm' title='Guardar'  onClick={handleGuardar} ><FontAwesomeIcon icon={faSave}  /></small>
                             </div>
                        }
                      
                      
                    </div>
                }
                
                
            </div>
    </div>
   
  )
}
