import { types } from "../types/types";



const initialState = {
    lProgramasExtension: [],
    lMaterialesEducativos: [],
    lIntegracion: [],
    lUnidadesAcademicas: [],
    lTipoProyecto: [],
    lMeses: [],
    lCategEquipo: [],
    lCategDedicacion: [],
    lCategDocente: [],
    lTipoInteg: [],
    lRolInteg: [],
    lPeriodoLectivo: [],
    lInstanciasCapacitacion: [],
    lAreas: [ ],
    lTipoEstado: [],
    lInstitucionesCargadas: [ ],
    lProyUlt3Anios: [],
    instIntervSelec: null,
    integEquipoSelec: null,
    participDirectoSelect: null,
    imgSrc: null
}

export const basesReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.baseGetData:
            return {
                ...state,
                ...action.payload
            }
        
        default:
            return state;
    }

}

