import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setTipoFormIp } from '../../../actions/ip'
import { startBorrarProyecto } from '../../../actions/p'
import { useGestionarUsuario } from '../../../hooks/useGestionarUsuario'
import { useState } from 'react'
import { useEffect } from 'react'
import { useGestionarConvocatoria } from '../../../hooks/useGestionarConvocatoria'
import { mostrarAlerta } from '../../../helpers/mostrarAlerta'


export const useTablasProyectos = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  
  const {listaP} = useSelector(state => state.pReducer);
  const {tipoUsuario} = useSelector( state => state.usrReducer.usrFocus);

  const [listaProyectosUsuario, setListaProyectosUsuario] = useState( []);

  const {
    usuarioEsEvaluadorExterno,
    usuarioEsEvaluadorProgramas,
    usuarioHabilitadoCrearProyectos
  } = useGestionarUsuario();

 
  const {
    convocatoriaEnEstadoRecepcionIP
  } = useGestionarConvocatoria();

  useEffect(() => {
    if(listaP){
      setListaProyectosUsuario(listaP.listaProyectosUsuario);
    }
  
    return () => {
      setListaProyectosUsuario(listaProyectosUsuario);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listaP])

  const handleOrdenarPorId = (e)=>{
    e.preventDefault();

    setListaProyectosUsuario([...listaProyectosUsuario.sort( (p1,p2) => p1.id - p2.id  )])

  }

  const handleOrdenarPorCodigo = (e)=>{
    e.preventDefault();

    setListaProyectosUsuario([...listaProyectosUsuario.sort( (p1,p2) => {
      const splitCodP1 = p1.codigo.split('-'); // ej: 23-325-PEIS-CUG-GS -> [23,325,PEIS,CUG,GS]
      const splitCodP2 = p2.codigo.split('-');
      if( splitCodP1[0] <= splitCodP2[0] ) return -1;
      else if( splitCodP1[1] < splitCodP2[1]) return -1;
      else if( splitCodP1[2] < splitCodP2[2]) return -1;
      else if( splitCodP1[3] < splitCodP2[3]) return -1;
      else if( splitCodP1[4] < splitCodP2[4]) return -1;
      else return 1;

    } )])
  }

  const handleOrdenarPorTitulo = (e)=>{
    e.preventDefault();

    setListaProyectosUsuario([...listaProyectosUsuario.sort( (p1,p2) => p1.titulo.localeCompare(p2.titulo)  )]);
  }

  const handleOrdenarPorResponsable = (e)=>{
    e.preventDefault();
    setListaProyectosUsuario([...listaProyectosUsuario.sort( (p1,p2) => p1.responsable.localeCompare(p2.responsable)  )]);
  }

  const handleOrdenarPorEstado = (e)=>{
    e.preventDefault();
    setListaProyectosUsuario([...listaProyectosUsuario.sort( (p1,p2) => p1.estado.localeCompare(p2.estado)  )]);
  }

  const handleFiltrar= (e)=>{
    e.preventDefault();
    const anio = e.currentTarget.value;
    if(anio !== ''){
      setListaProyectosUsuario(listaP.listaProyectosUsuario.filter( p => p.codigo.split('-')[0] === anio));
    } else {
      setListaProyectosUsuario(listaP.listaProyectosUsuario);
    }
  }

  const handleCrear = ()=>{
    if( (usuarioEsEvaluadorExterno()) || (usuarioEsEvaluadorProgramas()) ){
       alert('No tiene permiso de crear proyectos');
       return;
    } 
    else if( !usuarioHabilitadoCrearProyectos() && convocatoriaEnEstadoRecepcionIP()){
       alert('No tiene permiso de crear proyectos , usted se encuentra en un proyecto bianual vigente.');
       return;
    }
    else if( ! convocatoriaEnEstadoRecepcionIP() ){
      alert('Instancia de recepción de ideas proyecto finalizada.')
    }
    else {
      dispatch(setTipoFormIp('crear'));
    
      history.push('/gestor/form/ip');
    }
       
  }

  const handleEditar = (e,_id)=>{
    e.preventDefault();
    dispatch(setTipoFormIp('editar'));
  }

  const handleBorrar = (e,_id)=>{
    e.preventDefault();
    if(sePuedeBorrar(_id)){
      dispatch( startBorrarProyecto(_id));
    } else {
      mostrarAlerta('Proyecto en evaluación o de convocatoria anterior','No se puede eliminar',false);
    }
  }
  const printFechaDiaMesAnio = (fecha ) => `${fecha.getDate()}/${fecha.getMonth()+1}/${fecha.getFullYear()}`;

  const sePuedeBorrar = (_id)=>{
    const p = listaProyectosUsuario.find( p => p.id === _id);
    if(p) {
      return p.estado === 'REC_IP';
    } else {
      return false;
    }
  }


  return {
    convocatoriaEnEstadoRecepcionIP,
    handleBorrar,
    handleCrear,
    handleEditar,
    handleFiltrar,
    handleOrdenarPorId,
    handleOrdenarPorCodigo,
    handleOrdenarPorTitulo,
    handleOrdenarPorResponsable,
    handleOrdenarPorEstado,
    printFechaDiaMesAnio,
    usuarioEsEvaluadorExterno,
    usuarioEsEvaluadorProgramas,
    listaP,
    tipoUsuario,
    listaProyectosUsuario

  }
}
