

import { useState } from 'react';
import { useEffect} from 'react';
import { useSelector } from 'react-redux';

export const useGestionarConvocatoria = () => {
    
    const {anio,estados} = useSelector( state => state.convReducer.convFocus );

    const [anioConvocatoria, setAnioConvocatoria] = useState(null)

    useEffect(() => { setAnioConvocatoria(anio); }, [anio]);
    

    const convocatoriaEnEstadoRecepcionIP = ()=> {
        if( estados && estados.length > 0) {
          return estados[estados.length -1 ].tipoEstado === 'REC_IP';
        }
        return false;
    }

    const convocatoriaEnEstadoEvaluacionIP = ()=>{
        if( estados && estados.length > 0) {
            return estados[estados.length -1 ].tipoEstado === 'EVAL_IP';
          }
          return false;
    }

    const convocatoriaEnEstadoRecepcionProy = ()=>{
        if( estados && estados.length > 0) {
            return estados[estados.length -1 ].tipoEstado === 'REC_P';
          }
          return false;
    }

    const convocatoriaEnEstadoAdmisibilidad = ()=>{
        if( estados && estados.length > 0) {
            return estados[estados.length -1 ].tipoEstado === 'ADM';
          }
          return false;
    }

    const convocatoriaEnEstadoPertinencia = ()=>{
        if( estados && estados.length > 0) {
            return estados[estados.length -1 ].tipoEstado === 'PERT';
          }
          return false;
    }

    const convocatoriaEnEstadoCalidad = ()=>{
        if( estados && estados.length > 0) {
            return estados[estados.length -1 ].tipoEstado === 'CAL';
          }
          return false;
    }

    const convocatoriaFinalizada= ()=>{
        if( estados && estados.length > 0) {
            return estados[estados.length -1 ].tipoEstado === 'OK';
          }
          return false;
    }

    const convocatoriaEnEstadoEvaluacion = ()=>{
        return (
            convocatoriaEnEstadoEvaluacionIP() ||
            convocatoriaEnEstadoAdmisibilidad()||
            convocatoriaEnEstadoPertinencia()  ||
            convocatoriaEnEstadoCalidad()
        )
    }

    return {
        convocatoriaEnEstadoRecepcionIP,
        convocatoriaEnEstadoEvaluacionIP,
        convocatoriaEnEstadoRecepcionProy,
        convocatoriaEnEstadoAdmisibilidad,
        convocatoriaEnEstadoPertinencia,
        convocatoriaEnEstadoCalidad,
        convocatoriaFinalizada,
        convocatoriaEnEstadoEvaluacion,
        anioConvocatoria : anioConvocatoria 
    }

}
