


export const types = {

    baseGetData : 'OBTENIENDO DATA BASE',
    baseAddProp : 'AGREGAR PROP FORMULARIO',
    baseReset : 'RESET BASES',

    comisionGetLista : 'MOSTRAR LISTA COMISIONES',
    comisionGetListaProgExt : 'MOSTRAR PROY EXT',
    comisionGetData : 'MOSTRAR DATOS COMISION',
    comisionCrear : 'CREAR COMISION',
    comisionEditar : 'EDITAR COMISION',
    comisionReset : 'RESETEAR COMISION',

    convGetLista : 'VER LISTA CONVOCATORIA',
    convGetData : 'VER DATA CONVOCATORIA',
    convEditar : 'EDITAR CONVOCATORIA',
    convReset : 'RESET CONVOCATORIA',
 
    pGetLista : 'MOSTRAR LISTA PROYECTOS',
    pGetData : 'MOSTRAR DATOS P',
    pCrear : 'CREAR P',
    pEditar : 'EDITAR P',
    pReset : 'RESETEAR P',


    usrGetLista : 'MOSTRAR LISTA USUARIOS',
    usrGetData : 'MOSTRAR DATOS USR',
    usrCrear : 'CREAR USR',
    usrEditar : 'EDITAR USR',
    usrReset : 'RESETEAR USR',

    eipcaGetData : 'MOSTRAR DATOS EIPCA',
    eipcaCrear : 'CREAR EIPCA',
    eipcaEditar : 'EDITAR EIPCA',
    eipcaReset : 'RESETEAR EIPCA',

    ipGetData : 'MOSTRAR DATOS IP',
    ipCrear : 'CREAR IP',
    ipEditar : 'EDITAR IP',
    ipReset : 'RESETEAR IP',
    ipSetTipo : 'SET TIPO FORM',

    infAvGetData : 'MOSTRAR DATOS INF_AV',
    infAvCrear : 'CREAR INF_AV',
    infAvEditar : 'EDITAR INF_AV',
    infAvReset : 'RESETEAR INF_AV',
    infAvGetLista : 'VER LISTA INF_AV',

    infFinalGetData : 'MOSTRAR DATOS INF_FINAL',
    infFinalCrear : 'CREAR INF_FINAL',
    infFinalEditar : 'EDITAR INF_FINAL',
    infFinalReset : 'RESETEAR INF_FINAL',
    infFinalGetLista : 'VER LISTA INF_FINAL',

    eippeGetData : 'MOSTRAR DATOS EIPPE',
    eippeCrear : 'CREAR EIPPE',
    eippeEditar : 'EDITAR EIPPE',
    eippeReset : 'RESETEAR EIPPE',

    epcalGetData : 'MOSTRAR DATOS EPCAL',
    epcalCrear : 'CREAR EPCAL',
    epcalEditar : 'EDITAR EPCAL',
    epcalReset : 'RESETEAR EPCAL',

    eppertGetData : 'MOSTRAR DATOS EPPERT',
    eppertCrear : 'CREAR EPPERT',
    eppertEditar : 'EDITAR EPPERT',
    eppertReset : 'RESETEAR EPPERT',

    uiBackPennding : 'ESPERANDO BACKEND',
    uiBackFinish : 'BACKEND RECIBIDO',
    uiLoadingForm : 'CARGANDO FORM',
    uiSavingForm : 'GUARDANDO FORM',
    uiToggleVistaPrevia : 'TOOGLE VISTA PREVIA',
    uiFinishCargaFormProy : 'FORM PROYECTO CARGADO',
    uiFinishLoadingForm : 'FORM CARGADO',
    uiFinishSavingForm : 'FORM GUARDADO',
    uiFinishGetDataBase : 'DATA BASE OBTENIDA',
    uiFinishGetProyUlt3Anios : 'PROY ULT 3 ANIOS OBTENIDA',
    uiFinishGetInstCargadas : 'INST CARGADAS OBTENIDAS',
    uiFinisGetEvaluadores : 'EVALUADORES OBTENIDOS',
    uiFinishCargaFormInfAV : 'INF AV CARGADO',
    uiFinishGetListaInfAv : 'FINISH GET LISTA INF_AV',
    uiFinishCargaProy : 'FINALIZAR CARGA PROYECTO',
    uiFinishCargaIdeaProy : 'FINALIZAR CARGA IDEA PROYECTO',
    uiFinishCargaConvocatoria : 'FINALIZAR CARGA CONVOCATORIA',
    uiFinishCargaFormsEval : 'FINALIZAR CARGA FORMS EVALUACION',
    uiHideModal : 'OCULTANDO MODAL',
    uiResetInfAvance : 'RESET CARGA INF_AV',
    uiSetParamElegido : 'SET PARAMETRO ELEGIDO',
    uiSetIdObjEspModalActCargadas : 'SET ID OBJ ESP FOCUS',
    uiShowModalPalabrasClave : 'MOSTRANDO MODAL PALABRAS CLAVE',
    uiShowModalIntegrante : 'MOSTRANDO MODAL INTEGRANTE',
    uiShowModalInstitucionInterviniente : 'MOSTRANDO MODAL INSTITUCION INTERVINIENTE',
    uiShowModalCoherenciaInterna : 'MOSTRANDO MODAL COHERENCIA INTERNA',
    uiShowModalParticipanteDirecto : 'MOSTRANDO MODAL PARTICIPANTE DIRECTO',
    uiShowModalVistaPrevia : 'MOSTRANDO MODAL VISTA PREVIA DOCUMENTO',
    uiShowModalComisionEvaluadora : 'MOSTRANDO MODAL COMISION EVALUADORA',
    uiShowModalAsigProyProgExt : 'MOSTRANDO MODAL ASIGNAR PROY. PROG. EXT.',
    uiShowModalIntegranteEquipoIp : 'MOSTRANDO MODAL INTEGRANTE EQUIPO IP',
    uiShowModalInstitucionIp : 'MOSTRANDO MODAL INSITUCION IP',
    uiShowModalActividadNuevaInforme : 'MOSTRANDO MODAL ACTIVIDAD NUEVA INFORME',
    uiShowModalInformeInsitucionNueva : 'MOSTRANDO MODAL INSITUCION NUEVA INFORME',
    uiShowModalMaterialEducativoInforme: 'MOSTRANDO MODAL MATERIAL EDUCATIVO INFORME',
    uiStartCargaFormsEval : 'INICIAR CARGA FORMS EVALUACION',
    uiStartCargaBases : 'INICAR CARGA DATA BASE',
    uiStartCargaProyUlt3Anios : 'INICAR CARGA PROY ULT 3 AÑOS',
    uiStartCargaInstCargadas : 'INICAR CARGA INST CARGADAS',
    uiStartCargaProy : 'INICIAR CARGA PROYECTO',
    uiStartCargaIdeaProy : 'INICIAR CARGA IDEA PROYECTO',
    uiStartCargaConvocatoria : 'INICIAR CARGA CONVOCATORIA',
    uiSetPermisos : 'ACTUALIZANDO PERMISOS DE EDICION',
    uiFinishCargaFormInfFinal : 'INF FINAL CARGADO',
    uiFinishGetListaInfFinal : 'FINISH GET LISTA INF_FINAL',
    uiResetInfFina : 'RESET CARGA INF_FINAL',
    uiShowModalBecarioInforme : 'MOSTRANDO MODAL BECARIO INFORME',
    uiShowModalInstitucionInforme : 'MOSTRANDO MODAL INSTITUCION INFORME',
    uiShowModalPartDirectInforme : 'MOSTRANDO MODAL PART DIRECT INFORME',

    uiReset : 'RESET',
    uiPanelReset : 'RESET PANEL',
    uiSec1 : 'SEC1 COMPLETA',
    uiSec2 : 'SEC2 COMPLETA',
    uiSec3 : 'SEC3 COMPLETA',
    uiSec4 : 'SEC4 COMPLETA',
    uiSec5 : 'SEC5 COMPLETA',
 } 