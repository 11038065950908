import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEditarFormIp } from '../../actions/ip';
import { abrirModalInstitucionIp } from '../../actions/ui';
import { mostrarAlerta } from '../../helpers/mostrarAlerta';
import { useGestionarEstados } from '../../hooks/useGestionarEstados';
import { useGestionarUsuario } from '../../hooks/useGestionarUsuario';

export const ListaInstitucionesIp = () => {
    const dispatch = useDispatch();
    const {Institucion_id_Institucions} = useSelector(state => state.ipReducer.ipFocus);
    const {tipoUsuario} = useSelector( state => state.usrReducer.usrFocus);
    const {listaP,pFocus} = useSelector( state => state.pReducer);

    const {usuarioEsAdmin,usuarioEsDueñoDelProyecto} = useGestionarUsuario();

    const {
        bloquearFormularioIpPorEstadoEvaluacion,
        instanciaEvaluacionIpTerminada,
        chequearProyectoNuevo
    } = useGestionarEstados();
   
    const handleEliminarInstitucion= (e,_id)=>{
        e.preventDefault();

        if( (!chequearProyectoNuevo()) && 
            listaP.listaProyectosUsuario &&
            (!listaP.listaProyectosUsuario.find( proy => proy.id === pFocus.id )) &&
            (tipoUsuario !== 'admin') 
        ){
                
            alert('No posee permisos para realizar esta acción');
            return;
        }
        
        if(bloquearFormularioIpPorEstadoEvaluacion() || instanciaEvaluacionIpTerminada()){
            alert('No posee permisos para realizar esta acción');
            return;
        }

        mostrarAlerta(`¿Desea eliminar la institución?`,'Eliminar institución',true)
            .then( resp => 
                resp.isConfirmed &&
                dispatch(
                    startEditarFormIp({
                        Institucion_id_Institucions : Institucion_id_Institucions.filter( (inst)=> inst.id !== _id)
                    })
                )
             );

       
        return;
    }
    const handleMostrarModalInstitucionIp = (e)=>{
        e.preventDefault();

        if( (!chequearProyectoNuevo()) && 
            (listaP.listaProyectosUsuario) &&
            (!listaP.listaProyectosUsuario.find( proy => proy.id === pFocus.id )) &&
            (tipoUsuario !== 'admin')
        ){
                
            alert('No posee permisos para realizar esta acción');
            return;
        }
        
        if(bloquearFormularioIpPorEstadoEvaluacion() || instanciaEvaluacionIpTerminada()){
            alert('No posee permisos para realizar esta acción');
            return;
        }
        
        dispatch(abrirModalInstitucionIp());

        return;
        
    }
  
    return (
    <div className='col mb-5'>
         <h4 className='bg-light mb-3 p-3 '>
            Instituciones involucradas 
            {
                chequearProyectoNuevo()
                ?   <span className='btn btn-primary float-end' 
                        onClick={handleMostrarModalInstitucionIp}
                    >Agregar institucion
                    </span>
                :
                (tipoUsuario !== 'admin') && 
                (listaP.listaProyectosUsuario) &&
                (listaP.listaProyectosUsuario.find( proy => proy.id === pFocus.id )) && 
                <span className='btn btn-primary float-end' 
                    onClick={handleMostrarModalInstitucionIp}
                >Agregar institucion
                </span>
            }
           
         </h4>
         <hr />
        
        <ul className='list-group'>
            {
                Institucion_id_Institucions && 
                Institucion_id_Institucions.map( ({id,nom}) => 
                    <li className='list-group-item'>    
                        Nombre : {nom} 
                        {
                            chequearProyectoNuevo() 
                            ? <span className='btn btn-outline-danger float-end' onClick={(e)=>handleEliminarInstitucion(e,id)}>x</span>
                            :
                             (usuarioEsAdmin() || usuarioEsDueñoDelProyecto() ) &&
                            <span className='btn btn-outline-danger float-end' onClick={(e)=>handleEliminarInstitucion(e,id)}>x</span>
                        }
                       
                    </li>
                )
            }
        </ul>
        <p className='text-muted'>Ingrese la/s institución/es externa/s a la universidad involucrada/s en el proyecto</p>
    </div>
  )
}
