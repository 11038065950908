import React from 'react'
import { useGestionarEstados } from '../../hooks/useGestionarEstados';
import { useGestionarUsuario } from '../../hooks/useGestionarUsuario';


import { Campo } from '../Formulario/Campo';
import { useSelector } from 'react-redux';

export const EstructuraFormEvaluacion = ({
    estructura,
    campos,
    handleCampoChange,
    validarCampo,
    validaciones,
    tipoForm
}) => {


    const {evaluadorEsDueñoDeLaEvaluacion,usuarioEsAdmin} = useGestionarUsuario();
    const {bloquearEvaluacionPorInstanciaFueraDeEvaluacion} = useGestionarEstados();

    const {codigo} = useSelector( state => state.pReducer.pFocus);
  return (
    <>
    {
        Object.values(estructura).map( (seccion,i) => 

        <fieldset key={`fieldseteval-${i}`} className='col p-2'>
            <legend className='ms-2 fw-bold'>{seccion.titulo}</legend>
            {
            
                // console.log(seccion.campos.map( campo => Object.keys(campo)[0]))
                    seccion.campos.map( (campo,i) => {
                    const nomCampo = Object.keys(campo)[0];
                    return campo[nomCampo].primaryKey 
                    ? <></>
                    : <div key = {`evalcampo-${i}`} className='mt-2'>
                        <Campo 
                            key = {`campo-${i}`}
                            name= {campo[nomCampo].fieldName}
                            etiqueta={
                                tipoForm === 'ppert'
                                ?<small>{campo[nomCampo].etiqueta}</small>
                                :campo[nomCampo].etiqueta
                            }
                            msgayuda={
                                (tipoForm === 'ppert' && codigo.includes('PEIS') && nomCampo === 'iteme2')
                                ? <>
                                    <a className='me-2'
                                    href='https://ojs.intercambios.cse.udelar.edu.uy/index.php/ic/article/view/11'
                                    onClick={(e)=>{
                                        e.preventDefault(); 
                                        window.open(e.target.href,'_blank');
                                    }}
                                    >
                                        La integralidad como movimiento instituyente en la universidad.
                                    </a>
                                    {`InterCambios. Dilemas y Transiciones De La Educación Superior, 1(1), (44-51).`}
                                </>
                              : undefined
                            }
                            type={campo[nomCampo].tipo}
                            metodoSort = { tipoForm === 'pcal' ? (a,b)=> a.id - b.id : undefined }
                            opciones = { campo[nomCampo].tipo==='select' ?  campo[nomCampo].listaOpciones : undefined }
                            handlecampochange={handleCampoChange}
                            handleblur={validarCampo} 
                            maxLength = { campo[nomCampo].type.values ? undefined : campo[nomCampo].type.options.length}
                            nomopc1 = {campo[nomCampo].tipo === 'booleano' ? campo[nomCampo].nomopc1 : undefined}
                            nomopc2 = {campo[nomCampo].tipo === 'booleano' ? campo[nomCampo].nomopc2 : undefined }
                            valueopc1 = {campo[nomCampo].tipo === 'booleano' ? 1 : undefined }  
                            valueopc2 = {campo[nomCampo].tipo === 'booleano' ? 0 : undefined }
                            value = { campos[campo[nomCampo].fieldName] !== null 
                                    ? campos[campo[nomCampo].fieldName]
                                    : '' }
                            valido = {validaciones[campo[nomCampo].fieldName] }
                            required = {false}
                            rows = { campo[nomCampo].type.values ? undefined : 5}
                            style = { campo[nomCampo].type.values ? undefined : {resize : 'none'}   }
                            disabled = { 
                                ( (!usuarioEsAdmin()) && (!evaluadorEsDueñoDeLaEvaluacion()) ) || bloquearEvaluacionPorInstanciaFueraDeEvaluacion()
                                
                            }
                        />
                    </div>
                    }
                )
                    
            }
            </fieldset>
            )

        }
    </>
  )
}
