import { types } from "../types/types";
import { startFinishLoadingForm, startLoadingForm } from "./ui";

export const startMostrarEvaluacionPertinencia = (idForm = null)=>{
    return async(dispatch)=>{
        dispatch(startLoadingForm())
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/ppert/data/${idForm}`)
     
        const data = await resp.json()
         
         if(data.ok){
             dispatch(setEvaluacionPertinencia(data.data));
             dispatch(startFinishLoadingForm())
         }else{
             alert(data.error);
         }
     }
}

export const startEditarEvaluacionPertinencia = (campos = {})=>{
    return async(dispatch)=>{
        dispatch(editarEvaluacionPertinencia(campos));
    }
}

export const startGuardarEvaluacionPertinencia = (_idForm = null)=>{
    return async(dispatch,getState)=>{
        const items = getState().ppertReducer.ppertFocus;
        const {id : idUsr} = getState().usrReducer.usrFocus;
        const {id : idProy} = getState().pReducer.pFocus;
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/ppert/`,{
            method : 'PUT',
            headers: {
                'Content-Type' : 'application/json'
            },
            body :JSON.stringify({
                idUsr : idUsr,
                idProy : idProy,
                id : _idForm,
                dataEvaluacion : items
            })
        });

        const data = await resp.json();

        setTimeout(() => {
            if(data.ok) {
                alert(`datos guardados con exito`);
            } else {
                alert(data.error);
            } 
        });
    }
}

const setEvaluacionPertinencia = (data ={})=>({
    type : types.eppertGetData,
    payload : data
})

const editarEvaluacionPertinencia = (data ={})=>({
    type : types.eppertEditar,
    payload : data
})

export const resetEppert = ()=>({
    type : types.eppertReset
})