import React from 'react'
import { useSelector } from 'react-redux';
import { toPascalCase } from '../../helpers/toPascalCase';
import { useContextForm } from './hooks/useContextForm';
import { useGestionarUsuario } from '../../hooks/useGestionarUsuario';
import { useGestionarEstados } from '../../hooks/useGestionarEstados';
import { Spiner } from '../Ui/Spiner';


export const PieDeFormulario = () => {
    const {pFocus} = useSelector(state => state.pReducer);
    const {guardandoForm} = useSelector( state => state.uiReducer);

    const {
        activarVistaPrevia,
        handleSubmit,
        tipoEvaluacion,
        tipoFormulario,
        idForm
    } = useContextForm();
    
    const { usuarioEsAdmin, evaluadorEsDueñoDeLaEvaluacion,usuarioEsDueñoDelProyecto} = useGestionarUsuario();

    const { 
        esFormularioEvaluacion, 
        esFormularioIdeaProyecto,
        esFormularioProyecto,
        bloquearEvaluacionPorInstanciaFueraDeEvaluacion ,
        bloquearFormularioProyectoPorEstadoEvaluacion,
        bloquearFormularioIpPorEstadoEvaluacion,
        instanciaEvaluacionIpTerminada,
        proyectoEnEstadoRecepProyecto
    } = useGestionarEstados();

    const formEval = pFocus.lFormsEval ? pFocus.lFormsEval.find( _form => _form.id === idForm) : undefined; 
    

    const ocultarBotonGuardar = ()=>  {
        if(esFormularioEvaluacion(idForm,tipoFormulario)) {
            return ( (!usuarioEsAdmin()) && (!evaluadorEsDueñoDeLaEvaluacion()) ) || bloquearEvaluacionPorInstanciaFueraDeEvaluacion() ;
        }

        if(esFormularioIdeaProyecto(idForm,tipoFormulario)){
            return ( (!usuarioEsAdmin()) && (!usuarioEsDueñoDelProyecto()) ) || bloquearFormularioIpPorEstadoEvaluacion() || instanciaEvaluacionIpTerminada();
        }

        if(esFormularioProyecto(idForm,tipoFormulario)){
            return ( (!usuarioEsAdmin()) && (!usuarioEsDueñoDelProyecto()) ) || bloquearFormularioProyectoPorEstadoEvaluacion() || (!proyectoEnEstadoRecepProyecto());
        }
    }
        
      
  return (
    <div>
        {
            esFormularioEvaluacion(idForm,tipoFormulario) && 
            tipoEvaluacion !== 'pcal' &&
            <fieldset className='mb-4'>
                <legend className='ms-2 fw-bold' >Evaluado por:</legend>
                {
                    formEval &&
                    <ul className='list-group list-group-flush'>
                    {
                        pFocus && 
                        pFocus.lFormsEval &&
                        formEval.tipo.endsWith('_def')
                        ?
                            pFocus.lFormsEval.map(
                                ({evaluador,tipo}) => 
                                    tipo.startsWith(tipoEvaluacion) 
                                    ?<>
                                        <li className='list-group-item'><span className=' fw-bold'>Apellido y Nombre :</span> {evaluador.ape},{evaluador.nom} </li>
                                        <li className='list-group-item'><span className='fw-bold'>UU.AA. :</span> {evaluador.unidadAcademica} </li>
                                     </>
                                    :<></>
                            )
                        :
                        <>
                            <li className='list-group-item'>
                                <span className=' fw-bold'>{`${tipoEvaluacion === 'eippe' ? 'Programa de extensión : ' : 'Apellido y Nombre : '}`}</span> 
                                {`${tipoEvaluacion === 'eippe' ? `${toPascalCase(formEval.evaluador.nom)}` : `${toPascalCase(formEval.evaluador.ape)}, ${toPascalCase(formEval.evaluador.nom)}` }`} 
                            </li>
                            {tipoEvaluacion !== 'eippe' && <li className='list-group-item'><span className='fw-bold'>UU.AA. :</span> {formEval.evaluador.unidadAcademica} </li>}
                        </>
                    }
                    </ul>
                }
            
            </fieldset>
        }
    
        {
            (!activarVistaPrevia) &&

            <div className='col d-flex justify-content-center mt-4'>
                {
                    (guardandoForm) 
                    ? <Spiner />
                    :
                    <button className='btn btn-primary' 
                        onClick={(e)=>handleSubmit(e)} 
                        hidden = { ocultarBotonGuardar() }
                        disabled={ guardandoForm }
                    >
                        
                      { (tipoEvaluacion) ? 'Guardar Evaluación' : 'Guardar cambios' }
                    
                    </button>
                 }
            </div>
        }
    </div>
  )
}
