
import { startDescargarDocumento } from '../../../../actions/bases';
import { startSubirDocAdjunto } from '../../../../actions/formp';
import { useForm } from '../../../../hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { useGestionarUsuario } from '../../../../hooks/useGestionarUsuario';
import { mostrarAlerta } from '../../../../helpers/mostrarAlerta';
import { useHistory } from 'react-router-dom';
import { useGestionarEstados } from '../../../../hooks/useGestionarEstados';

export const useFormAdjuntarDocumentos = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const {pFocus} = useSelector(state => state.pReducer);
    const {usrFocus} = useSelector( state => state.usrReducer);
    
    const {usuarioEsAdmin, usuarioEsDueñoDelProyecto} = useGestionarUsuario();
    const {proyectoEnEstadoRecepProyecto} = useGestionarEstados();
    const {InstitucionIntervinientes,EquipoExtension} = pFocus || {InstitucionIntervinientes : [], EquipoExtension : null};
    const {IntegranteEquipos} = EquipoExtension || {IntegranteEquipos : []};

    const {lDocAdjuntos} = pFocus || {lDocAdjuntos : []};

    const tiposAceptados = [  
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];

    const printInteg = (integ,i) => ({ 
        id : i+10 , 
        nom : `CV_${integ.dni}_${integ.ape.split(' ').join('_')}_${integ.nom.split(' ').join('_')}_${integ.unidadAcademica}`
    })

    const mapearIntegrantes = ()=>{
        if(IntegranteEquipos && IntegranteEquipos.length > 0){
           return  IntegranteEquipos.map( printInteg )
        }
        return [];
    }
    const mapearInstituciones = ()=>{
        if(InstitucionIntervinientes && InstitucionIntervinientes.length > 0){
           return InstitucionIntervinientes.map( (inst,i) => ({ id : i+100 , nom : `AVAL_${inst.Institucion.nom.split(' ').join('_')}`}))
        }
        return [];
    }
    const lDocumentacionRequerida = [
        {id:1,nom:'PRESUPUESTO'},
        {id:2,nom:'CONSTANCIA_DIRECTOR'},
        {id:3,nom:'CONSTANCIA_JEFE_CATEDRA'},
        ...mapearIntegrantes(),
        ...mapearInstituciones()
    ]
    

    const { 
        campos, 
        validaciones,
        handleCampoChange, 
        validarCampo,
        validarForm
    } = useForm({
         documentoElegido : ''
      });

    const handleSalir =(e)=>{
        e.preventDefault();
    
        mostrarAlerta('¿Desea salir del formulario? Se perderán todos los campos que no haya guardado','Volver', true)
          .then( resp => resp.isConfirmed  ? history.push(`/gestor/panel/${pFocus.id}`) : null );
      }

    const handleSubmit = (e)=>{ 
        e.preventDefault();

        if(  (!usuarioEsAdmin()) && (!usuarioEsDueñoDelProyecto())){
            alert('No posee permisos necesarios para realizar esta acción');
            return;
        }

        if( !validarForm(['documentoElegido']) ){
            alert('debe selecionar un tipo de documento')
            return;
        } 

       
        if(e.target['doc_adjunto'].files.length === 0 ) {
            alert('no se cargo ningun documento todavia');
            return;
        }

        if(!tiposAceptados.find(tipo => tipo === e.target['doc_adjunto'].files[0].type)  ){
            alert('formato no admitido');
            return;
        }

        let formData = new FormData();
        
        const documentoASubir = lDocumentacionRequerida.find( docReq => docReq.id === Number(campos.documentoElegido) );
        const doc_nom = documentoASubir ? documentoASubir.nom : 'documento';

        formData.append('doc_nom',doc_nom);
        formData.append('doc_adjunto',e.target['doc_adjunto'].files[0]);
        formData.append('idUsr',usrFocus.id);

        dispatch( startSubirDocAdjunto( formData ));

       
    }

    const handleVerAval = (e)=>{
        e.preventDefault();
        window.open(e.target.href,'_blank');
    }

    const handleDescargarDocumento = (e,name)=>{
        e.preventDefault();

        dispatch(startDescargarDocumento(pFocus.codigo,name));
    }

    const validarPresupuestoPresentado = ()=>lDocAdjuntos && lDocAdjuntos.some( doc => doc.name.startsWith('PRESUPUESTO'));
    const validarConstanciaDirectorPresentada = ()=>lDocAdjuntos && lDocAdjuntos.some( doc => doc.name.startsWith('CONSTANCIA_DIRECTOR'));
    
    const validarConstanciaJefeCatedra = ()=> lDocAdjuntos && lDocAdjuntos.some( doc => doc.name.startsWith('CONSTANCIA_JEFE_CATEDRA'));
    
    const validarDocEquipoExtensionSubida = ()=>
    IntegranteEquipos && IntegranteEquipos.every( integ =>  lDocAdjuntos &&  lDocAdjuntos.some( docAdjunto => docAdjunto.name.split('_').some(item => item === integ.dni)  ) )
    ;
    const validarDocInstIntervPresentada = ()=>
        lDocAdjuntos && 
        lDocAdjuntos.some( doc => doc.name.startsWith('AVAL')) &&
        lDocAdjuntos.filter( doc => doc.name.startsWith('AVAL')).length >= InstitucionIntervinientes.length;
    // const validarDocInstIntervPresentada = ()=> true;
    

    const validarDocumentacionCompleta = ()=>
        validarPresupuestoPresentado() &&
        validarConstanciaDirectorPresentada() &&
        validarDocEquipoExtensionSubida() &&
        validarDocInstIntervPresentada()
        
    return {
     tiposAceptados,
     campos, 
     handleSubmit,
     handleSalir,
     handleVerAval,
     handleDescargarDocumento,
     lDocAdjuntos,
     lDocumentacionRequerida,
     usuarioEsAdmin, 
     usuarioEsDueñoDelProyecto,
     validaciones,
     handleCampoChange, 
     validarCampo,
     validarForm,
     validarDocEquipoExtensionSubida,
     validarDocInstIntervPresentada,
     validarPresupuestoPresentado,
     validarConstanciaDirectorPresentada,
     validarDocumentacionCompleta,
     validarConstanciaJefeCatedra,
     proyectoEnEstadoRecepProyecto
  }
}
