import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useFormAdjuntarDocumentos } from '../hooks/useFormAdjuntarDocumentos'


export const ItemEquipoExtension = () => {

  const {validarDocEquipoExtensionSubida} = useFormAdjuntarDocumentos();
  console.log(validarDocEquipoExtensionSubida())
  return (
    <li className='list-group-item bg-transparent' > 
        {'Curriculum Vitae de integrante/s del equipo (formato .pdf de 1 página por CV). '}
        {  validarDocEquipoExtensionSubida() &&  <FontAwesomeIcon className='ms-4' icon={faCheck}size={'2x'} color={ 'green' }  />  }
    </li>
  )
}
