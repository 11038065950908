export const obtenerEstructuraEvaluacion = (estructura,campos)=> 
    Object.values(estructura)
    .reduce( (salida,seccion) => ({
        ...salida,
        [seccion.titulo] : {
            ...seccion.campos
                .filter( campo => (!campo[Object.keys(campo)[0]].primaryKey) )
                .reduce( (salida,campo) =>({
                    ...salida,
                    [campo[Object.keys(campo)[0]].etiqueta] : campos[campo[Object.keys(campo)[0]].fieldName]
        }),{} )
    }
}) 
,{});