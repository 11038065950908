import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {  startGuardarEvaluacionIpCA } from '../../actions/evipca'
import { FormEvaluacion } from '../../components/FormularioEvaluacion/FormEvaluacion'
import { FormularioFirmaDigital } from '../../components/FormularioEvaluacion/FormularioFirmaDigital'
import { mostrarAlerta } from '../../helpers/mostrarAlerta'
import { useGestionarUsuario } from '../../hooks/useGestionarUsuario'
import { useGestionarEstados } from '../../hooks/useGestionarEstados'



export const FormEvaluacionIpCA = ({_tipoUsuario = ''}) => {

    const dispatch = useDispatch();
    const params = useParams();

   
    const {eipcaFocus} = useSelector(state => state.eipcaReducer);
    const {pFocus} = useSelector( state => state.pReducer);
    const {id : idUsr,tipoUsuario } = useSelector(state => state.usrReducer.usrFocus)


    const {activarVistaPrevia} = useSelector(state => state.uiReducer);
 

    const {evaluadorEsDueñoDeLaEvaluacion} = useGestionarUsuario();
    const {esFormularioDefinitivo} = useGestionarEstados();

     
    const handleSubmitEvaluacionIpCA = (e,reset)=>{
        e.preventDefault();
        if( tipoUsuario !== 'admin' &&
            pFocus.lFormsEval.find( _form => _form.id === eipcaFocus.id).evaluador.id !== idUsr) {

            alert('No es el evaluador correspondiente a este formulario');
            return;
        }
        mostrarAlerta('¿Desea guardar los cambios?','Guardar Evalucion',true)
        .then( resp => {
            if(resp.isConfirmed){
                dispatch( startGuardarEvaluacionIpCA(params.idForm));
                reset();
            }
        } )
    
        return;
    }


  return (
    <div className='container  '>
     
       {
        (eipcaFocus) &&
        <div className='row row-cols-1 animate__animated animate__fadeIn'>
            {
               
                <FormEvaluacion 
                    titulo='Evaluacion IP Consejo Asesor'
                    handleSubmitEvaluacion={handleSubmitEvaluacionIpCA}
                    _tipoEvaluacion = 'eipca'
                    _tipoUsuario={_tipoUsuario}
                />
            }
            {   
                (!activarVistaPrevia) && 

                evaluadorEsDueñoDeLaEvaluacion() && 

                esFormularioDefinitivo(eipcaFocus.id) &&

                <FormularioFirmaDigital _tipoForm='eipca' />
            }
            
    
        </div>
       }
        
       
    </div>
  )
}
