import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { startGuardarEvaluacionPertinencia } from '../../actions/eppert'
import { FormEvaluacion } from '../../components/FormularioEvaluacion/FormEvaluacion'
import { mostrarAlerta } from '../../helpers/mostrarAlerta'
import { useGestionarUsuario } from '../../hooks/useGestionarUsuario'



export const FormEvaluacionPertinencia = ({_tipoUsuario = ''}) => {
    const dispatch = useDispatch();
    
    
    const params = useParams();
    const {ppertFocus} = useSelector(state => state.ppertReducer);
    
    const {evaluadorEsDueñoDeLaEvaluacion,usuarioEsAdmin}= useGestionarUsuario(); 

    const handleSubmitEvaluacionPertinencia = (e,reset)=>{
        e.preventDefault();
        if( !usuarioEsAdmin() && !evaluadorEsDueñoDeLaEvaluacion() ) {

            alert('No es el evaluador correspondiente a este formulario');

            return;
        }

        mostrarAlerta('¿Desea guardar los cambios?','Guardar Evalucion',true)
            .then( resp => {
                if(resp.isConfirmed){
                    dispatch( startGuardarEvaluacionPertinencia(params.idForm));
                    reset();
                }
            } )
    }

   
  return (
    <div className='container  bg-secondary bg-opacity-10'>
       <div className='row row-cols-1 animate__animated animate__fadeIn'>
            {
                (ppertFocus) &&
                <>
                    <FormEvaluacion 
                        
                        titulo='Evaluación de Pertinencia'
                        handleSubmitEvaluacion={handleSubmitEvaluacionPertinencia}
                        _tipoEvaluacion = 'ppert'
                        _tipoUsuario={_tipoUsuario}
                    />
                </>
            }
                
            
         </div>
    </div>
  )
}
