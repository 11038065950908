import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEditarFormProyecto } from '../../../../actions/formp';
import { mostrarAlerta } from '../../../../helpers/mostrarAlerta';
import { useGestionarEstados } from '../../../../hooks/useGestionarEstados';
import { useGestionarUsuario } from '../../../../hooks/useGestionarUsuario';
import { startSelecParticipDirecto } from '../../../../actions/bases';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { abrirModalParticipanteDirecto } from '../../../../actions/ui';

export const FilaParticipanteDirecto = ({ id=null ,participante = '', actividad = []}) => {
    const dispatch = useDispatch();
    const {ParticipanteDirectos} = useSelector(state => state.pReducer.pFocus);

    
    const {
      bloquearFormularioProyectoPorEstadoEvaluacion
    } = useGestionarEstados();

    const {usuarioEsAdmin, usuarioEsDueñoDelProyecto} = useGestionarUsuario();

    const handleBorrarParticipanteDirecto = (e)=>{
        e.preventDefault();

        if( (!usuarioEsAdmin()) && (!usuarioEsDueñoDelProyecto())
        ){
                
            alert('No posee permisos para realizar esta acción');
            return;
        }
        
        if( bloquearFormularioProyectoPorEstadoEvaluacion()){
            alert('No posee permisos para realizar esta acción');
            return;
        }
    
        mostrarAlerta(`¿Desea eliminar el participante?`,'Eliminar participante directo',true)
            .then( resp => 
                resp.isConfirmed &&
                dispatch( 
                    startEditarFormProyecto({
                        ParticipanteDirectos : ParticipanteDirectos.filter( (part,i)=> i !== id )
                    })
                )
             );
    }

    const handleEditarParticipanteDirecto = (e,id)=>{
        e.preventDefault();
        if( (!usuarioEsAdmin()) && (!usuarioEsDueñoDelProyecto())
        ){
                
            alert('No posee permisos para realizar esta acción');
            return;
        }
        
        if( bloquearFormularioProyectoPorEstadoEvaluacion()){
            alert('No posee permisos para realizar esta acción');
            return;
        }
        
        dispatch( startSelecParticipDirecto(id) )
        dispatch( abrirModalParticipanteDirecto())
    }


  return (
    <div className='row'>
        <div className='col-5 ms-2'>{participante}</div>
        <div className='col-5 ms-2'>
            <ul>{actividad.map( (actividad,i) => <li key={`actPart-${i}`}>{actividad.desc}</li>)}</ul>
        </div>
        
        <div className='col-1  text-center justify-center'  >
        {
             (usuarioEsAdmin() || usuarioEsDueñoDelProyecto()) &&
             <small className='btn btn-outline-secondary' onClick={(e)=>handleEditarParticipanteDirecto(e,id)} >
                 <FontAwesomeIcon icon={faEdit} />    
             </small> 
        }  
        {
             (usuarioEsAdmin() || usuarioEsDueñoDelProyecto()) &&
             <small className='btn btn-outline-danger' onClick={handleBorrarParticipanteDirecto} >X</small> 
        } 
        </div>
        
    </div>
  )
}
