import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startMostrarListaEvalaudores } from '../../actions/bases'
import { startGetComsiones } from '../../actions/comsion'
import { Spiner } from '../../components/Ui/Spiner'
import { TablaComisionEvaluadora } from '../../components/PanelComisiones/TablaComisionEvaluadora'
import { TablaEvaluacionProgramas } from '../../components/PanelComisiones/TablaEvaluacionProgramas'

export const PanelComisiones = () => {
  const dispatch = useDispatch();
  const {cargandoEvaluadores,cargandoBases} = useSelector(state => state.uiReducer)

  useEffect(()=>{ dispatch(startGetComsiones())},[dispatch])
  useEffect(() => { dispatch(startMostrarListaEvalaudores())}, [dispatch])
  

  return (
    <div className='container'>
        {
          cargandoEvaluadores || cargandoBases
          ? <Spiner />
          :
            <div className='row row-cols-1'>
              <div className='col'>
                  <TablaComisionEvaluadora  />
              </div>
              <div className='col'>
                  <TablaEvaluacionProgramas />
              </div>
            </div>
        }
    </div>
  )
}
