import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {  startCargarVistaPrevia,  startSubirFirmaDigital } from '../../actions/bases';
import { startSubirDocAdjunto } from '../../actions/formp';

export const ModalVistaPreviaDoc = () => {

    const dispatch  = useDispatch();
    
    const {imgSrc} = useSelector(state => state.basesReducer);
    const {eipcaFocus} = useSelector(state => state.eipcaReducer);
    const {pcalFocus} = useSelector(state => state.pcalReducer);
    const {pFocus} = useSelector(state => state.pReducer);
    const {ppertFocus} = useSelector(state => state.ppertReducer);
    
    const idFormEval = eipcaFocus 
        ? eipcaFocus.id 
        : pcalFocus 
            ? pcalFocus.id
            : ppertFocus 
                ? ppertFocus.id 
                : null;

    const [showMsgFormatoInvalido, setshowMsgFormatoInvalido] = useState(false)
    const [showMsgArchivoNulo, setshowMsgArchivoNulo] = useState(false)

    const tiposAceptados = [ 'image/jpeg','image/pjpeg','application/pdf','application/vnd.ms-excel']
    
    const msgFormatoInvalido = 'No se acepta ese formato de archivo';
    const msgArchivoNulo = 'Debe subir un archivo primero';
    const handleVistaPreviaFirmaDigital = (e)=>{
        e.preventDefault();

        if(e.target.files.length > 0 && tiposAceptados.find(tipo => tipo === e.target.files[0].type)){
             
            dispatch( startCargarVistaPrevia(URL.createObjectURL(e.target.files[0])));
        } else {
            setshowMsgFormatoInvalido(true);
        }
        
    }
    
    const handleSubmitDocAdjunto = (e)=>{
        e.preventDefault();

        if(e.target['doc_adjunto'].files.length < 1){
            setshowMsgArchivoNulo(true);
            return;
        }

        let formData = new FormData();
        // formData.append('doc_nom',e.target['doc_nom'].value);
        formData.append('doc_adjunto',e.target['doc_adjunto'].files[0]);
        formData.append('idFormEvaluacion',idFormEval);
        formData.append('tipoFirma',eipcaFocus ? 'eipca' : ppertFocus ? 'ppert' : 'pcal');
        formData.append('idProy',pFocus.id);
        
        
        if(tiposAceptados.find(tipo => tipo === e.target['doc_adjunto'].files[0].type)){
             
            (eipcaFocus || pcalFocus)
                ? dispatch(startSubirFirmaDigital(formData))
                : dispatch(startSubirDocAdjunto(formData))
        }else {
            setshowMsgFormatoInvalido(true);
        }
        
        
    }

  return (
    <div className='row row-cols-1'>
        <h4> Adjuntar Documento </h4>
        <hr />
        {showMsgFormatoInvalido && 
            <p className='alert alert-info d-flex justify-content-between p-1'>
                {msgFormatoInvalido}
                <button className='btn btn-link ' 
                    onClick={()=>setshowMsgFormatoInvalido(false)}
                >x</button>
            </p>
        }
        {showMsgArchivoNulo && 
            <p className='alert alert-info d-flex justify-content-between p-1'>
                {msgArchivoNulo}
                <button className='btn btn-link ' 
                    onClick={()=>setshowMsgArchivoNulo(false)}
                >x</button>
            </p>
        }
        
        <div className='col'>
            <p className='text-secondary'> Seleccione el archivo que desea adjuntar, luego haga click en "Subir firma" </p>
            <form onSubmit={handleSubmitDocAdjunto} encType='multipart/form-data' >
                <input id='doc_adjunto' name='doc_adjunto' onChange={handleVistaPreviaFirmaDigital} className='form-control' type={'file'}/>
                <div className='col mt-2'>
                    <img id='vista_previa' src={imgSrc || '#'} alt='Vista Previa' width={450} height={450} hidden={!imgSrc || showMsgFormatoInvalido}/>
                </div>
                <hr />
                <div className='d-flex  justify-content-center'>
                    <input className='btn btn-outline-info mt-4' type={'submit'} value={'Subir Firma'} />
                </div>
                
            </form>
        </div>
       
    </div>
  )
}
