import React, { useEffect } from 'react'
import { FormularioObjetivoEspecifico } from './_FormularioObjetivo';
import { useCoherenciaInterna } from './hooks/useCoherenciaInterna';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';


const AlertaError = ({
    error = '',
    setError = ()=>{}
})=>
<p className='alert alert-info d-flex justify-content-between p-1'>
    {error}
    <button className='btn btn-link ' onClick={()=>setError(null)}>x</button>
</p>

export const ModalCoherenciaInterna = ({_titulo=''}) => {
    const {pFocus} = useSelector( state => state.pReducer)

    const {
        cantObjeivos,
        campos,
        objetivosEspecificos,
        handleCampoChange,
        handleAgregarObjetivoEspecifico,
        handleAgregarActividadObjetivo,
        handleEliminarActividadOBjEsp,
        handleEliminarObjetivoEspecifico,
        handleGuardarActividadObjetivo,
        handleGuardarObjetivoEspecifico,
        handleSubmitCoherenciaInterna,
        validaciones,
        validarCampo,
        mostrarFormObjEsp,
        error    
    } = useCoherenciaInterna();


    useEffect(() => {
        const element = document.getElementById(`form-obj-esp-${objetivosEspecificos.length-1}`);
        if(element){
            element.scrollIntoView({behavior:'smooth'});
        }
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cantObjeivos])
    

    
    
  return (
    <div  className='row row-cols-1'>
        <div className='col border-bottom'>
            <div className='row'>
                <div className='col-3'>
                    <h4>{_titulo}</h4>
                </div>
                <div className='col-5 d-flex justify-content-center'>
                    <button className='btn btn-primary ' 
                        onClick={handleSubmitCoherenciaInterna}
                    > 
                        Guardar Coherencia Interna
                    </button>
                </div>
            </div>
           
        </div>
        {   
            error &&  <div className='col'><AlertaError /></div>
        }
        <div className='col border-bottom mt-2  mb-2'>
           <div className='row row-cols-2 mb-2' >
                <div className='col'>
                    <div className='row input-group'>
                        <div className='col-3 input-group-text text-wrap'  style={{fontSize : '0.8em'}}>
                            {'Finalidad (máx. 1000 caracteres).'}
                        </div>
                        <textarea className='col-9 form-control' 
                            name='finalidad' 
                            minLength ={1} 
                            maxLength={1000}
                            onChange={handleCampoChange}
                            onBlur={validarCampo} 
                            value={campos['finalidad']}
                            valido={validaciones['finalidad']} 
                            required={true} 
                            style={{resize:'none',fontSize : '0.8em'}}
                            rows={3}
                        />
                    </div>
                </div>
                <div className='col'>
                    <div className='row input-group' >
                        <div className='col-3 input-group-text text-wrap' style={{fontSize : '0.8em'}}>
                            {'Objetivo General (máx. 1000 caracteres).'}
                            </div>
                       
                        <textarea className='col-9 form-control' 
                            name='objetivoGeneral' 
                            minLength ={1} 
                            maxLength={1000}
                            onChange={handleCampoChange}
                            onBlur={validarCampo} 
                            value={campos['objetivoGeneral']}
                            valido={validaciones['objetivoGeneral']} 
                            required={true} 
                            style={{resize:'none',fontSize : '0.8em'}}
                            rows={3}
                        />
                       
                    </div>
                </div>
           </div>
        </div>
        
        <div className='col ' style={{overflow : 'auto' , maxHeight : '25em'}}>
            <div className='container '>
                <div className='row border-bottom border-top border-secondary  m-1'  style={{fontSize : '0.8em'}}>
                    <div className='col-2'> 
                        <div className='row'>
                            <div className='col-9'>{'Objetivo específico (máx. 1000 caracteres)'}</div>
                            <div className='col-1' >
                                <button className={` float-end btn btn${ mostrarFormObjEsp ? '' : '-outline'}-primary  d-flex align-items-center`}
                                    onClick={handleAgregarObjetivoEspecifico}
                                    title='Agregar objetivo'
                                >
                                    <FontAwesomeIcon icon={faPlus} size={'1x'}/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-2 border-start border-end border-secondary'>{'Resultado esperado (máx. 1000 caracteres)'}</div>
                    <div className='col-7 border-end border-secondary'>
                        <div className='row border-bottom border-secondary'>
                            <div className='col text-center'>Actividades</div>
                        </div>
                        <div className='row '>
                            <div className='col-3'>{'Actividad (máx. 1000 caracteres)'}</div>
                            <div className='col-4 border-start border-end border-secondary'> Año 1</div>
                            {
                                pFocus.bianual ? <div className='col-4'> Año 2</div> : <></>
                            }
                        </div>
                    </div>
                    
                </div>      
                {
                    objetivosEspecificos.map( (oe,i)=>
                        <FormularioObjetivoEspecifico  
                            key={`${Date.now()}-${i}`}
                            index={i} 
                            _desc={oe.desc} 
                            _resEsp={oe.resEsp} 
                            _Actividades={oe.ActividadObjetivos}
                            handleEliminarObjetivoEspecifico={handleEliminarObjetivoEspecifico}
                            handleGuardarObjetivoEspecifico={handleGuardarObjetivoEspecifico}
                            handleAgregarActividadObjetivo={handleAgregarActividadObjetivo}
                            handleEliminarActividadOBjEsp={handleEliminarActividadOBjEsp}
                            handleGuardarActividadObjetivo={handleGuardarActividadObjetivo}
                            />
                    )
                }
            </div>
          
        </div>
    </div>
  )
}
