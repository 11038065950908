import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { startEditarFormProyecto } from '../../../actions/formp';
import { useForm } from '../../../hooks/useForm';
import { Campo } from '../../../components/Formulario/Campo';


export const ModalPalabrasClave = ({_titulo = ''}) => {
  const dispatch =  useDispatch();

  const {pFocus} = useSelector(state => state.pReducer);

  const [showMsgFormInvalido, setShowMsgFormInvalido] = useState(false);

  const {
    campos,
    validaciones,
    handleCampoChange,
    validarCampo,
    validarForm,
    reset
  
  } = useForm({palabra : ''});

  
  const handleSubmitModalCampoLista = (e)=>{
      e.preventDefault();
      if(validarForm(['palabra'])){

        setShowMsgFormInvalido(false);
        if(pFocus['PalabraClave_id_PalabraClaves'].length < 5){
          let listaPalabras = [];
          new Set([ ...pFocus['PalabraClave_id_PalabraClaves'], campos['palabra'] ]).forEach( palabra => listaPalabras.push(palabra) );
  
          dispatch( startEditarFormProyecto({ 'PalabraClave_id_PalabraClaves': listaPalabras    }));
          
          reset();
        }
      

      }else {
        setShowMsgFormInvalido(true);
      }
    }


  return ( 
    
        <div className='row row-cols-1'>
            <div className='col mb-3'> 
               
                <h4 className='mb-2'>{_titulo}</h4>
                <hr/>
                {showMsgFormInvalido && <p className='alert alert-info d-flex justify-content-between p-1'>Debe ingresar una palabra antes de guardar. <button className='btn btn-link ' onClick={()=>setShowMsgFormInvalido(false)}>x</button></p>}
            </div>
            <div className='col'>
              
                
                <Campo 
                    name='palabra' 
                    type='text'  
                    etiqueta='Palabras clave (máx. 255 caracteres).' 
                    maxLength={255} 
                    value={campos['palabra']} 
                    valido = {validaciones['palabra']}
                    handlecampochange={handleCampoChange}
                    handleblur={validarCampo}
                    required={true}
                />
              </div>
              <div className='col'>
                <button className='btn btn-primary float-center' onClick={handleSubmitModalCampoLista}>Guardar Palabra</button>
              </div>
        </div>

       
   
  )
}
