import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { startGuardarEvaluacionIpPE } from '../../actions/evippe'
import { mostrarAlerta } from '../../helpers/mostrarAlerta'
import { FormEvaluacion } from '../../components/FormularioEvaluacion/FormEvaluacion'
import { useGestionarUsuario } from '../../hooks/useGestionarUsuario'


export const FormEvaluacionIpPE = ({_tipoUsuario = ''}) => {
    const dispatch = useDispatch();
    
    const params = useParams();
    const {eippeFocus} = useSelector(state => state.eippeReducer);



    const {evaluadorEsDueñoDeLaEvaluacion,usuarioEsAdmin} = useGestionarUsuario();

  


    const handleSubmitEvaluacionIpPE = (e,reset)=>{
        e.preventDefault();
        if( !usuarioEsAdmin() && !evaluadorEsDueñoDeLaEvaluacion()) {
            alert('No es el evaluador correspondiente a este formulario');
            return;
        }
        mostrarAlerta('¿Desea guardar los cambios?','Guardar Evalucion',true)
        .then( resp => {
            if(resp.isConfirmed){
                dispatch( startGuardarEvaluacionIpPE(params.idForm));
                reset();
            }
        } )
    }

  return (
    <div className='container '>
       {
        
        eippeFocus &&

        <div className='row row-cols-1 animate__animated animate__fadeIn'>
            <FormEvaluacion 
                titulo='Evaluación IP Programas de Extensión'
                handleSubmitEvaluacion={handleSubmitEvaluacionIpPE}
                _tipoEvaluacion = 'eippe'
                _tipoUsuario={_tipoUsuario}
            />
        
        </div>
       }
    </div>
    
  )
}


