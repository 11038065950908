import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { abrirModalBecarioInforme } from '../../actions/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { startSelecIntegEquipo } from '../../actions/bases';

export const Becarios = ({esFinal = false}) => {
  const dispatch = useDispatch();
  
  const {Becarios} = useSelector(state => esFinal ?  state.infFinalReducer.infFinalFocus : state.infAvReducer.infAvFocus )
  const {lRolInteg} = useSelector(state => state.basesReducer);
  const handleAgregarBecario = (e)=>{
      e.preventDefault();
      dispatch(abrirModalBecarioInforme(esFinal));
    };
  const handleEditarBecario = (e, _indice)=>{
    e.preventDefault();
    dispatch(startSelecIntegEquipo(_indice));
    dispatch(abrirModalBecarioInforme(esFinal));
  }

  const handleBorrarBecario = (e,_indice)=> {
    e.preventDefault();
  }


  return (
    <div className='row row-cols-1 m-2'>
    <div className='col p-2'>
      <div className='row'>
        <div className='col-4'> <h4 >BECARIOS Y VOLUNTARIOS</h4> </div>
        <div className='col-1'>
            <button className='btn btn-outline-primary'  onClick={handleAgregarBecario}>Agregar</button>
          </div> 
      </div>
    </div>
    <div className='col'>
      <div className='row bg-primary text-light p-2 '>
        <div className='col-1 '> DNI </div>
        <div className='col-3  border-end border-start'> APE Y NOM </div>
        <div className='col-2 me-5 '> TEL </div>
        <div className='col-2 border-start me-5 '> EMAIL </div>
        <div className='col-1 border-start'> UA </div>
        <div className='col-1 border-start'> ROL </div>
        
      </div>
    </div>
    <div className='col' style={{minHeight : '18em',maxHeight : '18em', overflow : 'scroll'}}>
      
        {
          // (!cargandoForm) &&
          Becarios &&
          Becarios.map( (b,_indice) => 
            <div className='row p-2 ' key={`${Date.now()}-${_indice}`} >
              <div className='col-1 '> {b.Persona.dni} </div>
              <div className='col-3  border-end border-start'> { b.Persona.ape }, {b.Persona.nom} </div>
              <div className='col-2 me-5 '> {b.Persona.tel} </div>
              <div className='col-2 border-start me-5 '> {b.Persona.email} </div>
              <div className='col-1 border-start'> {b.Persona.unidadAcademica} </div>
              <div className='col-1 border-start'> {lRolInteg.find(r => r.id === Number(b.Rol_id)).nom || ''} </div>
              <div className='col-1 border-start text-center m-0'> 
                <div className='row' >
                  <div className='col-5' ><span className='btn btn-outline-secondary'> <FontAwesomeIcon  icon={faEdit} onClick={e => handleEditarBecario(e,_indice)} /> </span></div>
                  <div className='col-5' ><span className='btn btn-outline-danger'> <FontAwesomeIcon icon={faTrash} onClick={e => handleBorrarBecario(e,_indice)} /> </span></div>
                </div>
              </div>
            
            </div>
          )
        }
      
    </div>
    <hr className='mt-3'/>
</div>
  )
}
