import React from 'react'
import { FilaObjetivoEspecifico } from './FilaObjetivoEspecifico';
import { useCoherenciaInterna } from './hooks/useCoherenciaInterna';
import { useSelector } from 'react-redux';


const CabeceraTabla = ({bianual = false})=>
   <>
    <div className='col '>
      <div className='row row-cols-1'>
        <div className='col text-center'>Objetivos Especificos</div>
        <div className='col'>
          <div className='row border-top'>
            <div className='col-2 '>Objetivo</div>
            <div className='col-2 border-start border-end'>Result. Esperado</div>
            <div className='col-8'>
              <div className='row row-cols-1'>
                <div className='col text-center'>Actividades</div>
                <div className='col'>
                  <div className='row border-top'>
                    <div className='col-4'>Actividad</div>
                    <div className='col-4 border-start border-end'>Cronograma Año 1</div>
                    {
                      bianual ? <div className='col-4'>Cronograma Año 2</div> : <></>
                    }
                    
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>

   </>

export const TablaCoherencia = ( {bianual = false} ) => {

  const {CoherenciaInterna} = useSelector(state => state.pReducer.pFocus)


  const {
    handleCargarCoherenciaInterna,
    usuarioEsAdmin,
    usuarioEsDueñoDelProyecto,
    proyectoEnEstadoRecepProyecto
  } = useCoherenciaInterna();

  const mostrarBoton = usuarioEsAdmin() || usuarioEsDueñoDelProyecto();

  return (
      <div className='row row-cols-1'  style={{fontSize : '0.8em'}}>
        {
          mostrarBoton && 
          <div className='col  p-2 bg-secondary bg-opacity-10'>
            { proyectoEnEstadoRecepProyecto() 
              ? <button className=' btn btn-primary float-end' 
                  onClick={handleCargarCoherenciaInterna}
                >
                  Cargar coherencia Interna
                </button> 
              : <></> }
          </div>
        }
         {
            CoherenciaInterna &&
            <div className='col'>
                <div className='row border-bottom'>
                      <div className='col-2 bg-primary text-light border-end'>Finalidad</div>
                      <div className='col-4 bg-transparent' style={{height : '8rem', overflowY : 'auto'}}>{CoherenciaInterna.finalidad}</div>
                      <div className='col-2 bg-primary text-light border-end'>Objetivo General</div>
                      <div className='col-4 bg-transparent border-start border-end' style={{height : '8rem', overflowY : 'auto'}}>{CoherenciaInterna.objetivoGeneral}</div>
                </div>
               <div className='row bg-primary text-light'>
                  <CabeceraTabla bianual={bianual} />
                </div>
                
              <div className='row row-cols-1' style={{maxHeight : '31rem', overflow : 'auto'}}>
                
                {
                   CoherenciaInterna.ObjetivoEspecificos.map( 
                    ({desc,ActividadObjetivos,resEsp},i) => 
                       <FilaObjetivoEspecifico  
                          key={`TablaObj-${i}`} 
                          objetivo={desc} 
                          lActividad={ActividadObjetivos} 
                          resEsp={resEsp} 
                        />
                     )
                }
                
              </div>

            </div>
           }
           
          </div>
      
    )
  
 
}
