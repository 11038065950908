
import { useDispatch } from 'react-redux';
import { startLoguearUsuario } from '../../actions/usr';
import { mostrarAlerta } from '../../helpers/mostrarAlerta';
import { useForm } from '../../hooks/useForm';

export const useLoginUsuario = () => {
    const dispatch = useDispatch();

    const { 
      campos, 
      validaciones,
      handleCampoChange, 
      validarCampo,
      validarForm
  } = useForm({
      email : '',
      pass : ''
    });
  
  
    const handleSubmit = (e)=>{
       e.preventDefault();
      if(validarForm(['email','pass'])){
        dispatch( startLoguearUsuario( campos['email'],campos['pass'] ))
      } else {
        mostrarAlerta('Debe ingresart su correo electrónico y contraseña','Error',false);
      }
    }

    return {
        campos,
        handleCampoChange,
        validarCampo,
        validaciones,
        handleSubmit
    }
}
