

import { useDispatch, useSelector } from 'react-redux';
import { abrirModalEquipoExtension } from '../../../../../actions/ui';
import { useGestionarEstados } from '../../../../../hooks/useGestionarEstados';
import { useGestionarUsuario } from '../../../../../hooks/useGestionarUsuario';
import { startSelecIntegEquipo } from '../../../../../actions/bases';
import { startEditarFormProyecto } from '../../../../../actions/formp';
import { mostrarAlerta } from '../../../../../helpers/mostrarAlerta';


export const useEquipoExtension = () => {

    const dispatch = useDispatch();
 
    const {EquipoExtension} = useSelector( state => state.pReducer.pFocus);
    const {lRolInteg} = useSelector( state => state.basesReducer);

    const IntegranteEquipos = EquipoExtension.IntegranteEquipos ? EquipoExtension.IntegranteEquipos : []  ;
    
    const showMsgError = 
        EquipoExtension &&
        EquipoExtension.IntegranteEquipos &&
        EquipoExtension.IntegranteEquipos.length < 2;
  
    const {
      bloquearFormularioProyectoPorEstadoEvaluacion,
      bloquearFormularioPorProyectoCerrado,
      proyectoEnEstadoRecepProyecto
    } = useGestionarEstados();
    
    const formularioBloqueado = 
        (bloquearFormularioProyectoPorEstadoEvaluacion() ||  bloquearFormularioPorProyectoCerrado() );
    const {usuarioEsAdmin, usuarioEsDueñoDelProyecto} = useGestionarUsuario();

    const handleAgregarIntegrante = (e)=>{
      e.preventDefault();

      if( (!usuarioEsAdmin()) && (!usuarioEsDueñoDelProyecto())){
          alert('No posee permisos para realizar esta acción');
          return;
      }
  
      if(bloquearFormularioProyectoPorEstadoEvaluacion()){
          alert('No posee permisos para realizar esta acción');
          return;
      }
    
      dispatch( abrirModalEquipoExtension() );
      
     
    }


    const hanldeEditarIntegrante = (e,_indice)=>{
        e.preventDefault();
        const integranteAEditar =  IntegranteEquipos[_indice];

        if( integranteAEditar && integranteAEditar.lrol && integranteAEditar.lrol.some(rol => rol === 'esp') ){
            alert('Elimine al integrante del listado de especialista/s asociado/s antes de editar, luego podrá volver a asignarlo si es necesario');
            return ;
        }

        if( (!usuarioEsAdmin()) && (!usuarioEsDueñoDelProyecto()) ){
                
            alert('No posee permisos para realizar esta acción');
            return;
        }
        
        if(bloquearFormularioProyectoPorEstadoEvaluacion()){
            alert('No posee permisos para realizar esta acción');
            return;
        }
        dispatch( startSelecIntegEquipo(_indice) )
        dispatch( abrirModalEquipoExtension() )
    }
    
    const handleBorrarIntegrante = (e,dni)=>{
        e.preventDefault() ;

        if( (!usuarioEsAdmin()) && (!usuarioEsDueñoDelProyecto()) ){
                
            alert('No posee permisos para realizar esta acción');
            return;
        }
        
        if(bloquearFormularioProyectoPorEstadoEvaluacion()){
            alert('No posee permisos para realizar esta acción');
            return;
        }
        
        const integranteABorrar =  IntegranteEquipos.find( integ => integ.dni === dni );

        if( integranteABorrar && integranteABorrar.lrol && integranteABorrar.lrol.some(rol => rol === 'dir')) {
            alert(' El/la integrante está asignado/a como director/a ');
            return;
        }
        if( integranteABorrar && integranteABorrar.lrol && integranteABorrar.lrol.some(rol => rol === 'finan')) {
            alert(' El/la integrante está asignado/a como responsable financiero/a ');
            return;
        }
        if( integranteABorrar && integranteABorrar.lrol && integranteABorrar.lrol.some(rol => rol === 'codir')) {
            alert(' El/la integrante está asignado/a como codirector/a ');
            return;
        }
        if( integranteABorrar && integranteABorrar.lrol && integranteABorrar.lrol.some(rol => rol === 'coord')) {
            alert(' El/la integrante está asignado/a como coordinador/a ');
            return;
        }
        
        if( integranteABorrar && integranteABorrar.lrol && integranteABorrar.lrol.some(rol => rol === 'esp')) {
            alert(' El/la integrante está asignado/a como especialista asociado/a ');
            return;
        }

        mostrarAlerta('¿Desea eliminar el integrante?','Eliminar',true)
            .then( resp => resp.isConfirmed && 
                dispatch( startEditarFormProyecto({
                    EquipoExtension : {
                        ...EquipoExtension,
                        IntegranteEquipos : IntegranteEquipos.filter( integ => integ.dni !== dni )
                    }
                }))
            );
        
    }

    


  return {
    formularioBloqueado,
    handleAgregarIntegrante,
    hanldeEditarIntegrante,
    handleBorrarIntegrante,
    IntegranteEquipos,
    lRolInteg,
    usuarioEsAdmin,
    usuarioEsDueñoDelProyecto,
    showMsgError,
    proyectoEnEstadoRecepProyecto
  }
}
