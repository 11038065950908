import { types } from "../types/types";
import { startFinishLoadingForm, startLoadingForm } from "./ui";


export const startMostrarEvaluacionIpPE = (idForm = null)=>{
    return async(dispatch)=>{
        dispatch(startLoadingForm())
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/eippe/data/${idForm}`)
     
        const data = await resp.json()
         
         if(data.ok){
             dispatch(setEvaluacionIpPE(data.data));
             dispatch(startFinishLoadingForm())
         }else{
             alert(data.error);
         }
     }
}

export const startEditarEvaluacionIpPE = (campos = {})=>{
    return async(dispatch)=>{
        dispatch(editarEvaluacionIpPE(campos));
    }
}

export const startGuardarEvaluacionIpPE = (_idForm = null)=>{
    return async(dispatch,getState)=>{
        const {
            item1,
            item2,
            item3,
            tipoPertinencia,
            
        } = getState().eippeReducer.eippeFocus;

        const {id : idUsr} = getState().usrReducer.usrFocus;
        const {id : idProy} = getState().pReducer.pFocus;
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/eippe/`,{
            method : 'PUT',
            headers: {
                'Content-Type' : 'application/json'
            },
            body :JSON.stringify({
                idUsr : idUsr,
                id : _idForm,
                idProy : idProy,
                dataEvaluacion : {
                    item1,
                    item2,
                    item3,
                    tipoPertinencia
                }
            })
        });

        const data = await resp.json();

        setTimeout(() => {
            if(data.ok) {
                alert('datos guardados con exito');
            } else {
                alert(data.error);
            } 
        });
    }
}

const setEvaluacionIpPE = (data ={})=>({
    type : types.eippeGetData,
    payload : data
})

const editarEvaluacionIpPE = (data ={})=>({
    type : types.eippeEditar,
    payload : data
})

export const resetEippe = ()=>({
    type : types.eippeReset
})