import { useEquipoExtension } from '../../hooks/useEquipoExtension';
import { useForm } from '../../../../../../hooks/useForm';

export const useListaAsigRolEquipo = () => {

    const {IntegranteEquipos,lRolInteg,formularioBloqueado} = useEquipoExtension();

    const etiquetasIntegrantes = IntegranteEquipos 
        ? IntegranteEquipos.map( (integ) => ({ id : integ.dni , nom : `${integ.dni}-${integ.ape},${integ.nom}`  }) )
        : [];

    const filtrarIntegrantesNoEstudiantes = ()=> IntegranteEquipos 
        ? IntegranteEquipos
            .filter( integ => (!integ.periodoLectivo) )
            .map( (integ) => ({ id : integ.dni , nom : `${integ.dni}-${integ.ape},${integ.nom}`  }) )
            .sort( (opc1,opc2) => opc1.nom.localeCompare(opc2.nom) )
        : []; 


    const asginarIntegrante = (rol = {id: null})=>
        (IntegranteEquipos && IntegranteEquipos.length > 0) 
            ? {...IntegranteEquipos.find( integ => integ.lrol && integ.lrol.find( _rol => _rol === rol.codigo ))}.dni
            : ''

    const asignarEspecialistas = ()=>(IntegranteEquipos && IntegranteEquipos.length > 0) 
    ? [...IntegranteEquipos.filter( integ => integ.lrol && integ.lrol.find( _rol => _rol === 'esp' )).map( integ => integ.dni)]
    : []


    const rolesAsignados = lRolInteg 
        ? lRolInteg
                .filter( rol => ['esp','vol','becar'].every( r => rol.codigo !== r)  )
                .reduce( (roles,rol)=>({
                    ...roles , 
                    [rol.codigo] : asginarIntegrante(rol) 
                    }) ,
                    { esp : asignarEspecialistas() }
                )
        : {};

   

    const {
        campos,
        validaciones,
        handleCampoChange,
        validarCampo
        } = useForm(rolesAsignados, 'rol');


  return {
    etiquetasIntegrantes,
    rolesAsignados,
    campos,
    validaciones,
    lRolInteg,
    formularioBloqueado,
    filtrarIntegrantesNoEstudiantes,
    handleCampoChange,
    validarCampo
  }
}
