
import { mostrarAlerta } from "../helpers/mostrarAlerta";
import { types } from "../types/types";
import { 
    finalizarCargaInfAvance, 
    finalizarCargaInfFinal, 
    finalizarCargaListaINfAv, 
    finalizarCargaListaINfFinal, 
    startFinishSavingForm, 
    startSavingForm 
} from "./ui";


export const startGetListaInforme = (esFinal = false)=>{
    return async(dispatch,getState)=>{
        const {id} = getState().pReducer.pFocus;
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/informes/${esFinal ? 'if' : 'ia'}/all/${id}`)

        const data = await resp.json();

        if(data.ok) {
            if(esFinal) {
                dispatch( getListaInfFinal(data.data) );
                dispatch(finalizarCargaListaINfFinal());
            } else {
                dispatch( getListaInfAv(data.data) );
                dispatch(finalizarCargaListaINfAv());
            }
          
        }
    }
}
export const startMostrarInforme = (esFinal = false)=>{
    return async(dispatch,getState)=>{
        console.log('mostrandoInforme')
        const {listaInfAv} = getState().infAvReducer;
        const {listaInfFinal} = getState().infFinalReducer;
        
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/informes/${esFinal ? 'if' : 'ia'}/${ esFinal ? listaInfFinal[0].id : listaInfAv[0].id}`)

        const data = await resp.json();

        if(data.ok) {
            if(esFinal) {
                dispatch(  setInformeFinal(data.data));
                dispatch(finalizarCargaInfFinal());
            } else {
                dispatch( setInformeAvance(data.data));
                dispatch(finalizarCargaInfAvance());
            }
            
        } else {
            alert(data.error);
        }

    }
}
export const startPostInforme = (esFinal = false)=>{
    return async(dispatch,getState)=>{

        dispatch(startSavingForm());

        const {pFocus} = getState().pReducer;
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/informes/${esFinal ? 'if' : 'ia'}/${pFocus.id}`,{
            method : 'POST',
            headers: {
                'Content-Type' : 'application/json'
            }
        });

        const data = await resp.json();

        if(data.ok) {
           
            mostrarAlerta('Informe avance guardado!','Guardar Informe de Avance',false).then( ()=> {
                dispatch(startFinishSavingForm());
            });
           
        } else {
            mostrarAlerta(data.error,'Error',false).then(()=> dispatch(startFinishSavingForm()));
           
        }
    }
}
export const startPutInforme = (esFinal = false)=>{
    return async(dispatch,getState)=>{

        dispatch(startSavingForm());

        const infData = esFinal ?getState().infFinalReducer.infFinalFocus :getState().infAvReducer.infAvFocus;

        const resp = await fetch(`${process.env.REACT_APP_API_URL}/informes/${esFinal ? 'if' : 'ia'}`,{
            method : 'PUT',
            headers: {
                'Content-Type' : 'application/json'
            },
            body :JSON.stringify(infData)
        });

        const data = await resp.json();

       

        if(data.ok) {
           
            mostrarAlerta('Informe  guardado!','Guardar Informe ',false).then( ()=> {
                dispatch(startEditarInforme(data.data,esFinal));
                dispatch(startFinishSavingForm());
                
            });
           
        } else {
            mostrarAlerta(data.error,'Error',false).then(()=> dispatch(startFinishSavingForm()));
           
        }
    }
}

export const setIdObjEspModalActCargdas = (idObjEsp )=>({
    type : types.uiSetIdObjEspModalActCargadas,
    payload : idObjEsp
})

export const startAltaActividadInforme = (dataAct, esFinal = false  )=> 
    async(dispatch,getState)=>{

        try {
            const {infAvFocus} = getState().infAvReducer;

            const objetivosEspecificos = infAvFocus.CoherenciaInterna.ObjetivoEspecificos;

            const {idObjEspFocus,...restDataAct} = dataAct;

            let iObjEsp = objetivosEspecificos.find( objEsp => objEsp.id === idObjEspFocus ) ;

            if(!iObjEsp){ throw new Error( `no se encontro obj de id ${idObjEspFocus}`)}
            
            iObjEsp.ActividadObjetivos = [...iObjEsp.ActividadObjetivos, restDataAct];
            
            dispatch( 
                startEditarInforme( {
                    ...infAvFocus,
                    CoherenciaInterna : {
                        ...infAvFocus.CoherenciaInterna,
                        objetivoEspecificos : objetivosEspecificos.map( objEsp => objEsp.id === idObjEspFocus ? iObjEsp : objEsp)
                    }
                },esFinal )
            );
        } catch (error) {
            console.log(error);
            
        }

}

export const startAltaBecarioInforme = ( dataBecario, esFinal = false )=>{
    return async(dispatch,getState)=> {
        const informe = esFinal ? getState().infFinalReducer.infFinalFocus : getState().infAvReducer.infAvFocus;
        const { Becarios } = informe;
        const becario = Becarios.find( b => b.Persona.dni === dataBecario.Persona.dni);
        
        if(becario) {
            dispatch( 
                startEditarInforme( {
                    ...informe,
                    Becarios : informe.Becarios.map( b => b.Persona.dni === dataBecario.dni ? {...b , ...dataBecario }: b)
                },esFinal )
            );
    
        } else {
            dispatch( 
                startEditarInforme( {
                    ...informe,
                    Becarios : [
                        ...informe.Becarios,
                        {...dataBecario}
                    ]
                },esFinal )
            );
        }
        


    }
}
export const startAltaInstInforme = (dataInst , esFinal = false) => {
    return (dispatch,getState)=>{
        const dataInforme = esFinal ? getState().infFinalReducer.infFinalFocus : getState().infAvReducer.infAvFocus;
        const {pFocus} = getState().pReducer;
        
        const {
            id,
            esNueva,
            nomInst,
            domInst,
            emailInst,
            telInst,
            geolInst,
            ...dataP
        } = dataInst;

        const Institucion = {
            id : id,
            nom : nomInst ,
            dom : domInst ,
            email : emailInst ,
            tel : telInst ,
            geolocalizacion : geolInst ,
        };
        const Responsable = {
            Persona_id: 0,
            dni : dataP.dni,
            ape : dataP.ape,
            nom : dataP.nom,
            tel : dataP.tel,
            email : dataP.email,
            dom : dataP.dom,
            inicioGestion : dataP.iniGestion,
            finGestion : dataP.finGestion,
        }
        const data = { 
            ...dataInforme, 
            InstitucionIntervinientes : [
                ...dataInforme.InstitucionIntervinientes ,
                { 
                    esNueva : esNueva,
                    Institucion , 
                    Responsable, 
                    Institucion_id : Institucion.id,
                    FormularioProyecto_id : pFocus.FormularioProyecto_id  
                }
            ]}


        dispatch( startEditarInforme( data,esFinal ) )
    }
}
export const startEditarInforme = (data,esFinal = false)=>{
    return async(dispatch)=>{
        if(esFinal) {
           dispatch(editarInfFinal(data))
        }else {
           dispatch(editarInfAvance(data))
        } 
          
       
    }
}

export const startAltaPartDirectInforme = (data,esFinal)=>{
    return async( dispatch, getState )=>{
   
        const dataInforme = esFinal ? getState().infFinalReducer.infFinalFocus : getState().infAvReducer.infAvFocus;

        const {ParticipanteDirectos} = dataInforme;

        const participante = {
            id : data.id,
            nom : data.nom,
            observ : '',
            ActividadParticipantes : data.actividades.map( a =>( {...a, valoracion : '',ParticipanteDirecto_id : data.id}))
        }

        dispatch( 
            startEditarInforme(
                {
                    ...dataInforme,
                    ParticipanteDirectos : [...ParticipanteDirectos, participante]
                } ,
                esFinal
            ));
    }
}

export const startBajaBecarioInforme = (_indice, esFinal)=>{
    return async(dispatch,getState)=> {
        const informe = esFinal ? getState().infFinalReducer.infFinalFocus : getState().infAvReducer.infAvFocus;
        const becario = informe.Becarios[_indice];
        dispatch( 
            startEditarInforme( {
                ...informe,
                Becarios : informe.Becarios.map( b => b.Persona.dni !== becario.dni)
            },esFinal )
        );
        


    }
}

export const resetInfFinal = ()=>({
    type : types.infFinalReset
});


export const resetInfAv = ()=>({
    type : types.infAvReset
});

const getListaInfAv = ( lista  ) => ({
    type : types.infAvGetLista,
    payload : lista
})
const getListaInfFinal = ( lista  ) => ({
    type : types.infFinalGetLista,
    payload : lista
})
const editarInfAvance = ( data )=>({
    type : types.infAvEditar,
    payload : data
})
const editarInfFinal = ( data )=>({
    type : types.infFinalEditar,
    payload : data
})
const setInformeAvance = (data)=>({
    type : types.infAvGetData,
    payload : data
})
const setInformeFinal = (data)=>({
    type : types.infFinalGetData,
    payload : data
})