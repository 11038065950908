import { types } from "../types/types";


const initialState = {
    listaInfFinal : [],
    infFinalFocus : {
        tituloProy : '',
        intedisciplinaridad : null,
        integralidad : null,
        politicasPublicasEstablecidas : null,
        materialesEducativos : null,
        estrategiasComunicacion : null,
        observInstituciones : null,
        observParticipantesDirectos : null,
        observGral : null,
        valoracionGral : null,
        ProgramaExtension_id_ProgramasExtensions : null,
        CoherenciaInterna : {
            ObjetivoEspecificos : []
        },
        InstitucionIntervinientes : [],
        EquipoExtension : {
            IntegranteEquipos : []
        },
        director : {
            ape : '',
            nom : ''
        }
    }
}

export const infFinalReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.infFinalGetLista : 
            return {
                ...state ,
                listaInfFinal : action.payload
            }
       
        case types.infFinalGetData : 
            return {
                ...state,
                infFinalFocus : {...action.payload}
            }
        case types.infFinalCrear : 
            return {
                ...state,
                listaInfFinal : [...state.listaInfFinal,action.payload]
            }
        case types.infFinalEditar : 
            return {
                ...state,
                infFinalFocus : {
                    ...state.infFinalFocus,
                    ...action.payload
                }
            }
        case types.infFinalReset : 
            return {
                ...state,
                infFinalFocus : initialState.infFinalFocus
            };
        default:
            return state;
    }

}
