import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { abrirModalVistaPrevia } from '../../actions/ui'

import {faDownload, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startDescargarDocumento } from '../../actions/bases';
import { useGestionarEstados } from '../../hooks/useGestionarEstados';
import { mostrarAlerta } from '../../helpers/mostrarAlerta';



export const FormularioFirmaDigital = ({_tipoForm =''}) => {
    
    const dispatch = useDispatch();

    const {documentos} = useSelector(state => state[`${_tipoForm}Reducer`][`${_tipoForm}Focus`]);

    const {bloquearEvaluacionPorInstanciaFueraDeEvaluacion} = useGestionarEstados()

    const handleDownloadFirma = (e)=>{
        e.preventDefault();
        if(documentos.length > 0){
            
            dispatch(startDescargarDocumento( documentos[0].codigo,documentos[0].fileName ))
        }
    }

    const handleAdjuntarFirmaDigital = (e)=>{
        e.preventDefault();

        if(bloquearEvaluacionPorInstanciaFueraDeEvaluacion()){
            mostrarAlerta('El proyecto ya no se encuentra en evaluación','',false);
            return;
        }

        (documentos && documentos.length < 1) && dispatch(abrirModalVistaPrevia())
    }

  return (
    <fieldset>
        <hr className='mt-3' />
        <h4 className='text-center'><b>Cargar firma digital</b></h4>
        <div className='row gx-3 row-cols-2 mb-4 d-flex justify-content-between'>
            {
                documentos &&
                <>
                <div className={`col-4 d-flex align-items-center justify-content-center ${(documentos && documentos.length > 0 ) ? '' : 'btn btn-outline-primary'}`} >
                <FontAwesomeIcon 
                    size={'3x'} 
                    icon={faSave} 
                    title='Subir archivo ( solo se permite ".jpg")' 
                    onClick={  handleAdjuntarFirmaDigital} 
                    color = { documentos && documentos.length > 0 ? 'green' : ''}
                />
                { (documentos && documentos.length > 0) && <p className='ms-3'>Documento firmado.</p> }
            </div>
        
                <div className={`col-4 d-flex align-items-center justify-content-center ${(documentos && documentos.length > 0) ? 'btn btn-outline-primary' : ''}`} 
                
                >
                    <FontAwesomeIcon 
                        size={'3x'} 
                        icon={faDownload} 
                        title='Descargar firma digital' 
                        onClick={ handleDownloadFirma }
                    />
                    
                    
                </div>
                </>
            }
        </div>
    
</fieldset>
  )
}
