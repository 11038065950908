import React from 'react';
import ReactDOM from 'react-dom';

import 'animate.css';
import './styles/custom.css';

import {App} from './App';



ReactDOM.render(<App />,document.getElementById('root'));
