import React from 'react'
import { useSelector } from 'react-redux'

export const CabeceraEvaluacion = ({titulo = '',codigo = '',duracion=''}) => {

  const  { pcalFocus } = useSelector( state => state.pcalReducer)
  const  { lFormsEval } = useSelector( state => state.pReducer.pFocus)
  
  const evaluadoresCalidad = pcalFocus 
    ? lFormsEval.filter( formEval => formEval.tipo.includes('cal') ).map( formEval => formEval.evaluador)
    : undefined

  const verEvalInterno = (evaluadores)=> {
     const evaluador = evaluadores.find( _eval => _eval.tipoUsuario.includes('eval_int'));
     return `${evaluador.ape}, ${evaluador.nom}`
  };

  const verEvalExterno1 = (evaluadores)=> {
    const evaluador = evaluadores.find( _eval => _eval.tipoUsuario === 'eval_ext');
    return `${evaluador.ape}, ${evaluador.nom}`
  };

  const verEvalExterno2 = (evaluadores)=> {
    const evaluador = evaluadores.at(evaluadores.lastIndexOf( _eval => _eval.tipoUsuario === 'eval_ext'));
    return `${evaluador.ape}, ${evaluador.nom}`
  };

  return (
    <fieldset className='col p-1'>
        <ul className='list-group list-group-flush'>
              <>
                <li className='list-group-item '> <span className='fw-bold'>Título :     </span> {titulo}   </li>
                <li className='list-group-item '> <span className='fw-bold'>UU.AA. :     </span> {codigo.split('-')[3]}  </li>
                <li className='list-group-item '> <span className='fw-bold'>Modalidad : </span>  {codigo.split('-')[2]}  </li>
              </>
          {
            pcalFocus 
            ? 
             <>
                <li className='list-group-item '> <span className='fw-bold'>Duración :     </span> {duracion}   </li>
                {
                  evaluadoresCalidad
                  ?
                  <>
                    <li className='list-group-item '> <span className='fw-bold'>Evaluador Interno :  </span> {verEvalInterno(evaluadoresCalidad)}  </li>
                    <li className='list-group-item '> <span className='fw-bold'>Evaluador Externo 1: </span>  {verEvalExterno1(evaluadoresCalidad)}  </li>
                    <li className='list-group-item '> <span className='fw-bold'>Evaluador Externo 2: </span>  {verEvalExterno2(evaluadoresCalidad)}  </li>
                    
                  </>
                  :undefined
                }
               
             </>
            :
              undefined
              
          }
            
        </ul>
    </fieldset>
  )
}
