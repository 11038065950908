
import { useDispatch, useSelector } from 'react-redux';
import { startGuardarUltimaSeccion } from '../../../../actions/formp';
import { mostrarAlerta } from '../../../../helpers/mostrarAlerta';
import { useForm } from '../../../../hooks/useForm';
import { useGestionarEstados } from '../../../../hooks/useGestionarEstados';
import { useGestionarUsuario } from '../../../../hooks/useGestionarUsuario';

export const useFormProyectoSec4 = () => {

    const dispatch = useDispatch();
    const {pFocus} = useSelector( state => state.pReducer ) || {};
    const {lMaterialesEducativos,lIntegracion,lInstanciasCapacitacion} = useSelector( state => state.basesReducer );
    
    const {
        accionesComunicacion,
        MaterialEducativo_id_MaterialEducativos,
        tipoIntegracion,
        integracionDesc,
        InstanciaCapacitacion_id_InstanciaCapacitacions,
        sustentabilidad,
        sintesis

    } = pFocus ? pFocus : {}

    const {
        bloquearFormularioProyectoPorEstadoEvaluacion,
        bloquearFormularioPorProyectoCerrado
      } = useGestionarEstados();
    
    const {usuarioEsAdmin , usuarioEsDueñoDelProyecto} = useGestionarUsuario();


    const formularioBloqueado = ( bloquearFormularioProyectoPorEstadoEvaluacion() || bloquearFormularioPorProyectoCerrado()  );

    const {
        campos,
        validaciones,
        handleCampoChange,
        validarCampo,
        validarForm
      } = useForm({
        accionesComunicacion : accionesComunicacion,
        MaterialEducativo_id_MaterialEducativos : MaterialEducativo_id_MaterialEducativos,
        tipoIntegracion : tipoIntegracion,
        integracionDesc : integracionDesc,
        InstanciaCapacitacion_id_InstanciaCapacitacions : InstanciaCapacitacion_id_InstanciaCapacitacions,
        sustentabilidad : sustentabilidad,
        sintesis : sintesis

      },'p');

      const handleSubmitUltimaSeccion = (e)=>{
        e.preventDefault();
        if( (!usuarioEsAdmin()) && (!usuarioEsDueñoDelProyecto()) ){
                
            alert('No posee permisos para realizar esta acción');
            return;
        }
    
        if(bloquearFormularioProyectoPorEstadoEvaluacion()){
            alert('No posee permisos para realizar esta acción');
            return;
        }
        if( validarForm(
            [
                'accionesComunicacion',
                'MaterialEducativo_id_MaterialEducativos',
                'tipoIntegracion',
                'integracionDesc',
                'InstanciaCapacitacion_id_InstanciaCapacitacions',
                'sustentabilidad',
                'sintesis',
            ]
        ))  {

            mostrarAlerta('¿Desea guardar la sección ?','Guardar Sección',true)
            .then( resp => {
              if( resp.isConfirmed){
                
            dispatch(startGuardarUltimaSeccion());


              }
            } )
        } else {
            alert(
                Object.keys(validaciones).map(
                    validacion => `${validaciones[validacion].msgError 
                                    ? `${validacion}:${validaciones[validacion].msgError}`
                                    : ''
                                }` 
                    ).join('\n')
            );
        }
        
      }
  return {
    handleSubmitUltimaSeccion,
    campos,
    validaciones,
    handleCampoChange,
    validarCampo,
    lMaterialesEducativos,
    lIntegracion,
    lInstanciasCapacitacion,
    formularioBloqueado,
    pFocus
  }
}
