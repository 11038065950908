import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { startAltaInstInforme } from '../../actions/informe'
import { cerrarModal } from '../../actions/ui'
import { startSelecInstInterv } from '../../actions/bases'




export const ModalInformeInstitucionNueva = ({esFinal}) => {
    const dispatch =  useDispatch();
    const {instIntervSelec,lInstitucionesCargadas} = useSelector( state => state.basesReducer);
    const {InstitucionIntervinientes} = useSelector( state => esFinal ? state.infFinalReducer.infFinalFocus : state.infAvReducer.infAvFocus);

    const [filtro,setFiltro] = useState([]);

    const [matchInst, setMatchInst] = useState(null);

    

    const [formFields, setFormFields] = useState({
        id: 0,
        esNueva : true,
        nomInst : '',
        domInst : '',
        emailInst : '',
        telInst : '',
        geolInst : '',
        dni : '',
        ape : '',
        nom : '',
        tel : '',
        email : '',
        dom : '',
        iniGestion : '',
        finGestion : '',
    });

    useEffect(() => {

        if(formFields.nomInst !== '' ){
           setFiltro(lInstitucionesCargadas.filter( i => i.nom.includes(formFields.nomInst)));
        }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [formFields.nomInst])

    useEffect(() => {
      if(instIntervSelec !== null) {
        setFormFields(InstitucionIntervinientes[instIntervSelec]);
      }
    
      return () => {
        setFormFields(formFields);
      }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instIntervSelec]);
    
    useEffect(() => {
      if(matchInst !== null){
        setFormFields({
            ...formFields,
            id : matchInst.id,
            nomInst : matchInst.nom,
            domInst : matchInst.dom,
            emailInst : matchInst.email,
            telInst : matchInst.tel,
            geolInst : matchInst.geol,
        });
      }
    
      return () => {
        setFormFields(formFields);
      }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchInst])
    

    
    
    
    const handleChangeNomInst = (e)=>{
        e.preventDefault();
        
        const match =Array.from(document.getElementById('lInstitucionesCargadas').options).find(o => o.value === e.currentTarget.value);
        if(match) {
            setMatchInst(filtro.find( i => i.nom === match.value));
        }
        setFormFields({...formFields,nomInst : e.currentTarget.value});

    }

    const handleChange = (e)=>{
        e.preventDefault();
        setFormFields({
            ...formFields,
            [e.currentTarget.name] : e.currentTarget.value
        });

    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        
        dispatch( startAltaInstInforme(formFields,esFinal) );
        dispatch( startSelecInstInterv(null));
        dispatch( cerrarModal());
    }

    const handleCancelar = (e)=>{
        e.preventDefault();
        dispatch( startSelecInstInterv(null));
        dispatch(cerrarModal());
    }
    const handleAbrirEnlace = (e)=>{
        e.preventDefault();
        window.open(e.target.href,'_blank');
    
    }


   
  return (
    <div className='container-md' style={{maxWidth : '50em'}}>
        <form onSubmit={handleSubmit}>
           
            <div className='row row-cols-1'>
                <div className='col mb-3'>
                    <fieldset >
                    <legend > Datos de la institución</legend>
                    <div className='row row-cols-1'>
                         <div className='col'>
                            <div className='input-group'>
                                <span className='input-group-text' style={{ width : '6em',  textAlign : 'left',fontSize : '0.8em'}}> NOM </span>
                                <input className='form-control form-control-sm' list={'lInstitucionesCargadas'} id='nomInst' value={formFields.nomInst} name='nomInst' type={'search'} onChange={handleChangeNomInst}  required={true}/>
                                <datalist id="lInstitucionesCargadas" >
                                        {
                                          filtro.map( i => <option value={i.nom} >{i.nom}</option>)
                                        }
                                    </datalist>
                                
                            </div>
                        </div>
                        <div className='col mt-2'>
                            <div className='row '>
                                <div className='col-5'>
                                    <div className='input-group'>
                                        <span className='input-group-text' style={{ width : '6em',  textAlign : 'left',fontSize : '0.8em'}}> DOM </span>
                                        <input className='form-control form-control-sm' type='text' value={formFields.domInst} id='domInst' name='domInst' onChange={handleChange}  required={true}/>
                                    </div>
                                    <div className='input-group'>
                                        <span className='input-group-text' style={{ width : '6em',  textAlign : 'left',fontSize : '0.8em'}}> TEL </span>
                                        <input className='form-control form-control-sm' type='text' value={formFields.telInst} id='telInst' name='telInst' onChange={handleChange} required={true} />
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='input-group' >
                                        <span className='input-group-text' style={{ width : '6em',  textAlign : 'left',fontSize : '0.8em'}} htmlFor='domInst'> EMAIL </span>
                                        <input className='form-control form-control-sm' type='text' value={formFields.emailInst} id='emailInst' name='emailInst' onChange={handleChange} required={true} />
                                    </div>
                                    <div className='input-group' >
                                        <span className='input-group-text' style={{ width : '6em',  textAlign : 'left',fontSize : '0.8em'}} htmlFor='geolInst'> GEOLOC. </span>
                                        <input className='form-control form-control-sm' type='url' id='geolInst' name='geolInst' value={formFields.geolInst} onChange={handleChange} required={true} />
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-6'></div>
                                <div className='col-6'>
                                <small className='text-secondary text-small'>
                                    Utilice la herramienta de <a onClick={handleAbrirEnlace} href='https://www.google.com.ar/maps'>Google Maps</a>  para insertar la geolocalización.
                                     Si necesita ayuda , consulte <a onClick={handleAbrirEnlace} href='https://www.youtube.com/watch?v=KoN9aRs6a4E'>en este video.</a>
                                </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    </fieldset>
                  
                </div>
                <div className='col border-top'>
                    <fieldset >
                        <legend > Responsable a cargo </legend>
                        <div className='row row-cols-1'>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-4'>
                                    <div className='input-group'>
                                        <span className='input-group-text' style={{ width : '4.5em',  textAlign : 'left',fontSize : '0.8em'}} htmlFor='dni'> DNI </span>
                                        <input id={'dni'} className='form-control form-control-sm' name='dni' onChange={handleChange} type='text' value={formFields.dni} minLength={1} maxLength={8} required={true} style={{maxWidth: '13em'}} />
                                    </div>
                                </div>
                                <div className='col'>
                                 <small className='text-secondary'> dni sin puntos, con 0 adelante si tiene 7 dígitos</small>
                                </div>
                            </div>
                            
                         
                           
                        </div>
                        <div className='col'>
                            <div className='row '>
                                <div className='col-6'>
                                     {/* <small className='text-secondary'>mínimo 3 caracteres.</small> */}
                                    <div className='input-group'>
                                        <span className='input-group-text' style={{ width : '4.5em', textAlign : 'left', fontSize : '0.8em'}} htmlFor='ape'> APE </span>
                                        <input id={'ape'} className='form-control form-control-sm' name='ape' onChange={handleChange} type='text' value={formFields.ape} minLength={1} maxLength={150} required={true}/>
                                    </div>
                                    {/* <small className='text-secondary'>mínimo 3 caracteres.</small> */}
                                    <div className='input-group'>
                                        <span className='input-group-text' style={{ width : '4.5em', textAlign : 'left', fontSize : '0.8em'}} htmlFor='nom'> NOM </span>
                                        <input id={'nom'} className='form-control form-control-sm' name='nom' onChange={handleChange} type='text' value={formFields.nom} minLength={1} maxLength={150}required={true}/>
                                    </div>
                                
                                    <div className='input-group'>
                                        <span className='input-group-text' style={{ width : '4.5em', textAlign : 'left', fontSize : '0.8em'}} htmlFor='nom'> TEL </span>
                                        <input id={'tel'} className='form-control form-control-sm' name='tel' onChange={handleChange} type='text' value={formFields.tel} maxLength={150} />
                                    </div>
                                    <div className='input-group'>
                                        <span className='input-group-text' style={{ width : '4.5em', textAlign : 'left', fontSize : '0.8em'}}  htmlFor='email'> EMAIL </span>
                                        <input id={'email'} className='form-control form-control-sm' name='email' value={formFields.email}  onChange={handleChange} max={250} minLength={1} type='email' />
                                    </div>
                                    <div className='input-group'>
                                        <span className='input-group-text' style={{ width : '4.5em', textAlign : 'left', fontSize : '0.8em'}}  htmlFor='dom'> DOM </span>
                                        <input id={'dom'} className='form-control form-control-sm' name='dom' onChange={handleChange} type='text' value={formFields.dom}  maxLength={150}  />
                                    </div>
                                </div>
                                <div className='col'>
                                    <div >
                                        <label className='form-label' style={{  textAlign : 'left', fontSize : '0.8em'}}  htmlFor='iniGestion'> INICIO DE GESTIÓN </label>
                                        <input id={'iniGestion'} className='form-control form-control-sm' name='iniGestion' onChange={handleChange} value={formFields.iniGestion}  type='date'  />
                                    </div>
                                    <div >
                                        <label className='form-label' style={{ textAlign : 'left', fontSize : '0.8em'}}  htmlFor='finGestion'> FINALIZACIÓN DE GESTIÓN</label>
                                        <input id={'finGestion'} className='form-control form-control-sm' name='finGestion' value={formFields.finGestion} onChange={handleChange} type='date'  />
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        </div>
                    </fieldset>
                </div>
                <div className='col mt-2 p-2 border-top d-flex justify-content-center'>
                    <input className='btn btn-primary ps-3 pe-3' type='submit' value={'Agregar'}/>
                    <button className='btn btn-outline-secondary' onClick={handleCancelar}>Cancelar</button>
                </div>
            </div>
           
        </form>
    </div>
  )
}
