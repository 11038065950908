import React from 'react'
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useEffect } from 'react';
import { useState } from 'react'
import { useDispatch } from 'react-redux';
import { startAltaPartDirectInforme } from '../../actions/informe';
import { cerrarModal } from '../../actions/ui';


export const FormActividad = ({ 
  
  desc, 
  index , 
  handleChange = ()=>{},
  handleDeleteActividad = ()=>{} 
})=>
  <div className='col'>
        <div className='input-group'>
          <span className='input-group-text'>{`#${index+1}`}</span>
          <span className='input-group-text'>DESCRIP. :</span>
          <textarea className='form-control' 
            id={`act-${index}`} 
            name={`desc-${index}`} 
            value={desc} 
            onChange={handleChange}
            rows={3}
            required={true}
          />
          <span className='input-group-text btn btn-danger' onClick={e => handleDeleteActividad(e,index)}><FontAwesomeIcon icon={faTrash}/></span>
        </div>
      </div>

export const ModalPartDirectInforme = () => {
  const dispatch = useDispatch();
  const [cFormActividad, setcFormActividad] = useState([]);
  const [showError, setShowError] = useState(false);
  const [formFields, setFormFields] = useState({
    id : 0,
    nom : '',
    actividades : []
  });

  const handleAddFormActividad = (e)=>{
    e.preventDefault();

    setFormFields({
      ...formFields,
      actividades : [...formFields.actividades, { id: 0, desc : '' }]
    })

  }

  const handleDeleteActividad = (e,index)=>{
    e.preventDefault();
    setFormFields({
      ...formFields, 
      actividades : formFields.actividades.slice(0,index).concat( formFields.actividades.slice(index+1))
    });
  }

  const handleChange = (e)=>{
    e.preventDefault();
    if(    e.currentTarget.name === 'nom') {
      setFormFields({...formFields, nom : e.currentTarget.value});
    }
    if(e.currentTarget.name.includes(`desc`)){
      setFormFields({
        ...formFields, 
        actividades : formFields.actividades.map( 
          (a,index)=> e.currentTarget.name.includes(index) ? { ...a, desc : e.currentTarget.value } : a 
        )})
    }
  }

  useEffect(() => { 
    setcFormActividad(
      formFields.actividades.map( (a,index) => 
        <FormActividad 
          index={index} 
          desc={a.desc}
          handleDeleteActividad={handleDeleteActividad}
          handleChange={handleChange}
        />
      )
    ) 
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFields.actividades]);

  const handleSubmit = (e)=>{
    e.preventDefault();
    if(!formFields.actividades.length){
      setShowError(true);
    } else {
      setShowError(false);
      dispatch( startAltaPartDirectInforme(formFields) );
      dispatch( cerrarModal() );
    }
   
  }
  const handleCancelar = (e)=>{
    e.preventDefault();
    dispatch( cerrarModal() );
  }
  return (
    <div className='container' style={{width : '50em'}}>
      <form onSubmit={handleSubmit}>
        <div className='row row-cols-1'>
          <div className='col  border-bottom'>
            <fieldset className='mb-2'>
              <legend>Participante Directo</legend>
              <div className='input-group'>
                <span className='input-group-text pe-2'>NOMBRE :</span>
                <input className='form-control' id='nom' name='nom' value={formFields.nom} type='text'  onChange={handleChange} required={true}/>
                <span className='input-group-text btn btn-primary' onClick={handleAddFormActividad}>+ ACTIVIDAD</span>
              </div>
            </fieldset>
          </div>
          
          <div className='col  border-bottom '>
          <fieldset className='mb-3' style={{minHeight : '22em' , overflowY : 'scroll'}} >
            <legend className='fs-5'> Actividades</legend>
            {showError && <small className='text-danger'> Debe cargar al menos una. </small>}
              <div className='row row-cols-1'>
                {
                  cFormActividad.map( item => item)
                }
              </div>
          </fieldset>
          
          </div>
          <div className='col mt-3'>
             <div className='row justify-content-center'>
              <div className='col-2'>
              <input className='btn btn-primary' type='submit' value={'Guardar'} />
              </div>
              <div className='col-2'>
                
              <button className='btn btn-outline-secondary' onClick={handleCancelar}>Cancelar</button>
              </div>
             </div>
          </div>
        </div>
      </form>
    </div>
  )
}
