import React from 'react';
import {  useSelector } from 'react-redux';
import {  Switch, Route} from 'react-router-dom';
import { SeccionFormularios } from '../../../components/PanelProyecto/SeccionFormularios/SeccionFormularios';

/**
 * 
 * @param {*} param0 _tipo = 'estandar' 'admin' 
 * @returns 
 */
export const PanelRouter = () => {


//   const {listaInfAv} = useSelector(state => state.infAvReducer);
  const { parametrosPanel } = useSelector(state => state.uiReducer);
  const { codigo, duracion : bianual } = useSelector(state => state.pReducer.pFocus);
  
  const filtrarFormulariosProyecto = () =>   parametrosPanel.find( item => item._nom === 'proyecto')._linkForm;
  const filtrarFormulariosIdeaProyecto = () =>  parametrosPanel.find( item => item._nom === 'idea_proyecto')._linkForm;
  const obtenerFormulariosInfAvance = ()=>parametrosPanel.find( item => item._nom === 'informe_avance')._linkForm;
  const obtenerFormulariosInfFinal = ()=>parametrosPanel.find( item => item._nom === 'informe_final')._linkForm;
 
  return (

        <Switch>
             <Route exact path={`/gestor/panel/ip`}>
                  <SeccionFormularios _listaFormularios={filtrarFormulariosIdeaProyecto()} />
              </Route>
              <Route exact path={`/gestor/panel/p`}>
                  <SeccionFormularios _listaFormularios={filtrarFormulariosProyecto()} />
              </Route>
             
              <Route  exact path={`/gestor/panel/inf_avance`}>
                  { codigo.includes('PEIS') && bianual &&  <SeccionFormularios _listaFormularios={obtenerFormulariosInfAvance()} />}
              </Route>
              <Route  exact path={`/gestor/panel/inf_final`}>
                  {  <SeccionFormularios _listaFormularios={obtenerFormulariosInfFinal()} />}
              </Route>
        </Switch>


  )
}
