const opciones = {
  listaUUAA :  [
        {
          id: "FICH",
          nom: "Facultad de Ingeniería y Cs. Hídricas"
        },
        {
          id: "FIQ",
          nom: "Facultad de Ingeniería Química"
        },
        {
          id: "FCA",
          nom: "Facultad de Ciencias Agrarias"
        },
        {
          id: "FCV",
          nom: "Facultad de Ciencias Veterinarias"
        },
        {
          id: "FCJS",
          nom: "Facultad de Ciencias Jurídicas y Sociales"
        },
        {
          id: "FCM",
          nom: "Facultad de Ciencias Médicas"
        },
        {
          id: "FBCB",
          nom: "Facultad de Bioquimica y Ciencias Biológicas"
        },
        {
          id: "ESS",
          nom: "Facultad de Bioquimica y Ciencias Biológicas - ESS"
        },
        {
          id: "FHUC",
          nom: "Facultad de Humanidades y Ciencias"
       },
        {
          id: "FADU",
          nom: "Facultad de Arquitectura, Diseño y Urbanismo"
        },
        {
          id: "FCE",
          nom: "Facultad de Ciencias Económicas"
        },
        {
          id: "ISM",
          nom: "Instituto Superior de Música"
        },
        {
          id: "CURA",
          nom: "Centro Universitario Reconquista-Avellaneda"
        },
        {
          id: "CUG",
          nom: "Centro Universitario Gálvez"
        },
        {
          id: "CURS",
          nom: "Sede Rafaela-Sunchalez"
        },
        {
          id: "EAGG",
          nom: "Escuela de Agronomía y Granja"
        },
        {
          id: "EIS",
          nom: "Escuela Industrial Superior"
        },
        {
          id : "ESEC",
          nom : "Escuela Secundaria UNL"
        }
      ],
    tiposUsuario : [
      {id:"admin", nom : "admin"},
      {id:"estandar", nom :"estandar"},
      {id:"eval_int",nom : "eval_int"},
      {id:"eval_ext",nom : "eval_ext"},
      {id:"eval_prog",nom : "eval_prog"}
    ]
}

export default opciones;