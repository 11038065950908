import { types } from "../types/types"

const initialState = {
    listaP : null,
    ppertFocus : null
}

export const ppertReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.eppertGetData : 
            return {
                ...state,
                ppertFocus : {...action.payload}
            }
        case types.eppertEditar : 
            return {
                ...state,
                ppertFocus : {
                    ...state.ppertFocus,
                    ...action.payload
                }
            }
        case types.eppertReset : 
            return {
                ...state,
                ppertFocus : initialState.ppertFocus
            };
        default:
            return state;
    }

}