import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startPostComision } from '../../actions/comsion';
import { useForm } from '../../hooks/useForm';
import { Campo } from '../Formulario/Campo';

export const ModalComisionEvaluadora = () => {
    const dispatch = useDispatch();
    const {listaP} = useSelector(state => state.pReducer);

    const {comisionFocus} = useSelector( state => state.comisionReducer);

    const [showMsgFormInvalido, setShowMsgFormInvalido] = useState(false);

    const {lEvaluadores} = useSelector(state => state.basesReducer);

    const { 
      campos, 
      validaciones, 
      handleCampoChange, 
      validarCampo, 
      validarForm,
  } = useForm(
        {
        listaP : comisionFocus &&  comisionFocus.listaP ?  comisionFocus.listaP.map(p => p.codigo) : '' ,
        listaEval : comisionFocus &&  comisionFocus.listaEval ? comisionFocus.listaEval.map(evaluador => evaluador.id) : '',
        tipoComision : 'CA'
        } ,'com');
  
  const listaPTolistaSelect = ()=> listaP ? listaP.listaProyectosUsuario.map( p => ({id : p.codigo, nom : `${p.codigo}-${p.titulo}`})) : [];
  

  const handleGuardarComision = (e)=>{
    e.preventDefault();
    if( 
        validarForm(['listaP','listaEval','tipoComision']) && 
        campos.listaEval.length > 2
    ){
        dispatch(startPostComision())
    } else {
        setShowMsgFormInvalido(true);
    }

  }

  
  return (
    <div className='container'>
        <div className='row row-cols-1'>
            <div className='col'>
                <h4> Comision Evaluadora </h4>
                <hr />
                {showMsgFormInvalido && <p className='alert alert-info d-flex justify-content-between p-1'>Error al guardar la comisión, revise los campos cargados y recuerde asignar mínimo 3 evaluadores. <button className='btn btn-link ' onClick={()=>setShowMsgFormInvalido(false)}>x</button></p>}
            </div>
            <div className='col'>
                <Campo 
                    etiqueta={'Seleccione los proyectos a asignar:'}
                    type='select'
                    name='listaP'
                    multiple={true}
                    handlecampochange={handleCampoChange}
                    handleblur={validarCampo}
                    required={true}
                    opciones={ listaPTolistaSelect() || []}
                    value={campos['listaP']}
                    valido={validaciones['listaP']}
                    size={10}
                    disabled={false}
                />
            </div>
            <div className='col'>
                <Campo 
                    etiqueta={'Seleccione los integrantes de la comisión'}
                    type='select'
                    name='listaEval'
                    multiple={true}
                    handlecampochange={handleCampoChange}
                    handleblur={validarCampo}
                    required={true}
                    opciones={ lEvaluadores.map(evaluador => ({id : evaluador.id , nom : `${evaluador.ape},${evaluador.nom}[${evaluador.tipo}]`})) || []}
                    value={campos['listaEval'] || []}
                    valido={validaciones['listaEval']}
                    size={10}
                    disabled={false}
                />
            </div>

            <div className='col'>
                <Campo 
                    etiqueta={'Seleccione tipo de comision'}
                    type='select'
                    name='tipoComision'
                    multiple={false}
                    handlecampochange={handleCampoChange}
                    handleblur={validarCampo}
                    required={true}
                    opciones={[
                        {id : 'CA', nom : 'Evaluacion consejo asesor'},
                        {id : 'CAL', nom : 'Evaluacion calidad'},
                        {id : 'PER', nom : 'Evaluacion pertinencia'},
                    ]}
                    value={campos['tipoComision']}
                    valido={validaciones['tipoComision']}
                    disabled={false}
                />
            </div>

            <div className='col'>
                <button className='btn btn-primary' onClick={handleGuardarComision}>Guardar cambios</button>
            </div>
        </div>
    </div>
  )
}
