import React, { useState } from 'react'

import { faEdit, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const MsgError = ()=><div className='alert alert-danger' style={{fontSize : '0.7em'}}> Debe ingresar una descripción. </div>

const FilaActividad = ({ 
    idAct = 0,
    desc = '', 
    handleGuardarAct = ()=>{}, 
    handleBorrar = ()=>{}

})=>{
        const [editando, setEditando] = useState(false);
        
        const [showMsgFormInvalido, setShowMsgFormInvalido] = useState(false);

        const [currentDesc, setCurrentDesc] = useState(desc);
        const handleChange = (e)=>{ 
            e.preventDefault();
            setCurrentDesc(e.currentTarget.value); 
        }

        const handleGuardar = (e)=>{
            e.preventDefault();
            if(currentDesc.length < 1){
                setShowMsgFormInvalido(true);
            }else {
                setShowMsgFormInvalido(false);
                handleGuardarAct(idAct,currentDesc);
                setEditando(false);
            }
        }
        return (
            <div className='row'>
                <div className='col-8'>
                <textarea  className='form-control'         
                    name='desc' 
                    type = {'text'}
                    minLength={1} 
                    maxLength={500}
                    onChange={handleChange}
                    value={currentDesc}
                    required={true} 
                    disabled={!editando}
                />
                </div>
                <div className='col-2'>
                    {showMsgFormInvalido ?  <MsgError /> : <></> }
                </div>
                <div className='col-2'>
                   <div className='row'>
                   {
                        (editando) &&
                        <div className='col-4' >
                            <button className='btn btn-outline-secondary btn-sm' style={{fontSize : '0.8em'}} 
                                onClick={handleGuardar} 
                                title='Guardar'
                            > 
                                <FontAwesomeIcon icon={faSave}  />
                            </button>
                        </div>
                    }
                     {
                        (!editando) &&
                        <div className='col-4'>
                            <button className='btn btn-outline-secondary btn-sm' style={{fontSize : '0.8em'}} 
                            title='Editar' 
                            onClick={()=>setEditando(true)} >
                                <FontAwesomeIcon icon={faEdit} />
                            </button>
                        
                        </div>
                    }
                    {
                        (!editando) &&
                        <div className='col-4'>
                            <button className='btn btn-outline-danger btn-sm' style={{fontSize : '0.8em'}} 
                            title='Eliminar' 
                            onClick={e=>handleBorrar(e,idAct)} >
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                        
                        </div>
                    }
                   </div>
                   
                </div>
                
            </div>
        )
}



export const TablaActividades = ({
    actividadesParticipante = [],
    handleGuardarAct = ()=>{},
    handleBorrarAct = ()=>{}
}) => {

   

  return (
    <div className='container' style={{ height : '15rem', overflowY : 'auto' }}>
        <div className='row bg-primary text-light'>
            <div className='col-10'>Actividad</div>
            <div className='col'>Acciones</div>
            
        </div>
        <div className='row row-cols-1'>
            {
                actividadesParticipante.map(
                    (act,indice) => 
                        <FilaActividad 
                            key={`${Date.now()}-${indice}`}
                            idAct={indice}
                            desc={act.desc}
                            handleBorrar={handleBorrarAct}
                            handleGuardarAct={handleGuardarAct}
                            
                        />
                )
            }
        </div>
    </div>
  )
}
