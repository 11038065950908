import React from 'react'
import { MenuNavegacion } from '../MenuNavegacion/MenuNavegacion'
import { faClipboardList } from '@fortawesome/free-solid-svg-icons'


export const MenuPanelProyecto = () => {
    const itemsMenu = [ 
        { ruta : '/ip', etiqueta :'IDEA PROYECTO',icono : faClipboardList , soloAdmin : false},
        { ruta : '/p', etiqueta :'PROYECTO',icono : faClipboardList , soloAdmin : false},
        { ruta : '/inf_avance', etiqueta :'INFORME AVANCE',icono : faClipboardList , soloAdmin : false},
        { ruta : '/inf_final', etiqueta :'INFORME FINAL',icono : faClipboardList , soloAdmin : false},
        // { ruta : '/eipca/:idForm' , etiqueta : '', soloAdmin : false},
        // { ruta : '/eipca_def/:idForm' , etiqueta : '', soloAdmin : false},
        // { ruta : '/eippe/:idForm' , etiqueta : '', soloAdmin : false},
        // { ruta : '/pcal/:idForm' , etiqueta : '', soloAdmin : false},
        // { ruta : '/pcal_def/:idForm' , etiqueta : '', soloAdmin : false},
        // { ruta : '/ppert/:idForm' , etiqueta : '', soloAdmin : false},
        // { ruta : '/ppert_def/:idForm' , etiqueta : '', soloAdmin : false}
    ]

  return (
    <MenuNavegacion 
        _menuInicio={false}
        _menuItems={ itemsMenu  }
    />
  )
}
