import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startEditarFormProyecto } from '../../../../actions/formp';
import { cerrarModal } from '../../../../actions/ui';
import { useForm } from '../../../../hooks/useForm';
import { Campo } from '../../../../components/Formulario/Campo';
import { startSelecParticipDirecto } from '../../../../actions/bases';
import { TablaActividades } from './TablaActividades';
import { useEffect } from 'react';


export const ModalParticipanteDirecto = ({_titulo = ''}) => {
    const dispatch = useDispatch();

    const {ParticipanteDirectos} = useSelector( state => state.pReducer.pFocus);

    const {participDirectoSelect} = useSelector(state => state.basesReducer);

    const [participanteDirecto, setParticipanteDirecto] = useState(
        participDirectoSelect !== null  
            ? ParticipanteDirectos[participDirectoSelect]  
            : 
            { 
                nom : '',
                ActividadParticipantes : []
            }
    );




    const [showMsgFormInvalido, setShowMsgFormInvalido] = useState(false);

   
  

    const { 
        campos, 
        validaciones,
        handleCampoChange, 
        validarCampo,
        validarForm, 
        reset 
    } = useForm({nom : participanteDirecto ? participanteDirecto.nom : ''});

    useEffect(() => {
      setParticipanteDirecto({...participanteDirecto, nom : campos['nom']})
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campos])
    

    const handleSubmitParticipanteDirecto = (e)=>{
        e.preventDefault();
        if( campos['nom'] === '' ) {   setShowMsgFormInvalido(true);   return ;    }
        if(participanteDirecto.ActividadParticipantes.length < 1 ){  setShowMsgFormInvalido(true);   return;  }
        if(!validarForm(['nom'])){    setShowMsgFormInvalido(true);  return;  } 

        if(participDirectoSelect === null) {
            dispatch( 
                startEditarFormProyecto({
                    ParticipanteDirectos :  [ ...ParticipanteDirectos, participanteDirecto ]
                }) 
            );
        } else {
            dispatch( 
                startEditarFormProyecto({
                    ParticipanteDirectos : ParticipanteDirectos.map( (part,indice) => 
                        (indice === Number(participDirectoSelect)) 
                        ? participanteDirecto
                        : part
                    )
                })
            );
        }

        reset();

        dispatch(startSelecParticipDirecto(null));

        dispatch( cerrarModal());
        
    }
    
    const handleGuardarAct = (idAct,desc)=>{
        setParticipanteDirecto({
            ...participanteDirecto,
            ActividadParticipantes :  participanteDirecto.ActividadParticipantes.map((act,index) => index === idAct    ? ({...act,desc : desc})    : act    )
        });
         
     }
 
     const handleBorrarAct = (e,idAct)=>{
 
         e.preventDefault();
         
         let nuevoArrayActividades = [];
         
         if(idAct === 0) { 
             nuevoArrayActividades = participanteDirecto.ActividadParticipantes.slice(1);
         }
         else if(idAct === participanteDirecto.ActividadParticipantes.length -1) {
             nuevoArrayActividades = participanteDirecto.ActividadParticipantes.slice(0,idAct);
         }
         else {
             nuevoArrayActividades = [
                 ...participanteDirecto.ActividadParticipantes.slice(0,idAct),
                 ...participanteDirecto.ActividadParticipantes.slice(idAct+1)
             ]
         }
        
         setParticipanteDirecto({...participanteDirecto, ActividadParticipantes : nuevoArrayActividades });
     }

     const handleAgregarAct = (e)=>{
        e.preventDefault();
        setParticipanteDirecto({
            ...participanteDirecto,
            ActividadParticipantes :  [
                ...participanteDirecto.ActividadParticipantes,
                {
                    id : 0,
                    desc: '',
                    ParticipanteDirecto_Id : participanteDirecto.id ? participanteDirecto.id : 0,
                    valoracion : null
                    
                }
            ]
        } )
     }
  return (
    <div className='row row-cols-1'>
        <div className='col'>
            <h4>{_titulo}</h4>
            {
                showMsgFormInvalido && 
                <p className='alert alert-info d-flex justify-content-between p-1'>
                    Debe ingresar el nombre del participante y al menos una actividad. 
                    <button className='btn btn-link ' 
                        onClick={()=>setShowMsgFormInvalido(false)}
                    > x
                    </button>
                </p>
            }
            <hr/>
        </div>
        <div className='col'>
            <div className='row row-cols-2'>
                <div className='col-10'>
                    <Campo  
                        
                        etiqueta={'Partipantes (máx. 500 caracteres).'} 
                        name='nom' 
                        type = {'text'}
                        minLength={1} 
                        maxLength={500}
                        handlecampochange={handleCampoChange}
                        handleblur={validarCampo} 
                        value={campos['nom']}
                        valido={validaciones['nom']} 
                        required={true} 
                        enModal={true}
                    />
                    
                </div>
                <div className={`col-2  d-flex justify-content-center align-items-center`}  >
                    <button className={`btn btn-outline-primary `} 
                         onClick={handleAgregarAct}
                    >
                        Agregar Actividad
                    </button>
                    
                </div>
            </div>

        </div>
        <div className='col'>
                <TablaActividades 
                    actividadesParticipante={participanteDirecto.ActividadParticipantes} 
                    handleBorrarAct={handleBorrarAct}
                    handleGuardarAct={handleGuardarAct}
                />
            </div>
        <div className='col d-flex justify-content-center'>
           
            <button className='btn btn-primary ' onClick={handleSubmitParticipanteDirecto}>Guardar participante directo</button>
        </div>
    </div>
  )
}
