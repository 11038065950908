import React from 'react';
import {Route,Redirect} from 'react-router-dom';
import { useSelector } from 'react-redux';


export const RutaPublica = ({children,...rest}) => {

  const {logueado} = useSelector(state => state.usrReducer);


  return (

     
      <Route {...rest} >
        { ( !logueado )
            ? children
            : <Redirect to='/gestor'  /> 
                
        }
    </Route>
    
  )
}
