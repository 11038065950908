import React from 'react'
import { Campo } from '../../../components/Formulario/Campo';
import { Formulario } from '../../../components/Formulario/Formulario';
import { useFormProyectoSec4 } from './hooks/useFormProyectoSec4';
import { VistaPreviaFormProyecto } from '../VistaPreviaFormProyecto';

export const FormProyectoSec4 = ({_refVistaPrevia,_componenteVistaPrevia}) => {

   const {
    handleSubmitUltimaSeccion,
    campos,
    validaciones,
    handleCampoChange,
    validarCampo,
    lMaterialesEducativos,
    lIntegracion,
    lInstanciasCapacitacion,
    formularioBloqueado,
    pFocus
  } = useFormProyectoSec4();

  return (
    <Formulario
        idForm={pFocus.FormularioProyecto_id} 
        titulo={`Comunicación/Capacitación${pFocus.tipoProyecto === 'PEIS' ? '/Integralidad' : ''}/ Sustentabilidad/Síntesis`}
        imprimible={true}
        handleSubmit={handleSubmitUltimaSeccion}
        tipoFormulario={'p'}
        componenteVistaPrevia={<VistaPreviaFormProyecto ref={_refVistaPrevia} />}
        refVistaPrevia={_refVistaPrevia}
    >
        <div className='col mt-2'>
            <h4>{'10) Comunicación. Acciones y materiales.'}</h4>
            
            <Campo
                disabled={formularioBloqueado ? formularioBloqueado : undefined} 
                name='MaterialEducativo_id_MaterialEducativos'
                etiqueta={'10.1) ¿El proyecto producirá materiales educativos y/o de comunicación?'}
                type='select'
                multiple={true}
                opciones={lMaterialesEducativos || []}
                value={campos['MaterialEducativo_id_MaterialEducativos']} 
                valido={validaciones['MaterialEducativo_id_MaterialEducativos']}
                handlecampochange={handleCampoChange}
                handleblur={validarCampo}
                required={true}
                size={3}
            />
            <Campo
                disabled={formularioBloqueado ? formularioBloqueado : undefined} 
                name='accionesComunicacion'
                etiqueta={'10.2) Enunciar las acciones de comunicación y detallar qué tipo de materiales educativos y piezas comunicacionales se utilizarán (máx. 2000 caracteres).'}
                type='textarea'
                maxLength={2000}
                minLength={1} 
                value={campos['accionesComunicacion'] || ''} 
                valido={validaciones['accionesComunicacion']}
                handlecampochange={handleCampoChange}
                handleblur={validarCampo}
                required={true}
                style={{resize:'none'}}
                rows={12}
            />
        </div>
        {
            pFocus.tipoProyecto === 'PEIS' &&
            <div className='col'>
                <h4>{'11) Integralidad de funciones'}</h4>
                <Campo
                disabled={formularioBloqueado ? formularioBloqueado : undefined} 
                    name='tipoIntegracion'
                    etiqueta={'11.1) Tipo de integralidad'}
                    type='select'
                    opciones={lIntegracion || []}
                    value={campos['tipoIntegracion']} 
                    valido={validaciones['tipoIntegracion']}
                    handlecampochange={handleCampoChange}
                    handleblur={validarCampo}
                    required={true}
                />
                <Campo
                disabled={formularioBloqueado ? formularioBloqueado : undefined} 
                    name='integracionDesc'
                    etiqueta={'11.2) Explicar a través de qué acciones se desarrollará. (máx. 2000 caracteres).'}
                    type='textarea'
                    maxLength={2000}
                    minLength={1} 
                    value={campos['integracionDesc'] || ''} 
                    valido={validaciones['integracionDesc']}
                    handlecampochange={handleCampoChange}
                    handleblur={validarCampo}
                    required={true}
                    style={{resize:'none'}}
                    rows={12}
                />
            </div>
        }
        <div className='col'>
        <h4>{`${pFocus.tipoProyecto === 'PEIS' ? '12)' : '11)'} Capacitaciones`}</h4>
            <Campo
               disabled={formularioBloqueado ? formularioBloqueado : undefined} 
                name='InstanciaCapacitacion_id_InstanciaCapacitacions'
                etiqueta={`${pFocus.tipoProyecto === 'PEIS' ? '12.1)' : '11.1)'} Seleccionar al menos dos opciones que sean de interés para el equipo`}
                type='select'
                multiple={true}
                opciones={lInstanciasCapacitacion|| []}
                value={campos['InstanciaCapacitacion_id_InstanciaCapacitacions']} 
                valido={validaciones['InstanciaCapacitacion_id_InstanciaCapacitacions']}
                handlecampochange={handleCampoChange}
                handleblur={validarCampo}
                required={true}
                size={6}
            />
        </div>
        <div className='col'>
            <h4>{`${pFocus.tipoProyecto === 'PEIS' ? '13)' : '12)'} Sustentabilidad`}</h4>
            <Campo
               disabled={formularioBloqueado ? formularioBloqueado : undefined} 
                name='sustentabilidad'
                etiqueta={`${pFocus.tipoProyecto === 'PEIS' ? '13.1 ' : '12.1' } Explicar brevemente la continuidad en el tiempo de las tareas emprendidas una vez que finalice el financiamiento del Proyecto (máx. 2000 caracteres).`}
                type='textarea'
                maxLength={2000}
                minLength={1} 
                value={campos['sustentabilidad'] || ''} 
                valido={validaciones['sustentabilidad']}
                handlecampochange={handleCampoChange}
                handleblur={validarCampo}
                required={true}
                style={{resize:'none'}}
                rows={12}

            />
        </div>
        <div className='col'>
            <h4>{`${pFocus.tipoProyecto ==='PEIS' ? '14)' : '13)'} Síntesis`}</h4>
            <Campo
                disabled={formularioBloqueado ? formularioBloqueado : undefined} 
                name='sintesis'
                etiqueta={`${pFocus.tipoProyecto === 'PEIS' ? '14.1)' : '13.1)'} Breve síntesis del Proyecto de Extensión: problema, finalidad, población y ubicación temporal y espacial (máx. 2000 caracteres).`}
                type='textarea'
                maxLength={2000}
                minLength={1} 
                value={campos['sintesis'] || ''} 
                valido={validaciones['sintesis']}
                handlecampochange={handleCampoChange}
                handleblur={validarCampo}
                required={true}
                style={{resize:'none'}}
                rows={12}
            />
        </div>
    </Formulario>
  )
}
