import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

export const BotonActivarVistaPrevia = ({
    handleActivarVistaPrevia = ()=>{},
    activarVistaPrevia = false
}) => {
  return (
    <button className='btn btn-outline-secondary'  
        onClick={handleActivarVistaPrevia}  
        title={'Vista de Documento'}
    >  
        <FontAwesomeIcon  
                icon={ activarVistaPrevia
                    ? faEyeSlash 
                    : faEye 
                }
                size={'3x'}
        />
    </button>
  )
}
