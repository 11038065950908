
import validator from 'validator'

export const validarCamposInstInterv= ( campos = {})=>{

    let salida = {
        condicion : true,
        msg : ''
    };
    salida.condicion = salida.condicion && campos.dni && validator.isNumeric(campos.dni) && campos.dni.length > 7 && campos.dni.length < 9;
    console.log('dni',salida.condicion)
    if(!salida.condicion){
        salida.msg += 'dni inválido\n';    
        return salida;
    }
    
    salida.condicion = salida.condicion && campos.ape &&  validator.isAlpha(campos.ape,'es-ES',{ignore: ' \''}) && validator.isLength(campos.ape,{min : 1});
    console.log('apellido',salida.condicion)
    if(!salida.condicion){
        salida.msg += 'apellido inválido\n';    
        return salida;
    }
    
    salida.condicion = salida.condicion && campos.nom &&  validator.isAlpha(campos.nom,'es-ES',{ignore: ' \''}) && validator.isLength(campos.nom,{min : 1});
    console.log('nombre',salida.condicion)
    if(!salida.condicion){
        salida.msg += 'nombre inválido\n';    
        return salida;
    }
    
    salida.condicion = salida.condicion && campos.tel && validator.isLength(campos.tel,{min : 1, max : 150});
    console.log('tel',salida.condicion)
    if(!salida.condicion){
        salida.msg += 'tel./cel. inválido\n';    
        return salida;
    }
     
    salida.condicion = salida.condicion && campos.dom && validator.isLength(campos.tel,{min : 1, max : 150});
    console.log('domicilio',salida.condicion)
    if(!salida.condicion){
        salida.msg += 'domicilio inválido\n';    
        return salida;
    }
    
    salida.condicion = salida.condicion && campos.email && validator.isEmail(campos.email);
    console.log('correo',salida.condicion)
    if(!salida.condicion){
        salida.msg += 'correo electrónico inválido\n ';   
        return salida;
    }
    
    salida.condicion = salida.condicion && campos.nomInst &&  validator.isAlphanumeric(campos.nomInst,'es-ES',{ignore: '" °\'´'}) && validator.isLength(campos.nomInst,{min : 1});
    console.log('nombre',salida.condicion)
    if(!salida.condicion){
        salida.msg += 'nombre institución inválido\n';   
        return salida;
    }
  
    salida.condicion = salida.condicion && campos.telInst && validator.isLength(campos.tel,{min : 1, max : 150});
    console.log('tel',salida.condicion)
    if(!salida.condicion){
        salida.msg += 'tel./cel. institución inválido\n';
        return salida;
    }
    
    salida.condicion = salida.condicion && campos.domInst && validator.isLength(campos.tel,{min : 1, max : 150});
    console.log('domicilio',salida.condicion)
    if(!salida.condicion){
        salida.msg += 'domicilio institución inválido\n';   
        return salida;
    }
   
    salida.condicion = salida.condicion && campos.emailInst && validator.isEmail(campos.emailInst);
    console.log('correo',salida.condicion)
    if(!salida.condicion){
        salida.msg += 'correo electrónico institución inválido \n';    
        return salida;
    }
   
    salida.condicion = salida.condicion && campos.geolocalizacion && validator.isURL(campos.geolocalizacion);
    console.log('geolocalizacion',salida.condicion)
    if(!salida.condicion){
        salida.msg += 'geolocalizacion inválido\n';    
        return salida;
    }


    return salida;
}