import { useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { startEditarFormProyecto } from '../../../../../actions/formp';
import { obtenerCamposAValidarModalEquipoExtension } from '../../../../../helpers/obtenerCamposAValidarModalEquipoExtension';
import { useForm } from '../../../../../hooks/useForm'
import { cerrarModal } from '../../../../../actions/ui';
import { startSelecIntegEquipo } from '../../../../../actions/bases';
import { validarCamposIntegranteEquipo } from '../helpers/validacionesModalEquipoExtension';

export const useModalEquipoExtension = () => {
    const dispatch = useDispatch();
    
    const { pFocus } = useSelector( state => state.pReducer);
    const { integEquipoSelec } = useSelector( state => state.basesReducer);
    
    const [showMsgFormInvalido, setShowMsgFormInvalido] = useState(false);

    const integSelec = (integEquipoSelec !== null) &&  pFocus && pFocus.EquipoExtension.IntegranteEquipos 
    ? pFocus.EquipoExtension.IntegranteEquipos[integEquipoSelec]
    : undefined;


    const {
        lCategDedicacion,
        lCategDocente,
        lTipoInteg,
        lUnidadesAcademicas,
        lPeriodoLectivo,
        lAreas
    } = useSelector ( state => state.basesReducer);

    const getTipoInteg = ( )=>{
        if( !integSelec ) return '';

        const {categoriaDocente,periodoLectivo,titulo, unidadAcademica, area} = integSelec;

        if( categoriaDocente ) return 'doc';
        if( periodoLectivo ) return 'est';
        if( titulo && (!categoriaDocente) && (!periodoLectivo) ) return 'grad';
        if( (!titulo) && (!categoriaDocente) && (!periodoLectivo) && (unidadAcademica || area) ) return 'nodoc';
        return 'otro';
    }

    const getUbicacion = (tipoInteg = '')=>{
        if(tipoInteg === '') return '';
        if(tipoInteg === 'nodoc') return integSelec.unidadAcademica ? 0 : 1;
        return '';
    }

   
    const estadoInicialForm = {
        dni : integSelec && integSelec.dni  ? integSelec.dni : '',
        ape : integSelec && integSelec.ape  ? integSelec.ape : '',
        nom : integSelec && integSelec.nom  ? integSelec.nom : '',
        tel : integSelec && integSelec.tel  ? integSelec.tel : '',
        dom : integSelec && integSelec.dom  ? integSelec.dom : '',
        tieneTarjeta : integSelec && integSelec.tieneTarjeta  ? integSelec.tieneTarjeta : 0,
        unidadAcademica : integSelec && integSelec.unidadAcademica  ? integSelec.unidadAcademica : '',
        area : integSelec && integSelec.area  ? integSelec.area : '',
        categoriaDocente : integSelec && integSelec.categoriaDocente  ? integSelec.categoriaDocente : '',
        email : integSelec && integSelec.email  ? integSelec.email : '',
        titulo : integSelec && integSelec.titulo  ? integSelec.titulo : '',
        categoriaDedicacion : integSelec && integSelec.categoriaDedicacion  ? integSelec.categoriaDedicacion : '',
        tipoIntegrante : integSelec ? getTipoInteg()  : '',
        periodoLectivo : integSelec && integSelec.periodoLectivo ? integSelec.periodoLectivo : '',
        ubicacion :integSelec ? getUbicacion(getTipoInteg()) : '',
        observ : integSelec && integSelec.observ ? integSelec.observ : ''
    }

    const { 
        campos, 
        validaciones,
        handleCampoChange, 
        validarCampo,
        validarForm, 
        reset 
    } = useForm(estadoInicialForm);



    const {
        ubicacion,
        tipoIntegrante,
        ...camposPFocus
    }= campos;

    const definirCamposOcultos = (tipoInteg = '',_ubicacion)=>{
        let camposOcultos = [];
        if(tipoInteg === '') {
            camposOcultos = Object.keys(campos);
        }
        if(tipoInteg === 'est') {

            camposOcultos = ['ubicacion','tieneTarjeta','categoriaDocente' ,'categoriaDedicacion' ,'area' ];
        }
        if(tipoInteg === 'doc') {

            camposOcultos = ['ubicacion','area','periodoLectivo'];
        }
        if(tipoInteg === 'grad') {

            camposOcultos = ['ubicacion','area','periodoLectivo','categoriaDocente','categoriaDedicacion'];
        }
        if(tipoInteg === 'nodoc' && (_ubicacion === undefined)) {
            
            camposOcultos = ['periodoLectivo','categoriaDocente','categoriaDedicacion','titulo','unidadAcademica','area']
        }
        if(tipoInteg === 'nodoc' && (_ubicacion !== undefined)){

            camposOcultos = _ubicacion 
                ? ['periodoLectivo','categoriaDocente','categoriaDedicacion','titulo','unidadAcademica']
                : ['periodoLectivo','categoriaDocente','categoriaDedicacion','titulo','area'];
        }
        if(tipoInteg === 'otro'){
            
            camposOcultos = ['ubicacion','periodoLectivo','categoriaDocente','categoriaDedicacion','titulo','area','unidadAcademica'];
        }
        return camposOcultos;
    }

    

    const camposOcultos = useRef(definirCamposOcultos(getTipoInteg(),ubicacion));


    


 
    const handleSubmitModalEquipoExtension = (e)=>{
        e.preventDefault();
      
        
        if(!validarForm(obtenerCamposAValidarModalEquipoExtension(tipoIntegrante))){
            setShowMsgFormInvalido(true);
            return;
        }

        if( !validarCamposIntegranteEquipo(tipoIntegrante,campos) ){
            setShowMsgFormInvalido(true);
            return;
        }

        setShowMsgFormInvalido(false);

        let camposAInsertar = Object.keys(camposPFocus).reduce( 
            (campos,nomCampo) => (
                { 
                    ...campos , 
                    [nomCampo] : chequearVisibilidad(nomCampo) ? camposPFocus[nomCampo]  : null 
                }
            ) ,
            {} 
        )
        
        let nuevaData = {};

        if(integSelec !== undefined){
            nuevaData = {
                'EquipoExtension':{
                    ...pFocus['EquipoExtension'],
                    IntegranteEquipos : pFocus['EquipoExtension']['IntegranteEquipos'].map(
                      (integ,i) => i === integEquipoSelec ? {...integ,...camposAInsertar} : integ 
                      )
                }
            }

            dispatch(startSelecIntegEquipo(null));
        }
        if(integSelec === undefined) {
            nuevaData = {
                'EquipoExtension':{
                    ...pFocus['EquipoExtension'],
                    IntegranteEquipos : pFocus['EquipoExtension']['IntegranteEquipos']
                    ? [
                        ...pFocus['EquipoExtension']['IntegranteEquipos'],
                        {...camposAInsertar }
                      ]
                    : [{...camposAInsertar}]
                }
              }
            
            
            
            
        }

        dispatch( startEditarFormProyecto( nuevaData ) )
        reset();
        dispatch(cerrarModal());




    }
 

    const handleUbicacion = (e)=>{
        const ubicacion = e.target.value;
        camposOcultos.current = definirCamposOcultos(tipoIntegrante,Number(ubicacion));
        handleCampoChange(e);
       
    }

    const chequearVisibilidad = (nomCampo) =>  camposOcultos.current.every( campo => campo !== nomCampo  );;
     
    

    const handleTipoIntegChange = (e)=>{
        const tipoInteg = e.target.value;

        camposOcultos.current = definirCamposOcultos(tipoInteg);

        handleCampoChange(e);
         
    }


    

    return {
        handleSubmitModalEquipoExtension,
        campos, 
        chequearVisibilidad,
        validaciones,
        handleCampoChange, 
        handleUbicacion,
        handleTipoIntegChange,
        validarCampo,
        validarForm, 
        reset ,
        lCategDedicacion,
        lCategDocente,
        lTipoInteg,
        lUnidadesAcademicas,
        lPeriodoLectivo,
        lAreas,
        showMsgFormInvalido,
        integSelec,
        ubicacion,
        tipoIntegrante,
        setShowMsgFormInvalido

    }

}
