import { useContext,useRef } from "react";
import { useSelector } from 'react-redux';
import { ContextTabla } from "../components/Tabla/ContextTabla";
import { useRouteMatch} from 'react-router-dom';



/**
 * Custom Hook para obtener el value en tal fila de tabla
 * @returns los metodos para editar , eliminar, crear registros
 */

export const useContextTabla = () => {

  const tiposTablaGestionable = [ 'comision','programas','historial','usr','p']

  const {tipoUsuario} = useSelector( state => state.usrReducer.usrFocus);

  const path = useRef(useRouteMatch().path);
  
  const {handleBorrar,handleEditar,gestionable,tipo} = useContext(ContextTabla);

  const esRedireccionable = ()=>tipo === 'usr' || tipo === 'p' ;

  const esGestionable = ()=>tiposTablaGestionable.some( _tipo => tipo === _tipo);
  
  const sePuedeBorrar = ()=>
    handleBorrar &&
    tipoUsuario !== 'sec_unl'  && 
    tipoUsuario !== 'eval_ext' &&
    tipo !== 'programas'

  return {
    handleBorrar,
    handleEditar,
    esGestionable,
    esRedireccionable,
    gestionable,
    path,
    sePuedeBorrar,
    tipo,
    tipoUsuario
  }
}

