import React from 'react'
import { useSelector } from 'react-redux'
import { imprimirCampoRecursivo } from '../../helpers/imprimirCampoRecursivo';
import { pFocusToJson } from './helpers/pFocusToJson';

export const VistaPreviaFormProyecto = React.forwardRef((props, ref) => {
    
    const {pFocus} = useSelector( state => state.pReducer);

    const {ipFocus} = useSelector( state => state.ipReducer);
    
    const {
        basesReducer
    } = useSelector( state => state);


    const {
        lLineasTematicas,
        lProgramasExtension,
    } = basesReducer;

  
    const camposForm =  pFocusToJson(pFocus,basesReducer);   

    return (
    <div id='vista_previa_form_proy' className='container' ref={ref}>
    
        <div className='row row-cols-1'>
            <div className='col mb-4'>
                <ul className='list-group'>
                    <li className='list-group-item'> <b className='fs-5'>Titulo : </b> {ipFocus.titulo}  </li>
                    <li className='list-group-item'> <b className='fs-5'>Modalidad: </b> {pFocus.tipoProyecto}</li>
                    <li className='list-group-item'> <b className='fs-5'>UU.AA. : </b>{pFocus.unidadAcademica} </li>
                    <li className='list-group-item'> <b className='fs-5'>Linea/s temática/s</b> {pFocus.LineaTematica_id_LineaTematicas.map( linea => lLineasTematicas.find( _linea => _linea.id === linea).nom )} </li>
                    <li className='list-group-item'> <b className='fs-5'>Programas de extensión</b> {pFocus.ProgramaExtension_id_ProgramaExtensions.map( prog => lProgramasExtension.find( _prog => _prog.id === prog).nom )} </li>
                
                </ul>
            </div>
        
            <>
                {
                    Object.keys(camposForm).map( key => {
                        return (
                            <>
                                <div className='page-break'  media='print' />
                                <div className={`col mb-4 mt-4 contenido-sin-corte` } >
                                
                                    <h4 > { key } </h4>
                                    <div className='border border-secondary m-2 p-2'>
                                        
                                        {
                                            camposForm[key] &&  imprimirCampoRecursivo( camposForm[key], 4 )
                                        }
                                        
                                    </div>
                            
                                </div>
                                
                            </>
                      
                        
                    )
                       
                       
                }
                  
             )
                }  
            
            </>
        
        </div>
    </div>
  )
})
