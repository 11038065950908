
import { useDispatch, useSelector } from 'react-redux';
import { startEditarFormProyecto, startEditarInstitucionInterv } from '../../../../../actions/formp';
import { mostrarAlerta } from '../../../../../helpers/mostrarAlerta';
import { useGestionarUsuario } from '../../../../../hooks/useGestionarUsuario';
import { useGestionarEstados } from '../../../../../hooks/useGestionarEstados';

export const useFilaInstitucion = () => {

    const dispatch = useDispatch();
    
    const {InstitucionIntervinientes} = useSelector(state => state.pReducer.pFocus)
    
    const {usuarioEsAdmin, usuarioEsDueñoDelProyecto} = useGestionarUsuario();
    const {
        bloquearFormularioProyectoPorEstadoEvaluacion,
        proyectoEnEstadoRecepProyecto
    } = useGestionarEstados();

    const handleBorrarInstitucion = (e,_id)=>{
        e.preventDefault();

        if((!usuarioEsAdmin()) && (!usuarioEsDueñoDelProyecto()) ){
            alert('No posee permisos para realizar esta acción');
            return;
        }
        
        if(bloquearFormularioProyectoPorEstadoEvaluacion()){
            alert('No posee permisos para realizar esta acción');
            return;
        }
        mostrarAlerta('¿Desea Borrar la institución?','Borrar Inst. Interv.',true)
          .then( resp => resp.isConfirmed && dispatch( startEditarFormProyecto({
            InstitucionIntervinientes : InstitucionIntervinientes.filter( (inst,i) => i !== _id )
        })));
        
    }

    const handleEditarInstitucion = (e,_id)=>{
      e.preventDefault();


      if( (!usuarioEsAdmin()) && (!usuarioEsDueñoDelProyecto())){
              
          alert('No posee permisos para realizar esta acción');
          return;
      }
      
      if(bloquearFormularioProyectoPorEstadoEvaluacion()){
          alert('No posee permisos para realizar esta acción');
          return;
      }

      dispatch( startEditarInstitucionInterv(_id));
    }

  return{
    handleBorrarInstitucion,
    handleEditarInstitucion,
    usuarioEsAdmin,
    usuarioEsDueñoDelProyecto,
    proyectoEnEstadoRecepProyecto
  }
}
