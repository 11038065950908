export const formatearCamposParaImpresion = (campos)=>{
    if(!campos) return '';    

    return Object.keys(campos).reduce(
        (salida,nomCampo)=>{
            
            let value = ''

            if(nomCampo === 'Condición' && campos['Condición'] === 1) value = 'Aprobado';

            else if(nomCampo === 'Condición' && campos['Condición'] === 0 )value = 'No Aprobado';

            else if(nomCampo === 'Condición' && (campos['Condición'] === null) && campos['puntaje'] && campos['puntaje'] >= 6 ) value = 'Aprobado';

            else if(nomCampo === 'Condición' && (campos['Condición'] === null) &&  campos['puntaje'] && campos['puntaje'] < 6) value = 'No Aprobado';

            else if(nomCampo === 'puntaje' ) value = `${campos['puntaje']}`;

            else value = campos[nomCampo] instanceof Object
                ? formatearCamposParaImpresion(campos[nomCampo])
                : campos[nomCampo];

            return {
                ...salida,
                [nomCampo] : value
            }
           
        },
        {}
    )
  }

