import { types } from '../types/types'

const initialState = {
   
    usrFocus : {
        ape :'',
        nom : '',
        tipoUsuario : 'estandar',
    },
    logueado : false
}

export const usrReducer = ( state = initialState , action) => {
    switch (action.type) {
        case types.usrGetLista:
            return {
                ...state,
                listaUsr : [...action.payload]
            }
        case types.usrGetData : 
            return {
                ...state,
                usrFocus : {...action.payload},
                logueado : true
            }
        case types.usrEditar : 
            return {
                ...state,
                usrFocus : {
                    ...state.usrFocus,
                    ...action.payload
                }
            }
        case types.usrReset : 
            return initialState;
        default:
            return state;
    }
}
