

import { useDispatch, useSelector } from 'react-redux';
import { startEditarFormProyecto } from '../../../../../actions/formp';
import { abrirModalPalabrasClave } from '../../../../../actions/ui';
import { mostrarAlerta } from '../../../../../helpers/mostrarAlerta';
import { useGestionarEstados } from '../../../../../hooks/useGestionarEstados';
import {useGestionarUsuario} from '../../../../../hooks/useGestionarUsuario';
import { useEffect, useState } from 'react';


export const useListaPalabras = () => {

    const dispatch = useDispatch();

    const [showMsgError, setshowMsgError] = useState(false);

    const {pFocus} = useSelector(state => state.pReducer);
    
    const {
      chequearProyectoNuevo,
      bloquearFormularioProyectoPorEstadoEvaluacion
    } = useGestionarEstados();

    const {usuarioEsDueñoDelProyecto,usuarioEsAdmin} = useGestionarUsuario();
    
    useEffect(() => { 
      pFocus['PalabraClave_id_PalabraClaves'] && 
      pFocus['PalabraClave_id_PalabraClaves'].length < 3 
        ? setshowMsgError(true) 
        : setshowMsgError(false) 
      }, [pFocus])
    

    const handleAgregarPalabraClave = (e)=>{
        e.preventDefault();  

        if( bloquearFormularioProyectoPorEstadoEvaluacion() ){
                
            alert('No posee permisos para realizar esta acción');
          return;

        }   
        
        if(
          pFocus['PalabraClave_id_PalabraClaves'] && 
          pFocus['PalabraClave_id_PalabraClaves'].length === 5){

            alert('No puede cargar mas de 5 palabras');
            return;
        }

        dispatch(abrirModalPalabrasClave());
         
    }

    const handleEliminarPalbra = (e,_id)=>{
      e.preventDefault();

      if(  ( 
            (!chequearProyectoNuevo()) && 
            (!usuarioEsDueñoDelProyecto() ) &&
            (!usuarioEsAdmin()) 
          
            ) || bloquearFormularioProyectoPorEstadoEvaluacion() 
       ){
              
          alert('No posee permisos para realizar esta acción');
          return;
       }
      
      
      if(usuarioEsDueñoDelProyecto() || usuarioEsAdmin()){
        dispatch(startEditarFormProyecto({
          'PalabraClave_id_PalabraClaves' : 
              pFocus['PalabraClave_id_PalabraClaves'].filter( (pal,i)=> i !== _id) 
          }));
      }
      
      return;
    }

    const handleBorrarPalabrasClave = (e)=>{
      e.preventDefault();

      if((!bloquearFormularioProyectoPorEstadoEvaluacion())){
        mostrarAlerta('¿Desea eliminar todas las palabras clave?','Borrar palabras clave',true)
          .then( resp => resp.isConfirmed && 
              dispatch(startEditarFormProyecto({
                'PalabraClave_id_PalabraClaves' : []
              }))
            );
      }else{
        alert('No posee permisos para realizar esta acción');
      }
      
    }

  return{
    handleBorrarPalabrasClave,
    handleAgregarPalabraClave,
    handleEliminarPalbra,
    usuarioEsAdmin,
    usuarioEsDueñoDelProyecto,
    chequearProyectoNuevo,
    bloquearFormularioProyectoPorEstadoEvaluacion,
    showMsgError,
    pFocus
  }
}
