import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export const BotonImprimir = ({handlePrint = ()=>{}}) => {
  return (
    <div className='row d-flex justify-content-center'>
        <div className='col-1 btn btn-outline-secondary me-3'
            onClick={handlePrint}
        >
            <FontAwesomeIcon icon={faPrint} size={'2x'}/>
        </div>
    </div>
  )
}
