import { types } from "../types/types"

const initialState = {
    listaP : null,
    pcalFocus : null
}

export const pcalReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.epcalGetData : 
            return {
                ...state,
                pcalFocus : {...action.payload}
            }
        case types.epcalEditar : 
            return {
                ...state,
                pcalFocus : {
                    ...state.pcalFocus,
                    ...action.payload
                }
            }
        case types.epcalReset : 
            return {
                ...state,
                pcalFocus : initialState.pcalFocus
            };
        default:
            return state;
    }

}