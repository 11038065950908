import { mostrarAlerta } from "../helpers/mostrarAlerta";
import { types } from "../types/types";
import { startEditarEvaluacionCalidad } from "./epcal";
import { startEditarEvaluacionIpCA } from "./evipca";
import { 
    cerrarModal, 
    finalizarCargaDataBase, 
    finalizarCargaEvaluadores, 
    finalizarCargaInstCargadas, 
    finalizarCargaProyUlt3Anios, 
    informarEstadoQuery,
    iniciarCargaBases,
    iniciarCargaInstCargadas,
    iniciarCargaProyUlt3Anios
} from "./ui";
import download from 'downloadjs'



export const startMostrarListaEvalaudores = ()=>async(dispatch)=>{
    const resp = await fetch(`${process.env.REACT_APP_API_URL}/com/eval/all`);
    
    const data = await resp.json()
     
     if(data.ok){
         dispatch(cargarDataBase({
            lEvaluadores : data.data
         }));
         dispatch(finalizarCargaEvaluadores())
     }else{
         alert(data.error);
     }
}


export const startMostarBases = ()=>{
    return async(dispatch)=>{
        dispatch(informarEstadoQuery('iniciando gestor..'));
        dispatch(iniciarCargaBases());
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/base/bases`);
    
        const data = await resp.json()
       
        if(data.ok){
              

            dispatch(informarEstadoQuery('actualizando estado inicial..'));
        
            dispatch(cargarDataBase(data.data));
             
            dispatch(informarEstadoQuery('bases cargada ..'));
            dispatch(finalizarCargaDataBase());         
         }else{
             alert(data.error);
         }

        
    }
}

export const startSelecInstInterv = (_indice)=>{
    return async(dispatch)=>{
        dispatch( cargarDataBase({
            instIntervSelec : _indice
        }))
    }
}

export const startSelecIntegEquipo = (_indice)=>{
    return async(dispatch)=>{
        dispatch( cargarDataBase({
            integEquipoSelec : _indice
        }))
    }
}

export const startSelecParticipDirecto = (_indice)=>{
    return async(dispatch)=>{
        dispatch( cargarDataBase({
            participDirectoSelect : _indice
        }))
    }
}

export const startMostrarUltimoAviso = ()=>{

    return async(dispatch)=>{
      
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/base/aviso`);
        if(!resp.ok) {
            alert( 'Error de conexión con el servidor');
        }
        const data = await resp.json();

        if(!data.ok) {
            alert( 'Error de conexión con el servidor');
        }

        if(data.data){
          
            mostrarAlerta(data.data,'¡Aviso!',false);
            
        }
        dispatch(informarEstadoQuery(null));
    }
   
}

export const startMostarInstitucionesCargadas = ()=>{
    return async(dispatch)=>{
        dispatch( iniciarCargaInstCargadas());
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/base/bases/instit`)
    
        const data = await resp.json()
         
         if(data.ok){
             dispatch(cargarDataBase({
                lInstitucionesCargadas : data.data
             }));
             dispatch(informarEstadoQuery('instituciones cargadas..'));
         }else{
             alert(data.error);
         }
         dispatch(finalizarCargaInstCargadas());
    }
}

export const startMostrarProyUltTresAnios = ()=>{
    return async (dispatch)=>{
        dispatch(iniciarCargaProyUlt3Anios());
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/base/bases/ult3anios`);
        const data = await resp.json();

        if(data.ok){
            dispatch(cargarDataBase({
                lProyUlt3Anios : data.data
            }))
            dispatch(informarEstadoQuery('proy ult 3 años cargados..'));
        } else {
            alert(data.error)
        }
        dispatch(finalizarCargaProyUlt3Anios())
    }
}

export const startSubirFirmaDigital = (formData)=>{
    return async (dispatch,getState)=>{
        const {codigo} = getState().pReducer.pFocus;
       
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/base/firma/${codigo}`,{
            method : 'POST',
            body : formData
        })
       
        const data = await resp.json();

        if(data.ok){
            if(data.ok) {
                alert('doc cargado correctamente !');
                dispatch(cerrarModal())
            }
        } else {
            alert(data.error)
        }
    }
}

export const startCargarVistaPrevia = (src = '')=>{
    return async(dispatch)=>{
        
        dispatch(cargarDataBase({
            imgSrc : src
        }));

    }
}

export const startDescargarDocumento = (_codigoProy,_nameDoc)=>{
    return async()=>{

        const resp = await fetch(`${process.env.REACT_APP_API_URL}/base/doc/${_codigoProy}/${_nameDoc}`);

        if(resp.ok){
            const blob = await resp.blob();

            if(blob){
                download(blob);
            }
        } else {
            mostrarAlerta(resp.statusText,`Error ${resp.status}`,false)
        }

    }
}

export const startMostrarListaEvaluadoresFormEval = ()=>{
    return async(dispatch,getState)=>{


        const {eipcaFocus} = getState().eipcaReducer;
        const {lFormsEval} = getState().pReducer.pFocus;
        const {id : idUsr} = getState().usrReducer.usrFocus;
        
        const formEvaluacion = (lFormsEval && lFormsEval.find(({evaluador}) => evaluador === idUsr ));

        
        if(eipcaFocus === null && formEvaluacion !== undefined) {
            const resp = await fetch(`${process.env.REACT_APP_API_URL}/base/com/evals/CAL/${formEvaluacion.id}`);
            const data = await resp.json();

            if(data.ok){
                dispatch(startEditarEvaluacionCalidad({
                    listaEvaluadores : data.data
                }))
            } else {
                alert(data.error);
            }
        } else if(formEvaluacion !== undefined) {
            const resp = await fetch(`${process.env.REACT_APP_API_URL}/base/com/evals/CA/${formEvaluacion.id}`);
            const data = await resp.json();
            if(data.ok){
                dispatch(startEditarEvaluacionIpCA({
                    listaEvaluadores : data.data
                }))
            } else {
                alert(data.error);
            }
        }

        

    }
}

export const startGetEstructuraFormEvaluacion = ( estructura , setEstructura, tipoEvaluacion )=>{
    return async(dispatch,getState)=>{

        const {codigo} = getState().pReducer.pFocus;
        const tipoProyecto = codigo.split('-')[2];
        if((!estructura) && tipoProyecto){
            console.log('entrando en fetch...')
            const resp = await fetch(`${process.env.REACT_APP_API_URL}/${tipoEvaluacion}/estructura/${tipoEvaluacion === 'pcal' || tipoEvaluacion === 'ppert' ? tipoProyecto :'' }`);
            const data = await  resp.json();
            if(data.ok){
                setEstructura(data.data)
            } else {
                alert(data.error)
            }
        }
       
      
    }
}

const cargarDataBase = ( bases = {})=>({
    type : types.baseGetData,
    payload : bases
})



