import { types } from "../types/types"
import { FinalizarCargaIdeaProyecto, iniciarCargaIdeaProyecto, startFinishSavingForm, startSavingForm,  } from "./ui";


export const startCrearProyecto = ( history )=>{
    return async(dispatch,getState)=>{
        dispatch(startSavingForm());
        const { id : idUSr  } = getState().usrReducer.usrFocus;
        const dataIdeaProyecto = getState().ipReducer.ipFocus;
        const conv = getState().convReducer.convFocus.anio;

        const resp = await fetch(`${process.env.REACT_APP_API_URL}/p/${conv}`,{
            method : 'POST',
            headers: {
                'Content-Type' : 'application/json'
            },
            body :JSON.stringify({ idUsr : idUSr,dataIdeaProyecto })
           });

        const data = await resp.json();

        if(data.ok){
            alert('Proyecto creado correctamente');
            dispatch(startSavingForm());
            history.push('/');
        }else {
            dispatch(startSavingForm());
            alert(data.error)
        }
    }
}


export const startMostrarIdeaProyecto = ()=>{
    return async(dispatch,getState)=>{
       dispatch(iniciarCargaIdeaProyecto());
       const { IdeaProyecto_id } = getState().pReducer.pFocus;

       const resp = await fetch(`${process.env.REACT_APP_API_URL}/ip/${IdeaProyecto_id}`)
    
       const data = await resp.json();
        
        if(data.ok){
           
            dispatch(setIdeaProyecto(data.data));

        }else{
            alert(data.error);
        }
        dispatch(FinalizarCargaIdeaProyecto())
    }
}

export const startEditarFormIp = (data = {}) =>{
    return async(dispatch)=>{
        dispatch( editarFormIp(data));
    }
}

export const startGuardarIdeaProyecto = ()=>{
    return async(dispatch,getState)=>{
        dispatch(startSavingForm());
        const dataIdeaProyecto = getState().ipReducer.ipFocus;
        const {IdeaProyecto_id} = getState().pReducer.pFocus;
        const {id: idUsr} =  getState().usrReducer.usrFocus;;
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/ip`,{
            method : 'PUT',
            headers: {
                'Content-Type' : 'application/json'
            },
            body :JSON.stringify({
                idUsr : idUsr,
                id : IdeaProyecto_id,
                dataIdeaProyecto : dataIdeaProyecto
            })
        });

        const data = await resp.json();

        setTimeout(() => {
            if(data.ok) {
                dispatch(startFinishSavingForm());
                alert('datos guardados con exito');
                
            } else {
                dispatch(startFinishSavingForm());
                alert(data.error);
            } 
        }, 500);
    }
}

export const resetIp = ()=>({
    type: types.ipReset
})

export  const setTipoFormIp = ( tipo = '')=>({
    type : types.ipSetTipo,
    payload : tipo
})
const setIdeaProyecto = ( data = {})=>({
    type : types.ipGetData,
    payload : data
})

const editarFormIp = (data = {})=>({
    type : types.ipEditar,
    payload : data
})