import React from 'react'
import { Campo } from '../../../../../components/Formulario/Campo';
import { useListaAsigRolEquipo } from './hooks/useListaAsigRolEquipo';

export const ListaAsigRolEquipo = () => {

    
  const {
    campos,
    lRolInteg,
    handleCampoChange,
    formularioBloqueado,
    filtrarIntegrantesNoEstudiantes,
    validarCampo,
    validaciones,
    etiquetasIntegrantes
  } = useListaAsigRolEquipo();
    
  
  return (
    <ul className='list-group'>
        {
            campos && lRolInteg.filter( rol => ['esp','vol','becar'].every ( r => rol.codigo !== r) ).map(
                (rol,i) => 
                    <li className='list-group-item' key={`select-rol-${i}`} >
                        <Campo 
                            disabled= {formularioBloqueado ? formularioBloqueado : undefined}
                            etiqueta = { rol.nom }
                            secRoles={true}
                            name={rol.codigo}
                            type={'select'}
                            value={campos[rol.codigo]}
                            valido ={validaciones[rol.codigo]}
                            handlecampochange={handleCampoChange}
                            handleblur={validarCampo}
                            opciones={
                                etiquetasIntegrantes.sort( (opc1,opc2) => opc1.nom.localeCompare(opc2.nom) ) 
                            }
                            multiple={false}
                        />
                    </li>
            )
        }

        {
            campos && <li className='list-group-item' >
             <Campo 
                 disabled= {formularioBloqueado ? formularioBloqueado : undefined}
                 etiqueta = { 'Especialista/s asociado/s' }
                 secRoles = {true}
                 name={'esp'}
                 type={'select'}
                 multiple={true}
                 value={campos['esp']}
                 valido ={validaciones['esp']}
                 handlecampochange={handleCampoChange}
                 handleblur={validarCampo}
                 opciones={filtrarIntegrantesNoEstudiantes()}
                
             />
         </li>
        }
    </ul>
  )
}
