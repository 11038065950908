import { types } from "../types/types";
import { startFinishLoadingForm, startLoadingForm } from "./ui";


export const startMostrarEvaluacionIpCA = (idForm = null)=>{
    return async(dispatch,getState)=>{
        dispatch(startLoadingForm())
        
        const {codigo} = getState().pReducer.pFocus;
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/eipca/data/${codigo}/${idForm}`)
     
        const data = await resp.json()
         
         if(data.ok){
             dispatch(setEvaluacionIpCA(data.data));
            
         }else{
             alert(data.error);
         }

        setTimeout(() => {
            dispatch(startFinishLoadingForm())
        }, 600);
     }
}

export const startEditarEvaluacionIpCA = (campos = {})=>{
    return async(dispatch)=>{
        dispatch(editarEvaluacionIpCA(campos));
       
    }
}

export const startGuardarEvaluacionIpCA = (_idForm = null)=>{
    return async(dispatch,getState)=>{
        const {
            item1,
            item2,
            item3,
            item4,
            item5,
            item6,
            tipoPertinencia,
            
        } = getState().eipcaReducer.eipcaFocus;
        const {id : idUsr} = getState().usrReducer.usrFocus;
        const {id : idProy} = getState().pReducer.pFocus;
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/eipca/`,{
            method : 'PUT',
            headers: {
                'Content-Type' : 'application/json'
            },
            body :JSON.stringify({
                idUsr : idUsr,
                idProy : idProy,
                id : _idForm,
                dataEvaluacion : {
                    item1,
                    item2,
                    item3,
                    item4,
                    item5,
                    item6,
                    tipoPertinencia
                }
            })
        });

        const data = await resp.json();

        setTimeout(() => {
            if(data.ok) {
                alert('datos guardados con exito');
            } else {
                alert(data.error);
            } 
        });
    }
}

const setEvaluacionIpCA = (data ={})=>({
    type : types.eipcaGetData,
    payload : data
})

const editarEvaluacionIpCA = (data ={})=>({
    type : types.eipcaEditar,
    payload : data
})

export const resetEipca = ()=>({
    type : types.eipcaReset
})