import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startEditarInforme } from '../../actions/informe';

export const Integralidad = () => {
  const dispatch = useDispatch();
  const {infFinalFocus} = useSelector(state => state.infFinalReducer)
  const {integralidad} = infFinalFocus;
  const handleOnChange = (e)=>{
    e.preventDefault();

    dispatch( 
      startEditarInforme(
        {
            ...infFinalFocus,
            integralidad : e.target.value
        },
        true
      )
    );
   
   
  }

  return (
    <div className='row row-cols-1 m-3'>

    <div className='col'>
       <h5>¿ Se pudo alcanzar la integralidad de funciones propuesta en el proyecto ?</h5>
       <small> En caso de contestar que no, mencione el/los motivo/s.</small>
    </div>
     
    <div className='col'> 
      <textarea className='form-control' 
        name='observ' 
        value={integralidad} 
        rows={6} 
        style={{resize : 'none'}}
        onChange={handleOnChange}
      />
    </div>

  </div>
  )
}
