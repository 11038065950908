import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useFormAdjuntarDocumentos } from '../hooks/useFormAdjuntarDocumentos'
export const ItemConstanciaDirector = () => {
    const {validarConstanciaDirectorPresentada,handleVerAval} = useFormAdjuntarDocumentos();
    
  return (
    <li className='list-group-item bg-transparent' > 
        {'Constancia de Director/a del Proyecto firmada (formato .pdf). '} 
        <a href='https://drive.google.com/file/d/1QMSAdMW6tbnVAh2l5h0nrzoS85ShH6O2/view?usp=share_link'
          onClick={handleVerAval}
        > Descargue el modelo aquí </a>
        {  validarConstanciaDirectorPresentada() &&  <FontAwesomeIcon className='ms-4' icon={faCheck}size={'2x'} color={ 'green' }  />  }
    </li>
  )
}
