import React from 'react';

import { ListaAsigRolEquipo } from './ListaRolesEquipoExtension/ListaAsigRolEquipo';
import { ListaIntegrantesEquipoExtension } from './ListaIntegrantesEquipoExtension/ListaIntegrantesEquipoExtension';
import { useEquipoExtension } from './hooks/useEquipoExtension';
import { BotonAgregarIntegrante } from './BotonAgregarIntegrante';

export const SeccionEquipoExtension = () => {

  const { showMsgError,proyectoEnEstadoRecepProyecto} = useEquipoExtension();
  return (
    <div className='row row-cols-1 mb-5'>
        <div className='col'>
            <p className='fs-5'>{'3.2 ) Integrantes '}</p>
            <div className='row'>
                <div className='col-9'>
                  <p className='text-muted'> 
                    Una vez agregados, se podrá asignar el rol que ocupa dentro del equipo. 
                    Es obligatoria la asignación de un director/a y un/a responsable financiero/a como mínimo.
                  </p>
                  { 
                    showMsgError ?   <p className='text-danger' >    Debe cargar al menos 2 </p>: <></>
                  }
                </div>

                <div className='col-3 text-end'> 
                  
                  { proyectoEnEstadoRecepProyecto() ? <BotonAgregarIntegrante /> : <></> }
                   
                </div>
            </div>
        </div>
        <div className='col mt-3'>
          <div className='row row-cols-2'>
            <div className='col-7 '>
                <ListaIntegrantesEquipoExtension />
            </div>
            <div className='col-5'>
                <div className=' row bg-light p-3'>
                    <div className='col text-center'> 
                      ROL
                    </div> 
                </div>
                <ListaAsigRolEquipo />
            </div>
          </div>
        </div>
    </div>
  )
}
